<template lang="pug">
.video-image-uploader
    .uploader-container
        .preparing-asset(v-if='preparingAsset')
            h5 {{ $t('editor.preparingAsset') }}
            LoadingSpinner
        .uploader(
            :class='{ allow: isAllowedFile }',
            @dragenter='isAllowedFile = true',
            @dragleave='isAllowedFile = false',
            @dragover.prevent,
            @drop='onDrop',
            v-else
        )
            input.upload-input(
                :accept='acceptExtensions',
                @change='handleFileUpload',
                @click='$event.stopPropagation()',
                ref='uploadInput',
                type='file',
                v-show='!file'
            )
            .progress(v-if='isLoadingMedia')
                SVGRenderer(
                    :fill-color='"var(--editor-primary-color)"',
                    :has-hover='false',
                    :icon='eclipseLoaderIcon',
                    width='25'
                )
                span(v-if='mediaProgress') {{ mediaProgress }}%
            KetchUpButton.editor(@click.native='onFileUploaderClick')
                h5 {{ buttonText }}
            h5 {{ $t('editor.dragAndDropHere', { mediaSize }) }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import type { Nullable } from '@/services/interfaces/Content'
  import heic2any from 'heic2any'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import * as UpChunk from '@mux/upchunk'
  import CourseApi from '@/services/api/CourseApi'
  import * as Sentry from '@sentry/vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

  const props = defineProps({
    videoOnly: {
      type: Boolean,
      default: false,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    audioOnly: {
      type: Boolean,
      default: false,
    },
    zipOrPdfOnly: {
      type: Boolean,
      default: false,
    },
    fileType: {
      required: true,
      type: String,
    },
  })

  const emit = defineEmits(['image-file', 'audio-url', 'video-url', 'download_data'])

  const { eclipseLoaderIcon } = useIcons()
  const { translateString } = useI18n()

  const file = ref(null as Nullable<File>)
  const uploadInput = ref(null as Nullable<HTMLInputElement>)
  const isAllowedFile = ref(false)
  const isLoadingMedia = ref(false)
  const mediaProgress = ref(0)
  const uploadData = ref(null as Nullable<{ id: string; url: string }>)
  const preparingAsset = ref(false)

  const buttonText = computed(() => {
    if (props.audioOnly) return translateString('editor.SelectAnAudioFile')
    if (props.videoOnly) return translateString('editor.SelectAvideo')
    if (props.imageOnly) return translateString('editor.SelectAnImage')
    if (props.zipOrPdfOnly) return translateString('editor.selectAZipOrPdfFile')
    return translateString('editor.SelectImageOrVideo')
  })

  const acceptExtensions = computed(() => {
    const audioExtensions = '.m4a, .mp3, .wav, .wma, .aac'
    const videoExtensions = '.mp4, .mov, .wmv, .flv, .avi'
    const imageExtensions = '.svg, .jpg, .jpeg, .gif, .png, .bmp, .webp, .heic'
    const zipOrPdfExtensions = '.zip, .pdf'
    if (props.audioOnly) return audioExtensions
    if (props.videoOnly) return videoExtensions
    if (props.imageOnly) return imageExtensions
    if (props.zipOrPdfOnly) return zipOrPdfExtensions
    return `${imageExtensions}, ${videoExtensions}`
  })

  const onFileUploaderClick = () => {
    if (!file.value) {
      uploadInput.value!.click()
    }
  }
  const handleFileUpload = () => {
    file.value = uploadInput.value!.files![0]
    processFile()
  }

  const videoSize = computed(() => process.env.VUE_APP_MUX_VIDEO_UPLOAD_SIZE_LIMIT_MB)
  const audioSize = computed(() => process.env.VUE_APP_MUX_AUDIO_UPLOAD_SIZE_LIMIT_MB)
  const imageSize = computed(() => process.env.VUE_APP_IMAGE_UPLOAD_SIZE_LIMIT_MB)
  const zipOrPdfSize = computed(() => process.env.VUE_APP_ZIP_OR_PDF_UPLOAD_SIZE_LIMIT_MB)
  const s3BucketName = computed(() => process.env.VUE_APP_S3_BUCKET_NAME)

  const mediaSize = computed(() => {
    if (props.videoOnly) return videoSize.value
    if (props.audioOnly) return audioSize.value
    if (props.zipOrPdfOnly) return zipOrPdfSize.value
    return imageSize.value
  })

  const processFile = async () => {
    if (!file.value || isLoadingMedia.value) return

    const ext = fileExtension(file.value!.name)
    if (!isSupportedExtension(file.value!.name)) return

    if (!isFileSizeAllowed(ext, file.value.size)) {
      file.value = null
      return
    }
    isLoadingMedia.value = true

    if (isImageExtension(ext)) {
      await uploadAndCreateImgPreview(ext)
    } else if (isVideoExtension(ext) || isAudioExtension(ext)) {
      try {
        uploadData.value = await CourseApi.getMuxUploadData()
        uploadMediaToMux()
      } catch (err) {
        eventBus.$toasted.error(translateString('editor.messages.failedToGenerateUploadUrl'))
        isLoadingMedia.value = false
      }
    } else if (isZipOrPdfExtension(ext)) {
      try {
        await uploadZipFileAndPreviewDownloadUrl()
      } catch (err) {
        eventBus.$toasted.error(translateString('editor.messages.failedToGenerateUploadUrl'))
        isLoadingMedia.value = false
      }
    }
  }

  const fileExtension = (fileUrl: string) => {
    return fileUrl.split('.').slice(-1)[0]
  }

  const isSupportedExtension = (fileUrl: string) => {
    if (props.audioOnly) return isAudioExtension(fileExtension(fileUrl))
    if (props.videoOnly) return isVideoExtension(fileExtension(fileUrl))
    if (props.imageOnly) return isImageExtension(fileExtension(fileUrl))
    if (props.zipOrPdfOnly) return isZipOrPdfExtension(fileExtension(fileUrl))
    return isVideoExtension(fileExtension(fileUrl)) || isImageExtension(fileExtension(fileUrl))
  }

  const isAudioExtension = (extension: string) => {
    return ['m4a', 'mp3', 'wav', 'wma', 'aac'].includes(extension.toLowerCase())
  }

  const isVideoExtension = (extension: string) => {
    return ['mp4', 'mov', 'wmv', 'flv', 'avi'].includes(extension.toLowerCase())
  }

  const isImageExtension = (extension: string) => {
    return ['svg', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'heic'].includes(extension.toLowerCase())
  }

  const isZipOrPdfExtension = (extension: string) => ['zip', 'pdf'].includes(extension.toLowerCase())

  const isFileSizeAllowed = (ext: string, size: number) => {
    let limit = Number(videoSize.value) || 0
    let isAllowed = size / 1024 / 1024 <= limit
    if (isAudioExtension(ext)) {
      limit = Number(audioSize.value) || 0
      isAllowed = size / 1024 / 1024 <= limit
    }
    if (isImageExtension(ext)) {
      limit = Number(imageSize.value) || 0
      isAllowed = size / 1024 / 1024 <= limit
    }
    if (!isAllowed) eventBus.$toasted.error(translateString('editor.messages.fileSizeAboveMaxLimit', { size: limit }))
    return isAllowed
  }

  const uploadAndCreateImgPreview = async (ext: string) => {
    const formData = new FormData()
    formData.append('file', file.value!)
    formData.append('fileType', props.fileType)
    try {
      const imageData = await CourseApi.uploadData(formData)
      if (ext.toLowerCase() === 'heic') {
        heic2any({ blob: file.value as Blob }).then((conversionResult) => {
          emit('image-file', JSON.stringify(imageData), URL.createObjectURL(conversionResult as Blob))
        })
      } else {
        emit('image-file', JSON.stringify(imageData), URL.createObjectURL(file.value!))
      }
    } catch (err) {
      eventBus.$toasted.error(translateString('editor.messages.failedToGenerateImageData'))
      file.value = null
    } finally {
      isLoadingMedia.value = false
    }
  }

  const onDrop = (e: DragEvent) => {
    e.preventDefault()
    file.value = e.dataTransfer!.files[0]
    if (!file.value || !isSupportedExtension(file.value?.name || '')) {
      isAllowedFile.value = false
      file.value = null
      eventBus.$toasted.error(translateString('editor.messages.unsupportedFileType'))
      return
    }
    processFile()
  }

  const uploadMediaToMux = () => {
    const upload = UpChunk.createUpload({
      endpoint: uploadData.value!.url,
      file: file.value!,
      chunkSize: 5120, // Uploads the file in ~256kb chunks
    })

    // subscribe to events
    upload.on('error', (err) => {
      Sentry.captureException(err.detail)
      file.value = null
    })

    upload.on('progress', (progress) => {
      mediaProgress.value = Number(Number(progress.detail).toFixed(2))
    })

    upload.on('success', async () => {
      const { assetId, playbackIds } = await CourseApi.getMuxPlaybackIds(uploadData.value!.id)

      if (props.audioOnly) {
        emit('audio-url', `https://stream.mux.com/${playbackIds[0].id}.m3u8`)
      } else {
        await waitForAssetToBeReady(assetId)

        preparingAsset.value = false
        emit('video-url', `https://stream.mux.com/${playbackIds[0].id}.m3u8`)
      }
      isLoadingMedia.value = false
    })
  }

  const waitForAssetToBeReady = async (assetId: string) => {
    let { status } = await CourseApi.getMuxAssetStatus(assetId)

    while (status !== 'ready') {
      preparingAsset.value = true
      await new Promise((resolve) => setTimeout(resolve, 2000)) // Wait for 2 seconds
      status = (await CourseApi.getMuxAssetStatus(assetId)).status
    }
  }

  const uploadZipFileAndPreviewDownloadUrl = async () => {
    const formData = new FormData()
    formData.append('file', file.value!)
    formData.append('fileType', props.fileType)

    try {
      const fileData = await CourseApi.uploadData(formData)
      const url = `https://s3.eu-central-1.amazonaws.com/${s3BucketName.value}/${fileData.id}`
      if (fileData.id) {
        eventBus.$toasted.success(translateString('editor.uploadDownloadFileSuccessfully'))
      }
      emit('download_data', JSON.stringify(fileData), url)
    } catch (err) {
      eventBus.$toasted.error(translateString('editor.messages.failedToGenerateImageData'))
      file.value = null
    } finally {
      isLoadingMedia.value = false
    }
  }
</script>

<style lang="postcss">
  .video-image-uploader {
    .uploader-container {
      @apply ketch-bg-editor-primary-color ketch-bg-opacity-5 ketch-h-c150 ketch-p-c16;
      @apply ketch-rounded-normal;
      .preparing-asset {
        @apply ketch-flex ketch-items-center ketch-flex-col ketch-justify-center ketch-space-y-c10 ketch-h-full;
      }
      .uploader {
        @apply ketch-flex ketch-relative ketch-items-center ketch-justify-center ketch-h-full ketch-rounded-normal;
        @apply ketch-border ketch-border-transparent hover:ketch-border-dashed hover:ketch-border-editor-primary-color;
        @apply ketch-bg-editor-primary-color ketch-bg-opacity-10;

        &.allow {
          @apply ketch-border-dashed ketch-border-editor-primary-color;
        }
        > h5 {
          @apply ketch-text-editor-primary-color ketch-text-opacity-80 ketch-bottom-c10;
          @apply ketch-absolute;
        }
        button h5 {
          @apply ketch-font-bold;
        }
        .progress {
          @apply ketch-absolute ketch-bottom-c10 ketch-right-c10 ketch-flex ketch-flex-col;
          @apply ketch-items-center ketch-justify-center ketch-space-y-c5;
          span {
            @apply ketch-text-editor-primary-color;
          }
        }
        .upload-input {
          position: fixed;
          top: -100vh;
        }
      }
    }
  }
</style>
