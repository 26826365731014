<template lang="pug">
.number-input
    input(
        :autofocus='shouldAutoFocus',
        :placeholder='$t("courses.examSimulation.writeYourAnswerHere")',
        :readonly='readonly',
        data-cy='number-input',
        ref='inputRef',
        required,
        type='text',
        v-model='numberInput'
    )
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    value: {
      type: String,
      default: '',
    },
    shouldAutoFocus: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['formatted_value'])

  const { convertNumberAnswer } = useCommonMixin()

  const inputRef = ref(null as HTMLInputElement | null)
  const numberInput = ref(props.value)

  watch(numberInput, (value) => {
    numberInput.value = convertNumberAnswer(value)
    emit('formatted_value', numberInput.value)
  })
</script>

<style lang="postcss" scoped>
  .number-input {
    input {
      @apply ketch-w-full ketch-h-c50 ketch-rounded-normal ketch-border ketch-border-primary-text-color;
      @apply ketch-px-c20 ketch-placeholder-secondary-text-color;
      @apply focus:ketch-outline-none focus:ketch-border-primary-color;
    }
    &.correct {
      input {
        @apply ketch-border-[#28bd2e] ketch-text-[#28bd2e];
      }
    }
    &.incorrect {
      input {
        @apply ketch-border-[#ff0044] ketch-text-[#ff0044];
      }
    }
  }
</style>
