<template lang="pug">
.leuckentext-answers-pane
    .answers-pane-header
        h5 {{ $t('courses.leuckentext.dragAppropriateTerms') }}
    .answers-pane-body
        .pane-choices
            h6(v-html='$t("courses.leuckentext.termsSearchedFor", { termsLength: choices.length })')
            .answer-choices
                .answer-choice(
                    :class='{ "not-selectable": choice.id === draggedChoice || !choice.selectable }',
                    :draggable='choice.selectable',
                    :key='choice.id',
                    @dragend='onDragEnd',
                    @dragover.prevent,
                    @dragstart='onDragStart(choice.id)',
                    @drop.prevent,
                    v-for='choice in choices'
                )
                    .choice-drag-icon
                        SVGRenderer(
                            :fill-color='"var(--primary-foreground-color)"',
                            :has-hover='false',
                            :icon='menuIcon',
                            height='15',
                            width='15'
                        )
                    h5 {{ choice.text }}
        .pane-cta
            h5(
                @click='$emit("reset-all-answers")',
                v-if='showResetCTA'
            ) Reset
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import type { PropType } from 'vue'
  import type { LeuckentextChoice } from '@/services/interfaces/Course'

  const props = defineProps({
    choices: {
      type: Array as PropType<LeuckentextChoice[]>,
      required: true,
    },
    inResultsMode: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['dragged-answer', 'drag-end', 'reset-all-answers'])

  const { menuIcon } = useIcons()
  const draggedChoice = ref('')

  const showResetCTA = computed(() => {
    return props.inResultsMode ? false : props.choices.some((choice) => !choice.selectable)
  })

  const onDragStart = (choiceId: string) => {
    draggedChoice.value = choiceId
    emit('dragged-answer', choiceId)
  }

  const onDragEnd = () => {
    draggedChoice.value = ''
    emit('drag-end')
  }
</script>

<style lang="postcss" scoped>
  .leuckentext-answers-pane {
    @apply ketch-py-c15 ketch-px-c10 ketch-bg-module-selector-background-color ketch-rounded-r-normal;
    @screen md {
      flex: 0 0 255px;
    }
    .answers-pane-header {
      @apply ketch--mx-c10 ketch-pb-c10 ketch-px-c10 ketch-mb-[7px];
      @apply ketch-border-b ketch-border-primary-color ketch-border-opacity-25;
      h5 {
        @apply ketch-font-bold ketch-max-w-[175px];
      }
    }
    .answers-pane-body {
      @apply ketch-flex ketch-flex-col ketch-space-y-[125px];
      .pane-choices {
        .answer-choices {
          @apply ketch-mt-c20 ketch-flex ketch-flex-col ketch-space-y-c10;
          .answer-choice {
            @apply ketch-py-c8 ketch-px-c10 ketch-bg-primary-color ketch-text-primary-foreground-color ketch-rounded-normal;
            @apply ketch-flex ketch-items-center ketch-justify-center ketch-cursor-pointer ketch-relative;
            .choice-drag-icon {
              @apply ketch-absolute ketch-left-[10px];
            }
            &.not-selectable {
              @apply ketch-cursor-auto ketch-opacity-20;
            }
          }
        }
      }
      .pane-cta {
        @apply ketch-flex ketch-justify-end ketch-h-c20;
        h5 {
          @apply ketch-cursor-pointer ketch-underline;
        }
      }
    }
  }
</style>
