<template lang="pug">
.followup-modal
    h5.description(
        v-html='$t("editor.followup.modalDescription", { title: currentEditorLesson.title || "" })',
        v-if='currentEditorLesson'
    )
    .date-container
        h5.date-label {{ $t('editor.followup.dateLabel') }}
        DatePicker(
            format='DD.MM.YYYY',
            type='date',
            v-model='selectedDate'
        )
    TextRenderer(
        :can-discard-local-changes='false',
        :edit-title='$t("editor.followup.comment")',
        :editor-state='`followup-${currentEditorLesson.id}`',
        :mutate-and-queue-func='mutateAndQueueFunc',
        :source='(followUp && followUp.comment) || ""',
        editor-property='comment'
    )
    .buttons-container
        KetchUpButton.editor(
            :disabled='isSaving',
            @click.native='onSave'
        )
            LoadingSpinner(v-if='isSaving')
            template(v-else)
                SVGRenderer(
                    :has-hover='false',
                    :icon='checkmark',
                    height='10px',
                    stroke-color='var(--editor-foreground-color)'
                )
                h5 {{ $t('editor.saveAllChanges') }}
        KetchUpButton.tertiary(@click.native='onCancel')
            SVGRenderer(
                :has-hover='false',
                :icon='closeIcon',
                height='16px',
                stroke-color='var(--primary-text-color)',
                width='16px'
            )
            h5 {{ $t('cancel') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import useEditor from '@/composables/useEditor'
  // @ts-ignore
  import DatePicker from 'vue2-datepicker'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useCourse from '@/composables/useCourse'
  import type { EditorStatePayload } from '@/services/interfaces/Course'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import { EditorModule } from '@/store/modules/editor'
  import * as Sentry from '@sentry/vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const { followUp, currentEditorLesson } = useEditor()
  const { checkmark, closeIcon } = useIcons()
  const { lessonId, currentCourseId, moduleId } = useCourse()
  const { translateString } = useI18n()
  const emit = defineEmits(['close'])

  const selectedDate = ref<Date | null>(followUp.value?.date ? new Date(followUp.value?.date) : null)
  const isSaving = ref(false)
  const comment = ref(followUp.value?.comment || '')

  const followUpPayload = computed(() => ({
    lessonId: lessonId.value,
    date: `${selectedDate.value?.getFullYear() ?? ''}-${((selectedDate.value?.getMonth() ?? 0) + 1)
      .toString()
      .padStart(2, '0')}-${selectedDate.value?.getDate().toString().padStart(2, '0')}`,
    comment: comment.value,
  }))

  const onSave = async () => {
    if (selectedDate.value && comment.value) {
      isSaving.value = true
      const isEditing = !!followUp.value?.id
      try {
        if (followUp.value?.id) {
          await CourseApi.updateFollowup(followUp.value!.id, followUpPayload.value)
        } else {
          await CourseApi.createFollowup(followUpPayload.value)
        }
        const lesson = await CourseApi.getLesson(currentCourseId.value, moduleId.value, lessonId.value)
        CourseModule.setCurrentLesson(lesson)
        EditorModule.setCurrentLesson(lesson)
        if (isEditing) eventBus.$toasted.success(translateString('editor.messages.followupUpdatedSuccessfully'))
        else eventBus.$toasted.success(translateString('editor.messages.followupCreatedSuccessfully'))
      } catch (err) {
        Sentry.captureException(err)
      } finally {
        isSaving.value = false
        emit('close')
      }
    }
  }

  const onCancel = () => {
    eventBus.$emit('close-modal')
  }

  const mutateAndQueueFunc = (data: EditorStatePayload) => {
    comment.value = data.value as string
  }
</script>

<style lang="postcss">
  .followup-modal {
    .description span {
      @apply ketch-font-bold;
    }
    .date-container {
      @apply ketch-my-c20 ketch-flex ketch-items-center ketch-justify-between;
      .date-label {
        @apply ketch-font-bold;
      }
      .mx-datepicker input {
        @apply focus:ketch-border-secondary-color;
      }
    }
    .edit-title-header h5 {
      @apply ketch-text-primary-text-color;
    }
    .buttons-container {
      @apply ketch-flex ketch-justify-between ketch-mt-c30;
      button {
        @apply ketch-w-auto ketch-space-x-c10 ketch-px-c10;
        &.editor h5 {
          @apply ketch-font-bold;
        }
      }
    }
  }
</style>
