import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import { ThemeState } from '@/services/interfaces/Theme'

@Module({ name: 'theme', store, dynamic: true })
class Theme extends VuexModule implements ThemeState {
  public theme: 'light' | 'dark' = localStorage.theme || 'light'

  @Action({ commit: 'SET_THEME' })
  toggleTheme() {
    if (this.theme === 'dark') {
      return 'light'
    }
    return 'dark'
  }

  @Mutation
  SET_THEME(theme: 'light' | 'dark') {
    this.theme = theme
    localStorage.userTheme = theme
  }
}

export const ThemeModule = getModule(Theme)
