import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { NewsItem, Nullable } from '@/services/interfaces/Content'
import CuratedContentApi from '@/services/api/CuratedContentApi'
import store from '../index'

@Module({ name: 'news', store, dynamic: true })
class News extends VuexModule {
  public newsContent: Nullable<NewsItem[]> = null
  public newNewsContentLength = 0

  @Mutation
  SET_NEWS_CONTENT(news: NewsItem[]) {
    this.newsContent = news
  }

  @Mutation
  SET_NEW_NEWS_CONTENT_LENGTH(newContentLength: number) {
    this.newNewsContentLength = newContentLength
  }

  @Action({ commit: 'SET_NEWS_CONTENT' })
  getNewsContent() {
    return CuratedContentApi.getNews()
      .then((news) => news)
      .catch(() => null)
  }

  @Action({ commit: 'SET_NEW_NEWS_CONTENT_LENGTH' })
  setNewNewsContentLength(newContentLength: number) {
    return newContentLength
  }

  @Action({ commit: 'SET_NEW_NEWS_CONTENT_LENGTH' })
  resetNewNewsContentLength() {
    return 0
  }
}

export const NewsModule = getModule(News)
