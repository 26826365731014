<template lang="pug">
.company-onboarding-popup
    h3.title-with-underline(v-if='onboardingPopupDetails.title') {{ onboardingPopupDetails.title }}
    h5.subtitle(v-if='onboardingPopupDetails.subtitle') {{ onboardingPopupDetails.subtitle }}
    .popup-video
        VideoPlayer(
            :poster-image='popupPosterImage',
            :video-source='onboardingPopupDetails.videoUrl',
            content-type='company_onboarding_popup'
        )
    HTMLRenderer.h5(
        :html='onboardingPopupDetails.outroText || ""',
        link-target='_blank'
    )
    .popup-cta
        KetchUpButton.tertiary(@click.native='$emit("close")')
            h5 {{ $t('okThankYou') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import useVideo from '@/composables/useVideo'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import type { PropType } from 'vue'
  import type { CompanyOnboardingPopupDetails } from '@/services/interfaces/Auth0'

  const props = defineProps({
    onboardingPopupDetails: {
      type: Object as PropType<CompanyOnboardingPopupDetails>,
      required: true,
    },
  })
  defineEmits(['close'])

  const { getPosterImage } = useVideo()

  const popupPosterImage = computed(() => {
    return (
      props.onboardingPopupDetails.posterImageUrl ??
      getPosterImage(
        props.onboardingPopupDetails.videoUrl,
        props.onboardingPopupDetails.timestamp ? props.onboardingPopupDetails.timestamp : 0,
      )
    )
  })
</script>

<style lang="postcss">
  .company-onboarding-popup {
    .title-with-underline {
      @apply ketch-mb-c10 ketch-pb-c10;
    }
    .popup-video {
      @apply ketch-my-c30;
      .video-player {
        @apply sm:ketch-max-h-[360px];
        .plyr {
          @apply ketch-rounded-large;
        }
      }
    }
    .popup-cta {
      @apply ketch-flex ketch-justify-end ketch-mt-c20;
      button {
        @apply ketch-w-auto;
      }
    }
  }
</style>
