<template lang="pug">
.lesson-download-material(
    :class='{ disabled: updatingCourseDownloadables }',
    @click='updateCourseDownloadMaterials'
)
    .icon-status
        SVGRenderer(
            :has-hover='false',
            :icon='checkedCheckboxSquare',
            stroke-color='var(--primary-text-color)',
            v-if='isLessonInDownloadMaterials',
            width='20'
        )
        SVGRenderer(
            :has-hover='false',
            :icon='checkboxSquare',
            stroke-color='var(--primary-text-color)',
            v-else,
            width='20'
        )
    h5 {{ $t('editor.addToCourseDownloadMaterials') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import CourseApi from '@/services/api/CourseApi'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { EditorCoursePayload } from '@/services/interfaces/Course'

  const { course, currentLesson } = useCourse()
  const { checkedCheckboxSquare, checkboxSquare } = useIcons()
  const { translateString } = useI18n()
  const updatingCourseDownloadables = ref(false)

  const isLessonInDownloadMaterials = computed(() => {
    return !!course.value!.downloadables?.find((l) => l.id === currentLesson.value!.id)
  })

  const updateCourse = (payload: any) => {
    CourseApi.updateCourse(course.value!.id, payload)
      .then((resp) => {
        if (resp === 200) {
          eventBus.$toasted.success(translateString('editor.updatedCourseDownloadables'))
        }
      })
      .finally(() => (updatingCourseDownloadables.value = false))
  }

  const updateCourseDownloadMaterials = () => {
    updatingCourseDownloadables.value = true
    const payload: any = {
      course: {} as EditorCoursePayload,
    }
    if (isLessonInDownloadMaterials.value) {
      const index = course.value!.downloadables!.findIndex((l) => l.id === currentLesson.value!.id)
      course.value!.downloadables!.splice(index, 1)
      payload.course.downloadables = course.value!.downloadables!.map((l) => ({ lessonId: l.id }))

      updateCourse(payload)
    } else {
      course.value!.downloadables!.push(currentLesson.value!)
      payload.course.downloadables = course.value!.downloadables!.map((l) => ({ lessonId: l.id }))

      updateCourse(payload)
    }
  }
</script>

<style lang="postcss" scoped>
  .lesson-download-material {
    @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-cursor-pointer;
    &.disabled {
      @apply ketch-opacity-50 ketch-pointer-events-none;
    }
  }
</style>
