import { render, staticRenderFns } from "./ExamResultsCompletions.vue?vue&type=template&id=c197d3ae&scoped=true&lang=pug"
import script from "./ExamResultsCompletions.vue?vue&type=script&setup=true&lang=ts"
export * from "./ExamResultsCompletions.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ExamResultsCompletions.vue?vue&type=style&index=0&id=c197d3ae&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c197d3ae",
  null
  
)

export default component.exports