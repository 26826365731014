<template lang="pug">
.feedback-modal
    h5.description(v-html='$t("feedback.modalDescription")')
    textarea(
        :placeholder='$t("feedback.placeholderMessage")',
        ref='messageBox',
        v-model='feedback.comment'
    )
    .bottom-section(:class='{ "no-rating": !showRating }')
        .rating-box(v-if='showRating')
            h5 {{ $t('feedback.optionalRating', { rating: hoverIndex || feedback.rating || '-' }) }}
            .stars
                SVGRenderer(
                    :fill-color='isDarkMode ? "#111122" : "white"',
                    :icon='starIcon',
                    :key='star',
                    :toggle-hover='star <= (hoverIndex || feedback.rating)',
                    @click.native='feedback.rating = star',
                    @mouseleave.native='hoverIndex = 0',
                    @mouseover.native='hoverIndex = star',
                    fill-hover-color='var(--primary-color)',
                    stroke-color='var(--border-color)',
                    stroke-hover-color='var(--primary-color)',
                    v-for='star in 5',
                    width='24'
                )
        KetchUpButton.primary(@click.native='onFeedbackSubmit')
            LoadingSpinner(v-if='submittingFeedback')
            h5(v-else) {{ $t('feedback.sendFeedback') }}
</template>

<script setup lang="ts">
  import { onMounted, reactive, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseApi from '@/services/api/CourseApi'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import useEditor from '@/composables/useEditor'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { Feedback } from '@/services/interfaces/Common'
  import type { Nullable } from '@/services/interfaces/Content'

  const props = defineProps({
    lessonId: {
      type: String,
      required: true,
    },
    lessonName: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
    questionTitle: {
      type: String,
      required: true,
    },
    showRating: {
      type: Boolean,
      default: true,
    },
  })

  const emit = defineEmits(['close'])

  const { starIcon } = useIcons()
  const { isDarkMode, stripHtmlTagsFromString } = useCommonMixin()
  const { lessonFeedbackProvided, questionFeedbackProvided } = useSegment()
  const { course, currentCourseId, currentModule, moduleId } = useCourse()
  const { decodeAndParseSource } = useEditor()
  const { translateString } = useI18n()

  const feedback = reactive({
    comment: '',
    rating: 0,
    lessonId: props.lessonId,
  } as Feedback)
  const hoverIndex = ref(0)
  const submittingFeedback = ref(false)
  const messageBox = ref(null as Nullable<HTMLTextAreaElement>)

  const postLessonFeedback = () => {
    CourseApi.postLessonFeedback(currentCourseId.value, moduleId.value, feedback).finally(() => {
      const rating = feedback.rating > 0 ? feedback.rating : null
      lessonFeedbackProvided(
        currentCourseId.value,
        course.value!.title,
        moduleId.value,
        currentModule.value.name,
        props.lessonId,
        props.lessonName,
        feedback.comment,
        rating,
      )
    })
  }

  const postQuestionFeedback = () => {
    CourseApi.postQuestionFeedback(
      props.questionId,
      stripHtmlTagsFromString.value(decodeAndParseSource(props.questionTitle)),
      feedback.comment,
    ).finally(() => {
      questionFeedbackProvided(
        currentCourseId.value,
        course.value!.title,
        props.questionId,
        stripHtmlTagsFromString.value(decodeAndParseSource(props.questionTitle)),
        feedback.comment,
      )
    })
  }

  const onFeedbackSubmit = async () => {
    if (feedback.comment) {
      submittingFeedback.value = true
      if (props.lessonId && props.lessonName) {
        await postLessonFeedback()
      } else {
        await postQuestionFeedback()
      }
      submittingFeedback.value = false
      eventBus.$toasted.success(translateString('feedback.successMessage'))
      emit('close')
    } else {
      eventBus.$toasted.error(translateString('feedback.errorMessage'))
    }
  }

  onMounted(() => {
    if (messageBox.value) {
      messageBox.value.focus()
    }
  })
</script>

<style lang="postcss">
  .feedback-modal {
    .description {
      @apply ketch-mb-c25;
    }
    textarea {
      @apply ketch-rounded-normal ketch-w-full ketch-h-c120 ketch-border ketch-border-border-color;
      @apply ketch-text-primary-text-color ketch-outline-none ketch-p-c20 ketch-resize-none;
      &::placeholder {
        @apply ketch-text-primary-text-color ketch-text-opacity-60;
      }
    }
    .bottom-section {
      @apply ketch-flex ketch-justify-between ketch-mt-c10;
      &.no-rating {
        @apply ketch-justify-end;
      }
      .rating-box {
        @apply ketch-flex ketch-flex-col ketch-space-y-c5;
        .stars {
          @apply ketch-flex ketch-space-x-c2;
          svg {
            @apply ketch-cursor-pointer;
          }
        }
      }
    }
  }
  .feedback-modal textarea {
    @apply dark:ketch-bg-gray-600;
  }
</style>
