<template lang="pug">
.feedback-container(@click='onClick')
    SVGRenderer(
        :has-hover='false',
        :icon='feedbackIcon',
        secondary-stroke-color='var(--primary-text-color)',
        stroke-color='var(--primary-color)',
        v-if='!isSmallerMobileDevice',
        width='60'
    )
    .text-icon
        .text
            h5.bold {{ $t('feedback.title') }}
            h5 {{ feedbackDescription }}
        SVGRenderer(
            :has-hover='false',
            :icon='arrowRight',
            stroke-color='var(--primary-text-color)',
            width='12'
        )
</template>

<script setup lang="ts">
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import eventBus from '@/main'

  const props = defineProps({
    lessonId: String,
    lessonName: String,
    questionId: String,
    questionTitle: String,
    feedbackModalTitle: {
      type: String,
      required: true,
    },
    feedbackDescription: {
      type: String,
      required: true,
    },
    showRating: {
      type: Boolean,
      default: true,
    },
  })
  const { feedbackIcon, arrowRight } = useIcons()
  const { isSmallerMobileDevice } = useBreakpoint()

  const onClick = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'FeedbackModal',
      cssClass: 'feedback-modal',
      modalTitle: props.feedbackModalTitle,
      modalProps: {
        lessonId: props.lessonId || '',
        lessonName: props.lessonName || '',
        showRating: props.showRating,
        questionId: props.questionId || '',
        questionTitle: props.questionTitle || '',
      },
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }
</script>

<style lang="postcss">
  .feedback-container {
    @apply ketch-rounded-large ketch-p-c15 sm:ketch-p-c30 ketch-bg-primary-color ketch-bg-opacity-5;
    @apply ketch-flex ketch-space-x-c20 ketch-cursor-pointer;
    > svg {
      flex: 0 0 60px;
    }
    .text-icon {
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-space-x-c20 ketch-flex-1;
      .text {
        @apply ketch-space-y-c10 ketch-text-primary-text-color;
        .bold {
          @apply ketch-font-bold;
        }
      }
    }
  }
</style>
