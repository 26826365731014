import axios from './interceptor'
import { Subscription, PremiumSubscription, Coupon, BillingInfo, BillingHistory } from '../interfaces/Checkout'

export default class CheckoutApi {
  static async createSession(payload: Subscription): Promise<{ sessionId: string }> {
    return axios
      .post('/create_session', payload)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postOneTimeBillingInfo(payload: BillingInfo): Promise<{ clientSecret: string }> {
    return axios
      .post('/course_payment_intent', payload)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async premiumSubscription(): Promise<PremiumSubscription> {
    return axios
      .get('/premium_subscription')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async couponDetails(couponCode: string): Promise<Coupon> {
    return axios
      .get(`/coupons/${couponCode}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async billingHistory(): Promise<BillingHistory[]> {
    return axios
      .get('/billing')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async createShopifyCheckout(productVariantId: string, email: string | null): Promise<{ checkoutUrl: string }> {
    return axios
      .post('create_checkout', {
        productVariantId,
        email,
      })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async createSubscriptionCheckout(
    productVariantId: string,
    email: string | null,
  ): Promise<{ checkoutUrl: string }> {
    return axios
      .post(
        `https://ketchup-idd-sub-staging-529373ef1194.herokuapp.com/create_subscription_checkout/${productVariantId}`,
        {
          email,
        },
      )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }
}
