import { render, staticRenderFns } from "./CreateCourseModal.vue?vue&type=template&id=88f31394&scoped=true&lang=pug"
import script from "./CreateCourseModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./CreateCourseModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CreateCourseModal.vue?vue&type=style&index=0&id=88f31394&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88f31394",
  null
  
)

export default component.exports