<template lang="pug">
.analytics-wrapper
    MixpanelAnalyticsLoader(v-if='loadingInsights')
    template(v-else)
        .analytics-header-overview
            .analytics-header-description
                h1.analytics-header {{ $t('analytics.analysisAndEvaluations') }}
                h5.analytics-description {{ $t('analytics.analysisAndEvaluationsDescription') }}
            .analytics-date-range
                .date-toggle(
                    :class='{ active: dateRangeActive === date.value }',
                    :key='index',
                    @click='dateRangeActive = date.value',
                    v-for='(date, index) in datesToToggle'
                )
                    h5 {{ date.text }}
        .analytics-overview-data-wrapper(v-if='mappedDataPoints.length')
            .analytics-overview-data(
                :key='index',
                v-for='(data, index) in mappedDataPoints'
            )
                .overview-data-point-icon
                    SVGRenderer(
                        :fill-color='"var(--primary-color)"',
                        :has-hover='false',
                        :icon='heart',
                        height='20',
                        width='20'
                    )
                .overview-data-point
                    .data-points
                        h2.actual-data-point {{ data.total }}
                        h6.percentage-increase-or-decrease(:class='{ increase: data.increase }') {{ data.percentage }}
                    h5.data-point-description {{ eventText(data.eventName) }}
        .analytics-charts-new-users
            .daily-active-users-chart(ref='dailyActiveUsersChart')
                .daily-active-users-chart__header-info
                    .user-activity-text-days-range
                        h2.user-activity-text {{ $t('analytics.userActivity') }}
                        h5.days-range {{ $t('analytics.inTheLastXDays', { days: dateRangeActive }) }}
                    .daily-active-users
                        h3 {{ $t('analytics.xAverageUsersPerDay', { users: dailyActiveUsersAverage }) }}
                        h5 {{ $t('analytics.average') }}
                PlaceholderShimmer.analytics-overview-data(
                    :animate='true',
                    height='450px',
                    v-if='!chartData'
                )
                BarChart(
                    :chart-data='chartData',
                    v-else
                )
            .top-viewed-lessons(
                :style='{ height: dailyActiveUsersChartHeight ? `${dailyActiveUsersChartHeight}px` : "auto" }'
            )
                .top-viewed-lessons__header-info
                    h2 {{ $t('analytics.topViewedLessons') }}
                    h5 {{ $t('analytics.topViewedLessonsDescription', { days: dateRangeActive }) }}
                TopLessonsViewedLoader(v-if='fetchingTopLessonsViewed')
                .top-viewed-lessons__list(
                    :class='{ "no-lessons": !topLessonsViewed.length }',
                    v-else
                )
                    h5.no-viewed-lesson(v-if='!topLessonsViewed.length') {{ $t('analytics.noLessonsViewed') }}
                    .viewed-lesson(
                        :key='item.lessonId',
                        @click='showLessonModal(item)',
                        v-else,
                        v-for='item in topLessonsViewed'
                    )
                        .lesson-course-name
                            h5.lesson-name {{ item.lessonName }}
                            h6.course-name {{ course(item.courseId) ? course(item.courseId).title : '-' }}
                        h6.lesson-views {{ item.count }}
</template>

<script setup lang="ts">
  import { computed, ref, watch, nextTick } from 'vue'
  import AnalyticsApi from '@/services/api/AnalyticsApi'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import BarChart from '@/components/common/BarChart.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { DateTime } from 'luxon'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import TopLessonsViewedLoader from '@/components/loaders/TopLessonsViewedLoader.vue'
  import MixpanelAnalyticsLoader from '@/components/loaders/MixpanelAnalyticsLoader.vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { LessonViewed } from '@/services/interfaces/Analytics'

  const { heart } = useIcons()
  const {
    formatDate,
    formatNumberToGermanLocale,
    isSachkundeCompany,
    getColorVariableValue,
    setIsLoadingComponentViewData,
  } = useCommonMixin()
  const { fetchCourses, showStartCourseForFreeModal, openLessonModal } = useCourse()
  const { translateString } = useI18n()
  const loadingInsights = ref(true)
  const mappedDataPoints = ref([] as { eventName: string; total: string; percentage: string; increase: boolean }[])
  const signUpsDataPoints = ref([] as { [key: string]: number }[])
  const dailyActiveUsersDataPoints = ref([] as { [key: string]: number }[])
  const lessonsViewed = ref([] as { [key: string]: number }[])
  const guidedQuizQuestionAnswered = ref([] as { [key: string]: number }[])
  const examQuestionAnswered = ref([] as { [key: string]: number }[])
  const combinedQuestionsAnsweredData = ref([] as { [key: string]: number }[])
  const dateRangeActive = ref(7)
  const dailyActiveUsersChartLabels = ref([] as string[])
  const dailyActiveUsersChartData = ref([] as number[])
  const previousDailyActiveUsersAverage = ref(0)
  const fetchingTopLessonsViewed = ref(false)
  const topLessonsViewed = ref([] as LessonViewed[])
  const dailyActiveUsersChart = ref(null as HTMLDivElement | null)
  const dailyActiveUsersChartHeight = ref(0)

  const dataEvents = ['user_signed_up', 'daily_active_users', 'lessons_viewed', 'questions_answered']

  const datesToToggle = [
    {
      text: '7D',
      value: 7,
    },
    {
      text: '30D',
      value: 30,
    },
    {
      text: '3M',
      value: 90,
    },
  ]

  const totalEventData = computed(() => (event: string) => {
    if (event === 'user_signed_up') {
      return getTotalFromSingleRange(signUpsDataPoints.value)
    } else if (event === 'daily_active_users') {
      if (!dailyActiveUsersChartData.value.length) return 0
      return dailyActiveUsersAverage.value
    } else if (event === 'lessons_viewed') {
      return getTotalFromSingleRange(lessonsViewed.value)
    }
    return getTotalFromSingleRange(combinedQuestionsAnsweredData.value)
  })

  const dataEventPercentageIncreaseOrDecrease = computed(() => (event: string) => {
    const value = previousEventTotalData.value(event)
    return value > 0 ? `+${value}%` : `${value}%`
  })

  const previousEventTotalData = computed(() => (event: string) => {
    if (event === 'user_signed_up') {
      return getPercentage(getTotalFromTwoRanges(signUpsDataPoints.value), totalEventData.value(event))
    } else if (event === 'daily_active_users') {
      return getPercentage(previousDailyActiveUsersAverage.value, dailyActiveUsersAverage.value)
    } else if (event === 'lessons_viewed') {
      return getPercentage(getTotalFromTwoRanges(lessonsViewed.value), totalEventData.value(event))
    }
    return getPercentage(getTotalFromTwoRanges(combinedQuestionsAnsweredData.value), totalEventData.value(event))
  })

  const dailyActiveUsersAverage = computed((): number => {
    if (!dailyActiveUsersChartData.value.length) return 0
    const activeUsersTotal = dailyActiveUsersChartData.value.reduce((a, b) => a + b)
    return Math.floor(activeUsersTotal / dailyActiveUsersChartData.value.length)
  })

  const eventText = computed(() => (eventName: string) => {
    if (eventName === 'user_signed_up') return translateString('analytics.newUsers')
    if (eventName === 'daily_active_users') return translateString('analytics.usersActiveOnAverage')
    if (eventName === 'lessons_viewed') return translateString('analytics.lessonsViewed')
    return translateString('analytics.questionsAnswered')
  })

  const chartData = computed(() => {
    return {
      labels: dailyActiveUsersChartLabels.value,
      datasets: [
        {
          label: translateString('analytics.dailyActiveUsers'),
          backgroundColor: [getColorVariableValue.value('--primary-color')],
          data: dailyActiveUsersChartData.value,
        },
      ],
    }
  })

  const course = computed(() => (courseId: string) => {
    const course = CourseModule.courses?.find((course) => course.id === courseId)
    return course ? course : null
  })

  const setMappedDataPoints = () => {
    mappedDataPoints.value = []
    dataEvents.forEach((event) => {
      mappedDataPoints.value.push({
        eventName: event,
        total: formatNumberToGermanLocale.value(totalEventData.value(event)),
        percentage: dataEventPercentageIncreaseOrDecrease.value(event),
        increase: previousEventTotalData.value(event) > 0 || previousEventTotalData.value(event) === 0,
      })
    })
  }

  const getTotalFromTwoRanges = (data: { [p: string]: number }[]) => {
    if (!(data.length > 0)) return 0
    let total = 0
    let slicedData: { [p: string]: number }[] = []
    if (data.length >= 2 * dateRangeActive.value) {
      slicedData = data.slice(2 * -dateRangeActive.value, -dateRangeActive.value)
    } else if (data.length < 2 * dateRangeActive.value && data.length >= dateRangeActive.value) {
      slicedData = data.slice(-dateRangeActive.value)
    } else if (data.length < dateRangeActive.value) {
      slicedData = data
    }
    slicedData.forEach((item) => {
      total += Object.values(item).reduce((a, b) => a + b)
    })
    return total
  }

  const getTotalFromSingleRange = (data: { [p: string]: number }[]) => {
    if (!(data.length > 0)) return 0
    let total = 0
    if (data.length > dateRangeActive.value) {
      data.slice(-dateRangeActive.value).forEach((item) => (total += Object.values(item).reduce((a, b) => a + b)))
    } else {
      data.forEach((item) => (total += Object.values(item).reduce((a, b) => a + b)))
    }
    return total
  }

  const getPercentage = (previousValue: number, currentValue: number): number => {
    if (!previousValue) return 0
    return Math.floor(((previousValue - currentValue) / currentValue) * 100)
  }

  const replaceQuotes = (str: string) => str.replace(/'/g, '')

  const sortDataByDate = (data: { [p: string]: number }[]) => {
    return data.sort(
      (a, b) =>
        // @ts-ignore
        DateTime.fromFormat(`${replaceQuotes(Object.keys(a)[0])}`, 'LLL dd yy') -
        // @ts-ignore
        DateTime.fromFormat(`${replaceQuotes(Object.keys(b)[0])}`, 'LLL dd yy'),
    )
  }

  const setSignUpsDataPoints = (data: any) => {
    // here we remove the $overall entry which is in the 0 index
    const actualSignupsDataEntriesObj = data.slice(1, data.length)

    const signupsDataObj = Object.fromEntries(actualSignupsDataEntriesObj)
    const formattedData = Object.keys(signupsDataObj).reduce(
      (acc, curr) => {
        if (signupsDataObj[curr]) {
          // @ts-ignore
          acc.push({ [curr]: signupsDataObj[curr]['all'] })
          return acc
        }
        return acc
      },
      [] as { [key: string]: number }[],
    )

    // sorting the data by date
    signUpsDataPoints.value = sortDataByDate(formattedData)
  }

  const setDailyActiveUsersData = (data: any) => {
    dailyActiveUsersDataPoints.value = Object.keys(data).reduce(
      (acc, curr) => {
        if (data[curr]) {
          acc.push({ [curr]: data[curr] })
          return acc
        }
        return acc
      },
      [] as { [key: string]: number }[],
    )
  }

  const setLessonsViewedData = (data: any) => {
    // here we remove the $overall entry which is in the 0 index
    const actualLessonsViewedEntriesObj = data.slice(1, data.length)

    const lessonsViewedObj = Object.fromEntries(actualLessonsViewedEntriesObj)
    const formattedData = Object.keys(lessonsViewedObj).reduce(
      (acc, curr) => {
        if (lessonsViewedObj[curr]) {
          // @ts-ignore
          acc.push({ [curr]: lessonsViewedObj[curr]['all'] })
          return acc
        }
        return acc
      },
      [] as { [key: string]: number }[],
    )

    // sorting the data by date
    lessonsViewed.value = sortDataByDate(formattedData)
  }

  const setQuestionsAnsweredData = (data: any, entity: 'exam' | 'guided-quiz') => {
    // here we remove the $overall entry which is in the 0 index
    const actualQuestionsAnsweredObj = data.slice(1, data.length)

    const questionsAnsweredObj = Object.fromEntries(actualQuestionsAnsweredObj)
    const formattedData = Object.keys(questionsAnsweredObj).reduce(
      (acc, curr) => {
        if (questionsAnsweredObj[curr]) {
          // @ts-ignore
          acc.push({ [curr]: questionsAnsweredObj[curr]['all'] })
          return acc
        }
        return acc
      },
      [] as { [key: string]: number }[],
    )

    // sorting the data by date
    if (entity === 'guided-quiz') {
      guidedQuizQuestionAnswered.value = sortDataByDate(formattedData)
    } else if (entity === 'exam') {
      examQuestionAnswered.value = sortDataByDate(formattedData)
    }
  }

  const setCombinedQuestionsAnsweredData = () => {
    const spreadQuestionsAnsweredArray = [...guidedQuizQuestionAnswered.value, ...examQuestionAnswered.value]

    const result = spreadQuestionsAnsweredArray.reduce(
      (acc, curr) => {
        const key = Object.keys(curr)[0]
        if (acc[key]) {
          acc[key] += curr[key]
        } else {
          acc[key] = curr[key]
        }
        return acc
      },
      {} as { [key: string]: number },
    )

    combinedQuestionsAnsweredData.value = Object.keys(result).map((key) => ({ [key]: result[key] }))
  }

  const setDailyActiveUsersChartData = (range: number) => {
    dailyActiveUsersDataPoints.value.slice(-range).forEach((item) => {
      dailyActiveUsersChartData.value.push(Object.values(item)[0])
      dailyActiveUsersChartLabels.value.push(formatDate(Object.keys(item)[0], 'dd/MM'))
    })
  }

  const setPreviousDailyActiveUsersAverage = (range: number) => {
    if (!(dailyActiveUsersDataPoints.value.length > 0)) return 0
    const slicedData = dailyActiveUsersDataPoints.value.slice(2 * -range, -range)
    let total = 0
    slicedData.forEach((item) => (total += Object.values(item).reduce((a, b) => a + b)))
    previousDailyActiveUsersAverage.value = Math.floor(total / slicedData.length)
  }

  const showLessonModal = async (item: LessonViewed) => {
    if (item.lessonId && item.moduleId && item.courseId) {
      const _course = course.value(item.courseId)
      if (_course) {
        if (_course.purchased || _course.trial) {
          openLessonModal({
            lessonId: item.lessonId,
            courseId: item.courseId,
            moduleId: item.moduleId,
          })
        } else {
          eventBus.$emit('turn-on-loader')
          await CourseModule.getCourseModules(_course.id)
          eventBus.$emit('turn-off-loader')
          showStartCourseForFreeModal(_course.id)
        }
      }
    }
  }

  // will be added to the company data
  const signedUpUsersReportId = computed(() => {
    if (isSachkundeCompany.value) return 30678448
    return 30924692
  })

  const getSignedUpUsersDate = AnalyticsApi.getInsightsReports(signedUpUsersReportId.value)

  // will be added to the company data
  const dailyActiveUsersReportId = computed(() => {
    if (isSachkundeCompany.value) return 30682359
    return 30924731
  })

  const getDailyActiveUsersData = AnalyticsApi.getInsightsReports(dailyActiveUsersReportId.value)

  // will be added to the company data
  const lessonsViewedReportId = computed(() => {
    if (isSachkundeCompany.value) return 30881995
    return 30924633
  })

  const getLessonsViewedData = AnalyticsApi.getInsightsReports(lessonsViewedReportId.value)

  // will be added to the company data
  const questionsAnsweredReportId = computed(() => {
    if (isSachkundeCompany.value) return 30896525
    return 30924564
  })

  const getGuidedQuizAndExamQuestionsAnswered = AnalyticsApi.getInsightsReports(questionsAnsweredReportId.value)

  const to_date = DateTime.now().toFormat('yyyy-MM-dd')
  const from_date = computed(() => DateTime.now().minus({ days: dateRangeActive.value }).toFormat('yyyy-MM-dd'))

  const getTopLessonsViewedData = () => {
    fetchingTopLessonsViewed.value = true
    AnalyticsApi.getEventReportData('lesson_viewed', from_date.value, to_date)
      .then((res) => {
        topLessonsViewed.value = res
      })
      .finally(() => (fetchingTopLessonsViewed.value = false))
  }

  watch(dateRangeActive, (value) => {
    setPreviousDailyActiveUsersAverage(value)
    dailyActiveUsersChartData.value = []
    dailyActiveUsersChartLabels.value = []
    setDailyActiveUsersChartData(value)
    setMappedDataPoints()
    getTopLessonsViewedData()
  })

  watch(loadingInsights, (value) => {
    if (!value) {
      nextTick(() => {
        if (dailyActiveUsersChart.value) {
          dailyActiveUsersChartHeight.value = dailyActiveUsersChart.value.getBoundingClientRect().height
        }
      })
    }
    setIsLoadingComponentViewData(value)
  })

  Promise.all([
    getSignedUpUsersDate,
    getDailyActiveUsersData,
    getLessonsViewedData,
    getGuidedQuizAndExamQuestionsAnswered,
  ])
    .then(async (res) => {
      if (res) {
        setSignUpsDataPoints(Object.entries(res[0].series['user_signed_up - Total'] ?? {}))
        setDailyActiveUsersData(res[1].series['page_viewed - DAU']['$overall'] ?? [])
        setLessonsViewedData(Object.entries(res[2].series['page_viewed - Total'] ?? {}))
        setQuestionsAnsweredData(
          Object.entries(res[3].series['A. guided_quiz_question_answered - Total'] ?? {}),
          'guided-quiz',
        )
        setQuestionsAnsweredData(Object.entries(res[3].series['B. exam_question_answered - Total'] ?? {}), 'exam')
        setPreviousDailyActiveUsersAverage(7)
        setDailyActiveUsersChartData(7)
        setCombinedQuestionsAnsweredData()
        setMappedDataPoints()
        loadingInsights.value = false
      }
    })
    .catch(() => (loadingInsights.value = false))

  if (!CourseModule.courses) {
    fetchCourses()
  }

  getTopLessonsViewedData()
</script>

<style lang="postcss">
  .analytics-wrapper {
    .loading {
      @apply ketch-mx-auto;
    }
    .analytics-header-overview {
      @apply ketch-flex ketch-justify-between ketch-items-end ketch-mb-c50;
      .analytics-header-description {
        @apply ketch-flex ketch-flex-col ketch-space-y-c15;
        .analytics-header {
          @apply ketch-font-big-daily-short md:ketch-text-2xl;
        }
        .analytics-description {
          @apply ketch-max-w-[350px];
        }
      }
      .analytics-date-range {
        @apply ketch-flex ketch-items-center;
        .date-toggle {
          @apply ketch-px-c12 ketch-py-c10 ketch-border ketch-border-border-color ketch-cursor-pointer;
          @apply hover:ketch-bg-primary-color hover:ketch-border-primary-color hover:ketch-transition-colors;
          h5 {
            @apply hover:ketch-text-primary-foreground-color;
          }
          &.active {
            @apply ketch-bg-primary-color ketch-border-primary-color;
            h5 {
              @apply ketch-text-primary-foreground-color;
            }
          }
          &:first-of-type {
            @apply ketch-rounded-l-normal;
          }
          &:last-of-type {
            @apply ketch-rounded-r-normal;
          }
          &:not(&:first-of-type) {
            @apply ketch-border-l-0;
          }
        }
      }
    }
    .analytics-overview-data-wrapper {
      @apply ketch-grid ketch-grid-cols-4 ketch-gap-c20 ketch-mb-c20;
      .analytics-overview-data {
        @apply ketch-flex ketch-justify-center ketch-space-x-c20 ketch-p-c25;
        @apply ketch-border ketch-border-border-color ketch-rounded-normal;
        .overview-data-point-icon {
          @apply ketch-flex ketch-items-center ketch-justify-center ketch-w-c60 ketch-h-c60 ketch-rounded-full;
          @apply ketch-bg-primary-color ketch-bg-opacity-10;
        }
        .overview-data-point {
          @apply ketch-flex ketch-flex-1 ketch-flex-col;
          .data-points {
            @apply ketch-flex ketch-items-baseline ketch-space-x-c5;
            .actual-data-point {
              @apply ketch-font-bold md:ketch-text-[22px];
            }
            .percentage-increase-or-decrease {
              @apply ketch-font-bold;
              &.increase {
                @apply ketch-text-editor-primary-color;
              }
              &:not(.increase) {
                @apply ketch-text-red-500;
              }
            }
          }
        }
      }
    }
    .analytics-charts-new-users {
      @apply ketch-flex ketch-justify-between ketch-space-x-c20;
      .daily-active-users-chart,
      .top-viewed-lessons {
        @apply ketch-border ketch-border-border-color ketch-rounded-[8px];
        @apply ketch-flex ketch-flex-col ketch-py-c25 ketch-px-c15;
        &__header-info {
          @apply ketch-flex;
          h2,
          h3 {
            @apply ketch-font-bold;
          }
          h5 {
            @apply ketch-opacity-40;
          }
        }
      }
      .daily-active-users-chart {
        @apply ketch-w-full ketch-flex-1;
        &__header-info {
          @apply ketch-items-center ketch-justify-between;
          .user-activity-text-days-range {
            @apply ketch-flex ketch-flex-col ketch-space-y-c8;
          }
          .daily-active-users {
            @apply ketch-flex ketch-flex-col ketch-items-end ketch-space-y-c10;
          }
        }
      }
      .top-viewed-lessons {
        @apply ketch-w-[350px];
        &__header-info {
          @apply ketch-flex-col ketch-space-y-c8 ketch-mb-c25;
        }
        &__list {
          @apply ketch-flex ketch-flex-col ketch-overflow-y-auto ketch--mx-c15 ketch-scroll-smooth;
          &.no-lessons {
            @apply ketch-flex ketch-items-center ketch-justify-center ketch-h-full ketch-text-center;
          }
          .viewed-lesson {
            @apply ketch-flex ketch-items-center ketch-justify-between ketch-cursor-pointer;
            @apply ketch-py-c10 ketch-px-c15 ketch-border-t ketch-border-border-color;
            @apply hover:ketch-bg-white hover:ketch-transition-colors;
            .lesson-course-name {
              @apply ketch-flex ketch-flex-col ketch-space-y-c5;
              .lesson-name {
                @apply ketch-font-bold;
              }
              .lesson-name,
              .course-name {
                @apply ketch-max-w-[270px] ketch-whitespace-nowrap ketch-overflow-hidden ketch-overflow-ellipsis;
              }
            }
            .course-name,
            .lesson-views {
              @apply ketch-opacity-60;
            }
            &:hover * {
              @apply ketch-transition-colors;
              :not(.lesson-views) {
                @apply ketch-text-primary-color;
              }
            }
          }
        }
      }
    }
  }
</style>
