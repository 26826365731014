<template lang="pug">
.interested-categories
    h3 {{ header }}
    LoadingSpinner(v-if='interestedCategories === null')
    template(v-else)
        .choices
            Choice(
                :key='category.id',
                :selected='selectedRootFolders.includes(category.id)',
                :svg-renderer-props='selectedRootFolderIcon(category.id)',
                :title='category.name',
                @click.native='onRootFolderClick(category.id)',
                v-for='category in interestedCategories'
            )
</template>
svgRendererProps
<script setup lang="ts">
  import { computed, ref } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import Choice from '@/components/common/Choice.vue'
  import useIcons from '@/composables/useIcons'
  import RootFoldersApi from '@/services/api/RootFoldersApi'
  import type { RootFolder } from '@/services/interfaces/Folder'
  import type { Nullable } from '@/services/interfaces/Content'
  import type { SvgRendererProps } from '@/services/interfaces/Common'

  defineProps({
    header: String,
  })

  const emit = defineEmits(['selected-categories'])

  const { checkedCheckboxSquareWhite, checkboxSquareWhite } = useIcons()
  const interestedCategories = ref(null as Nullable<RootFolder[]>)
  const selectedRootFolders = ref([] as string[])

  const selectedRootFolderIcon = computed(() => {
    return (folderId: string): SvgRendererProps => {
      return selectedRootFolders.value.includes(folderId)
        ? { icon: checkedCheckboxSquareWhite.value, strokeColor: 'var(--primary-text-color)' }
        : { icon: checkboxSquareWhite.value, strokeColor: 'var(--primary-text-color)' }
    }
  })

  const onRootFolderClick = (folderId: string): void => {
    if (selectedRootFolders.value.includes(folderId)) {
      const index = selectedRootFolders.value.indexOf(folderId)
      selectedRootFolders.value.splice(index, 1)
    } else {
      selectedRootFolders.value.push(folderId)
    }
    emit('selected-categories', selectedRootFolders.value)
  }

  RootFoldersApi.interestedCategories().then((rootFolders) => {
    interestedCategories.value = rootFolders
    selectedRootFolders.value = rootFolders.filter((folder) => folder.selected).map((folder) => folder.id)
  })
</script>

<style lang="postcss" scoped>
  .interested-categories {
    @apply ketch-mt-c40;
    h3 {
      @apply ketch-mb-c5;
    }
    .choices {
      @apply ketch-space-y-c10;
      .choice {
        @apply ketch-w-full;
        .choice-content {
          img {
            filter: invert(1);
          }
        }
      }
    }
  }
</style>
