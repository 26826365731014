import { render, staticRenderFns } from "./FullScreenLoader.vue?vue&type=template&id=cc815eb8&scoped=true&lang=pug"
import script from "./FullScreenLoader.vue?vue&type=script&setup=true&lang=ts"
export * from "./FullScreenLoader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FullScreenLoader.vue?vue&type=style&index=0&id=cc815eb8&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc815eb8",
  null
  
)

export default component.exports