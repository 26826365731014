<template lang="pug">
.content-container(
  :class='{ plus: increasePadding, shadow: addImageShadow, clamped: contentClamped, formatContent: formatContent }',
  :id='docId',
  v-html='sourceContent'
)
</template>

<script setup lang="ts">
  import { computed, onMounted, nextTick } from 'vue'
  import useEditor from '@/composables/useEditor'
  import hljs from 'highlight.js'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseApi from '@/services/api/CourseApi'
  import Vue from 'vue'
  import { UserModule } from '@/store/modules/user'
  import useCourse from '@/composables/useCourse'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    html: {
      type: String,
      required: true,
    },
    increasePadding: {
      type: Boolean,
      default: false,
    },
    linkTarget: {
      type: String,
      default: '_self',
    },
    linkPattern: {
      type: String,
      default: '.*.ketchup.academy/courses',
    },
    addImageShadow: {
      type: Boolean,
      default: false,
    },
    contentClamped: {
      type: Boolean,
      default: false,
    },
    formatContent: {
      type: Boolean,
      default: false,
    },
    searchTerm: String,
    skipGlossaryCheck: {
      type: Boolean,
      default: false,
    },
  })

  const { decodeAndParseSource } = useEditor()
  const { formatPhrase } = useCommonMixin()
  const { openLessonModal } = useCourse()
  const { translateString } = useI18n()

  const docId = `doc-id-${Math.floor(Math.random() * 1000000000).toString()}`

  const content = computed((): string =>
    props.formatContent
      ? formatPhrase(decodeAndParseSource(props.html) as string, props.searchTerm)
      : (decodeAndParseSource(props.html) as string),
  )

  const sourceContent = computed(() => {
    if (props.skipGlossaryCheck) return content.value
    const glossaryItems = UserModule.glossaryItems
    if (glossaryItems?.length === 0) {
      return content.value
    }
    let replacedContent = ''
    let splitContent = content.value.split(/\n/)

    glossaryItems?.forEach((item) => {
      replacedContent = splitContent
        .map((p) => {
          const regexMatch = new RegExp(`\\b(${item.text})\\b`, 'g')
          return p.replace(
            regexMatch,
            `<span class='glossary-item-wrapper'>
                 <span class='glossary-item'>
                    <span>${item.text}</span>
                 </span>
              </span>`,
          )
        })
        .join('\n')
      splitContent = replacedContent.split(/\n/)
    })
    return replacedContent
  })

  const showLessonModal = (link: string) => {
    const linkProps = link.slice(link.indexOf('courses')).split('/')
    eventBus.$emit('turn-on-loader')
    const lessonId = linkProps.slice(linkProps.indexOf('lesson'))[1] ?? ''
    const courseId = linkProps[1] ?? ''
    const moduleId = linkProps.slice(linkProps.indexOf('module'))[1] ?? ''

    CourseApi.getLesson(courseId, moduleId, lessonId)
      .then((lesson) => {
        openLessonModal({
          lessonId,
          courseId,
          moduleId,
          lessonToRender: lesson,
        })
      })
      .catch(() => {
        eventBus.$toasted.error(translateString('courses.couldNotFetchLesson'))
      })
      .finally(() => eventBus.$emit('turn-off-loader'))
  }

  const setAnchorElementAttributes = (rendererDiv: HTMLDivElement) => {
    const anchorElements = rendererDiv?.getElementsByTagName('a') || []
    for (let index = 0; index < anchorElements.length; index++) {
      const anchorElement = anchorElements.item(index)
      anchorElement?.setAttribute('target', props.linkTarget)
      if (props.linkPattern && anchorElement?.href.match(props.linkPattern)?.length) {
        if (anchorElement?.href.match(/lesson/)) {
          anchorElement?.addEventListener('click', (e: Event) => {
            e.preventDefault()
            showLessonModal(anchorElement.href)
          })
        }
      }
    }
  }

  const setHoverEventListenerToGlossaryItems = (rendererDiv: HTMLDivElement) => {
    const glossaryElements = rendererDiv?.getElementsByClassName('glossary-item') || []
    for (let index = 0; index < glossaryElements.length; index++) {
      const element = glossaryElements.item(index)
      if (element) {
        element.addEventListener('mouseenter', () => {
          const glossaryText = element.children[0].innerHTML
          const glossaryItem = UserModule.glossaryItems?.find((item) => item.text === glossaryText)
          const tooltipComponent = Vue.extend({
            template: `<span class='glossary-item-wrapper'>
                              <VPopover popover-inner-class='glossary-popover'>
                                <span class='glossary-item'>
                                  <span>${glossaryText}</span>
                                </span>
                                <template #popover>
                                  <h6 class='ketch-opacity-60 ketch-mb-c10'>${translateString('briefDescription')}</h6>
                                  <h6>${glossaryItem?.description}</h6>
                                </template>
                              </VPopover>
                           </span>`,
          })
          const tooltipComponentInstance = new tooltipComponent()
          tooltipComponentInstance.$mount(element)
        })
      }
    }
  }

  onMounted(() => {
    nextTick(() => {
      const rendererDiv = document.getElementById(docId) as HTMLDivElement
      setAnchorElementAttributes(rendererDiv)
      setHoverEventListenerToGlossaryItems(rendererDiv)
      hljs.highlightAll()
    })
  })
</script>

<style lang="postcss">
  .content-container {
    &.shadow {
      img {
        @apply ketch-shadow-content-image-shadow;
      }
    }
    &.clamped {
      > * {
        @apply ketch-pb-0;
      }
      > :first-child {
        @apply ketch-overflow-hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > :not(:first-child) {
        @apply ketch-hidden;
      }
    }
    h1,
    h2,
    h3,
    h4 {
      @apply ketch-font-bold ketch-my-c10;
    }
    h3:first-of-type {
      @apply ketch-mt-0;
    }
    ul,
    ol {
      @apply ketch-pl-c30 ketch-list-none;
    }
    ul {
      @apply ketch-relative;
      li {
        @apply ketch-pl-c15;
        &:before {
          @apply ketch-inline-block ketch-whitespace-nowrap ketch-left-c30 ketch-absolute;
        }
      }
      > li:before {
        content: '\2022';
      }
      ul {
        @apply ketch-pl-c20;
        > li:before {
          @apply ketch-left-c20;
          content: '\26AC';
        }
        ul {
          > li:before {
            content: '\203A';
          }
        }
      }
    }
    ol {
      @apply ketch-list-decimal;
      ol {
        list-style-type: lower-alpha;
        ol {
          list-style-type: lower-roman;
        }
      }
    }
    &.formatContent {
      em,
      em.special,
      em.special * {
        @apply ketch-font-bold ketch-text-primary-color;
      }
    }
    > * {
      @apply ketch-pb-c10;
    }
    :first-child,
    :last-child {
      @apply ketch-m-0;
    }
    :last-child {
      @apply ketch-pb-0;
    }
    &.plus p {
      @apply ketch-my-c10;
    }
    blockquote {
      @apply ketch-mb-c10 ketch-pb-0 ketch-px-c20 ketch-mx-0 ketch-italic;
      @apply ketch-border-l-[5px] ketch-border-[#ccc];
    }
    code,
    kbd,
    samp,
    pre {
      @apply ketch-text-sm ketch-leading-lg;
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    }
    pre {
      @apply ketch-p-c10 ketch-text-gray-430 ketch-border ketch-border-gray-350;
      @apply ketch-rounded-sm ketch-text-left ketch-whitespace-pre-wrap ketch-mb-c10;
      direction: ltr;
      tab-size: 4;
      font-style: normal;
      background: hsla(0, 0%, 78%, 0.3);
    }
    a {
      @apply ketch-text-primary-text-color ketch-underline;
    }
    table {
      @apply ketch-overflow-x-auto ketch-block ketch-mb-c20 ketch-pb-c20;
      td,
      th {
        @apply ketch-pr-c10;
      }
      th {
        @apply ketch-pb-c6;
      }
      td {
        @apply ketch-text-xs ketch-leading-sm sm:ketch-text-sm sm:ketch-leading-lg;
        @apply ketch-font-normal ketch-align-top ketch-min-w-[200px] ketch-py-c2;
      }
    }
    hr {
      @apply ketch-my-c20 ketch-border-border-color;
    }
    ::-webkit-scrollbar {
      @apply ketch-h-c10;
    }
    ::-webkit-scrollbar-track {
      @apply ketch-rounded-normal ketch-bg-gray-430;
    }

    ::-webkit-scrollbar-thumb {
      @apply ketch-rounded-normal ketch-bg-white;
    }
    .glossary-item-wrapper {
      @apply ketch-inline-flex;
      .glossary-item {
        @apply ketch-border ketch-border-primary-color ketch-px-c5 ketch-rounded-small;
        @apply ketch-inline-flex ketch-items-center ketch-space-x-c4;
      }
    }
  }
</style>
