<template lang="pug">
.leuckentext-results-mode
    .items-results
        .leuckentext-result-item(
            :key='item.id',
            v-for='item in leuckentextItems'
        )
            .item-status-icon
                SVGRenderer(
                    :has-hover='false',
                    :icon='checkedCheckboxSquareGreen',
                    :stroke-color='"#28bd2e"',
                    height='15',
                    v-if='correctlyAnswered(item.id)',
                    width='15'
                )
                SVGRenderer(
                    :icon='wrongAnswerIcon',
                    height='15',
                    v-else,
                    width='15'
                )
            LeuckentextQuestionItem.default-behaviour(
                :class='{ correct: correctlyAnswered(item.id) }',
                :in-results-mode='true',
                :item='item'
            )
    .leuckentext-results-cta
        .evaluation-preview
            h5 {{ $t('courses.leuckentext.evaluation') }}:
            h5(v-html='evaluationStatus')
        KetchUpButton(
            :class='{ primary: !answeredAllCorrectly, quaternary: answeredAllCorrectly }',
            @click.native='$emit("try-again")'
        )
            h5(v-if='answeredAllCorrectly') {{ $t('courses.leuckentext.tryAgainAnyway') }}
            h5(v-else) {{ $t('courses.leuckentext.tryAgain') }}
            SVGRenderer(
                :has-hover='false',
                :icon='arrowRight',
                :stroke-color='"var(--primary-foreground-color)"',
                v-if='!answeredAllCorrectly'
            )
        HTMLRenderer.message(
            :html='successMessage',
            v-if='successMessage && answeredAllCorrectly'
        )
        HTMLRenderer.message(
            :html='failureMessage',
            v-else-if='failureMessage && !answeredAllCorrectly'
        )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import LeuckentextQuestionItem from '@/components/course/leuckentext/LeuckentextQuestionItem.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { LeuckentextQuestionItem as ILeuckentextQuestionItem } from '@/services/interfaces/Course'

  const props = defineProps({
    leuckentextItems: {
      type: Array as PropType<ILeuckentextQuestionItem[]>,
      required: true,
    },
    correctItemIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
    attempt: {
      type: Number,
      required: true,
    },
    successMessage: String,
    failureMessage: String,
  })

  defineEmits(['try-again'])

  const { translateString } = useI18n()
  const { checkedCheckboxSquareGreen, wrongAnswerIcon, arrowRight } = useIcons()

  const correctlyAnswered = computed(() => (itemId: string) => {
    return props.correctItemIds.includes(itemId)
  })

  const answeredAllCorrectly = computed(() => props.leuckentextItems.length === props.correctItemIds.length)

  const evaluationStatus = computed(() => {
    return translateString('courses.leuckentext.evaluationStatus', {
      passed: props.correctItemIds.length,
      total: props.leuckentextItems.length,
      attempt: props.attempt,
    })
  })
</script>

<style lang="postcss">
  .leuckentext-results-mode {
    @apply ketch-flex ketch-flex-col ketch-justify-between ketch-h-full;
    .items-results {
      @apply ketch-flex ketch-flex-col ketch-mb-c35 ketch-space-y-c15 md:ketch-mb-0;
      .leuckentext-result-item {
        @apply ketch-flex ketch-space-x-c10;
        .item-status-icon {
          @apply ketch-w-c15 ketch-flex ketch-justify-center;
        }
        .leuckentext-question-item.correct .results-mode {
          @apply ketch-bg-[#28bd2e] ketch-bg-opacity-10 ketch-border-0;
          .answer {
            @apply ketch-text-[#28bd2e];
          }
          span:first-child {
            & {
              @apply ketch-top-c8;
            }
            &,
            &:before,
            &:after {
              @apply ketch-bg-[#28bd2e];
            }
          }
        }
      }
    }
    .leuckentext-results-cta {
      @apply ketch-space-y-[18px] ketch-border-t ketch-border-dashed;
      @apply ketch-border-primary-color ketch--mx-c15 ketch-px-c15 ketch-pt-c15;
      button {
        @apply ketch-w-auto;
        &.quaternary {
          @apply ketch-p-0;
        }
      }
      .message {
        @apply ketch-mt-c10;
      }
    }
  }
</style>
