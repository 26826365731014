<template lang="pug">
.course-section-loader
    .section-title
        PlaceholderShimmer(
            :animate='true',
            height='80px'
        )
    .brief
        .description
            PlaceholderShimmer(
                :animate='true',
                height='60px'
            )
        .bullet-points
            PlaceholderShimmer(
                :animate='true',
                :key='item',
                height='15px',
                v-for='item in 3'
            )
    .tag-filter(v-if='!isMobileDevice')
        .tags
            PlaceholderShimmer(
                :animate='true',
                :key='item',
                height='20px',
                v-for='item in 3',
                width='150px'
            )
        .total-courses
            PlaceholderShimmer(
                :animate='true',
                height='20px'
            )
    .courses
        .course(
            :key='item',
            v-for='item in 4'
        )
            PlaceholderShimmer(
                :animate='true',
                :height='isMobileDevice ? "200px" : "360px"',
                :key='item'
            )
</template>

<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  const { isMobileDevice } = useBreakpoint()
</script>

<style lang="postcss" scoped>
  .course-section-loader {
    @apply ketch-flex ketch-flex-col ketch-mt-c30 md:ketch-mt-0;
    .section-title {
      @apply ketch-mb-c15 md:ketch-mb-c30 xl:ketch-max-w-[50%] xl:ketch-pr-c10;
    }
    .brief {
      @apply ketch-flex ketch-flex-col md:ketch-flex-row md:ketch-mb-c30;
      .description {
        @apply ketch-mb-c10 md:ketch-w-c600 md:ketch-pr-c100 md:ketch-mb-0;
      }
      .bullet-points {
        @apply ketch-flex ketch-flex-col ketch-space-y-c5;
        flex-basis: 50%;
      }
    }
    .tag-filter {
      @apply ketch-flex ketch-justify-between;
      .tags {
        @apply ketch-grid ketch-grid-cols-3 ketch-gap-c12;
      }
      .total-courses {
        @apply ketch-w-c150;
      }
    }
    .courses {
      @apply ketch-grid ketch-grid-cols-1 xs4:ketch-grid-cols-2 md:ketch-grid-cols-3 xl:ketch-grid-cols-4;
      @apply ketch-gap-c20 ketch-mt-c15;
    }
  }
</style>
