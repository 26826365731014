<script setup lang="ts">
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useBreakpoint from '@/composables/useBreakpoint'

  const { windowOpenProxyCheck } = useCommonMixin()
  const { isMobileDevice } = useBreakpoint()

  const props = defineProps({
    bundlePrefix: {
      type: String,
      required: true,
    },
  })

  const redirectToCustomRegistration = () => {
    const isInIframe = (window as any).self !== (window as any).top

    if (isInIframe) {
      ;(window as any).top.postMessage(
        {
          action: 'navigate',
          url: `https://sachkunde.ketchup.academy/registrierung?fromEmbeddedQuiz=${props.bundlePrefix}`,
        },
        '*',
      )
    } else {
      windowOpenProxyCheck(
        `http://localhost:3000/registrierung?fromEmbeddedQuiz=${props.bundlePrefix}`,
        'embedded_guided_quiz_signup_intent',
      )
    }
  }
</script>

<template lang="pug">
.guided-quiz-register-modal.ketch-bg-white.ketch-p-c15(
  :class='{ "ketch-shadow-l-modal": isMobileDevice, "ketch-rounded-2xl": isMobileDevice }'
)
  h2.title-with-underline {{ $t('onboarding.customSignup.register') }}
  h3.ketch-mb-c20 {{ $t('courses.embeddedQuiz.signupToSeeMoreQuestions') }}
  .ketch-w-full.ketch-flex.ketch-justify-end
    KetchUpButton.primary.ketch-rounded-xlarge(@click.stop.native='redirectToCustomRegistration')
      h5 {{ $t('registerNow') }}
</template>

<style scoped lang="postcss"></style>
