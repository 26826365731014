import axios from 'axios'
import { LanguageTranslation } from '@/services/interfaces/Common'

export default class CommonApi {
  static async fetchCountries(): Promise<any> {
    return axios
      .get('https://restcountries.com/v3.1/all')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async fetchLanguageTranslations(): Promise<LanguageTranslation[]> {
    return axios
      .get('/language_translations')
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async postSlackMessage(message: string): Promise<number> {
    return axios
      .post('/post_slack_message', {
        message,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }
}
