<template lang="pug">
.table-lesson
  .table-wrapper(v-html='parsedContent')
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    content: {
      type: String,
      required: true,
    },
    formatContent: {
      type: Boolean,
      default: false,
    },
  })

  const { formatPhrase } = useCommonMixin()

  const parsedContent = computed(() => {
    return props.formatContent ? formatPhrase(props.content) : props.content
  })
</script>

<style lang="postcss">
  .table-lesson {
    @apply ketch-relative ketch-overflow-hidden md:ketch-max-w-[calc(100vw-450px)] xl:ketch-max-w-[640px];
    ::-webkit-scrollbar {
      @apply ketch-h-c10;
    }
    ::-webkit-scrollbar-track {
      @apply ketch-rounded-normal ketch-bg-gray-350;
    }

    ::-webkit-scrollbar-thumb {
      @apply ketch-rounded-normal ketch-bg-gray-430;
    }
    .table-wrapper {
      @apply ketch-overflow-x-scroll ketch-max-w-full md:ketch-ml-c150 xl:ketch-ml-c200;
    }
    table {
      @apply md:ketch-border-separate ketch-pb-c20;

      th {
        @apply ketch-align-middle;
        &:first-child {
          @apply ketch-p-0;
        }
      }
      td,
      th {
        @apply ketch-px-c10 ketch-py-c10  ketch-w-c200;
        img {
          @apply ketch-w-full ketch-object-cover ketch-object-center;
        }
      }
      td {
        @apply ketch-text-xs ketch-leading-sm sm:ketch-text-sm sm:ketch-leading-lg;
        @apply ketch-font-normal ketch-z-[1] ketch-align-top ketch-min-w-[200px];
        &:first-child {
          @apply ketch-font-bold md:ketch-absolute md:ketch-left-0 md:ketch-top-auto;
          @apply ketch-px-0 md:ketch-z-[2];
        }
      }
    }
  }
  .ketch-dark .table-lesson {
    ::-webkit-scrollbar-track {
      @apply ketch-bg-gray-430;
    }

    ::-webkit-scrollbar-thumb {
      @apply ketch-bg-white;
    }
  }
</style>
