<template lang="pug">
.tabs(:class='{ editor: inEditor, "default-style": normalTab }')
  h5.tab(
    :class='{ active: activeTabIndex === index, inactive: inactiveTabIndexes.includes(index) }',
    :key='index',
    @click.stop='onTabClick(index)',
    v-for='(tab, index) in tabs',
    v-html='tab'
  )
</template>

<script setup lang="ts">
  import type { PropType } from 'vue'

  const props = defineProps({
    tabs: {
      required: true,
      type: Array as PropType<string[]>,
    },
    activeTabIndex: {
      type: Number,
      default: 0,
    },
    inEditor: {
      type: Boolean,
      default: false,
    },
    normalTab: {
      type: Boolean,
      default: false,
    },
    inactiveTabIndexes: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  })

  const emit = defineEmits(['tab-index'])

  const onTabClick = (index: number) => {
    if (props.activeTabIndex === index) return
    emit('tab-index', index)
  }
</script>

<style lang="postcss">
  .tabs {
    @apply ketch-w-full ketch-flex ketch-space-x-c1;
    .tab {
      @apply ketch-text-primary-text-color ketch-flex ketch-items-center ketch-border ketch-border-b-0;
      @apply ketch-border-border-color ketch-rounded-t-normal ketch-cursor-pointer ketch-pb-c4;
      @apply ketch-pt-c6 ketch-px-c15;
      &.active {
        @apply ketch-bg-background-color ketch-rounded-t-normal ketch-cursor-default ketch-font-bold;
      }
      &.inactive {
        @apply ketch-opacity-40 ketch-pointer-events-none;
      }
      span {
        @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-w-c20 ketch-h-c20;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-rounded-full ketch-ml-c8;
        @apply ketch-text-xxs ketch-leading-sm;
      }
    }
    &.default-style {
      @apply ketch-border-b ketch-border-border-color ketch-space-x-c25;
      .tab {
        @apply ketch-border-0 ketch-px-c10 ketch-pt-0 ketch-pb-c15 ketch--mb-[2px];
        &.active {
          @apply ketch-border-b-[3px] ketch-border-primary-color;
        }
      }
    }
    &.editor {
      @apply ketch-border-b ketch-border-editor-primary-color;
      .tab {
        @apply ketch-bg-editor-primary-color ketch-bg-opacity-10 ketch-text-editor-primary-color;
        @apply ketch-border-editor-primary-color ketch-font-bold;
        &.active {
          @apply ketch-bg-editor-primary-color ketch-bg-opacity-100 ketch-text-editor-foreground-color;
        }
      }
    }
  }
</style>
