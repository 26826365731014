<template lang="pug">
.course-module-status-container
    .text
        h6 {{ $t('courses.nextContent') }}
        h5 {{ nextModuleLessonText }}
    KetchUpButton.primary(
        :disabled='!nextModule',
        @click.native='toLesson'
    )
        h5 {{ $t('courses.continue') }}
        SVGRenderer(
            :has-hover='false',
            :icon='arrowRight',
            stroke-color='var(--primary-foreground-color)'
        )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useCourse from '@/composables/useCourse'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useIcons from '@/composables/useIcons'
  import { useRouter } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    courseGroupId: String,
  })

  const { translateString } = useI18n()
  const router = useRouter()
  const { arrowRight } = useIcons()
  const { nextCourseModule, nextLesson, nextModuleIndex, nextLessonIndex, progressPercentage, currentCourseId } =
    useCourse()
  const { convertToRoman } = useCommonMixin()

  const toLesson = () => {
    const lessonIndex = nextLessonIndex(props.courseGroupId)
    const courseModule = nextCourseModule(props.courseGroupId)

    if (courseModule && progressPercentage.value(currentCourseId.value, props.courseGroupId) === 100) {
      return router
        .push({
          name: 'CourseModule',
          params: { moduleId: courseModule.id },
        })
        .catch(() => {
          return
        })
    }

    if (lessonIndex === (courseModule?.lessons.length || 0)) {
      if (courseModule?.guidedQuiz) {
        return router.push({ name: 'GuidedQuiz', params: { moduleId: courseModule?.id || '' } }).catch(() => {
          return
        })
      }
      toNextCourseModule()
    } else if (lessonIndex === 0) {
      toNextCourseModule()
    } else {
      router
        .push({
          name: 'Lesson',
          params: {
            moduleId: nextModule.value!.id,
            lessonId: nextLesson(props.courseGroupId)?.id || '',
          },
        })
        .catch(() => {
          return
        })
    }
  }

  const nextModule = computed(() => nextCourseModule(props.courseGroupId))

  const toNextCourseModule = () => {
    router
      .push({
        name: 'CourseModule',
        params: { moduleId: nextModule.value!.id },
      })
      .catch(() => {
        return
      })
  }

  const nextModuleLessonText = computed(() => {
    const courseModule =
      translateString('courses.module') + ` ${convertToRoman(nextModuleIndex(props.courseGroupId) + 1)} `
    const lessonIndex = nextLessonIndex(props.courseGroupId)
    let nextLesson = ''
    if (lessonIndex > 0) nextLesson = ` - ${translateString('courses.lesson')} ${lessonIndex + 1}`
    return courseModule + nextLesson
  })
</script>

<style lang="postcss" scoped>
  .course-module-status-container {
    @apply ketch-flex ketch-items-center ketch-justify-between ketch-border-b ketch-border-border-color;
    &:last-of-type {
      @apply ketch-border-b-0;
    }
    button {
      @apply ketch-text-primary-text-color hover:ketch-font-bold ketch-space-x-c15;
    }
    .text h6 {
      @apply ketch-text-gray-400;
    }
  }
</style>
