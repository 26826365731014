import axios from './interceptor'
import auth_config from '../../../auth_config.json'
import { SignupResponse } from '../interfaces/Auth0'

const { staging, production } = auth_config
const domain = process.env.APP_ENV === 'production' ? production.domain : staging.domain
const clientId = process.env.APP_ENV === 'production' ? production.clientId : staging.clientId
const audience = process.env.APP_ENV === 'production' ? production.audience : staging.audience
const clientSecret =
  process.env.APP_ENV === 'production'
    ? process.env.CLIENT_SECRET
    : 'Kt1EJyOGGDYVzyAD9f1NoaERyAuEGvfblsdCFOkK2xh3hufo6zJu0FsJW5v1jnC4'

interface DBSignInResponse {
  access_token: string
  id_token: string
  scope: string
  token_type: string
  expires_in: number
}

export default class Auth0Api {
  static async dbConnectionSignup(email: string, password: string): Promise<SignupResponse> {
    const body: any = {
      client_id: clientId,
      connection: 'Username-Password-Authentication',
      email,
      password,
    }
    return axios
      .post('/dbconnections/signup', body, {
        baseURL: `https://${domain}`,
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async dbConnectionSignin(email: string, password: string): Promise<DBSignInResponse> {
    return axios
      .post(
        '/oauth/token',
        {
          grant_type: 'password',
          username: email,
          password,
          audience,
          scope: 'openid profile email',
          client_id: clientId,
          client_secret: clientSecret,
        },
        {
          baseURL: `https://${domain}`,
        },
      )
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async changePassword(email: string): Promise<any> {
    return axios
      .post(
        '/dbconnections/change_password',
        {
          client_id: clientId,
          email,
          connection: 'Username-Password-Authentication',
        },
        {
          baseURL: `https://${domain}`,
        },
      )
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
