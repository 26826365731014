<template lang="pug">
SimpleSVG(
    :custom-id='svgId',
    @load='onSVGLoaded',
    @mouseleave.native='mouseLeave',
    @mouseover.native='mouseOver',
    v-bind='svgProps'
)
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  //@ts-ignore
  import { SimpleSVG } from 'vue-simple-svg'

  const props = defineProps({
    icon: {
      required: true,
      type: String,
    },
    strokeClass: {
      type: String,
      default: 'icon-stroke',
    },
    secondaryStrokeClass: {
      type: String,
      default: 'icon-stroke-secondary',
    },
    strokeColor: {
      default: '#fff',
    },
    secondaryStrokeColor: {
      default: '#fff',
    },
    strokeHoverColor: {
      default: '#53F1B8',
    },
    fillClass: {
      type: String,
      default: 'icon-fill',
    },
    fillColor: {
      default: '#fff',
    },
    secondaryFillClass: {
      type: String,
      default: 'icon-fill-secondary',
    },
    secondaryFillColor: {
      default: '#fff',
    },
    hasHover: {
      type: Boolean,
      default: true,
    },
    fillHoverColor: {
      default: '#53F1B8',
    },
    toggleHover: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
  })

  const svgId = `svg-id-${Math.floor(Math.random() * 100000000000)}`
  const isHovering = ref(false)

  const svgProps = computed(() => {
    const data: { [key: string]: string } = { src: props.icon, width: props.width, height: props.height }
    if (props.fillClass) {
      data.fill = props.toggleHover || isHovering.value ? props.fillHoverColor : props.fillColor
      data.fillClassName = props.fillClass
    }
    if (props.strokeClass) {
      data.stroke = props.toggleHover || isHovering.value ? props.strokeHoverColor : props.strokeColor
      data.strokeClassName = props.strokeClass
    }
    if (document.getElementById(svgId)) {
      setSecondaryColor()
    }

    return data
  })

  const mouseLeave = () => {
    if (props.hasHover) {
      isHovering.value = false
    }
  }

  const mouseOver = () => {
    if (props.hasHover) {
      isHovering.value = true
    }
  }

  const onSVGLoaded = () => {
    setSecondaryColor()
  }

  const setSecondaryColor = () => {
    const strokeEl = document
      .getElementById(svgId)
      // eslint-disable-next-line no-undef
      ?.querySelectorAll(`.${props.secondaryStrokeClass}`) as NodeListOf<SVGPathElement>
    if (strokeEl?.length) {
      strokeEl.forEach((path: SVGPathElement) => {
        path.style.setProperty('stroke', props.secondaryStrokeColor)
      })
    }
    const fillEl = document
      .getElementById(svgId)
      // eslint-disable-next-line no-undef
      ?.querySelectorAll(`.${props.secondaryFillClass}`) as NodeListOf<SVGPathElement>
    if (fillEl?.length) {
      fillEl.forEach((path: SVGPathElement) => {
        path.style.setProperty('fill', props.secondaryFillColor)
      })
    }
  }
</script>
