<template lang="pug">
.users-management(data-cy='user-management')
  OverviewActionBar(
    :count='overallTotalUsers',
    :text='$t("homepage.usersInYourAcademy")'
  )
    .cta-search
      .search(v-tooltip='{ content: $t("search.minCharTooltipText"), show: showSearchTooltip, trigger: "manual" }')
        input.h5(
          :placeholder='$t("homepage.findUsers")',
          @keyup.enter='searchUsers',
          type='text',
          v-model='searchTerm'
        )
        .search-icon(@click='clearSearchTerm')
          SVGRenderer(
            :has-hover='false',
            :icon='searchTerm.length ? closeIcon : searchIcon',
            :stroke-color='"var(--primary-text-color)"',
            width='20'
          )
      KetchUpButton.primary(
        @click.native='openInviteUserModal',
        data-cy='open-invite-user-modal'
      )
        h5 {{ $t('homepage.addMembers') }}
        SVGRenderer(
          :has-hover='false',
          :icon='plusVectorIcon',
          :stroke-color='"var(--primary-foreground-color)"',
          width='12'
        )
      KetchUpButton.tertiary.export-users(
        @click.native='exportUsersAsCSV',
        v-tooltip.bottom='{ content: $t("exportUsersAsCSV") }'
      )
        LoadingSpinner(v-if='exportingUsers')
        SVGRenderer(
          :fill-color='"var(--primary-text-color)"',
          :has-hover='false',
          :icon='download',
          v-else,
          width='20'
        )
  .grouped-users-wrapper
    UsersAndGroupsLoader(
      :show-header-loader='false',
      v-if='searchingUsers'
    )
    .role-users(
      :key='role',
      v-else,
      v-for='role in userMemberRolesToGroup'
    )
      .role
        h4.name
          span {{ customUserRoleText(role) }}
          span [{{ specificRoleUsersCount(role) }}]
        h5.role-description {{ userRoleDescription(role) }}
      .user-table-wrapper-pagination-cta
        UserTable(
          :in-user-management-tab='true',
          :no-users-text='$t("homepage.noUsersInRole")',
          :selected-user='selectedUser[role]',
          :user-role-category='role',
          :users='specificRoleUsers(role)',
          @create-new-group='$emit("create-new-group")',
          @delete-user='deleteUser($event)',
          @reload-user-members='reloadUserMembers',
          @set-selected-user='setSelectedUser($event, role)',
          show-time-spent
        )
        LoadingSpinner(v-if='loadingMoreUsers')
        .load-more-or-all(v-else-if='!loadedAllRoleUsers(role) && xMoreUsersToLoad(role) > 0')
          h5
            span(@click='loadMoreUsers(role)') {{ loadXMore(role) }}
            span {{ $t('or') }}
            span(@click='loadAllRoleUsers(role)') {{ $t('loadAll') }}
</template>

<script setup lang="ts">
  import type { PropType } from 'vue'
  import { computed, ref } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { UserModule } from '@/store/modules/user'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import OverviewActionBar from '@/components/user-management/OverviewActionBar.vue'
  import UserTable from '@/components/user-management/UserTable.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import UsersAndGroupsLoader from '@/components/loaders/UsersAndGroupsLoader.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { UserMember, UserMembersResponse } from '@/services/interfaces/Auth0'
  import UsersApi from '@/services/api/UsersApi'

  defineProps({
    openInviteUserModal: {
      required: true,
      type: Function as PropType<() => void>,
    },
  })
  defineEmits(['create-new-group'])
  const { closeIcon, searchIcon, plusVectorIcon, download } = useIcons()
  const { userMembersPerPageLimit } = useCommonMixin()
  const { translateString } = useI18n()
  const userMembers = ref(null as UserMembersResponse | null)
  const selectedUser = ref({} as { [key: string]: UserMember | null })
  const searchTerm = ref('')
  const loadingMoreUsers = ref(false)
  const activeSearch = ref(false)
  const searchingUsers = ref(false)
  const showSearchTooltip = ref(false)
  const exportingUsers = ref(false)

  const allUserRolesMembers = computed(() => {
    if (activeSearch.value) return UserModule.searchedUserMembers
    return userMembers.value?.roles
  })

  const specificRoleMembersResponse = computed(() => (role: string) => {
    return allUserRolesMembers.value?.find((r) => r.roleName === role)
  })

  const specificRoleUsers = computed(() => (role: string) => {
    return specificRoleMembersResponse.value(role)?.users ?? []
  })

  const overallTotalUsers = computed(() => userMembers.value?.totalUsers ?? 0)

  const specificRoleUsersCount = computed(() => (role: string) => {
    if (!specificRoleMembersResponse.value(role)) return 0
    return specificRoleMembersResponse.value(role)?.users.length ?? 0
  })

  const specificRoleTotalUsersCount = computed(() => (role: string) => {
    if (!specificRoleMembersResponse.value(role)) return 0
    return specificRoleMembersResponse.value(role)?.totalUsers ?? 0
  })

  const xMoreUsersToLoad = computed(() => (role: string) => {
    return specificRoleTotalUsersCount.value(role) - specificRoleUsersCount.value(role) > userMembersPerPageLimit.value
      ? userMembersPerPageLimit.value
      : specificRoleTotalUsersCount.value(role) - specificRoleUsersCount.value(role)
  })

  const loadedAllRoleUsers = computed(() => (role: string) => {
    return specificRoleTotalUsersCount.value(role) === specificRoleUsersCount.value(role)
  })

  const loadXMore = computed(
    () => (role: string) => translateString('loadXMore', { number: xMoreUsersToLoad.value(role) }),
  )

  const userMemberRolesToGroup = computed(() => {
    return ['COMPANY_ADMIN', 'EDITOR', 'USER']
  })

  const customUserRoleText = computed(() => {
    return (role: string) => {
      if (role === 'COMPANY_ADMIN') {
        return translateString('homepage.administrators')
      }
      if (role === 'EDITOR') {
        return translateString('homepage.editors')
      }
      return translateString('homepage.user')
    }
  })

  const userRoleDescription = computed(() => {
    return (role: string) => {
      if (role === 'COMPANY_ADMIN') {
        return translateString('homepage.administratorsRoleDescription')
      }
      if (role === 'EDITOR') {
        return translateString('homepage.editorRoleDescription')
      }
      return translateString('homepage.userRoleDescription')
    }
  })

  const roleName = computed(() => (roleId: string) => UserModule.roles.find((r) => r.roleId === roleId)?.name)

  const exportUsersAsCSV = () => {
    exportingUsers.value = true
    UsersApi.exportCompanyUsersAsCSV(UserModule.currentCompany!.id)
      .then(() => {
        eventBus.$toasted.success(translateString('exportUsersSuccessful') as string)
      })
      .finally(() => (exportingUsers.value = false))
  }

  const setSelectedUser = (user: UserMember, role: string) => {
    // first reset selectedUser
    selectedUser.value = {}
    eventBus.$set(selectedUser.value, role, user)
  }

  const searchUsers = () => {
    if (searchTerm.value.length < 3) {
      showSearchTooltip.value = true
    } else {
      showSearchTooltip.value = false
      activeSearch.value = true
      searchingUsers.value = true
      UserModule.getSearchedUserMembers({
        perPage: userMembersPerPageLimit.value,
        page: 1,
        term: searchTerm.value,
        roles: ['COMPANY_ADMIN', 'EDITOR', 'USER'],
      }).then(() => {
        searchingUsers.value = false
      })
    }
  }

  const clearSearchTerm = () => {
    if (searchTerm.value) {
      activeSearch.value = false
      searchingUsers.value = false
      searchTerm.value = ''
    }
  }

  const deleteUser = (deleteUserData: { userId: string; rolesUserHas: string[] }) => {
    const { userId, rolesUserHas } = deleteUserData
    if (!rolesUserHas.length) {
      // if the user has no role that means they have the default USER role
      spliceOutDeletedUser('USER', userId)
    } else {
      rolesUserHas.forEach((roleId) => {
        const role = roleName.value(roleId)
        if (role) {
          spliceOutDeletedUser(role, userId)
        }
      })
    }
  }

  const spliceOutDeletedUser = (roleName: string, userId: string) => {
    if (specificRoleUsers.value(roleName)) {
      const roleIndex = userMembers.value?.roles.findIndex((r) => r.roleName === roleName)
      const userToDeleteIndex = specificRoleUsers.value(roleName).findIndex((user) => user.id === userId)
      if (typeof roleIndex === 'number' && typeof userToDeleteIndex === 'number') {
        userMembers.value?.roles[roleIndex].users.splice(userToDeleteIndex, 1)
      }
    }
  }

  const loadMoreUsers = (role: string) => {
    if (specificRoleMembersResponse.value(role)) {
      loadingMoreUsers.value = true
      const payload: any = {
        perPage: userMembersPerPageLimit.value,
        page: specificRoleMembersResponse.value(role)!.currentPage + 1,
        roles: [role],
        userCategory: activeSearch.value ? 'search' : 'roles',
      }
      if (activeSearch.value) {
        payload.term = searchTerm.value
      }
      UserModule.loadMoreUsers(payload).finally(() => (loadingMoreUsers.value = false))
    }
  }

  const loadAllRoleUsers = (role: string) => {
    if (specificRoleMembersResponse.value(role)) {
      loadingMoreUsers.value = true
      const payload: any = {
        perPage: userMembersPerPageLimit.value,
        page: specificRoleMembersResponse.value(role)!.currentPage + 1,
        roles: [role],
        userCategory: activeSearch.value ? 'search' : 'roles',
        loadAll: true,
      }
      if (activeSearch.value) {
        payload.search = searchTerm.value
      }
      UserModule.loadMoreUsers(payload).finally(() => (loadingMoreUsers.value = false))
    }
  }

  const reloadUserMembers = () => {
    userMembers.value = UserModule.userMembers
  }

  userMembers.value = UserModule.userMembers
</script>

<style lang="postcss">
  .users-management {
    @apply ketch-flex ketch-flex-col;
    .overview-action-bar {
      .cta-search {
        @apply ketch-flex ketch-items-center ketch-space-x-c20;
        .search {
          @apply ketch-relative;
          input {
            @apply ketch-bg-transparent ketch-rounded-large ketch-border ketch-border-border-color;
            @apply ketch-py-c10 ketch-pl-c20 ketch-pr-c40 ketch-outline-none ketch-w-[300px];
          }
          .search-icon {
            @apply ketch-absolute ketch-top-c10 ketch-right-c20 ketch-cursor-pointer;
          }
        }
        .export-users {
          @apply ketch-w-c70;
          .dot {
            @apply ketch-w-c8 ketch-h-c8;
          }
        }
      }
    }
    .grouped-users-wrapper {
      @apply ketch-flex ketch-flex-col;
      .role-users {
        @apply ketch-flex ketch-py-c45 ketch-border-b ketch-border-dashed ketch-border-border-color;
        &:first-of-type {
          @apply ketch-pt-0;
        }
        &:last-of-type {
          @apply ketch-border-b-0;
        }
        .role {
          @apply ketch-flex ketch-flex-col ketch-space-y-c8 ketch-flex-auto;
          .name {
            @apply ketch-flex ketch-space-x-[7px];
            > :first-child {
              @apply ketch-font-bold;
            }
          }
          .role-description {
            @apply ketch-max-w-[250px];
          }
        }
      }
    }
  }
</style>
