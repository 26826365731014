<template lang="pug">
.bookmark-lesson(
    @mouseleave='hoveringBookmark(false)',
    @mouseover='hoveringBookmark(true)',
    data-cy='bookmark'
)
    SVGRenderer(
        :has-hover='!isMobileDevice',
        :height='isMobileDevice ? "20px" : "40px"',
        :icon='bookmarkInactive',
        :stroke-color='bookmarkIconStrokeColor',
        :stroke-hover-color='"var(--primary-color)"',
        :toggle-hover='hovering',
        :width='isMobileDevice ? "auto" : "25px"',
        @click.stop.native='bookmarkLesson'
    )
    .blurred-out-non-editable-section(v-if='inEditorMode')
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import useCourse from '@/composables/useCourse'
  import useEditor from '@/composables/useEditor'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { CourseModule } from '@/store/modules/course'

  const props = defineProps({
    canEdit: {
      default: true,
      type: Boolean,
    },
    lessonModuleId: String,
  })

  const { inEditorMode: editorMode } = useEditor()
  const { bookmarkCourseLesson, unBookmarkCourseLesson, courseLessonIsBookmarked, course, currentLesson } = useCourse()
  const { bookmarkInactive } = useIcons()
  const { isMobileDevice } = useBreakpoint()
  const hovering = ref(false)

  const inEditorMode = computed(() => editorMode.value && props.canEdit)

  const isBookmarked = computed(() => {
    return courseLessonIsBookmarked.value(currentLesson.value!.id)
  })

  const bookmarkIconStrokeColor = computed(() => {
    if (isMobileDevice.value) return isBookmarked.value ? 'var(--primary-color)' : 'white'
    return isBookmarked.value ? 'var(--primary-color)' : 'var(--primary-text-color)'
  })

  const courseModule = computed(() => {
    return CourseModule.lessonModule(course.value!.id, currentLesson.value!.id)
  })

  const hoveringBookmark = (hovered: boolean) => {
    if (!inEditorMode.value && !isMobileDevice.value) {
      hovering.value = hovered
    }
  }

  const bookmarkLesson = () => {
    if (isBookmarked.value) {
      unBookmarkCourseLesson(
        course.value!.id,
        course.value!.title,
        courseModule.value!.id,
        courseModule.value!.name,
        currentLesson.value!.id,
        currentLesson.value!.title,
      )
    } else {
      bookmarkCourseLesson(
        course.value!.id,
        course.value!.title,
        courseModule.value!.id,
        courseModule.value!.name,
        currentLesson.value!.id,
        currentLesson.value!.title,
      )
    }
  }
</script>

<style lang="postcss" scoped>
  .bookmark-lesson {
    @apply ketch-relative;
  }
</style>
