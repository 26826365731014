import { render, staticRenderFns } from "./CourseSearchResults.vue?vue&type=template&id=696b67e5&lang=pug"
import script from "./CourseSearchResults.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseSearchResults.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseSearchResults.vue?vue&type=style&index=0&id=696b67e5&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports