import { render, staticRenderFns } from "./CourseModuleDuration.vue?vue&type=template&id=6b976a60&scoped=true&lang=pug"
import script from "./CourseModuleDuration.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseModuleDuration.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseModuleDuration.vue?vue&type=style&index=0&id=6b976a60&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b976a60",
  null
  
)

export default component.exports