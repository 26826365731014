import { render, staticRenderFns } from "./DurationInput.vue?vue&type=template&id=6c4312fe&scoped=true&lang=pug"
import script from "./DurationInput.vue?vue&type=script&setup=true&lang=ts"
export * from "./DurationInput.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DurationInput.vue?vue&type=style&index=0&id=6c4312fe&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c4312fe",
  null
  
)

export default component.exports