<script setup lang="ts">
  import { computed, ref } from 'vue'
  import type { PropType } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import type { CourseExamResult } from '@/services/interfaces/Course'
  import { useRouter } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'
  import useCommonMixin from '@/composables/useCommonMixin'

  const router = useRouter()
  const { translateString } = useI18n()
  const { formatDate } = useCommonMixin()
  const fetchingMoreCompletionResults = ref(false)

  defineProps({
    fetchingCompletionResults: {
      type: Boolean,
      required: true,
    },
    fetchedAllCompletions: {
      type: Boolean,
      required: true,
    },
    examResults: {
      type: Array as PropType<CourseExamResult[]>,
      required: true,
    },
  })

  defineEmits(['fetch-more-completion-results'])

  const showCompletionStatus = computed(() => {
    return (completion: CourseExamResult) => {
      const performedStatus = completion.passed
        ? translateString('courses.examSimulation.passed')
        : translateString('courses.examSimulation.failed')
      const allScores: string[] = []
      completion.parts.forEach((part) => {
        part.subjects.forEach((subject) => {
          allScores.push(`${subject.score}%`)
        })
      })
      return `${formatDate(completion.performedDate, 'dd.MM.yyyy')}: ${performedStatus} (${allScores.toString()})`
    }
  })

  const routeToResults = (completionId: string) => {
    router.push({ name: 'CourseExamResults', params: { completionId } })
  }
</script>

<template lang="pug">
.exam-results-completions-wrapper
    LoadingSpinner.fetching-results(v-if='fetchingCompletionResults')
    .exam-results-completions(
        data-cy='exam-completions',
        v-else-if='examResults && examResults.length'
    )
        .completions-wrapper
            .completion(
                :key='completion.id',
                @click='routeToResults(completion.id)',
                data-cy='completion',
                v-for='completion in examResults'
            )
                h5 {{ showCompletionStatus(completion) }}
        .completions-cta(v-if='!fetchedAllCompletions')
            LoadingSpinner(v-if='fetchingMoreCompletionResults')
            h6.load-more-pagination(
                @click='$emit("fetch-more-completion-results")',
                v-else
            )
                span {{ $t('loadMore') }}
</template>

<style scoped lang="postcss">
  .exam-results-completions-wrapper {
    .fetching-results {
      @apply ketch-mt-c20 ketch-mx-auto;
    }
  }
  .exam-results-completions {
    .completions-wrapper {
      @apply ketch-mt-c30 ketch-mb-c30 ketch-space-y-c5;
      .completion {
        @apply ketch-underline ketch-cursor-pointer;
        width: fit-content;
      }
    }
  }
</style>
