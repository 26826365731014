<template lang="pug">
.confirmation-modal(data-cy='confirmation-modal')
    .info-cta
        .info
            h4(v-html='confirmationText')
        .cta
            KetchUpButton.tertiary(@click.native='cancelAction')
                SVGRenderer(
                    :has-hover='false',
                    :icon='closeIcon',
                    :stroke-color='showSvgs ? "var(--primary-text-color)" : "var(--primary-foreground-color)"',
                    v-if='showSvgs',
                    width='16'
                )
                h5 {{ cancelButtonText }}
            KetchUpButton.primary(
                @click.native='okAction',
                data-cy='ok-action'
            )
                LoadingSpinner(v-if='isConfirmingAction')
                template(v-else)
                    SVGRenderer(
                        :has-hover='false',
                        :icon='checkmark',
                        :stroke-color='showSvgs ? "var(--editor-foreground-color)" : "var(--primary-foreground-color)"',
                        v-if='showSvgs',
                        width='11'
                    )
                    h5 {{ okButtonText }}
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import type { PropType } from 'vue'

  const props = defineProps({
    cancelCallback: {
      type: Function as PropType<() => void>,
    },
    okCallback: {
      type: Function as PropType<(closeCallback?: () => void) => void>,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    okButtonText: {
      type: String,
      default: 'OK',
    },
    confirmationText: {
      type: String,
      required: true,
    },
    showSvgs: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['close'])

  const isConfirmingAction = ref(false)
  const { closeIcon, checkmark } = useIcons()

  const okAction = () => {
    isConfirmingAction.value = true
    props.okCallback(() => {
      emit('close')
    })
  }

  const cancelAction = () => {
    if (typeof props.cancelCallback === 'function') props.cancelCallback()
    emit('close')
  }
</script>

<style lang="postcss">
  .confirmation-modal {
    .info-cta {
      @apply ketch-w-full ketch-h-full ketch-flex ketch-flex-col;
      .info {
        @apply ketch-mb-c40;
        h4 span {
          @apply ketch-font-bold;
        }
      }
      .cta {
        @apply ketch-flex ketch-items-center ketch-justify-end ketch-space-x-c20;
        .tertiary {
          @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-px-c15;
          svg {
            @apply ketch-w-c16 ketch-mt-c1;
          }
        }
        .primary {
          @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-px-c15;
          svg {
            @apply ketch-w-c12 ketch-mt-c1;
          }
        }
      }
    }
  }
</style>
