<template lang="pug">
.author-audio-section-wrapper
  .presented-by-info
    .presented-by
      img(
        :class='{ "no-author": !audioAuthor }',
        :src='cornerLeftDown'
      )
      .presented-by-text
        h2.ketch-font-bold {{ $t('courses.listenToLesson') }}
        h5(
          v-html='$t("courses.presentedBy", { expertise: audioAuthor.expertise || "", authorName: audioAuthor.name || "" })',
          v-if='audioAuthor'
        )
    SVGRenderer(
      :fill-color='"var(--primary-text-color)"',
      :has-hover='false',
      :height='isMobileDevice ? "20px" : "30px"',
      :icon='headphones',
      :width='isMobileDevice ? "20px" : "30px"'
    )
  .author-audio-section
    .author-avatar(v-if='audioAuthor')
      img(
        :src='audioAuthor.avatarUrl',
        v-if='audioAuthor.avatarUrl'
      )
    AudioPlayer(
      :audio-source='audioSource',
      track-play-events
    )
</template>

<script setup lang="ts">
  import AudioPlayer from '@/components/AudioPlayer.vue'
  import type { Author } from '@/services/interfaces/Common'
  import type { PropType } from 'vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  const { cornerLeftDown, headphones } = useIcons()
  const { isMobileDevice } = useBreakpoint()

  defineProps({
    audioSource: {
      type: String,
      required: true,
    },
    audioAuthor: {
      type: Object as PropType<Author | null>,
      default: null,
    },
  })
</script>

<style lang="postcss" scoped>
  .author-audio-section-wrapper {
    @apply ketch-flex ketch-flex-col;
    .presented-by-info {
      @apply ketch-bg-white ketch-px-c10 ketch-py-c5 ketch-mb-c20 ketch-flex ketch-items-center ketch-justify-between;
      @apply ketch-shadow-content-image-shadow sm:ketch-px-c20 sm:ketch-py-c15;
      .presented-by {
        @apply ketch-flex ketch-items-center ketch-space-x-c8 sm:ketch-space-x-c15;
        img {
          @apply ketch-w-c20 sm:ketch-w-c40;
          &.no-author {
            @apply sm:ketch-w-c30;
          }
        }
      }
    }
    .author-audio-section {
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-space-x-c10 sm:ketch-space-x-c20;

      .author-avatar {
        @apply ketch-rounded-full ketch-border ketch-border-white;
        img {
          @apply ketch-max-w-c50 ketch-max-h-c50;
        }
      }

      .audio-player {
        @apply ketch-flex-auto;
      }
    }
  }
</style>
