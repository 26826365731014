<template lang="pug">
.company-selection
    .company-selection-content
        LoadingSpinner(v-if='loadingCompanyThemes')
        .wrapper(v-else)
            h1.name
                span {{ $t('hello') }}
                span {{ firstName }}!
            h4.description {{ $t('multipleAcademiesDescription') }}
            .companies
                .company(
                    :key='company.id',
                    :style='companyTheme(index)',
                    @click='setUserCompany(company)',
                    v-for='(company, index) in companies'
                )
                    .text
                        h3.name {{ company.name }}
                        h5.title {{ company.headerTitle }}
                    SVGRenderer(
                        :has-hover='false',
                        :icon='arrowRight',
                        :stroke-color='companyTheme(index).color',
                        height='30px'
                    )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import { UserModule } from '@/store/modules/user'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import ThemeApi from '@/services/api/ThemeApi'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useAuthentication from '@/composables/useAuthentication'
  import type { CompanyTheme } from '@/services/interfaces/Theme'
  import type { Company } from '@/services/interfaces/Auth0'

  const { redirectToNewDomain, setIsLoadingComponentViewData } = useCommonMixin()
  const { redirect, companyKey } = useAuthentication()
  const { arrowRight } = useIcons()
  const companyThemes = ref([] as CompanyTheme[])

  const firstName = computed(() => UserModule.firstName)

  const companies = computed(() => UserModule.companies)

  const loadingCompanyThemes = computed(() => !companyThemes.value.length)

  const companyTheme = (index: number) => {
    if (
      companyThemes.value[index].lightVariants.headerBackgroundColor &&
      companyThemes.value[index].lightVariants.headerForegroundColor
    ) {
      return {
        'background-color':
          companyThemes.value[index].lightVariants.headerBackgroundColor || 'var(--header-background-color)',
        color: companyThemes.value[index].lightVariants.headerForegroundColor || 'var(--header-foreground-color)',
      }
    }
  }
  const setUserCompany = (company: Company) => {
    CookieHelper.removeCrossDomainCookie('logoUrl')
    CookieHelper.setCrossDomainCookie(companyKey.value, company.id)
    if (['production', 'qa'].includes(process.env.APP_ENV!)) {
      return redirectToNewDomain(company.subDomain, redirect.value)
    }
    window.location.href = redirect.value
  }

  const setCompanyPromisedThemes = () => {
    const promisedThemes = companies.value.map((company) => ThemeApi.getTheme(company.id))
    Promise.all(promisedThemes).then((themes) => {
      companyThemes.value = themes
    })
  }

  watch(loadingCompanyThemes, (value) => {
    setIsLoadingComponentViewData(value)
  })

  onMounted(() => {
    setCompanyPromisedThemes()
  })
</script>

<style lang="postcss">
  .company-selection {
    @apply ketch-mt-c30 md:ketch-mt-0;
    &-content {
      @apply ketch-flex ketch-justify-center ketch-pb-c30 sm:ketch-pb-0;
      .wrapper {
        @apply ketch-w-full xs4:ketch-p-0 xs4:ketch-w-[425px];
      }
      h1.name {
        @apply ketch-font-big-daily-short ketch-space-x-c10 ketch-mb-c30;
      }
      .companies {
        @apply ketch-space-y-c15 ketch-mt-c40;
        .company {
          @apply ketch-rounded-normal ketch-p-c12 ketch-pr-c20 xs4:ketch-p-c20 ketch-flex ketch-justify-between;
          @apply ketch-items-center ketch-cursor-pointer hover:ketch-translate-x-c20 ketch-transition-transform;
          @apply ketch-duration-[400ms] ketch-ease-in;
          .text {
            @apply ketch-pr-c20 xs4:ketch-pr-c40;
            .name {
              @apply ketch-font-bold ketch-mb-c10;
            }
          }
          svg {
            flex: 0 0 18px;
          }
        }
      }
    }
  }
</style>
