<template lang="pug">
.text-renderer
  .ck-editor-wrapper(v-if='inEditorMode')
    .ck-outside-header(:class='{ "has-edit-title": editTitle }')
      .left.edit-title-header(v-if='editTitle')
        h5(
          :class='{ required: required }',
          v-html='editTitle'
        )
        h6 {{ editSubTitle }}
      .right(v-if='hasLocalChanges')
        h6(@click='discardLocalChanges') {{ $t('editor.discardChanges') }}
    input.text-input(
      :key='textInputKey',
      :placeholder='placeholder',
      :readonly='isTextInputReadOnly',
      type='text',
      v-if='showTextInput',
      v-model='textInput'
    )
    ExpandableTextAreaInput(
      :key='textAreaInputKey',
      :text-area-placeholder='placeholder',
      :text-message='textInput',
      @text-change='onTextAreaInputChange',
      v-else-if='showTextArea'
    )
    .ck-editor-el(
      ref='editorEl',
      v-else
    )
  h2(
    v-else-if='showTextInput && source && !isSmallText',
    v-html='sourceContent'
  )
  h6(
    v-else-if='isSmallText && source',
    v-html='sourceContent'
  )
  HTMLRenderer(
    :add-image-shadow='addImageShadow',
    :format-content='formatContent',
    :html='source',
    :skip-glossary-check='skipGlossaryCheck',
    link-target='_blank',
    v-else-if='source'
  )
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, watch, nextTick } from 'vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import ExpandableTextAreaInput from '@/components/common/ExpandableTextAreaInput.vue'
  import useEditor from '@/composables/useEditor'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { EditorModule } from '@/store/modules/editor'
  import useCourse from '@/composables/useCourse'
  import { useRoute } from 'vue-router/composables'
  import type { PropType } from 'vue'
  import type { Nullable } from '@/services/interfaces/Content'
  import type {
    CourseModule,
    Lesson,
    CourseSectionMap,
    EditorStatePayload,
    EditorSlide,
  } from '@/services/interfaces/Course'

  const props = defineProps({
    source: {
      type: String,
      required: true,
    },
    formatContent: {
      type: Boolean,
      default: false,
    },
    showTextInput: {
      type: Boolean,
      default: false,
    },
    showTextArea: {
      type: Boolean,
      default: false,
    },
    isSmallText: {
      type: Boolean,
      default: false,
    },
    editTitle: String,
    editSubTitle: String,
    placeholder: String,
    showToolbarTextBlockOnly: {
      type: Boolean,
      default: false,
    },
    addImageShadow: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      default: '',
      type: String as PropType<'yes' | 'no' | ''>,
    },
    allowSourceChangesInEditor: Boolean,
    showExtraTextTools: Boolean,
    showBulletTextTools: Boolean,
    editorState: String as PropType<'currentLesson' | 'currentModule'>,
    editorProperty: String as PropType<keyof Lesson | keyof CourseModule>,
    mutateAndQueueFunc: Function as PropType<(data: EditorStatePayload) => void>,
    canDiscardLocalChanges: {
      default: true,
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isTextInputReadOnly: {
      type: Boolean,
      default: false,
    },
    skipGlossaryCheck: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['text-input'])

  const route = useRoute()
  const { inEditorMode: editMode, discardChanges, decodeAndParseSource, isDiscardingAllChanges } = useEditor()
  const { isLargeDesktop } = useBreakpoint()
  const {
    convertStringWithNewLineCharactersIntoArrayOfStrings,
    joinArrayOfStringsWithNewlineCharacter,
    locale,
    formatPhrase,
  } = useCommonMixin()
  const { currentLesson, currentModule } = useCourse()

  const editorEl = ref(null as Nullable<HTMLDivElement>)
  let editorInstance: Nullable<any> = null
  const editorKey = ref(Math.random().toString(36))
  const textInputKey = ref(Math.random().toString(36))
  const textAreaInputKey = ref(Math.random().toString(36))

  const inEditorMode = computed(() => {
    if (props.allowEdit !== '') {
      return isLargeDesktop.value && props.allowEdit === 'yes'
    }
    return isLargeDesktop.value && editMode.value
  })

  const parsedSource = computed(() => {
    if (props.showTextInput || props.showTextArea) return props.source
    return decodeAndParseSource(props.source)
  })

  const sourceContent = computed(() => (props.formatContent ? formatPhrase(props.source) : props.source))
  const textInput = ref<string>(parsedSource.value)

  watch(inEditorMode, (el) => {
    if (el) {
      reInitializeEditorInstance()
    }
  })

  watch(
    () => props.source,
    () => {
      textInput.value = parsedSource.value
      if (!(props.showTextInput || props.showTextArea) && props.allowSourceChangesInEditor) {
        editorInstance?.setData(textInput.value)
      }
    },
  )

  watch(editorKey, () => {
    if (!props.showTextArea && !props.showTextInput) {
      reInitializeEditorInstance()
    }
  })

  watch(textInput, (text) => {
    emit('text-input', text)
    if (props.editorProperty && props.editorState && !isDiscardingAllChanges.value && !props.showTextArea) {
      EditorModule.setEditableState({
        key: `${props.editorState}${props.editorProperty}`,
        path: route.path,
        state: props.editorState,
        property: props.editorProperty,
        value: props.showTextInput || props.showTextArea ? text : encodeURIComponent(text),
        mutateAndQueue: props.mutateAndQueueFunc,
      })
    }
  })

  const reInitializeEditorInstance = () => {
    nextTick(() => {
      editorInstance?.destroy()
      editorInstance = null
      initializeCKEditor()
    })
  }

  const initializeCKEditor = () => {
    if (editorEl.value && !editorInstance) {
      const { ClassicEditor } = window as any
      if (!ClassicEditor) return
      const toolbarConfig: any = {
        placeholder: props.placeholder || '',
        typing: {
          transformations: {
            include: [
              // Use only the 'quotes' and 'typography' groups.
              'quotes',
              'typography',
            ],
            extra: [
              // Add some custom transformations – e.g. for emojis.
              { from: ':)', to: '🙂' },
              { from: ':+1:', to: '👍' },
              { from: ':tada:', to: '🎉' },
            ],
          },
        },
        language: locale.value,
      }

      const toolbarItems = [
        'bold',
        'italic',
        'code',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'codeBlock',
        'blockQuote',
        '|',
        'heading',
        'horizontalLine',
      ]
      if (props.showToolbarTextBlockOnly) {
        toolbarItems.splice(2)
      }
      if (props.showBulletTextTools) {
        toolbarItems.push('bulletedList', 'numberedList')
      }
      if (props.showExtraTextTools) {
        toolbarItems.push('blockQuote', 'heading')
      }

      toolbarConfig.toolbar = toolbarItems
      ClassicEditor.setup(editorEl.value, toolbarConfig).then((editor: any) => {
        editorInstance = editor

        editor.setData(parsedSource.value)
        editor.model.document.on('change:data', () => {
          textInput.value = editor.getData()
        })
      })
    }
  }

  const hasLocalChanges = computed(() => textInput.value !== parsedSource.value)

  const discardChangesCallback = (
    source?: string | EditorStatePayload,
    stateBeforeChange?: {
      currentLesson: Nullable<Lesson>
      currentModule: Nullable<CourseModule>
      lessonSlideshows: Nullable<EditorSlide[]>
    },
  ) => {
    const originalSource =
      (stateBeforeChange?.[props.editorState!] as any)?.[props.editorProperty!] ??
      ((source as EditorStatePayload)?.value as CourseSectionMap)?.prevPropValue ??
      source
    if (typeof originalSource === 'string') {
      if (props.showTextInput || props.showTextArea) {
        textInput.value = originalSource
      } else {
        textInput.value = decodeAndParseSource(originalSource)
      }
    } else if (Array.isArray(originalSource)) {
      textInput.value = joinArrayOfStringsWithNewlineCharacter(originalSource)
    } else {
      textInput.value = parsedSource.value
    }
    // to re-render the text inputs and editor
    editorKey.value = Math.random().toString(36)
    textAreaInputKey.value = Math.random().toString(36)
    textAreaInputKey.value = Math.random().toString(36)
    if (!(props.showTextInput || props.showTextArea)) {
      editorInstance?.setData(textInput.value)
    }
  }

  const updateStateModel = (data: EditorStatePayload) => {
    let source = data.value
    if (Array.isArray(source)) source = joinArrayOfStringsWithNewlineCharacter(source)
    else if (typeof source !== 'string') source = data.apiPayload
    discardChangesCallback(source as string, {
      currentLesson: currentLesson.value,
      currentModule: currentModule.value as CourseModule,
      lessonSlideshows: EditorModule.lessonSlideshows,
    })
  }

  // TODO: investigate if we still need discard local changes
  const discardLocalChanges = () => {
    if (!props.canDiscardLocalChanges) {
      textInput.value = ''
      if (!(props.showTextInput || props.showTextArea)) {
        editorInstance?.setData(textInput.value)
      }
      return
    }
    discardChanges(() =>
      discardChangesCallback(undefined, {
        currentLesson: currentLesson.value,
        currentModule: currentModule.value as CourseModule,
        lessonSlideshows: EditorModule.lessonSlideshows,
      }),
    )
  }

  const onTextAreaInputChange = (text: string) => {
    if (props.editorProperty && props.editorState && !isDiscardingAllChanges.value) {
      EditorModule.setEditableState({
        key: `${props.editorState}${props.editorProperty}`,
        path: route.path,
        state: props.editorState,
        property: props.editorProperty,
        value: convertStringWithNewLineCharactersIntoArrayOfStrings(text),
        mutateAndQueue: props.mutateAndQueueFunc,
      })
    }
  }

  onBeforeUnmount(() => {
    editorInstance?.destroy()
    EditorModule.unsubscribe({ type: 'discard', callback: discardChangesCallback })
    EditorModule.unsubscribe({ type: 'save', callback: updateStateModel })
  })

  onMounted(() => {
    EditorModule.subscribe({
      type: 'discard',
      key: `${props.editorState}${props.editorProperty}`,
      callback: () =>
        discardChangesCallback(textInput.value, {
          currentLesson: currentLesson.value,
          currentModule: currentModule.value as CourseModule,
          lessonSlideshows: EditorModule.lessonSlideshows,
        }),
    })

    EditorModule.subscribe({
      type: 'save',
      key: `${props.editorState}${props.editorProperty}`,
      callback: updateStateModel,
    })

    if (inEditorMode.value) {
      initializeCKEditor()
    }
  })
</script>

<style lang="postcss">
  .text-renderer {
    .ck-editor-wrapper {
      input {
        @apply ketch-h-c40 ketch-bg-transparent;
      }
      .ck-outside-header {
        @apply ketch-flex ketch-justify-end ketch-items-end;
        &.has-edit-title {
          @apply ketch-justify-between;
        }
        .left {
          span {
            @apply ketch-font-normal ketch-text-xs ketch-leading-sm;
          }
        }
        .right h6 {
          @apply hover:ketch-underline ketch-text-editor-primary-color hover:ketch-cursor-pointer ketch-whitespace-nowrap;
        }
      }
      .ck-editor {
        @apply ketch-text-sm ketch-leading-lg ketch-font-body;
        .ck-content {
          @apply ketch-p-c15;
          &.ck-focused {
            @apply ketch-border-editor-primary-color ketch-shadow-none;
          }
          .ck-placeholder:before {
            @apply ketch-text-black ketch-text-opacity-[0.3];
          }
          > * {
            @apply ketch-pb-c10;
          }
          :first-child,
          :last-child {
            @apply ketch-m-0;
          }
          :last-child {
            @apply ketch-pb-0;
          }
          blockquote {
            @apply ketch-mb-c10 ketch-pb-0;
          }
          > pre {
            @apply ketch-p-c10 ketch-mb-c10;
          }
          ul,
          ol {
            @apply ketch-pl-c30 ketch-list-none;
          }
          ul {
            @apply ketch-relative;
            li {
              @apply ketch-pl-c15;
              &:before {
                @apply ketch-inline-block ketch-whitespace-nowrap ketch-left-c30 ketch-absolute;
              }
            }
            > li:before {
              content: '\2022';
            }
            ul {
              @apply ketch-pl-c20;
              > li:before {
                @apply ketch-left-c20;
                content: '\26AC';
              }
              ul {
                > li:before {
                  content: '\203A';
                }
              }
            }
          }
          ol {
            @apply ketch-list-decimal;
            ol {
              list-style-type: lower-alpha;
              ol {
                list-style-type: lower-roman;
              }
            }
          }
          h1,
          h2,
          h3,
          h4 {
            @apply ketch-font-bold;
          }
          a {
            @apply ketch-text-primary-text-color ketch-underline;
          }
        }
      }
    }
  }
</style>
