<template lang="pug">
.lesson-questions-tab-view
    QuizQuestions(
        :add-lesson-question='addQuizQuestion',
        :lesson-questions='lessonQuizQuestions',
        :original-questions='originalQuizQuestions || []',
        :remove-lesson-question='removeNewQuizQuestion',
        v-if='currentLesson && currentLesson.type === "Quiz"'
    )
    QuizQuestions(
        :add-lesson-question='addLessonQuestion',
        :can-drag='true',
        :lesson-questions='lessonQuestions',
        :original-questions='courseLessonQuestions || []',
        :remove-lesson-question='removeNewLessonQuestion',
        v-else
    )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import QuizQuestions from '@/components/course/QuizQuestions.vue'
  import useCourse from '@/composables/useCourse'
  import useEditor from '@/composables/useEditor'
  import type { EditorQuestion } from '@/services/interfaces/Course'
  import { CourseModule } from '@/store/modules/course'
  import { EditorModule } from '@/store/modules/editor'

  const { currentLesson, currentLessonQuestions: courseLessonQuestions } = useCourse()
  const { currentQuizQuestions, currentLessonQuestions } = useEditor()

  const lessonQuizQuestions = computed(() => {
    return (currentQuizQuestions.value?.filter((q) => q._destroy !== true) || []) as EditorQuestion[]
  })

  const lessonQuestions = computed(() => {
    return (currentLessonQuestions.value?.filter((q) => q._destroy !== true) || []) as EditorQuestion[]
  })

  const originalQuizQuestions = computed(() => CourseModule.originalQuizQuestions)
  CourseModule.setQuizQuestions(originalQuizQuestions.value)
  EditorModule.setQuizQuestions(originalQuizQuestions.value)

  const addQuizQuestion = (question: EditorQuestion) => {
    ;(currentQuizQuestions.value as EditorQuestion[])!.push(question)
  }

  const addLessonQuestion = (question: EditorQuestion) => {
    ;(currentLessonQuestions.value as EditorQuestion[])!.push(question)
  }

  const removeNewQuizQuestion = (index: number) => {
    const deleteIndex = (currentQuizQuestions.value as EditorQuestion[])?.findIndex(
      (q) => q.editorId === lessonQuizQuestions.value[index]?.editorId,
    )
    return deleteIndex > -1 ? currentQuizQuestions.value!.splice(deleteIndex, 1) : []
  }

  const removeNewLessonQuestion = (index: number) => {
    const deleteIndex = (currentLessonQuestions.value as EditorQuestion[])?.findIndex(
      (q) => q.editorId === lessonQuestions.value[index]?.editorId,
    )
    return deleteIndex > -1 ? currentLessonQuestions.value!.splice(index, 1) : []
  }
</script>
