<template lang="pug">
.library
    h5.spaced-caption {{ $t('library.bestOfTheInternet') }}
    .top-section
        .left-side
            h1.header-text {{ $t('navigation.library') }}
            h4.description {{ $t('library.description') }}
        .right-side
            .item(
                :key='bullet',
                v-for='bullet in libraryBullets'
            )
                SVGRenderer(
                    :has-hover='false',
                    :icon='checkmark',
                    stroke-color='var(--primary-text-color)',
                    width='12'
                )
                h4(v-html='bullet')
    InterestedCategoriesContents
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted, nextTick } from 'vue'
  import InterestedCategoriesContents from '@/components/personal-landing-page/InterestedCategoriesContents.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useSegment from '@/composables/useSegment'
  import useToursAndHints from '@/composables/useToursAndHints'
  import useBreakpoint from '@/composables/useBreakpoint'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const { isDesktopDevice } = useBreakpoint()
  const { showOrHideToursAndHints, hideAllHints } = useToursAndHints()
  const { translateString } = useI18n()

  const libraryBullets = [
    translateString('library.bullets.bullet1'),
    translateString('library.bullets.bullet2'),
    translateString('library.bullets.bullet3'),
  ]
  const { checkmark } = useIcons()
  const { trackPage, pageViewed } = useSegment()

  onMounted(() => {
    trackPage('Library')
    pageViewed('Library')
    nextTick(() => {
      if (isDesktopDevice.value) showOrHideToursAndHints()
      eventBus.$emit('reset-background-tile-height')
    })
  })

  onUnmounted(() => {
    hideAllHints()
  })
</script>

<style lang="postcss">
  .library {
    .top-section {
      @apply ketch-flex ketch-flex-col md:ketch-flex-row md:ketch-justify-between;
      @apply md:ketch-items-end ketch-mb-c100 ketch-mt-c10;
      .right-side {
        @screen md {
          flex: 0 0 480px;
        }
        @apply ketch-mt-c30 md:ketch-m-0 md:ketch-ml-c60 md:ketch-pr-c20 ketch-space-y-c5;
        .item {
          @apply ketch-flex ketch-items-center ketch-space-x-c10;
          span {
            @apply ketch-font-bold;
          }
        }
      }
      .header-text {
        @apply ketch-text-[50px] ketch-leading-lg ketch-mb-c15 ketch-font-bold;
        span {
          @apply ketch-font-bold;
        }
      }
    }
  }
</style>
