<template lang="pug">
.course-breadcrumbs(:class='{ sticky: isMobileDevice }')
    template(v-if='isMobileDevice')
        router-link.crumb(
            :class='{ "has-bookmark": currentLesson }',
            :key='crumb.title',
            :to='crumb.url',
            v-for='crumb in mobileCrumbs'
        )
            SVGRenderer(
                :has-hover='false',
                :icon='arrowRightIndicator',
                fill-color='white',
                v-if='isMobileDevice',
                width='16'
            )
            h6(v-html='crumb.title')
        BookmarkLesson(v-if='currentLesson')
    template(v-else)
        .crumb.h6(
            :key='crumb.title',
            v-for='(crumb, index) in allCrumbs'
        )
            router-link(
                :to='crumb.url',
                v-html='crumb.title',
                v-if='index < allCrumbs.length - 1'
            )
            span(
                v-else,
                v-html='crumb.title'
            )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import BookmarkLesson from '@/components/course/BookmarkLesson.vue'
  import { useRoute } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'

  const props = defineProps({
    isSearching: {
      type: Boolean,
      default: false,
    },
  })

  const { translateString } = useI18n()
  const route = useRoute()
  const { currentLessonTitle, lessonId, moduleId, currentModuleTitle, currentMobileModuleTitle, currentLesson } =
    useCourse()
  const { arrowRightIndicator } = useIcons()
  const { isMobileDevice } = useBreakpoint()

  const isStartPage = computed(() => route.name === 'CourseModuleStartPage')

  const allCrumbs = computed(() => {
    const crumbs: { url: string; title: string }[] = [
      {
        url: '/trainings',
        title: translateString('trainings.title'),
      },
      {
        url: `/courses/${route.params.courseId}/info`,
        title: translateString('courses.trainingDetails'),
      },
    ]
    if (props.isSearching) {
      crumbs.push({
        url: '',
        title: translateString('search.searchResults'),
      })
      return crumbs
    }
    if (moduleId.value && ((isMobileDevice.value && currentMobileModuleTitle.value) || currentModuleTitle.value)) {
      crumbs.push({
        url: `/courses/${route.params.courseId}/module/${moduleId.value}`,
        title: isMobileDevice.value ? currentMobileModuleTitle.value : currentModuleTitle.value,
      })
    }
    if (lessonId.value && currentLessonTitle.value) {
      crumbs.push({
        url: '',
        title: currentLessonTitle.value,
      })
    }
    if (isStartPage.value) {
      crumbs.push({
        url: '',
        title: translateString('courses.startPage'),
      })
    }
    if (route.name === 'DownloadMaterials') {
      crumbs.push({
        url: '',
        title: translateString('downloads'),
      })
    }
    if (route.name === 'ExamStartPage') {
      crumbs.push({
        url: '',
        title: translateString('courses.examSimulation.title'),
      })
    }
    if (route.name === 'GuidedQuiz') {
      crumbs.push({
        url: '',
        title: translateString('courses.guidedQuizCrumb'),
      })
    }
    return crumbs
  })

  const mobileCrumbs = computed(() => {
    if (isMobileDevice.value) {
      const crumbs = allCrumbs.value.slice(0, -1)
      if (crumbs.length) return crumbs.slice(-1)
    }
    return []
  })
</script>

<style lang="postcss">
  .course-breadcrumbs {
    @apply ketch-flex ketch-space-x-c30 ketch-text-secondary-text-color ketch-flex-wrap;
    @apply ketch-bg-black md:ketch-bg-transparent ketch--mx-c16 sm:ketch--mx-c20 md:ketch-mx-0;
    @apply ketch-h-c30 md:ketch-h-auto ketch-py-c5 ketch-px-c16 sm:ketch-px-c20 md:ketch-p-0;
    &.sticky {
      @apply ketch-sticky ketch-top-0 ketch-z-10 ketch-justify-between;
    }

    a.crumb {
      @apply ketch-flex ketch-items-center ketch-space-x-c16;
      @apply ketch-max-w-[calc(100vw-32px)] sm:ketch-max-w-[calc(100vw-40px)];
      &.has-bookmark {
        @apply ketch-max-w-[calc(100vw-82px)] sm:ketch-max-w-[calc(100vw-90px)];
      }
      svg {
        @apply ketch-rotate-180;
        flex: 0 0 16px;
      }
      h6 {
        @apply ketch-text-white ketch-text-ellipsis ketch-overflow-hidden ketch-whitespace-nowrap;
        span {
          @apply ketch-font-bold;
        }
      }
    }
    .crumb {
      @apply ketch-relative;
      &:nth-of-type(n + 2):before {
        @apply ketch-absolute ketch-left-[-19px];
        content: '->';
      }
    }
  }
</style>
