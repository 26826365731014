import axios from './interceptor'
import { RootFolder } from '../interfaces/Folder'

export default class RootFoldersApi {
  static async getRootFolders(): Promise<RootFolder[]> {
    return axios
      .get('/root_folders')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postInterestedCategories(categoryIds: string[]): Promise<number> {
    return axios
      .post('/interested_categories', { categoryIds })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async interestedCategories(): Promise<RootFolder[]> {
    return axios
      .get('/interested_categories')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async interestedCategoriesContents(): Promise<RootFolder[]> {
    return axios
      .get('/interested_categories_contents')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
