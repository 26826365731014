<template lang="pug">
.quiz-results-wrapper
  LoadingSpinner(v-if='isLoading')
  .results-container(v-else)
    .header-info(v-if='currentLesson')
      HTMLRenderer.h2.lesson-title(:html='currentLesson.title')
      HTMLRenderer.h5(:html='currentLesson.introText')
    .results-summary-wrapper
      .results-summary
        .quiz-status
          SVGRenderer(
            :fill-color='"var(--primary-color)"',
            :has-hover='false',
            :icon='checkboxCircleGreen',
            v-if='passedQuiz'
          )
          img.status-icon(
            :src='wrongAnswerIcon',
            v-else
          )
          .title-status-tries
            h2.title-status {{ lessonQuizStatus }}
            h2.tries {{ tryStatus }}
      .score-summary-cta(:class='{ "has-average-pass-rate": passedQuiz && averageUserTries }')
        .score-summary
          .score-wrapper
            .score-block
              h1.score {{ quizPercentage }}
              .percentage-wrapper
                h1.percent %
                h5 {{ $t('quiz.quizResults.correct') }}
            h5 {{ toActivateCertificateText }}
          CustomProgressBar(
            :divisions='completionQuestions.length',
            :fill-divisions='correctAnswerState',
            :has-cert-logo='false'
          )
        .pass-rate-cta
          .user-average-pass-rate(v-if='passedQuiz && averageUserTries')
            h5.title {{ $t('quiz.quizResults.youCompletedAboveAverage') }}
            hr
            h5.average-pass-tries {{ $t('quiz.quizResults.onAverageUsersNeeded', { tries: averageUserTries }) }}
          .cta
            router-link.retake-quiz(:to='{ name: "Lesson" }')
              h5 {{ $t('startQuizAgain') }}
              SVGRenderer(
                :icon='arrowRight',
                :stroke-color='"var(--primary-text-color)"'
              )
    .test-results-items
      TestResultItem(
        :answers-in-question='item.answers',
        :key='item.questionId',
        :question-title='item.title',
        @correct-answer='onCorrectAnswers($event, index)',
        v-for='(item, index) in completionQuestions'
      )
    .next-lesson-or-module
      KetchUpButton.tertiary(@click.native='nextLessonOrModule')
        h5 {{ lessonCTA }}
        SVGRenderer(
          :has-hover='false',
          :icon='arrowRight',
          :stroke-color='"var(--primary-text-color)"'
        )
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import TestResultItem from '@/components/TestResultItem.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import CustomProgressBar from '@/components/common/CustomProgressBar.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import { useRoute } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'
  import type { Question, QuizCompletion } from '@/services/interfaces/QuizQuestion'

  defineProps({
    lessonCTA: {
      type: String,
      default: '',
    },
  })
  const emit = defineEmits(['next-lesson-or-module'])
  const route = useRoute()
  const { trackPage, pageViewed } = useSegment()
  const { currentCourseId, moduleId, lessonId, currentLesson } = useCourse()
  const { checkboxCircleGreen, wrongAnswerIcon, arrowRight } = useIcons()
  const { translateString } = useI18n()
  const quizCompletion = ref({} as QuizCompletion)
  const correctAnsCount = ref(0)
  const correctAnswerState = ref([] as boolean[])

  const isLoading = computed(() => {
    return !completionQuestions.value.length || !currentLesson.value
  })

  const completionId = computed((): string | (string | null)[] => {
    return route.query.completionId
  })

  const completionQuestions = computed((): Question[] => quizCompletion.value.questions ?? [])

  const passedQuiz = computed(() => quizCompletion.value.passed)

  const quizPercentage = computed((): number => {
    return Math.floor((correctAnsCount.value / completionQuestions.value.length) * 100) || 0
  })

  const toActivateCertificateText = computed(() => {
    return translateString('courses.toActivateCertificate')
  })

  const lessonQuizStatus = computed(() => {
    return passedQuiz.value ? translateString('passedQuiz') : translateString('failedQuiz')
  })

  const userTries = computed(() => currentLesson.value?.quiz?.meta.tries)

  const averageUserTries = computed(() => currentLesson.value?.quiz?.meta.usersAveragePassTries)

  const tryStatus = computed(() => translateString('quiz.quizResults.userTries', { tries: userTries.value }))

  const setActiveLessonAttributes = () => {
    CourseModule.getLesson({ courseId: currentCourseId.value, moduleId: moduleId.value, lessonId: lessonId.value })
  }

  const fetchQuizCompletion = () => {
    CourseApi.getLessonQuizCompletion(
      currentCourseId.value,
      moduleId.value,
      lessonId.value,
      completionId.value as string,
    ).then((completion) => {
      quizCompletion.value = completion
      completion.questions.forEach(() => correctAnswerState.value.push(false))
    })
  }

  const onCorrectAnswers = (isCorrect: boolean, index: number) => {
    if (isCorrect) {
      correctAnsCount.value++
      correctAnswerState.value.splice(index, 1, true)
    }
  }

  const nextLessonOrModule = () => {
    emit('next-lesson-or-module')
  }

  fetchQuizCompletion()
  setActiveLessonAttributes()

  onMounted(() => {
    trackPage('Course Lesson Quiz Results')
    pageViewed('Course Lesson Quiz Results')
  })
</script>

<style lang="postcss">
  .quiz-results-wrapper {
    @apply ketch-w-full ketch-pb-c40;
    .loading {
      @apply ketch-mx-auto ketch-mt-c30;
    }
    .results-container {
      @apply ketch-flex ketch-flex-col;
      .header-info {
        @apply ketch-mb-c30;
        .lesson-title {
          @apply ketch-font-bold ketch-pb-c10 ketch-mb-c10 ketch-relative;
          &::after {
            content: '';
            @apply ketch-h-c1 ketch-left-0 ketch-w-c50 ketch-bg-primary-hover-color ketch-absolute ketch-bottom-0;
          }
        }
      }
      .results-summary-wrapper {
        @apply ketch-flex ketch-flex-col ketch-mb-c20;
        .results-summary {
          @apply ketch-flex ketch-mb-c15;
          .quiz-status {
            @apply ketch-flex ketch-items-center;
            svg,
            img {
              @apply ketch-h-c30 ketch-w-c30 ketch-pr-c10;
            }
            .title-status-tries {
              @apply ketch-flex ketch-items-center ketch-space-x-c5;
              .title-status {
                @apply ketch-font-bold;
              }
            }
          }
        }
        .score-summary-cta {
          @apply ketch-flex ketch-flex-wrap ketch-justify-between sm:ketch-flex-row;
          .score-summary {
            @apply ketch-flex ketch-flex-col ketch-p-c20 ketch-border-border-color ketch--mx-c16 ketch-border-t ketch-border-b ketch-border-l-0 ketch-border-r-0;
            @apply sm:ketch-w-c360 sm:ketch-border-l sm:ketch-border-r sm:ketch-rounded-large sm:ketch--mx-0;
            .score-wrapper {
              @apply ketch-flex md:ketch-flex-col;
              .score-block {
                @apply ketch-flex ketch-items-center ketch-pb-c25 ketch-mr-c30;
                @apply sm:ketch-pb-c30 md:ketch-mr-0;
                .score {
                  @apply ketch-font-bold ketch-text-4xl ketch-pr-c10;
                  @apply sm:ketch-text-[64px] sm:ketch-pr-c15;
                }
                .percentage-wrapper {
                  @apply ketch-flex ketch-flex-col;
                  .percent {
                    @apply ketch-font-bold;
                  }
                }
              }
            }
            .custom-progress-bar {
              @apply ketch-pt-c20 md:ketch-w-full;
            }
          }
          .pass-rate-cta {
            @apply ketch-flex ketch-flex-col ketch-items-end ketch-justify-between sm:ketch-max-w-[270px];
            .user-average-pass-rate {
              @apply ketch-flex ketch-flex-col ketch-bg-secondary-color ketch-rounded-large;
              @apply ketch-mt-c20 ketch-p-c15 sm:ketch-mt-0;
              h5 {
                @apply ketch-text-secondary-foreground-color;
              }
              hr {
                @apply ketch-mt-c12 ketch-mb-[7px] ketch-opacity-20;
              }
              .title {
                @apply ketch-font-bold;
              }
            }
            .cta {
              @apply ketch-mt-c20 md:ketch-mt-0;
              .retake-quiz {
                @apply ketch-flex ketch-items-center ketch-justify-center ketch-space-x-c20 ketch-px-c20 ketch-h-c40;
                @apply ketch-w-[fit-content] ketch-rounded-normal ketch-outline-none;
                @apply ketch-border ketch-border-primary-text-color ketch-bg-transparent;
              }
            }
          }
          &:not(.has-average-pass-rate) {
            @apply ketch-items-end;
          }
        }
      }
      .test-results-items {
        @apply ketch-px-0;
        @apply sm:ketch-space-y-c10;
        @apply md:ketch-px-c20 lg:ketch-px-0;
      }
      .next-lesson-or-module {
        @apply ketch-mt-c40 ketch-text-right;
        button {
          @apply ketch-space-x-c15 ketch-w-auto;
        }
      }
    }
  }
</style>
