<template lang="pug">
.course-exam-content-wrapper
    LoadingSpinner.loading-exam-content(v-if='loadingExamContent')
    .course-exam-content(v-else)
        transition(
            mode='out-in',
            name='fade',
            v-if='isCourseExamPaused && !showPartOneDone && !reviewingQuestion'
        )
            .paused-content(data-cy='paused-content')
                h5.title {{ $t('courses.examSimulation.examPaused') }}
                h5.description {{ $t('courses.examSimulation.examPausedDescription') }}
                .buttons
                    KetchUpButton.tertiary(@click.native='routeToExamStartPage')
                        h5 {{ $t('quiz.questionQuizModal.abortButtonText') }}
                    KetchUpButton.primary(
                        :disabled='continueExamRequest',
                        @click.native='$emit("continue-exam")',
                        data-cy='continue-exam-content'
                    )
                        LoadingSpinner(v-if='continueExamRequest')
                        h5(v-else) {{ $t('courses.examSimulation.continue') }}
        transition(
            mode='out-in',
            name='fade',
            v-else-if='showPartOneDone'
        )
            .part-done
                h3.part {{ $t('courses.examSimulation.part') }} 1 {{ $t('courses.examSimulation.closed') }}
                h5.info {{ $t('courses.examSimulation.donePartInfo') }}
                .cta
                    KetchUpButton.end(@click.native='routeToExamStartPage')
                        h5 {{ $t('courses.examSimulation.end') }}
                    KetchUpButton.primary(@click.native='$emit("continue-exam")')
                        h5 {{ $t('courses.examSimulation.startPart2') }}
        .question-details(v-else-if='currentExamQuestion')
            .subject
                h5.text(v-if='currentExamQuestion.subjectName') {{ currentExamQuestion.subjectName }}
                h5.pick-from-lesson {{ currentExamQuestion.pickFromLessonName }}
            QuestionWithButtons(
                :current-question-number='questionNumberOnPart',
                :in-results-mode='reviewingQuestion',
                :questions='[currentExamQuestion]',
                :show-next-wrong-question-button='showNextWrongQuestionButton',
                :show-skip-question-button='showSkipQuestionButton',
                :total-questions='totalPartQuestions(currentPartIndex)',
                @next-wrong-question='fetchNextWrongQuestion',
                @number-answer='numberAnswer = $event',
                @previous='fetchPreviousQuestion',
                @skip-question='skipQuestion',
                @submit='fetchNextQuestion'
            )
</template>

<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useCourse from '@/composables/useCourse'
  import type { QuestionAnswer, Answer } from '@/services/interfaces/QuizQuestion'
  import { CourseModule } from '@/store/modules/course'
  import CookieHelper from '@/helpers/CookieHelper'
  import QuestionWithButtons from '@/components/common/QuestionWithButtons.vue'
  import useSegment from '@/composables/useSegment'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useGamification from '@/composables/useGamification'
  import type { CourseExamQuestion, ExamCompletionState } from '@/services/interfaces/Course'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import CourseApi from '@/services/api/CourseApi'

  const props = defineProps({
    loadingExamContent: {
      type: Boolean,
      required: true,
    },
    showPartOneDone: {
      type: Boolean,
      required: true,
    },
    continueExamRequest: {
      type: Boolean,
      required: true,
    },
  })
  const emit = defineEmits(['set-no-questions-answered-yet', 'set-show-part-one-done-value', 'continue-exam'])
  const router = useRouter()
  const { convertNumberAnswer, shuffleArray, isKnowledgeCheckCompany } = useCommonMixin()
  const { trackGamificationActivity } = useGamification()
  const {
    currentCourseId,
    course,
    courseExamId,
    isCourseExamPaused,
    currentExamQuestion,
    reviewingQuestion,
    totalPartQuestions,
    totalExamQuestions,
    currentPartIndex,
    answeredQuestions,
    latestExamCompletionId,
    examCompletionIdParam,
  } = useCourse()
  const { trackPage, pageViewed, examCompleted, examQuestionAnswered, examPartCompleted, examQuestionsReviewed } =
    useSegment()
  const { translateString } = useI18n()

  const loadingNextOrPrevQuestion = ref(false)
  const postingAnswer = ref(false)
  const questionNumberOnPart = ref(1)
  const questionNumber = ref(1)
  const selectedAnswer = ref({} as { [key: string]: string[] })
  const questionIdsFromUnansweredQuestion = ref([] as { id: string; pos: number }[])
  const prevQuestionAnswerEntries = ref({} as { [key: string]: Answer[] })
  const reviewingWrongQuestions = ref(false)
  const skippingQuestion = ref(false)
  const reviewedQuestionIds = ref([] as string[])
  const numberAnswer = ref('')

  const questionAnswered = computed(() => {
    return !!selectedAnswer.value[currentExamQuestion.value?.questionId || '']?.length || reviewingQuestion.value
  })

  const postExamState = computed((): ExamCompletionState => {
    return questionNumber.value === totalExamQuestions.value && !props.showPartOneDone ? 'completed' : null
  })

  const answeredQuestionNumberId = computed(() => {
    if (!answeredQuestions.value.length) return ''
    return answeredQuestions.value[0].questionId
  })

  const showNextWrongQuestionButton = computed(
    () => reviewingQuestion.value && !isLastQuestionInReviewMode.value && nextWrongQuestionIndex.value > -1,
  )

  const showSkipQuestionButton = computed(() =>
    currentExamQuestion.value
      ? !isLastQuestionInSubject.value && !isLastQuestion.value && !reviewingQuestion.value
      : false,
  )

  const currentAnsweredQuestionIndex = computed(() =>
    answeredQuestions.value.findIndex((question) => question.questionId === currentExamQuestion.value?.questionId),
  )

  const nextWrongQuestionIndex = computed(() => {
    const allWrongQuestions = answeredQuestions.value.filter((q) => !q.passed)
    const allWrongQuestionIndexes: number[] = []
    allWrongQuestions.forEach((wrongQ) => {
      const index = answeredQuestions.value.findIndex((q) => q.questionId === wrongQ.questionId)
      if (index > -1) {
        allWrongQuestionIndexes.push(index)
      }
    })
    return allWrongQuestionIndexes.find((i) => i > currentAnsweredQuestionIndex.value) || -1
  })

  const isLastQuestion = computed(
    () => totalPartQuestions.value(currentPartIndex.value) === currentExamQuestion.value?.questionNumberOnPart,
  )

  const isLastQuestionInSubject = computed(
    () => currentExamQuestion.value?.totalSubjectQuestions === currentExamQuestion.value?.questionNumber,
  )

  const isLastQuestionInReviewMode = computed(
    () => currentAnsweredQuestionIndex.value === answeredQuestions.value.length - 1,
  )

  watch(
    () => props.loadingExamContent,
    (value) => {
      if (!value) {
        const examPausedBySystem = CookieHelper.getCookieValue('exam_paused_by_system')
        emit('set-show-part-one-done-value', !!examPausedBySystem && isCourseExamPaused.value)
      }
    },
  )

  watch(answeredQuestions, (value) => {
    if (value.length) {
      CourseModule.getCourseExamAnsweredQuestion({
        questionId: answeredQuestionNumberId.value,
        completionId: examCompletionIdParam.value,
      }).then(() => {
        if (currentExamQuestion.value) {
          questionNumber.value = currentExamQuestion.value.questionNumber
          questionNumberOnPart.value = currentExamQuestion.value.questionNumberOnPart
          setOriginalAnswers()
        }
      })
    }
  })

  watch(
    () => props.showPartOneDone,
    (value: boolean) => {
      if (value) questionIdsFromUnansweredQuestion.value = []
    },
  )

  watch(currentExamQuestion, () => {
    if (currentExamQuestion.value) {
      if (currentExamQuestion.value.questionType !== 'number' && !reviewingQuestion.value) {
        if (questionAnswered.value) {
          // set current question answers from the previous answer entries
          CourseModule.setCurrentExamQuestionAnswers(
            prevQuestionAnswerEntries.value[currentExamQuestion.value.questionId],
          )
        } else {
          // shuffle current exam question answers
          CourseModule.setCurrentExamQuestionAnswers(shuffleArray(currentExamQuestion.value.answers))
        }
      }
    }
  })

  const fetchNextQuestion = (answers?: QuestionAnswer[]) => {
    if (answers) {
      const currentQuestionAnswer = answers.find(
        (a) => a.questionId === currentExamQuestion.value?.questionId,
      ) as QuestionAnswer
      eventBus.$set(selectedAnswer.value, currentQuestionAnswer.questionId, currentQuestionAnswer.selectedAnswerIds)
      if (currentExamQuestion.value && currentExamQuestion.value.questionType !== 'number') {
        // set current exam question answers state with the correctly selected answers
        const currentExamQuestionAnswersState = currentExamQuestion.value.answers
        answers.forEach((answer) => {
          answer.selectedAnswerIds.forEach((answerId) => {
            currentExamQuestionAnswersState?.forEach((answerState) => {
              if (answerState.answerId === answerId) {
                answerState.selected = true
              }
            })
          })
        })
        prevQuestionAnswerEntries.value[currentExamQuestion.value.questionId] = currentExamQuestionAnswersState
      }
    }
    if (reviewingQuestion.value) {
      reviewingWrongQuestions.value = false
      fetchNextAnsweredQuestion(answeredQuestions.value[currentAnsweredQuestionIndex.value + 1]?.questionId ?? null)
    } else {
      if (currentExamQuestion.value?.questionId) {
        const currentQuestionIndex = questionIdsFromUnansweredQuestion.value.findIndex(
          (q) => q.id === currentExamQuestion.value!.questionId,
        )
        if (currentQuestionIndex >= 0 && currentQuestionIndex < questionIdsFromUnansweredQuestion.value.length - 1) {
          CourseModule.getExamQuestion(questionIdsFromUnansweredQuestion.value[currentQuestionIndex + 1].id).then(
            (resp) => {
              if (resp && resp.question) {
                questionNumberOnPart.value = resp.question.questionNumberOnPart
                questionNumber.value = resp.question.questionNumber
              }
            },
          )
          return
        }
      }
      fetchNextUnansweredQuestion()
    }
  }

  const fetchNextAnsweredQuestion = (nextAnsweredQuestionId: string | null) => {
    if (isLastQuestionInReviewMode.value) {
      router.push({ name: 'CourseExamResults', params: { completionId: examCompletionIdParam.value } })
    } else {
      if (nextAnsweredQuestionId) {
        if (!reviewedQuestionIds.value.includes(nextAnsweredQuestionId)) {
          reviewedQuestionIds.value.push(nextAnsweredQuestionId)
        }
        loadingNextOrPrevQuestion.value = true
        CourseModule.getCourseExamAnsweredQuestion({
          questionId: nextAnsweredQuestionId,
          completionId: examCompletionIdParam.value,
        })
          .then(() => {
            questionNumber.value = currentExamQuestion.value!.questionNumber
            questionNumberOnPart.value = currentExamQuestion.value!.questionNumberOnPart
          })
          .finally(() => {
            loadingNextOrPrevQuestion.value = false
            setOriginalAnswers()
          })
      }
    }
  }

  const fetchNextWrongQuestion = () => {
    if (nextWrongQuestionIndex.value > -1) {
      reviewingWrongQuestions.value = true
      const nextWrongQuestionId = answeredQuestions.value[nextWrongQuestionIndex.value].questionId
      fetchNextAnsweredQuestion(nextWrongQuestionId)
    }
  }

  const skipQuestion = async () => {
    skippingQuestion.value = true
    loadingNextOrPrevQuestion.value = true
    await CourseModule.skipExamQuestion()
      .then((resp: { question: CourseExamQuestion }) => {
        if (resp && resp.question) {
          questionNumberOnPart.value = resp.question.questionNumberOnPart
          questionNumber.value = resp.question.questionNumber
        }
      })
      .finally(() => {
        loadingNextOrPrevQuestion.value = false
        skippingQuestion.value = false
        eventBus.$toasted.success(translateString('courses.examSimulation.skippedQuestionToastMessage'), {
          duration: 5000,
        })
      })
  }

  const examAnswerPayload = computed(() => {
    let numberAnswer = ''
    let selectedAnswerIds: string[] = []
    if (currentExamQuestion.value?.questionType === 'number') {
      numberAnswer = selectedAnswer.value[currentExamQuestion.value.questionId][0]
      numberAnswer = convertNumberAnswer(numberAnswer)
    } else {
      selectedAnswerIds = selectedAnswer.value[currentExamQuestion.value!.questionId]
    }

    return {
      numberAnswer,
      selectedAnswerIds,
      state: postExamState.value,
      lessonPickId: currentExamQuestion.value!.lessonPickId || null,
    }
  })

  const answeredQuestionCorrectly = computed(() => {
    const correctAnswers =
      currentExamQuestion.value?.answers?.filter((answer) => answer.isCorrect).map((answer) => answer.answerId) || []
    const isCorrectNumberQuestion =
      currentExamQuestion.value?.questionType === 'number'
        ? currentExamQuestion.value?.answers[0].answer === numberAnswer.value
        : true
    return (
      examAnswerPayload.value.selectedAnswerIds.every((answerId) => correctAnswers.includes(answerId)) &&
      examAnswerPayload.value.selectedAnswerIds.length === correctAnswers.length &&
      isCorrectNumberQuestion
    )
  })

  const fetchNextUnansweredQuestion = async () => {
    if (loadingNextOrPrevQuestion.value === true) return
    if (currentExamQuestion.value && (skippingQuestion.value ? false : !questionAnswered.value)) return
    if (
      currentExamQuestion.value &&
      (skippingQuestion.value ? false : !reviewingQuestion.value && !props.showPartOneDone)
    ) {
      emit('set-no-questions-answered-yet', false)
      postingAnswer.value = true
      await CourseModule.postExamAnswer(examAnswerPayload.value)
      postingAnswer.value = false

      await examQuestionAnswered(
        currentCourseId.value,
        course.value!.title,
        courseExamId.value,
        currentExamQuestion.value!.questionId,
        currentPartIndex.value + 1,
        questionNumberOnPart.value,
        totalPartQuestions.value(currentPartIndex.value),
        answeredQuestionCorrectly.value,
      )
    }
    if (
      questionNumberOnPart.value === totalPartQuestions.value(currentPartIndex.value) &&
      !props.showPartOneDone &&
      !skippingQuestion.value
    ) {
      if (currentPartIndex.value === course.value!.exam!.parts.length - 1) {
        trackGamificationActivity('EXAM_COMPLETED', { courseId: currentCourseId.value, moduleId: null, lessonId: null })
        await examCompleted(
          currentCourseId.value,
          course.value!.title,
          courseExamId.value,
          latestExamCompletionId.value,
        )
        if (!latestExamCompletionId.value) return
        if (isKnowledgeCheckCompany.value && latestExamCompletionId.value) {
          await CourseApi.sendExamResultEmail(currentCourseId.value, courseExamId.value, latestExamCompletionId.value)
        }
        router.push({ name: 'CourseExamResults', params: { completionId: latestExamCompletionId.value } }).catch(() => {
          return
        })
      } else {
        // reset questionNumberOnPart to 1 for the start of the next part
        questionNumberOnPart.value = 1
        await examPartCompleted(
          currentCourseId.value,
          course.value!.title,
          courseExamId.value,
          currentPartIndex.value + 1,
        )
        emit('set-show-part-one-done-value', true)
      }
      return
    }

    loadingNextOrPrevQuestion.value = true
    await CourseModule.getNextQuestion()
      .then((resp: { question: CourseExamQuestion }) => {
        if (resp && resp.question) {
          questionNumberOnPart.value = resp.question.questionNumberOnPart
          questionNumber.value = resp.question.questionNumber
          emit(
            'set-no-questions-answered-yet',
            resp.question.questionNumberOnPart === 1 && currentPartIndex.value === 0,
          )
        }
      })
      .finally(() => {
        loadingNextOrPrevQuestion.value = false
        setOriginalAnswers()
      })
  }

  const fetchPreviousAnsweredQuestion = (previousAnsweredQuestionId: string) => {
    const currentReviewedQuestionIndex = reviewedQuestionIds.value.findIndex(
      (id) => id === currentExamQuestion.value!.questionId,
    )
    // splice out the currently reviewed question
    if (currentReviewedQuestionIndex > -1) {
      reviewedQuestionIds.value.splice(currentReviewedQuestionIndex, 1)
    }
    loadingNextOrPrevQuestion.value = true
    CourseModule.getCourseExamAnsweredQuestion({
      questionId: previousAnsweredQuestionId,
      completionId: examCompletionIdParam.value,
    })
      .then(() => {
        questionNumber.value = currentExamQuestion.value!.questionNumber
        questionNumberOnPart.value = currentExamQuestion.value!.questionNumberOnPart
      })
      .finally(() => {
        loadingNextOrPrevQuestion.value = false
        setOriginalAnswers()
      })
  }

  const fetchPreviousQuestion = () => {
    if (reviewingQuestion.value) {
      let previousQuestionId = ''
      const currentQuestionIdIndex = reviewedQuestionIds.value.findIndex(
        (id) => currentExamQuestion.value!.questionId === id,
      )
      if (currentQuestionIdIndex > 0) {
        if (reviewedQuestionIds.value.length === 1) {
          // the previous question is the default first one
          previousQuestionId = answeredQuestions.value[0].questionId
        } else {
          previousQuestionId = reviewedQuestionIds.value[currentQuestionIdIndex - 1]
        }
      } else {
        // set previousQuestionId to default the first question
        previousQuestionId = answeredQuestions.value[0].questionId
      }
      fetchPreviousAnsweredQuestion(previousQuestionId)
    } else {
      if (loadingNextOrPrevQuestion.value || currentExamQuestion.value?.questionNumber === 1) return
      loadingNextOrPrevQuestion.value = true
      CourseModule.getPreviousQuestion(currentExamQuestion.value!.questionId)
        .then((resp) => {
          if (resp.question && resp.question.questionNumberOnPart) {
            if (!questionIdsFromUnansweredQuestion.value.some((q) => q.id === resp.question.questionId)) {
              questionIdsFromUnansweredQuestion.value.push({
                id: resp.question.questionId,
                pos: resp.question.questionNumberOnPart,
              })
              questionIdsFromUnansweredQuestion.value.sort((a, b) => a.pos - b.pos)
            }
            questionNumberOnPart.value = resp.question.questionNumberOnPart
          }
        })
        .finally(() => {
          loadingNextOrPrevQuestion.value = false
          setOriginalAnswers()
        })
    }
  }

  const setOriginalAnswers = () => {
    const answers = currentExamQuestion.value?.answers || []
    if (answers.some((ans) => ans.selected)) {
      eventBus.$set(
        selectedAnswer.value,
        currentExamQuestion.value!.questionId,
        answers.filter((ans) => ans.selected).map((ans) => ans.answerId),
      )
    }
  }

  const routeToExamStartPage = () => {
    router.push({ name: isKnowledgeCheckCompany.value ? 'KnowledgeCheckInfo' : 'ExamStartPage' }).catch(() => {
      return
    })
  }

  if (reviewingQuestion.value) {
    CourseModule.getCourseExamQuestionsAnswered(examCompletionIdParam.value)
  } else {
    fetchNextQuestion()
  }

  onMounted(() => {
    if (reviewingQuestion.value) {
      trackPage('Course Exam Questions Review')
      pageViewed('Course Exam Questions Review')
      examQuestionsReviewed(currentCourseId.value, course.value!.title, courseExamId.value, examCompletionIdParam.value)
    } else {
      trackPage('Course Exam')
      pageViewed('Course Exam')
    }
  })

  onUnmounted(() => {
    CourseModule.resetCurrentExamQuestion()
  })
</script>

<style lang="postcss">
  .course-exam-content-wrapper {
    .loading-exam-content {
      @apply ketch-mt-c20;
    }
    .course-exam-content {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-pb-c25;
      .paused-content {
        @apply sm:ketch-w-c500 ketch-p-c50 ketch-flex ketch-flex-col ketch-items-center;
        .title {
          @apply ketch-font-bold ketch-mb-c25;
        }
        .description {
          @apply ketch-px-c40 ketch-text-center;
        }
        .buttons {
          @apply ketch-space-x-c10 ketch-mt-c50;
        }
      }
      .part-done {
        @apply ketch-flex ketch-flex-col ketch-items-center ketch-max-w-[430px] ketch-mt-[85px];
        .part {
          @apply ketch-font-bold ketch-mb-c25;
        }
        .info {
          @apply ketch-mb-c55 ketch-text-center;
        }
        .cta {
          @apply ketch-flex ketch-items-center ketch-justify-center ketch-space-x-c10;
          .end {
            @apply ketch-border-border-color ketch-w-[100px];
          }
          .primary {
            @apply ketch-w-c200 ketch-px-c15;
          }
        }
      }
      .question-details {
        @apply ketch-p-c20 sm:ketch-p-c50;
        .subject {
          @apply ketch-flex ketch-items-center ketch-mb-c20;
          .text {
            @apply ketch-font-bold ketch-mr-c5;
          }
        }
        .question-with-buttons {
          @apply ketch-max-w-[unset] sm:ketch-max-w-[540px] lg:ketch-min-w-[500px];
          .comment-area {
            @apply ketch-w-full;
          }
        }
        .subtitle {
          @apply ketch-text-secondary-text-color;
        }
      }
    }
  }
</style>
