import { computed, ComputedRef, ref, Ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import type { Validation, ValidationArgs } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import auth_config from '../../auth_config.json'
import Auth0Api from '@/services/api/Auth0Api'
import { UserModule } from '@/store/modules/user'
import useSegment from '@/composables/useSegment'
import eventBus from '@/main'
import useI18n from '@/composables/useI18n'
import CookieHelper from '@/helpers/CookieHelper'
import { useRoute } from 'vue-router/composables'

const { staging, production } = auth_config
interface useAuthenticationReturn {
  emailErrors: ComputedRef<string[]>
  passwordErrors: ComputedRef<string[]>
  signInWithGoogleConnection: () => void
  signInWithFacebookConnection: () => void
  form: Ref<{ [key: string]: string }>
  redirect: ComputedRef<string>
  validator: ComputedRef<Validation<ValidationArgs, any>>
  requestResetPassword: (email: string) => void
  requestingResetPassword: ComputedRef<boolean>
  tokenKey: ComputedRef<string>
  companyKey: ComputedRef<string>
}

export default function useAuthentication(): useAuthenticationReturn {
  const { passwordResetRequested } = useSegment()
  const { translateString } = useI18n()
  const route = useRoute()

  const requestingResetInstructions = ref(false)

  const options = {
    domain: process.env.APP_ENV === 'production' ? production.domain : staging.domain,
    clientId: process.env.APP_ENV === 'production' ? production.clientId : staging.clientId,
    audience: process.env.APP_ENV === 'production' ? production.audience : staging.audience,
  }

  const form = ref({
    userEmail: '',
    password: '',
  } as { [key: string]: string })

  const requiredFieldMessage = translateString('onboarding.errors.requiredField')
  const validations: ValidationArgs<any> = {
    userEmail: { required, email },
    password: { required, minLength: minLength(6) },
  }

  const $v: any = useVuelidate(validations, form.value)
  const validator = computed(() => $v.value)

  const emailErrors = computed((): string[] => {
    const errors: string[] = []
    if (!$v.value.userEmail.$dirty) return errors
    if ($v.value.userEmail.required.$invalid) errors.push(requiredFieldMessage)
    else if ($v.value.userEmail.email.$invalid) errors.push(translateString('onboarding.errors.invalidEmail'))

    return errors
  })
  const passwordErrors = computed((): string[] => {
    const errors: string[] = []
    if (!$v.value.password.$dirty) return errors
    if ($v.value.password.required.$invalid) errors.push(requiredFieldMessage)
    if ($v.value.password!.minLength.$invalid)
      errors.push(translateString('onboarding.errors.Atleast8charactersinlength'))

    return errors
  })

  const redirect = computed(() => {
    if (!eventBus.$route.query.redirect) {
      if (location.search) {
        return location.pathname + location.search + location.hash
      }
      return '/'
    }
    const params = Object.keys(eventBus.$route.query).filter((k) => k !== 'redirect')
    let query = eventBus.$route.query.redirect as string
    if (params.length) {
      params.forEach((key) => {
        query += `&${key}=${eventBus.$route.query[key]}`
      })
    }
    if (eventBus.$route.hash) query += eventBus.$route.hash
    return (eventBus.$route.query.redirect as string).includes('login') ? '/' : query
  })

  const requestingResetPassword = computed(() => requestingResetInstructions.value)

  const signInWithGoogleConnection = () => {
    const isAcademySignup = CookieHelper.getCookieValue('is-academy-signup')
    if (!isAcademySignup && route.name === 'CustomRegistration') {
      CookieHelper.setCrossDomainCookie('is-academy-signup', 'true')
    }
    eventBus.$auth.signInWithSocialConnection(
      Object.assign({}, options, { connection: 'google-oauth2', redirect: redirect.value }),
    )
  }

  const signInWithFacebookConnection = () => {
    eventBus.$auth.signInWithSocialConnection(
      Object.assign({}, options, { connection: 'facebook', redirect: redirect.value }),
    )
  }

  const requestResetPassword = (email: string) => {
    requestingResetInstructions.value = true
    Auth0Api.changePassword(email).then(() => {
      requestingResetInstructions.value = false
      passwordResetRequested(UserModule.email)
      eventBus.$toasted.success(translateString('account.passwordResetResponse'))
    })
  }

  const tokenKey = computed(() => {
    return process.env.APP_ENV === 'production' ? production.tokenKey : staging.tokenKey
  })
  const companyKey = computed(() => {
    return process.env.APP_ENV === 'production' ? production.companyKey : staging.companyKey
  })

  return {
    validator,
    emailErrors,
    passwordErrors,
    signInWithGoogleConnection,
    signInWithFacebookConnection,
    form,
    redirect,
    requestResetPassword,
    requestingResetPassword,
    tokenKey,
    companyKey,
  }
}
