import axios from './interceptor'
import {
  CourseItem,
  CourseModule,
  Lesson,
  nextLesson,
  CourseExamResult,
  CourseExamQuestion,
  CourseExamQuestionAnswered,
  EditorModulePayload,
  CoursePackage,
  Note,
  LessonQuestion,
  EditorLessonPayload,
  CourseSection,
  LeuckentextQuestionItem,
  LessonQuestionAnswer,
  LeuckentextQuiz,
  EditorCoursePayload,
  Tag,
  CourseGroupItem,
  CourseSectionUpdatePayload,
  PlaybackAssetData,
  ExamCompletionState,
} from '../interfaces/Course'
import {
  QuestionAnswer,
  QuizCompletion,
  QuizCompletionResponse,
  ExamQuestionAnswer,
  Question,
  GuidedQuizCompletionAbstractResult,
  SkipExamQuestionParams,
} from '@/services/interfaces/QuizQuestion'
import { Author } from '@/services/interfaces/Common'
import { UserModule } from '@/store/modules/user'
import { FollowUp } from '../interfaces/Course'

export default class CourseApi {
  static async getCourses(payload?: {
    userId?: string
    companyId?: string
    auth0Id?: string
    companyCourses?: boolean
  }): Promise<CourseItem[]> {
    const body: any = { params: {} }
    if (payload?.userId) {
      body.params.userId = payload.userId
    }
    if (payload?.companyId) {
      body.params.companyId = payload.companyId
    }
    if (payload?.auth0Id) {
      body.params.visible = true
      body.params.auth0Id = payload.auth0Id
    }
    if (payload?.companyCourses) {
      body.params.all_company_courses = payload.companyCourses
    }
    return axios
      .get('/courses', body)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseById(courseId: string, userId?: string): Promise<CourseItem> {
    const body: any = {}
    if (userId) {
      body.params = {
        userId,
      }
    }
    return axios
      .get(`/courses/${courseId}`, body)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async createCourse(payload: { course: EditorCoursePayload }): Promise<{ courseId: string }> {
    return axios
      .post('/courses', payload)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async updateCourse(courseId: string, payload: { course: EditorCoursePayload }): Promise<number> {
    return axios
      .put(`/courses/${courseId}`, payload)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseModules(courseId: string): Promise<CourseModule[]> {
    return axios
      .get(`/courses/${courseId}/modules`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getLesson(courseId: string, moduleId: string, lessonId: string): Promise<Lesson> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async nextLesson(courseId: string): Promise<nextLesson> {
    return axios
      .get(`/courses/${courseId}/next_lesson`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postLessonQuizCompletion(
    courseId: string,
    moduleId: string,
    lessonId: string,
    answers: QuestionAnswer[],
    state: 'aborted' | 'completed' | 'timed_out',
  ): Promise<QuizCompletionResponse> {
    return axios
      .post(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/quiz_completion`, {
        questionAnswers: answers,
        state,
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getLessonQuizCompletion(
    courseId: string,
    moduleId: string,
    lessonId: string,
    completionId: string,
  ): Promise<QuizCompletion> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/quiz_completion/${completionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getSpecificGuidedQuizCompletion(
    courseId: string,
    moduleId: string,
    completionId: string,
  ): Promise<QuizCompletion[]> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/guided_quiz_answer/${completionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getGuidedQuizCompletions(
    courseId: string,
    moduleId: string,
  ): Promise<GuidedQuizCompletionAbstractResult[]> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/guided_quiz_completions`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postGuidedQuizAnswer(
    courseId: string,
    moduleId: string,
    payload: {
      completionId: string | null
      questionId: string
      selectedAnswerIds: string[]
      numberAnswer?: string
      state?: 'completed' | ''
    },
  ): Promise<{ completionId: string }> {
    return axios
      .post(`/courses/${courseId}/modules/${moduleId}/guided_quiz_answer`, payload)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getGuidedQuizCompletion(courseId: string, moduleId: string): Promise<QuizCompletion> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/guided_quiz/start`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async startEmbeddedGuidedQuiz(courseId: string, moduleId: string): Promise<QuizCompletion> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/embedded_guided_quiz/start`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getExamCompletionState(
    courseId: string,
    examId: string,
    examCompletionId: string,
  ): Promise<{ state: ExamCompletionState; timeRemainingInSeconds: number | null }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/completion/${examCompletionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async updateExamCompletionTimeRemaining(
    courseId: string,
    examId: string,
    examCompletionId: string,
    timeRemainingInSeconds: number | null,
  ): Promise<number> {
    return axios
      .put(`/courses/${courseId}/exam/${examId}/completion/${examCompletionId}/update_time_remaining`, {
        timeRemainingInSeconds,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getExamResults(
    courseId: string,
    examId: string,
    page: number,
  ): Promise<{
    results: CourseExamResult[]
    no_more_results: boolean
    total_results: number
    remaining_results: number
  }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/exam_results`, {
        params: {
          page,
        },
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getExamResult(
    courseId: string,
    examId: string,
    examCompletionId: string,
  ): Promise<{ result: CourseExamResult }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/exam_results/${examCompletionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async sendExamResultEmail(courseId: string, examId: string, examCompletionId: string): Promise<number> {
    return axios
      .post(`/courses/${courseId}/exam/${examId}/exam_results/${examCompletionId}/send_result_email`)
      .then((resp) => resp.status as any)
      .catch((err) => Promise.reject(err))
  }

  static async getNextQuestion(
    courseId: string,
    examId: string,
    unpauseQuestion: boolean,
  ): Promise<{
    question: CourseExamQuestion
    examCompletionId: string | null
    examCompletionState: ExamCompletionState
  }> {
    const body: any = {}
    if (unpauseQuestion) {
      body.params = {
        unpause_question: unpauseQuestion,
      }
    }
    return axios
      .get(`/courses/${courseId}/exam/${examId}/next_question`, body)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async skipExamQuestion(
    courseId: string,
    examId: string,
    payload: SkipExamQuestionParams,
  ): Promise<{ question: CourseExamQuestion; examCompletionId: string }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/skip_question`, {
        params: payload,
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getPreviousQuestion(
    courseId: string,
    examId: string,
    currentQuestionId: string,
  ): Promise<{ question: CourseExamQuestion }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/previous_question`, { params: { questionId: currentQuestionId } })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getExamQuestion(
    courseId: string,
    examId: string,
    questionId: string,
  ): Promise<{ question: CourseExamQuestion }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/questions/${questionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async pauseExam(
    courseId: string,
    examId: string,
    examCompletionId: string | null,
    timeRemainingInSeconds: number,
  ): Promise<number> {
    return axios
      .post(`/courses/${courseId}/exam/${examId}/pause_exam`, {
        examCompletionId,
        timeRemainingInSeconds,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async postExamQuestionAnswer(
    courseId: string,
    examId: string,
    payload: ExamQuestionAnswer,
  ): Promise<{ examCompletionId: string }> {
    return axios
      .post(`/courses/${courseId}/exam/${examId}/exam_answer`, payload)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseExamQuestionsAnswered(
    courseId: string,
    examId: string,
    examCompletionId: string,
  ): Promise<{ questions: CourseExamQuestionAnswered[] }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/${examCompletionId}/questions`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseExamAnsweredQuestion(
    courseId: string,
    examId: string,
    examCompletionId: string,
    questionId: string,
  ): Promise<{ question: CourseExamQuestion }> {
    return axios
      .get(`/courses/${courseId}/exam/${examId}/${examCompletionId}/question/${questionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postLessonFeedback(
    courseId: string,
    moduleId: string,
    payload: { comment: string; lessonId: string; rating: number },
  ): Promise<number> {
    return axios
      .post(`/courses/${courseId}/modules/${moduleId}/lesson_feedback`, payload)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async postQuestionFeedback(questionId: string, questionTitle: string, comment: string): Promise<number> {
    return axios
      .post(`/questions/${questionId}/question_feedback`, {
        questionTitle,
        comment,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getPackageBundle(packageId: string): Promise<CoursePackage> {
    return axios
      .get(`/course_bundles/${packageId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async startCourseTrial(courseIds: string[]): Promise<number> {
    return axios
      .post('/courses/start_trial', {
        courseIds,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseBundles(): Promise<CoursePackage[]> {
    return axios
      .get('/course_bundles')
      .then((resp) => (resp.data as any).courseBundles)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseBundleSignupIntroText(courseBundlePrefix: string): Promise<{ signupIntroText: string }> {
    return axios
      .get(`/course_bundle/${courseBundlePrefix}/signup_intro_text`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async saveLessonNote(courseId: string, moduleId: string, lessonId: string, content: string): Promise<Note> {
    return axios
      .put(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/note`, { content })
      .then((resp) => (resp.data as any).note)
      .catch((err) => Promise.reject(err))
  }

  static async getLessonNote(courseId: string, moduleId: string, lessonId: string): Promise<Note | null> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/note`)
      .then((resp) => (resp.data as any).note)
      .catch((err) => Promise.reject(err))
  }

  static async getRandomQuestion(courseIds: string[]): Promise<Question> {
    return axios
      .get('/courses/random_question', { params: { courseIds } })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postRandomQuestionAnswer(
    lessonId: string,
    questionId: string,
    selectedAnswerIds: string[],
  ): Promise<Question> {
    return axios
      .post('/courses/random_question', { lessonId, questionId, selectedAnswerIds })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async reorderLessons(
    moduleLessons: { moduleId: string; lessons: { id: string; position: number }[] }[],
  ): Promise<number> {
    return axios
      .put(
        '/modules/reorder_lessons',
        { moduleLessons },
        {
          params: {
            companyId: UserModule.currentCompany!.id,
          },
        },
      )
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async reorderModules(
    courseId: string,
    courseModules: { moduleId: string; position: number }[],
  ): Promise<number> {
    return axios
      .put(
        `/courses/${courseId}/reorder_modules`,
        { courseModules },
        {
          params: {
            companyId: UserModule.currentCompany!.id,
          },
        },
      )
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateLessonContent(
    courseId: string,
    moduleId: string,
    lessonId: string,
    payload: {
      lesson: EditorLessonPayload
    },
  ): Promise<number> {
    return axios
      .put(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`, payload, {
        params: {
          companyId: UserModule.currentCompany!.id,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async createLessonContent(
    courseId: string,
    moduleId: string,
    payload: {
      lesson: EditorLessonPayload
    },
  ): Promise<{ lessonId: string }> {
    return axios
      .post(`/courses/${courseId}/modules/${moduleId}/lessons`, payload, {
        params: {
          companyId: UserModule.currentCompany!.id,
        },
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getCurrentLessonQuestions(
    courseId: string,
    moduleId: string,
    lessonId: string,
  ): Promise<LessonQuestion[]> {
    return axios
      .get(`/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/questions`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getMuxUploadData(): Promise<{ id: string; url: string }> {
    return axios
      .get('/get_mux_upload_url')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getMuxPlaybackIds(uploadId: string): Promise<PlaybackAssetData> {
    return axios
      .get(`/mux_videos/${uploadId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getMuxAssetStatus(assetId: string): Promise<{ status: 'ready' | 'preparing' | 'errored' }> {
    return axios
      .get(`/asset_status/${assetId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async uploadData(
    formData: FormData,
  ): Promise<{ id: string; storage: string; metadata: { filename: string; size: number; mime_type: string } }> {
    return axios
      .post('/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getAllAuthors(): Promise<Author[]> {
    return axios
      .get('/authors')
      .then((resp) => (resp.data as any).authors)
      .catch((err) => Promise.reject(err))
  }

  static async createModule(
    courseId: string,
    payload: {
      module: EditorModulePayload
    },
  ): Promise<{ moduleId: string }> {
    return axios
      .post(`/courses/${courseId}/modules`, payload, {
        params: {
          companyId: UserModule.currentCompany!.id,
        },
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async updateModuleContent(
    courseId: string,
    moduleId: string,
    payload: {
      module: EditorModulePayload
    },
  ): Promise<number> {
    return axios
      .put(`/courses/${courseId}/modules/${moduleId}`, payload, {
        params: {
          companyId: UserModule.currentCompany!.id,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getCourseSections(companyId: string): Promise<{ sections: CourseSection[] }> {
    return axios
      .get(`/companies/${companyId}/course_sections`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async updateCompanyAssignedCourse(
    companyId: string,
    courseSectionId: string,
    assignedCourseId: string,
    payload: { orderNumber?: number; promoted?: boolean; visible?: boolean },
  ): Promise<number> {
    return axios
      .put(
        `/companies/${companyId}/course_sections/${courseSectionId}/assigned_courses/${assignedCourseId}`,
        {
          assignedCourse: payload,
        },
        {
          params: {
            companyId: UserModule.currentCompany!.id,
          },
        },
      )
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateCourseSection(
    companyId: string,
    courseSectionId: string,
    payload: CourseSectionUpdatePayload,
  ): Promise<number> {
    return axios
      .put(`/companies/${companyId}/course_sections/${courseSectionId}`, payload)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async createCourseSection(
    companyId: string,
    payload: {
      courseSection: {
        name: string
        description: string
        bulletPoints: string[]
        assignedCourses: { courseId: string; promoted: boolean; visible: boolean; orderNumber: number }[]
      }
    },
  ): Promise<number> {
    return axios
      .post(`/companies/${companyId}/course_sections`, payload)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async assignCourseToSection(
    companyId: string,
    courseSectionId: string,
    payload: { orderNumber: number; promoted: boolean; visible: boolean; courseId: string },
  ): Promise<number> {
    return axios
      .post(
        `/companies/${companyId}/course_sections/${courseSectionId}/assigned_courses`,
        {
          assignedCourse: payload,
        },
        {
          params: {
            companyId: UserModule.currentCompany!.id,
          },
        },
      )
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async deleteCourseFromSection(
    companyId: string,
    courseSectionId: string,
    assignedCourseId: string,
  ): Promise<number> {
    return axios
      .delete(`/companies/${companyId}/course_sections/${courseSectionId}/assigned_courses/${assignedCourseId}`, {
        params: {
          companyId: UserModule.currentCompany!.id,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async assignCompanyCourseToUser(auth0Id: string, companyId: string, courseIds: string[]): Promise<number> {
    return axios
      .post(`/companies/${companyId}/users/${auth0Id}/company_courses`, {
        courseIds,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getQuestion(questionId: string): Promise<Question> {
    return axios
      .get(`/questions/${questionId}`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async createQuestion(question: LessonQuestion): Promise<{ id: string }> {
    return axios
      .post('/questions', { question })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async updateQuestion(question: LessonQuestion): Promise<Question> {
    return axios
      .put(`/questions/${question.id}`, { question })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async deleteQuestion(questionId: string): Promise<number> {
    return axios
      .delete(`/questions/${questionId}`)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async deleteQuestionAnswer(answerId: string): Promise<number> {
    return axios
      .delete(`/answers/${answerId}`)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateQuestionAnswer(answer: LessonQuestionAnswer): Promise<number> {
    return axios
      .put(`/answers/${answer.id}`, { answer })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async createQuestionAnswer(answer: LessonQuestionAnswer): Promise<{ id: string }> {
    return axios
      .post('/answers', { answer })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async assignCompanyCourseToGroup(groupId: string, companyId: string, courseIds: string[]): Promise<number> {
    return axios
      .post(`/companies/${companyId}/groups/${groupId}/assign_courses`, {
        courseIds,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async deleteCompanyCourseFromGroup(groupId: string, companyId: string, courseIds: string[]): Promise<number> {
    return axios
      .delete(`/companies/${companyId}/groups/${groupId}/assign_courses`, {
        data: {
          courseIds,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getGroupAssignedCourses(companyId: string, groupId: string): Promise<CourseItem[]> {
    return axios
      .get(`/companies/${companyId}/groups/${groupId}/assign_courses`)
      .then((resp) => resp.data.courses)
      .catch((err) => Promise.reject(err))
  }

  static async postLeuckentextAnswers(
    leuckentextId: string,
    payload: {
      lessonId: string
      leuckentextItems: { id: string; text: string }[]
    },
  ): Promise<{ completionId: string }> {
    return axios
      .post(`/leuckentexts/${leuckentextId}/answer`, payload)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async deleteLeuckentext(leukentextId: string, lessonId: string): Promise<number> {
    return axios
      .delete(`/leuckentexts/${leukentextId}`, {
        data: {
          lessonId,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateLeuckentext(payload: LeuckentextQuiz): Promise<number> {
    return axios
      .put(`/leuckentexts/${payload.id}`, payload)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async createLeuckentext(payload: LeuckentextQuiz): Promise<number> {
    return axios
      .post('/leuckentexts', payload)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async createLeuckentextAnswers(
    id: string,
    lessonId: string,
    leuckentextItems: LeuckentextQuestionItem[],
  ): Promise<number> {
    return axios
      .post(`/leuckentexts/${id}/answer`, {
        lessonId,
        leuckentextItems,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getLeuckentextCompletion(
    leuckentextId: string,
    completionId: string,
  ): Promise<{ attempt: number; correctItemIds: string[]; leuckentextItems: LeuckentextQuestionItem[] }> {
    return axios
      .get(`/leuckentexts/${leuckentextId}/results/${completionId}`)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async deleteFollowup(followupId: string): Promise<number> {
    return axios
      .delete(`/follow_ups/${followupId}`)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateFollowup(
    followupId: string,
    payload: { lessonId: string; date: string; comment: string },
  ): Promise<string> {
    return axios
      .put(`/follow_ups/${followupId}`, payload)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async createFollowup(payload: { lessonId: string; date: string; comment: string }): Promise<string> {
    return axios
      .post('/follow_ups', payload)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async getFollowups(): Promise<FollowUp[]> {
    return axios
      .get('/follow_ups')
      .then((resp) => resp.data.followUps)
      .catch((err) => Promise.reject(err))
  }

  static async getTags(): Promise<{ tags: Tag[] }> {
    return axios
      .get('/tags')
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async createTag(title: string): Promise<{ tag: Tag }> {
    return axios
      .post('/tags', {
        tag: {
          title,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async createCourseGroup(payload: {
    name: string
    orderingNumber: number
    courseId: string
  }): Promise<{ courseGroup: CourseGroupItem }> {
    return axios
      .post('/course_groups', {
        course_group: {
          name: payload.name,
          orderingNumber: payload.orderingNumber,
          courseId: payload.courseId,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async updateCourseGroup(payload: { name: string; courseGroupId: string }): Promise<number> {
    return axios
      .put(`/course_groups/${payload.courseGroupId}`, {
        course_group: {
          name: payload.name,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async deleteCourseGroup(courseGroupId: string): Promise<number> {
    return axios
      .delete(`/course_groups/${courseGroupId}`)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }
}
