<template lang="pug">
.search-results(data-cy='search-results')
    SearchLoader(v-if='isLoading')
    template(v-else)
        h2.title-with-underline {{ headlineCopy }}
        GenericContent(
            :content='searchResults',
            :limit='limit'
        )
</template>

<script setup lang="ts">
  import { watch, ref, provide, computed, onMounted } from 'vue'
  import GenericContent from '@/components/curated-content/GenericContent.vue'
  import SearchApi from '@/services/api/SearchApi'
  import useSegment from '@/composables/useSegment'
  import SearchLoader from '@/components/loaders/SearchLoader.vue'
  import { useRoute } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'
  import type { Nullable } from '@/services/interfaces/Folder'
  import type { CourseSearchItem } from '@/services/interfaces/Course'
  import useCommonMixin from '@/composables/useCommonMixin'

  const route = useRoute()
  const { trackPage, pageViewed } = useSegment()
  const { translateString } = useI18n()
  const { setIsLoadingComponentViewData } = useCommonMixin()

  const searchResults = ref(null as Nullable<CourseSearchItem[]>)
  const limit = 10
  provide('itemPurpose', 'search-result')

  const isLoading = computed(() => {
    return searchResults.value === null
  })

  const term = computed(() => {
    return (route.query.q as string) || ''
  })

  const headlineCopy = computed(() => {
    if (!searchResults.value?.length) return `${translateString('search.topResultsText')} "${term.value}"`
    return `${searchResults.value.length} ${translateString('search.resultsText')} "${term.value}"`
  })

  const routeQuery = computed((): { q: string } => {
    return route.query as { q: string }
  })

  const findSearchTerm = (term: string) => {
    searchResults.value = null
    SearchApi.search(term).then((data) => {
      searchResults.value = data as CourseSearchItem[]
    })
  }

  onMounted(() => {
    trackPage('Search Results')
    pageViewed('Search Results')
  })

  watch(routeQuery, (query: { q: string }) => {
    if (query.q) {
      findSearchTerm(query.q)
    }
  })

  watch(isLoading, (value) => {
    setIsLoadingComponentViewData(value)
  })

  findSearchTerm(term.value)
</script>

<style lang="postcss">
  .search-results {
    .loading {
      @apply ketch-mx-auto;
    }
    > h2 {
      @apply ketch-font-bold ketch-mb-c30 ketch-pb-c10 md:ketch-mb-c70 md:ketch-text-2xl;
    }
  }
</style>
