<template lang="pug">
.custom-progress-bar
    img.progress-marker(
        :src='logoMarker',
        :style='{ left: `${progressThumbPosition}px` }',
        v-if='false',
        v-show='fillDivisions.some((div) => !div) && showThumb'
    )
    span.marker(
        :class='{ fill: fillDivisions[marker - 1] }',
        :key='marker',
        v-for='marker in divisions'
    )
    img.cert(
        :src='certLogo',
        v-if='hasCertLogo'
    )
</template>

<script setup lang="ts">
  import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue'
  import type { PropType } from 'vue'
  import eventBus from '@/main'
  import useIcons from '@/composables/useIcons'

  const props = defineProps({
    divisions: {
      type: Number,
      default: 1,
    },
    fillDivisions: {
      type: Array as PropType<boolean[]>,
      default: () => [],
    },
    startAt: {
      type: Number,
      default: -10,
    },
    hasCertLogo: {
      type: Boolean,
      default: true,
    },
  })
  const { logoMarker, certLogo } = useIcons()
  const progressThumbPosition = ref(0)
  const showThumb = ref(true)
  const timerId = ref(0)

  const calculateProgressThumbPosition = () => {
    progressThumbPosition.value = props.startAt
    nextTick(() => {
      const markers = eventBus.$el.querySelectorAll('.marker.fill')
      markers?.forEach((el) => {
        progressThumbPosition.value += (el as HTMLSpanElement).offsetWidth
      })
      progressThumbPosition.value--
    })
  }

  const onResize = () => {
    showThumb.value = false
    if (timerId.value) {
      window.clearTimeout(timerId.value)
    }
    timerId.value = window.setTimeout(() => {
      calculateProgressThumbPosition()
      showThumb.value = true
    }, 200)
  }

  onMounted(() => {
    window.addEventListener('resize', onResize)
    calculateProgressThumbPosition()
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })
</script>

<style lang="postcss">
  .custom-progress-bar {
    @apply ketch-flex ketch-justify-between ketch-items-center ketch-w-[calc(100%-10px)];
    @apply ketch-relative md:ketch-mr-c10 ketch-pr-c10 md:ketch-pr-0;
    .marker {
      @apply ketch-inline-block ketch-border ketch-border-primary-text-color ketch-w-full ketch-h-c10 ketch-relative;
      &:first-of-type {
        @apply ketch-rounded-l-md;
      }
      &:last-of-type {
        @apply ketch-rounded-r-md;
      }
      &.fill {
        @apply ketch-bg-primary-color ketch-border-primary-color;
        border-right-color: rgba(0, 0, 0, 0.15);
      }
      + .marker {
        border-left-color: transparent;
        border-left-width: 0px;
      }
      &.fill + .fill {
        @apply ketch-border-l-primary-color;
      }
    }
    .progress-marker {
      @apply ketch-w-c20 ketch--left-c10 ketch-absolute ketch-z-10;
    }
    .cert {
      @apply ketch-absolute ketch-z-10 ketch-right-0;
      width: 22px;
      height: 22.58px;
      @screen md {
        right: -11px;
      }
    }
  }
</style>
