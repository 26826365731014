<template lang="pug">
.browser-extension-modal
    VideoPlayer(
        :content-type='"browser_extension"',
        :course-id='courseId',
        :lesson-id='lessonId',
        :module-id='moduleId',
        :poster-image='getPosterImage(videoUrl)',
        :video-source='videoUrl'
    )
    h5.caption {{ $t('browserExtension.modal.description') }}
    h5.description {{ $t('browserExtension.modal.description') }}
    .extensions
        KetchUpButton.tertiary.chrome(@click.native='openChromeWebStore')
            img(
                :src='chrome',
                alt='chrome'
            )
            h5 {{ $t('browserExtension.modal.downloadForChrome') }}
        KetchUpButton.tertiary.edge(@click.native='openEdgeWebStore')
            img(
                :src='edge',
                alt='chrome'
            )
            h5 {{ $t('browserExtension.modal.downloadForEdge') }}
</template>

<script setup lang="ts">
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import useVideo from '@/composables/useVideo'
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'

  defineProps({
    courseId: String,
    lessonId: String,
    moduleId: String,
  })

  const videoUrl = 'https://stream.mux.com/u6w1oO94jk6KMRgdNj00U7IWkZUdAWc87dlHz1axZ5008.m3u8'
  const { getPosterImage } = useVideo()
  const { chrome, edge } = useIcons()

  const openChromeWebStore = () => {
    window.open('https://chrome.google.com/webstore/category/extensions', '_blank')
  }

  const openEdgeWebStore = () => {
    window.open('https://microsoftedge.microsoft.com/addons/Microsoft-Edge-Extensions-Home', '_blank')
  }
</script>

<style lang="postcss">
  .browser-extension-modal {
    @apply ketch-pb-c30;
    .video-player {
      @apply ketch-max-h-[400px];
      .plyr {
        @apply ketch-rounded-large;
        .plyr__controls {
          @apply ketch-pt-c6 sm:ketch-pt-c15;
        }
      }
    }
    .caption {
      @apply ketch-font-bold ketch-my-c15;
    }
    .extensions {
      @apply ketch-flex ketch-justify-end ketch-space-x-c30 ketch-mt-c40;
      .chrome,
      .edge {
        @apply ketch-space-x-c8 ketch-flex ketch-items-center ketch-w-auto ketch-px-c10;
        img {
          @apply ketch-w-c20 ketch-h-c20 ketch-filter-none ketch-opacity-100;
        }
        h5 {
          @apply ketch-whitespace-nowrap;
        }
      }
    }
  }
</style>
