<template lang="pug">
.test-result-item
    img.icon.correct(
        :src='getCheckmarkIcon',
        v-if='checkAllAnswersCorrect'
    )
    img.icon(
        :src='crossDanger',
        v-else
    )
    .question-answer
        HTMLRenderer.question(:html='questionTitle')
        .correct-answers
            .answer(
                :key='item.answerId',
                v-for='item in selectedCorrectAnswers'
            )
                SVGRenderer(
                    :icon='checkedCircle',
                    :stroke-color='"#28bd2e"',
                    height='25',
                    width='25'
                )
                h5 {{ item.answer }}
        .wrong-answers
            .answer(
                :key='item.answerId',
                v-for='item in unChosenCorrectAnswers'
            )
                SVGRenderer(
                    :icon='checkedCircle',
                    :stroke-color='"var(--primary-text-color)"',
                    height='25',
                    width='25'
                )
                h5 {{ item.answer }}
            .answer(
                :key='item.answerId',
                v-for='item in selectedWrongAnswers'
            )
                SVGRenderer(
                    :icon='wrongAnswerIcon',
                    height='25',
                    width='25'
                )
                h5 {{ item.answer }}
</template>

<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import type { PropType } from 'vue'
  import type { Answer } from '@/services/interfaces/QuizQuestion'

  const props = defineProps({
    questionTitle: {
      type: String,
      required: true,
    },
    answersInQuestion: {
      type: Array as PropType<Answer[]>,
      required: true,
    },
  })
  const emit = defineEmits(['correct-answer'])
  const { isMobileDevice } = useBreakpoint()
  const { checkmark, checkmarkIconMobile, crossDanger, wrongAnswerIcon, checkedCircle } = useIcons()

  const getCheckmarkIcon = computed((): string => {
    return isMobileDevice.value ? checkmarkIconMobile.value : checkmark.value
  })

  const allCorrectAnswers = computed((): Answer[] => {
    return props.answersInQuestion.filter((el) => el.isCorrect)
  })

  const checkAllAnswersCorrect = computed((): boolean => {
    return allCorrectAnswers.value.length === selectedCorrectAnswers.value.length
  })

  const selectedCorrectAnswers = computed((): Answer[] => {
    return props.answersInQuestion.filter((el) => el.isCorrect && el.selected)
  })

  const unChosenCorrectAnswers = computed((): Answer[] => {
    return props.answersInQuestion.filter((el) => el.isCorrect && !el.selected)
  })

  const selectedWrongAnswers = computed((): Answer[] => {
    return props.answersInQuestion.filter((el) => el.selected && !el.isCorrect)
  })

  onMounted(() => {
    emit('correct-answer', checkAllAnswersCorrect.value)
  })
</script>

<style lang="postcss">
  .test-result-item {
    @apply ketch-min-h-[60px] ketch-max-w-[650px] ketch-grid ketch-border-t-0 ketch-border-b ketch-border-border-color;
    @apply sm:ketch-border sm:ketch-rounded-large md:ketch-min-h-[120px];
    grid-template-columns: 60px auto;
    @screen sm {
      grid-template-columns: 120px auto;
    }
    &:last-of-type {
      @apply ketch-border-b-0 sm:ketch-border-b;
    }
    .icon {
      @apply ketch-place-self-center ketch-h-c20 ketch-w-c20;
    }
    .question-answer {
      @apply ketch-flex ketch-flex-col ketch-items-start ketch-justify-center ketch-border-l ketch-border-border-color;
      @apply ketch-px-c20 ketch-py-c10;
      .question {
        @apply ketch-pb-c5;
        @apply md:ketch-pb-c10;
      }
      .answer {
        @apply ketch-flex ketch-items-center;
        svg {
          @apply ketch-h-c25 ketch-w-c25 ketch-pr-[7px];
          @apply md:ketch-w-c30 md:ketch-h-c30 md:ketch-pr-c10;
          flex: 0 0 25px;
        }
      }
      .wrong-answers,
      .correct-answers {
        @apply ketch-space-y-c6;
      }
    }
  }
  .ketch-dark .test-result-item {
    img.correct {
      filter: invert(1);
    }
  }
</style>
