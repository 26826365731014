<template lang="pug">
.sale-banner-wrapper(
  :style='{ backgroundColor: bannerBackgroundColor, color: bannerColor }',
  ref='saleBannerElement'
)
  .sale-banner.component-spacing
    .sale-icon
      SVGRenderer(
        :has-hover='false',
        :height='isMobileDevice ? "20px" : "30px"',
        :icon='sale',
        :stroke-color='bannerColor',
        :width='isMobileDevice ? "20px" : "30px"'
      )
    .sale-details
      h4(v-html='saleTitleAndDescription')
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, nextTick } from 'vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  const emit = defineEmits(['sale-banner-height'])

  const { sale } = useIcons()
  const { isMobileDevice } = useBreakpoint()

  const saleBannerElement = ref(null as HTMLDivElement | null)

  const saleBannerTitle = computed(() => process.env.VUE_APP_SALE_BANNER_TITLE)

  const saleBannerDescription = computed(() => process.env.VUE_APP_SALE_BANNER_DESCRIPTION)

  const bannerBackgroundColor = computed(() => {
    const envValue = process.env.VUE_APP_SALE_BANNER_BACKGROUND_COLOR_CODE
    return envValue ? `#${envValue}` : '#080d22'
  })

  const bannerColor = computed(() => {
    const envValue = process.env.VUE_APP_SALE_BANNER_FOREGROUND_COLOR_CODE
    return envValue ? `#${envValue}` : '#ffffff'
  })

  const saleTitleAndDescription = computed(() => `${saleBannerTitle.value}: <b>${saleBannerDescription.value}</b>`)

  onMounted(() => {
    nextTick(() => {
      if (saleBannerElement.value) {
        emit('sale-banner-height', saleBannerElement.value.getBoundingClientRect().height)
      }
    })
  })

  onBeforeUnmount(() => {
    emit('sale-banner-height', 0)
  })
</script>

<style lang="postcss" scoped>
  .sale-banner-wrapper {
    @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center;
    .sale-banner {
      @apply ketch-py-c10 ketch-flex ketch-items-center ketch-space-x-c10;
      .sale-icon {
        @apply ketch-max-h-[30px];
      }
      .sale-details {
        @apply ketch-flex ketch-items-center ketch-space-x-c5;
      }
    }
  }
</style>
