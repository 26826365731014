interface useDurationReturn {
  formatDurationToHHMM: (duration: number) => string
}

export default function useDuration(): useDurationReturn {
  const formatDurationToHHMM = (duration: number): string => {
    const hours = Math.floor(duration / 60).toString()
    const minutes = Math.trunc(duration % 60).toString()
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
  }

  return {
    formatDurationToHHMM,
  }
}
