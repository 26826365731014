import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { UserModule } from '@/store/modules/user'
import CookieHelper from '@/helpers/CookieHelper'
import auth_config from '../../../auth_config.json'

const { staging, production } = auth_config
const tokenKey = process.env.APP_ENV === 'production' ? production.tokenKey : staging.tokenKey

axios.interceptors.request.use(
  (config) => {
    const jwt = CookieHelper.getCookieValue(tokenKey)
    // if (!config.headers) {
    //   config.headers = {}
    // }

    if (!config.headers.Authorization && jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
      Sentry.setUser({ email: UserModule.email, name: UserModule.fullname, id: UserModule.auth0Id })
    }

    const companyId = UserModule.currentCompany?.id
    if (companyId) {
      config.params = {
        ...config.params,
        current_company_id: companyId,
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        CookieHelper.removeCrossDomainCookie(tokenKey)
        if (!window.location.pathname.includes('login')) {
          window.location.href = `/login?redirect=${encodeURIComponent(
            window.location.pathname + window.location.search + window.location.hash,
          )}`
        }
      } else {
        Sentry.addBreadcrumb({
          category: 'axios-interceptor-data',
          data: {
            status: error.response?.status || '',
            request: {
              method: error.config?.method || '',
              data: error.config?.data || {},
              baseURL: error.config?.baseURL || '',
              url: JSON.stringify(error.config?.url || ''),
            },
            response: JSON.stringify(error.response?.data ?? {}),
          },
        })
      }
    }
    return Promise.reject(error)
  },
)

export default axios
