<template lang="pug">
.promoted-course-wrapper
    LoadingSpinner(v-if='fetchingCourse')
    .promoted-course(v-else)
        .logo
            span
                img(:src='smallLogo')
        .content
            h2.caption {{ $t('onboarding.profilePage.promotedCourse.title') }}
            .image-container(v-if='course.cardTeaserImageUrl && course.teaserImageUrl')
                img(
                    :src='isMobileDevice ? course.cardTeaserImageUrl : course.teaserImageUrl',
                    @click='toCoursePage'
                )
            h2.course-title {{ course.title }}
            CourseDescription(:description='course.description')
            CourseModuleDuration(
                :course='course',
                :number-of-modules='totalCourseGroupModules'
            )
            .button-container
                h5.start-page(@click='toStartPage') {{ $t('onboarding.profilePage.promotedCourse.toPlatform') }}
                KetchUpButton.primary(@click.native='toCoursePage')
                    h5 {{ $t('onboarding.profilePage.promotedCourse.tryTheCourseForFree') }}
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import { CourseModule } from '@/store/modules/course'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import CourseDescription from '@/components/course/CourseDescription.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import CourseModuleDuration from '@/components/course/CourseModuleDuration.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { UserModule } from '@/store/modules/user'
  import useAuthentication from '@/composables/useAuthentication'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { smallLogo } = useIcons()
  const { isMobileDevice } = useBreakpoint()
  const { redirect } = useAuthentication()
  const { setIsLoadingComponentViewData } = useCommonMixin()
  const fetchingCourse = ref(true)

  const promotedCourseId = computed(() => {
    return UserModule.currentCompany?.promotedCourseId
  })

  const course = computed(() => CourseModule.course)

  const totalCourseGroupModules = computed(() => {
    if (!promotedCourseId.value) return
    const courseGroupsIds = course.value?.courseGroups?.map((c) => c.id) || []
    return CourseModule.totalCourseGroupModules(promotedCourseId.value, courseGroupsIds)
  })

  const toCoursePage = () => {
    window.location.href = `/courses/${course.value!.id}/info`
  }

  const toStartPage = () => {
    window.location.href = redirect.value
  }

  if (promotedCourseId.value) {
    CourseModule.getCourse({ courseId: promotedCourseId.value }).finally(() => {
      fetchingCourse.value = false
      CourseModule.getCourseModules(CourseModule.course!.id)
    })
  } else {
    toStartPage()
  }

  watch(fetchingCourse, (value) => {
    setIsLoadingComponentViewData(value)
  })
</script>

<style lang="postcss" scoped>
  .promoted-course-wrapper {
    @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center;
    .loading {
      @apply ketch-mx-auto;
    }
    .promoted-course {
      @apply ketch-max-w-[550px];
      .logo {
        @apply ketch-flex ketch-justify-center;
        span {
          @apply ketch-bg-white ketch-inline-flex ketch-items-center ketch-justify-center ketch-rounded-full ketch-w-[32px] ketch-h-[32px];
          img {
            @apply ketch-w-c16;
          }
        }
      }
      .content {
        .caption {
          @apply ketch-mt-c15 ketch-mb-c30 ketch-font-bold;
        }
        .image-container {
          @apply ketch-mb-c30 ketch-cursor-pointer;
        }
        .course-module-duration {
          @apply ketch-mt-c20;
        }
        .button-container {
          @apply ketch-flex ketch-justify-end ketch-items-center ketch-space-x-c30 ketch-mt-c50;
          .start-page {
            @apply ketch-underline ketch-cursor-pointer;
          }
          button {
            @apply ketch-w-[230px];
          }
        }
      }
    }
  }
</style>
