<template lang="pug">
.get-started-onboarding-modal
    VideoPlayer(
        :content-type='activeStepEntity',
        :poster-image='posterImage',
        :video-source='videoUrl'
    )
    KetchUpButton.tertiary(@click.native='$emit("close")')
        h5 {{ $t('okThankYou') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import useVideo from '@/composables/useVideo'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import type { PropType } from 'vue'
  import type { GetStartedStepEntity } from '@/services/interfaces/Common'

  const props = defineProps({
    videoUrl: {
      type: String,
      required: true,
    },
    thumbnailTimestamp: {
      type: Number,
      default: 0,
    },
    activeStepEntity: {
      type: String as PropType<GetStartedStepEntity>,
      required: true,
    },
  })
  defineEmits(['close'])
  const { getPosterImage } = useVideo()

  const posterImage = computed(() => {
    return getPosterImage(props.videoUrl, props.thumbnailTimestamp)
  })
</script>

<style lang="postcss">
  .get-started-onboarding-modal {
    @apply ketch-flex ketch-flex-col ketch-items-end ketch-mt-c20 ketch-space-y-c20;
    .video-player {
      @apply sm:ketch-max-h-[360px];
      .plyr {
        @apply ketch-rounded-large;
      }
    }
  }
</style>
