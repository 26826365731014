import { computed, ComputedRef, ref } from 'vue'
import RootFoldersApi from '@/services/api/RootFoldersApi'
import { RootFolder } from '@/services/interfaces/Folder'
import { Nullable, ContentItem } from '@/services/interfaces/Content'
import CuratedContentApi from '@/services/api/CuratedContentApi'
import { FolderItemStruct, Subfolder } from '@/services/interfaces/Folder'
import useVideo from './useVideo'

interface useCuratedContentReturn {
  actualContentItems: ComputedRef<{ rootFolder: RootFolder; content: ContentItem[] }[] | null>
  getPosterImageUrl: (item: ContentItem) => string
  itemHasImage: (item: ContentItem) => boolean
  getYoutubeId: (url: string) => string
  fetchInterestedCategories: () => void
}

export default function useCuratedContent(): useCuratedContentReturn {
  const rootFolders = ref(null as Nullable<RootFolder[]>)
  const rootFolderContent = ref(null as Nullable<FolderItemStruct[]>)

  const fetchInterestedCategories = () => {
    rootFolders.value = null
    rootFolderContent.value = null
    RootFoldersApi.interestedCategories().then(async (data) => {
      rootFolders.value = data.sort((x) => (x.selected ? -1 : 1))
      rootFolderContent.value = await Promise.all(
        rootFolders.value.map((folder) => CuratedContentApi.getContent(folder.id)),
      )
    })
  }

  fetchInterestedCategories()

  const getContent = (subFolders: Subfolder[]): ContentItem[] => {
    let allContentInCategory: ContentItem[] = []
    if (subFolders) {
      subFolders.forEach((item) => {
        if (item.contents) {
          item.contents.forEach((content) => {
            allContentInCategory.push(content)
          })
        }
      })
      const uniqContent = allContentInCategory!.reduce(
        (acc, value) => {
          if (!acc[value.id]) acc[value.id] = value
          return acc
        },
        {} as { [key: string]: ContentItem },
      )
      allContentInCategory = Object.values(uniqContent)
    }
    return allContentInCategory
  }

  const actualContentItems = computed(() => {
    if (!rootFolderContent.value) return null
    return Object.values(
      rootFolderContent.value.reduce(
        (acc, content, index) => {
          const rootFolder = rootFolders.value![index]
          if (!acc[rootFolder.id]) {
            acc[rootFolder.id] = {
              rootFolder: rootFolder,
              content: getContent(content.subfolders),
            }
          }
          return acc
        },
        {} as { [key: string]: { rootFolder: RootFolder; content: ContentItem[] } },
      ),
    )
  })
  const { getPosterImage } = useVideo()
  const getPosterImageUrl = (item: ContentItem): string => {
    if (item.type === 'unit' && item.contentType === null) return getPosterImage!(item.contentUrl as string)
    if (item.posterImageUrl) return item.posterImageUrl
    if (item.type === 'external' && item.contentType === 'Video') return youtubeThumbnailImage(item.contentUrl!)
    return ''
  }
  const youtubeThumbnailImage = (url: string): string => {
    return `https://img.youtube.com/vi/${getYoutubeId(url)}/0.jpg`
  }
  const getYoutubeId = (url: string): string => {
    const urlObj = new URL(url)
    return urlObj.search.replace('?v=', '')
  }
  const itemHasImage = (item: ContentItem): boolean => {
    return (
      (item.type === 'unit' && item.contentType === null) ||
      !!item.posterImageUrl ||
      (item.type === 'external' && item.contentType === 'Video')
    )
  }

  return {
    actualContentItems,
    getPosterImageUrl,
    itemHasImage,
    getYoutubeId,
    fetchInterestedCategories,
  }
}
