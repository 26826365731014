import { computed, ComputedRef } from 'vue'
import { UserModule } from '@/store/modules/user'

type Breakpoint = 'xs' | 'xs1' | 'xs2' | 'xs3' | 'xs4' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'

interface useBreakpointReturn {
  isMobileDevice: ComputedRef<boolean>
  isDesktopDevice: ComputedRef<boolean>
  isLargeDesktop: ComputedRef<boolean>
  isSmallerMobileDevice: ComputedRef<boolean>
  current: ComputedRef<string>
}

export default function useBreakpoint(): useBreakpointReturn {
  const breakpoints: { [key in Breakpoint]: number } = {
    xs: 319,
    xs1: 359,
    xs2: 374,
    xs3: 410,
    xs4: 539,
    sm: 767,
    md: 991,
    lg: 1023,
    xl: 1199,
    xxl: 1535,
    xxxl: 25600,
  }

  const windowWidth = computed(() => UserModule.windowWidth)

  const current = computed(() => {
    const breakpointKeys = Object.keys(breakpoints)
    for (const key of breakpointKeys) {
      const breakpoint = breakpoints[key as Breakpoint]
      if (windowWidth.value <= breakpoint) return key
    }
    return ''
  })

  const isDesktopDevice = computed(() => {
    return ['lg', 'xl', 'xxl', 'xxxl'].includes(current.value)
  })

  const isLargeDesktop = computed(() => {
    return ['xxl', 'xxxl'].includes(current.value)
  })

  const isMobileDevice = computed(() => !isDesktopDevice.value)

  const isSmallerMobileDevice = computed(() => {
    return ['xs', 'xs1', 'xs2', 'xs3', 'xs4'].includes(current.value)
  })

  return {
    isMobileDevice,
    isDesktopDevice,
    isLargeDesktop,
    isSmallerMobileDevice,
    current,
  }
}
