<template lang="pug">
.question(
    :class='{ "showing-results": inResultsMode || isTestingQuizFlagEnabled }',
    :id='question.questionId',
    data-cy='question'
)
    .question-number-skipped-status
        h5(
            data-cy='question-state',
            v-if='showQuestionNumber && questionNumber && totalNumberOfQuestions'
        ) {{ $t('questionState', { currentQuestion: questionNumber, totalQuestions: totalNumberOfQuestions }) }}
        .was-skipped(v-if='question.questionWasSkipped && !inResultsMode')
            h6 {{ $t('quiz.questionWasSkipped') }}
    h6.has-new-questions(v-if='hasNewQuestions') {{ $t('courses.guidedQuizHasNewQuestions') }}
    .question-title(:class='{ "title-with-underline": underlineTitle }')
        HTMLRenderer(
            :html='question.title',
            skip-glossary-check
        )
        .question-image(v-if='question.imageUrl')
            img(:src='question.imageUrl')
    h5.helper-text(v-if='questionHelperText') {{ questionHelperText }}
    .choices-block
        NumberInput(
            :class='{ correct: inResultsMode && isCorrectNumberAnswer, incorrect: inResultsMode && !isCorrectNumberAnswer }',
            :readonly='inResultsMode',
            :should-auto-focus='!inResultsMode',
            @formatted_value='numberInputModel = $event',
            v-if='question.questionType === "number"',
            v-model='numberInputModel'
        )
        template(v-else-if='question.answers')
            Choice(
                :class='{ wrong: isWrongAnswer(choice.answerId), correct: isCorrectAnswer(choice.answerId) || (isTestingQuizFlagEnabled && choice.isCorrect) }',
                :id='choice.answerId',
                :key='choice.answerId',
                :selected='isChoiceSelected(choice.answerId)',
                :svg-renderer-props='choiceIcon(choice.answerId)',
                :title='choice.answer',
                @click.native='onChoiceClick(choice.answerId)',
                v-for='choice in question.answers'
            )
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import Choice from './Choice.vue'
  import NumberInput from '@/components/common/NumberInput.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useFlags from '@/composables/useFlags'
  import type { PropType } from 'vue'
  import type { SvgRendererProps } from '@/services/interfaces/Common'
  import type { Question } from '@/services/interfaces/QuizQuestion'
  import type { CourseExamQuestion } from '@/services/interfaces/Course'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    question: {
      type: Object as PropType<Question | CourseExamQuestion>,
      required: true,
    },
    questionNumber: {
      type: Number,
      default: null,
    },
    underlineTitle: {
      type: Boolean,
      default: true,
    },
    showQuestionNumber: {
      type: Boolean,
      default: true,
    },
    inResultsMode: {
      type: Boolean,
      default: false,
    },
    totalNumberOfQuestions: {
      type: Number,
      default: null,
    },
    questionHelperText: {
      type: String,
      default: '',
    },
    hasNewQuestions: {
      type: Boolean,
      default: false,
    },
    onChoiceClick: {
      type: Function as PropType<(answerId: string) => void>,
      required: true,
    },
    isChoiceSelected: {
      type: Function as PropType<(answerId: string) => boolean>,
      required: true,
    },
  })

  const { checkedCircle, uncheckedCircle, checkedCheckboxSquareWhite, checkboxSquareWhite, wrongAnswerIcon } =
    useIcons()
  const { isFlagEnabled } = useFlags()
  const { correctNumberAnswer } = useCommonMixin()
  const numberInputModel = ref('')

  const isCorrectNumberAnswer = computed(() => {
    if (typeof (props.question as CourseExamQuestion).userNumberAnswer === 'object') {
      return (props.question as CourseExamQuestion).userNumberAnswer?.isCorrect
    }
    return correctNumberAnswer(props.question.answers[0].answer, numberInputModel.value)
  })

  const isCorrectAnswer = computed(() => {
    return (answerId: string) => !!props.question.answers.find((a) => a.isCorrect && a.answerId === answerId)
  })

  const isWrongAnswer = computed(() => {
    return (answerId: string) => {
      return !isCorrectAnswer.value(answerId) && props.isChoiceSelected(answerId)
    }
  })

  const isTestingQuizFlagEnabled = computed(() => isFlagEnabled('testing-quiz'))

  const choiceIcon = computed(() => {
    return (answerId: string): SvgRendererProps => {
      const svgProps: any = { strokeColor: 'var(--primary-text-color)' }

      if (props.question.questionType === 'single') {
        svgProps.icon = uncheckedCircle.value
        if (props.isChoiceSelected(answerId)) {
          svgProps.icon = checkedCircle.value
          setWrongOrCorrectIconProps(svgProps, answerId)
          return svgProps
        }
        if (props.inResultsMode && isCorrectAnswer.value(answerId)) svgProps.icon = checkedCircle.value
        return svgProps
      }

      svgProps.icon = checkboxSquareWhite.value
      if (props.isChoiceSelected(answerId)) {
        svgProps.icon = checkedCheckboxSquareWhite.value
        setWrongOrCorrectIconProps(svgProps, answerId)
        return svgProps
      }
      if (props.inResultsMode && isCorrectAnswer.value(answerId)) svgProps.icon = checkedCheckboxSquareWhite.value
      return svgProps
    }
  })

  const setWrongOrCorrectIconProps = (svgProps: SvgRendererProps, answerId: string) => {
    if (props.inResultsMode) {
      if (isCorrectAnswer.value(answerId)) {
        svgProps.strokeColor = '#28bd2e'
      } else {
        svgProps.icon = wrongAnswerIcon.value
        svgProps.strokeColor = '#ff0044'
      }
    }
  }

  const setFormattedNumberInput = () => {
    if (props.question.questionType === 'number' && props.question.userNumberAnswer) {
      if (typeof props.question.userNumberAnswer === 'object') {
        numberInputModel.value = props.question.userNumberAnswer?.answer
      } else {
        numberInputModel.value = props.question.userNumberAnswer
      }
    }
  }

  watch(numberInputModel, (value) => props.onChoiceClick(value ? String(value) : ''))

  setFormattedNumberInput()
</script>

<style lang="postcss" scoped>
  .question {
    @apply ketch-w-full;
    .question-number-skipped-status {
      @apply ketch-flex ketch-items-center ketch-justify-between;
      .was-skipped {
        @apply ketch-px-c4 ketch-py-c2 ketch-rounded-normal ketch-bg-orange-300;
      }
    }
    .has-new-questions {
      @apply ketch-text-secondary-text-color ketch-mt-c5;
    }
    .question-title {
      @apply ketch-mt-c5 ketch-font-bold;
      .question-image {
        @apply ketch-flex ketch-justify-center ketch-mt-c10;
        img {
          @apply ketch-max-h-c300 ketch-max-w-full;
        }
      }
    }
    .helper-text {
      @apply ketch-mt-c20 ketch-mb-c20 ketch-text-secondary-text-color;
    }
    .choices-block {
      @apply ketch-space-y-c10 ketch-mb-c30;
      .choice {
        @apply ketch-w-full;
        &.selected > * {
          h5 {
            @apply ketch-font-bold;
          }
        }
        &:not(.selected) > * {
          @apply ketch-opacity-100;
        }
      }
    }
    &.showing-results .choice {
      @apply ketch-cursor-default;
      &.correct {
        @apply ketch-border-[#28bd2e];
      }
      &.selected {
        &.correct {
          @apply ketch-border-[#28bd2e];
        }
        &.wrong {
          @apply ketch-border-[#ff0044];
        }
      }
    }
  }
</style>
