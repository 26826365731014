<template lang="pug">
.billing-page-wrapper
    LoadingSpinner(v-if='fetchingBillingHistory')
    template(v-else)
        .records-container
            h3.title {{ $t('billing.billingHistory') }}
            h5(v-if='noBillingHistoryYet') {{ $t('billing.invoiceInProgress') }}
            .billing-records(v-else)
                a.record(
                    :href='history.invoiceUrl',
                    :key='index',
                    @click='trackDownloadInvoice(history.product.type, history.invoiceUrl)',
                    download,
                    v-for='(history, index) in billingHistory'
                )
                    h5.record-title
                        span.course(v-if='isCourse(history.product.type)') {{ $t('billing.course') }}:
                        span {{ history.product.name }}
                    .date-wrapper
                        h6.date-purchased {{ formatDate(history.date, 'dd.MM.yyyy') }}
                        img(:src='vectorRight')
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useSegment from '@/composables/useSegment'
  import CheckoutApi from '@/services/api/CheckoutApi'
  import type { BillingHistory } from '@/services/interfaces/Checkout'
  import type { Nullable } from '@/services/interfaces/Content'

  const { vectorRight } = useIcons()
  const { formatDate, setIsLoadingComponentViewData } = useCommonMixin()
  const { trackPage, pageViewed, invoiceDownloaded } = useSegment()
  const billingHistory = ref(null as Nullable<BillingHistory[]>)
  const fetchingBillingHistory = ref(false)

  const noBillingHistoryYet = computed(() => {
    return billingHistory.value === null || !billingHistory.value.length
  })

  const isCourse = computed(() => {
    return (productType: string): boolean => {
      return productType === 'course'
    }
  })

  const fetchBillingHistory = async (): Promise<void> => {
    fetchingBillingHistory.value = true
    await CheckoutApi.billingHistory()
      .then((records) => {
        billingHistory.value = records
      })
      .finally(() => {
        fetchingBillingHistory.value = false
      })
  }

  const trackDownloadInvoice = (productType: string, invoiceUrl: string) => {
    invoiceDownloaded(productType, invoiceUrl)
  }

  onMounted(() => {
    fetchBillingHistory()
    trackPage('Billing Page')
    pageViewed('Billing Page')
  })

  watch(fetchingBillingHistory, (value) => {
    setIsLoadingComponentViewData(value)
  })
</script>

<style lang="postcss">
  .billing-page-wrapper {
    .loading {
      @apply ketch-mx-auto;
    }
    .records-container {
      @apply ketch-w-full md:ketch-max-w-[700px];
      .title {
        @apply ketch-font-bold ketch-mb-[58px];
      }
      .billing-records {
        @apply ketch-space-y-[13px];
        .record {
          @apply ketch-flex ketch-items-center ketch-justify-between ketch-pb-[13px] ketch-border-b ketch-border-border-color;
          .record-title {
            @apply ketch-max-w-[215px] xs1:ketch-max-w-[255px] xs2:ketch-max-w-[270px] xs4:ketch-max-w-[unset];
            .course {
              @apply ketch-pr-c5;
            }
          }
          .date-wrapper {
            @apply ketch-flex ketch-items-center ketch-space-x-c10;
            @apply sm:ketch-space-x-c20;
            .date-purchased {
              @apply ketch-text-secondary-text-color;
            }
            img {
              filter: invert(1);
            }
          }
        }
      }
    }
  }
  .ketch-dark .billing-page-wrapper {
    .records-container {
      .billing-records {
        .record {
          .date-wrapper {
            img {
              filter: none;
            }
          }
        }
      }
    }
  }
</style>
