import axios from './interceptor'
import { CourseSearchItem } from '@/services/interfaces/Course'

export default class BookmarkApi {
  static async bookmarkCourseLesson(data: { courseId: string; moduleId: string; lessonId: string }): Promise<number> {
    return axios
      .post('/bookmarks', data)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async deleteCourseLessonBookmark(data: {
    courseId: string
    moduleId: string
    lessonId: string
  }): Promise<number> {
    return axios
      .delete('/bookmarks', {
        data,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getAllUserBookmarks(): Promise<CourseSearchItem[]> {
    return axios
      .get(`/bookmarks`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
