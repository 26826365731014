import { render, staticRenderFns } from "./SkgQuizModal.vue?vue&type=template&id=71c1fc1f&scoped=true&lang=pug"
import script from "./SkgQuizModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./SkgQuizModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SkgQuizModal.vue?vue&type=style&index=0&id=71c1fc1f&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c1fc1f",
  null
  
)

export default component.exports