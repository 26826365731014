<template lang="pug">
.module-lesson-loader.module-lesson-content
    .content
        LessonLoader
    .sideshow(v-if='isDesktopDevice')
        ModuleSelectorLoader
</template>

<script setup lang="ts">
  import useBreakpoint from '@/composables/useBreakpoint'
  import LessonLoader from '@/components/loaders/LessonLoader.vue'
  import ModuleSelectorLoader from '@/components/loaders/ModuleSelectorLoader.vue'

  const { isDesktopDevice } = useBreakpoint()
</script>

<style lang="postcss" scoped>
  .module-lesson-loader {
    @apply ketch-contents ketch-mt-c15;
  }
</style>
