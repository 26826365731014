<template lang="pug">
.duration-input
    .input-header.edit-title-header(v-if='editTitle')
        h5 {{ editTitle }}
        h6 {{ editSubtext }}
    .duration
        input(
            type='text',
            v-model='durationModel'
        )
        span(v-if='showCalibration') {{ durationCalibration }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import type { PropType } from 'vue'
  import type { Nullable } from '@/services/interfaces/Content'
  import type { CourseModule, Lesson, EditorStatePayload } from '@/services/interfaces/Course'
  import { EditorModule } from '@/store/modules/editor'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { useRoute } from 'vue-router/composables'

  const props = defineProps({
    value: {
      required: true,
      type: Number,
    },
    durationCalibration: {
      type: String,
      default: 'min',
    },
    showCalibration: {
      type: Boolean,
      default: true,
    },
    editTitle: String,
    editSubtext: String,
    editorState: String as PropType<'currentLesson' | 'currentModule'>,
    editorProperty: String as PropType<keyof Lesson | keyof CourseModule>,
    mutateAndQueueFunc: Function as PropType<(data: EditorStatePayload) => void>,
  })

  const route = useRoute()
  const { revertConvertNumberInput } = useCommonMixin()
  const { currentLesson, currentModule } = useCourse()
  const durationModel = ref(props.value)

  const convertedDuration = computed(() => {
    return revertConvertNumberInput(String(durationModel.value))
  })

  watch(durationModel, () => {
    setDuration(convertedDuration.value || 0)
  })

  watch(
    () => props.value,
    (value) => {
      durationModel.value = value
    },
  )

  const setDuration = (duration: number) => {
    if (props.editorProperty && props.editorState) {
      EditorModule.setEditableState({
        key: `${props.editorState}${props.editorProperty}`,
        path: route.path,
        state: props.editorState,
        property: props.editorProperty,
        value: duration,
        mutateAndQueue: props.mutateAndQueueFunc,
      })
    }
  }

  const discardChangesCallback = (
    data: number | EditorStatePayload,
    stateBeforeChange?: {
      currentLesson: Nullable<Lesson>
      currentModule: Nullable<CourseModule>
    },
  ) => {
    durationModel.value = (stateBeforeChange?.[props.editorState!] as any)?.[props.editorProperty!] || props.value
  }

  const updateStateModel = (data: EditorStatePayload) => {
    durationModel.value = data.value as number
  }

  onBeforeUnmount(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: discardChangesCallback })
  })

  onMounted(() => {
    EditorModule.subscribe({
      type: 'discard',
      key: `${props.editorState}${props.editorProperty}`,
      callback: () =>
        discardChangesCallback(durationModel.value, {
          currentLesson: currentLesson.value,
          currentModule: currentModule.value as CourseModule,
        }),
    })
    EditorModule.subscribe({
      type: 'save',
      key: `${props.editorState}${props.editorProperty}`,
      callback: updateStateModel,
    })
  })
</script>

<style lang="postcss" scoped>
  .duration-input {
    .duration {
      @apply ketch-relative ketch-h-c40 ketch-w-c100;
      input {
        @apply ketch-border ketch-pr-c40 ketch-h-full ketch-rounded-small ketch-text-right;
        @apply ketch-outline-none ketch-w-full focus:ketch-border-primary-text-color;
      }
      span {
        @apply ketch-bg-primary-color ketch-absolute ketch-right-0 ketch-h-full ketch-w-c35 ketch-top-0;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-text-primary-foreground-color;
      }
    }
    &.editor {
      .duration {
        input {
          @apply ketch-rounded-none ketch-border-editor-primary-color;
        }
        span {
          @apply ketch-bg-editor-primary-color ketch-text-editor-foreground-color;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          @apply ketch-ml-c8;
        }
      }
    }
  }
</style>
