<template lang="pug">
.lesson-wrapper(
  :class='{ "trial-expired": trialPeriodExpired && !fetchingLesson }',
  data-cy='lesson'
)
  LessonLoader(v-if='fetchingLesson')
  template(v-else)
    NonFreeLesson(
      :current-lesson-title='currentLessonTitle',
      :lesson-course-id='lessonCourseId',
      v-if='forbidden'
    )
    template(v-else-if='currentLesson')
      template(v-if='showMobileLeuckentextQuiz && currentLesson.leuckentext')
        LeuckentextQuiz(
          :is-mobile-version='showMobileLeuckentextQuiz',
          :leuckentext='currentLesson.leuckentext',
          @back-to-lesson='showMobileLeuckentextQuiz = false'
        )
      template(v-else)
        .lesson-container
          .header-info
            TextRenderer.lesson-title(
              :allow-edit='inEditorMode ? "yes" : "no"',
              :class='{ "title-with-underline": isDesktopDevice }',
              :edit-title='$t("editor.title")',
              :format-content='formatContent',
              :placeholder='$t("editor.newLessonTitle")',
              :show-text-input='true',
              :source='currentLesson.title === $t("editor.newLessonTitle") ? "" : currentLesson.title || ""',
              editor-property='title',
              editor-state='currentLesson'
            )
            Tabs(
              :active-tab-index='editorTabIndex',
              :in-editor='true',
              :tabs='editorTabs',
              @tab-index='editorTabIndex = $event',
              v-if='inEditorMode'
            )
            .duration-lesson-types
              keep-alive
                DurationInput.editor(
                  :edit-title='$t("editor.duration")',
                  :value='currentLesson.duration || 0',
                  editor-property='duration',
                  editor-state='currentLesson',
                  v-if='inEditorMode && editorTabIndex === 0'
                )
              .drop-down-container(v-if='currentEditorLesson && inEditorMode && editorTabIndex === 0')
                .edit-title-header
                  h5 {{ $t('editor.type') }}
                keep-alive
                  Dropdown.editor-dropdown(
                    :items='editorLessonTypeDropdownItems',
                    :value='currentEditorLesson.type',
                    @input='onLessonTypeChange',
                    default-dropdown-behavior,
                    stroke-color-for-expand-icon='var(--editor-primary-color)'
                  )
            keep-alive
              .layout-drop-down-container(
                v-if='editorTabIndex === 0 && currentEditorLesson && inEditorMode && currentEditorLesson.type === "SlideShow"'
              )
                .edit-title-header
                  h5 Layout
                Dropdown.editor-dropdown(
                  :items='editorSlideshowLayoutDropdownItems',
                  :value='currentEditorLesson.isOnePager',
                  @input='onLayoutChange',
                  default-dropdown-behavior,
                  stroke-color-for-expand-icon='var(--editor-primary-color)'
                )
            keep-alive
              TextRenderer.intro-text(
                :allow-edit='inEditorMode ? "yes" : "no"',
                :edit-title='$t("editor.introOptional")',
                :format-content='formatContent',
                :source='currentLesson.introText || ""',
                editor-property='introText',
                editor-state='currentLesson',
                v-if='editorTabIndex === 0'
              )
          keep-alive
            LessonTypes(
              :can-edit='inEditorMode',
              :course-id='getCourseId',
              :current-lesson='isNewLesson ? currentEditorLesson : currentLesson',
              :format-content='formatContent',
              :module-id='moduleId || lessonModuleId',
              @download-document='downloadContent(currentLesson.downloadables.downloadUrl)',
              v-if='editorTabIndex === 0'
            )
            LessonQuestionsTabView(v-else-if='editorTabIndex === 1')

          LeuckentextQuiz(
            :leuckentext='currentLesson.leuckentext',
            @show-mobile-version='showMobileLeuckentextQuiz = true',
            v-if='currentLesson.leuckentext || (inEditorMode && currentLesson.type !== "Quiz")'
          )
          keep-alive
            TextRenderer.outro-text(
              :allow-edit='inEditorMode ? "yes" : "no"',
              :edit-title='$t("editor.outroOptional")',
              :format-content='formatContent',
              :source='currentLesson.outroText || ""',
              editor-property='outroText',
              editor-state='currentLesson',
              v-if='editorTabIndex === 0'
            )
        .cta-wrapper(
          :class='{ hasDownloadable: showDownloadCTA }',
          v-if='currentLesson.type !== "Quiz"'
        )
          KetchUpButton.download.tertiary(
            @click.native='downloadContent(currentLesson.downloadables.downloadUrl)',
            v-if='showDownloadCTA'
          )
            SVGRenderer(
              :has-hover='false',
              :icon='download',
              fill-color='var(--primary-text-color)'
            )
            h5 Download
          .cta(
            :class='{ "has-no-previous-lesson": !showPreviousLessonCTA }',
            v-if='isDesktopDevice && !inEditorMode'
          )
            .previous-lesson(
              @click='$emit("previous-lesson")',
              v-if='showPreviousLessonCTA'
            )
              SVGRenderer(
                :has-hover='false',
                :icon='arrowRight',
                stroke-color='var(--primary-text-color)'
              )
              h5 {{ $t('courses.sideshow.previousLesson') }}
            .bookmark-next-lesson
              BookmarkLesson(
                :can-edit='inEditorMode',
                :lesson-module-id='lessonModuleId'
              )
              .next-lesson(
                @click='$emit("next-lesson-or-module")',
                v-if='showNextCTA(lessonCTA)'
              )
                h5 {{ lessonCTA }}
                SVGRenderer(
                  :has-hover='false',
                  :icon='arrowRight',
                  stroke-color='var(--primary-text-color)'
                )
        .notes-feedback(v-if='course && moduleId && getCourseId && course.allowFeedback')
          hr.separator
          Notes
          Feedback(
            :feedback-description='$t("feedback.questionDescription")',
            :feedback-modal-title='$t("feedback.lessonFeedback")',
            :lesson-id='currentLesson.id || lessonId',
            :lesson-name='currentLesson.title'
          )
          .blurred-out-non-editable-section(v-if='inEditorMode')
  CourseTrialExpired(v-if='trialPeriodExpired && !fetchingLesson')
</template>

<script lang="ts">
  import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue'
  import NonFreeLesson from '@/components/course/NonFreeLesson.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import Feedback from '@/components/common/Feedback.vue'
  import Notes from '@/components/common/Notes.vue'
  import Dropdown from '@/components/common/Dropdown.vue'
  import DurationInput from '@/components/common/DurationInput.vue'
  import useEditor from '@/composables/useEditor'
  import Tabs from '@/components/common/Tabs.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import LessonTypes from '@/components/course/LessonTypes.vue'
  import LessonQuestionsTabView from '@/components/course/LessonQuestionsTabView.vue'
  import BookmarkLesson from '@/components/course/BookmarkLesson.vue'
  import LeuckentextQuiz from '@/components/course/LeuckentextQuiz.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { CourseModule as CourseModuleState, Lesson, LessonQuestion } from '@/services/interfaces/Course'
  import { v4 as uuidv4 } from 'uuid'
  import { Question } from '@/services/interfaces/QuizQuestion'
  import LessonLoader from '@/components/loaders/LessonLoader.vue'
  import { UserModule } from '@/store/modules/user'
  import CourseTrialExpired from '@/components/modals/CourseTrialExpired.vue'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import CookieHelper from '@/helpers/CookieHelper'

  export default defineComponent({
    name: 'Lesson',
    components: {
      CourseTrialExpired,
      LessonLoader,
      KetchUpButton,
      NonFreeLesson,
      SVGRenderer,
      Feedback,
      Notes,
      Tabs,
      TextRenderer,
      LessonTypes,
      DurationInput,
      Dropdown,
      BookmarkLesson,
      LeuckentextQuiz,
      LessonQuestionsTabView,
    },
    props: {
      lessonCTA: {
        type: String,
        required: true,
      },
      lessonCourseId: String,
      lessonId: String,
      lessonModuleId: String,
      formatContent: {
        type: Boolean,
        default: false,
      },
      lessonToRender: {
        type: Object as PropType<Lesson>,
        default: null,
      },
      trialPeriodExpired: Boolean,
    },
    metaInfo: function () {
      return {
        title: (this as any).currentLessonTitle,
      }
    },
    setup(props) {
      const route = useRoute()
      const { arrowRight, download } = useIcons()
      const { downloadItem } = useCommonMixin()
      const {
        currentLessonTitle,
        currentCourseId,
        lessonId,
        moduleId,
        currentModule,
        currentLesson: localLesson,
        course,
        isPurchasedCourse,
        showStartCourseForFreeModal,
        showNextCTA,
        completeALesson,
        currentLessonIndex,
        currentModuleIndex,
      } = useCourse()
      const { trackPage, pageViewed, lessonImageDownloaded, lessonViewed } = useSegment()
      const { isDesktopDevice } = useBreakpoint()
      const {
        inEditorMode: editorMode,
        currentLessonQuestions,
        isNewLesson,
        currentEditorLesson,
        lessonSlideshows,
      } = useEditor()
      const { translateString } = useI18n()
      const forbidden = ref(false)
      const editorTabIndex = ref(0)
      const showMobileLeuckentextQuiz = ref(false)

      const inEditorMode = computed(() => editorMode.value && !props.lessonId)
      const currentLesson = computed(() =>
        (props.lessonToRender ?? inEditorMode.value) ? EditorModule.currentLesson : localLesson.value,
      )

      const editorTabs = computed(() => {
        let questionsCount: (LessonQuestion | Question)[] =
          currentLessonQuestions.value?.filter((q) => q._destroy !== true) ?? []
        if (currentLesson.value?.type === 'Quiz' && currentLesson.value?.quiz?.hasQuestions) {
          questionsCount = currentLesson.value.quiz.questions ?? []
        }
        return [
          translateString('content'),
          translateString('editor.lessonQuestionsCount', { count: questionsCount.length }),
        ]
      })

      const fetchingLesson = computed(
        () => (isNewLesson.value ? currentEditorLesson.value : CourseModule.currentLesson) === null,
      )

      const getCourseId = computed(() => props.lessonCourseId || currentCourseId.value)

      const showDownloadCTA = computed(() => {
        return (
          currentLesson.value?.downloadables &&
          currentLesson.value?.downloadables.downloadUrl &&
          currentLesson.value?.type === 'Downloadable' &&
          !inEditorMode.value
        )
      })

      const showPreviousLessonCTA = computed(() => currentModuleIndex.value >= 0 && currentLessonIndex.value > 0)

      const fetchLesson = async (isNewLessonId?: boolean) => {
        if (EditorModule.editMode && (fetchingLesson.value || isNewLessonId)) {
          if (isNewLesson.value) {
            CourseModule.setCurrentLessonQuestions([])
          } else {
            await EditorModule.setCurrentLesson(null)
            CourseModule.resetCurrentLesson()
            await CourseModule.getCurrentLessonQuestions({
              courseId: getCourseId.value,
              moduleId: props.lessonModuleId || moduleId.value,
              lessonId: props.lessonId || lessonId.value,
            })
          }
        }
        if (inEditorMode.value && isNewLesson.value) return
        CourseModule.getLesson({
          courseId: getCourseId.value,
          moduleId: props.lessonModuleId || moduleId.value,
          lessonId: props.lessonId || lessonId.value,
        }).then((resp) => {
          if (resp.response?.status === 403) {
            forbidden.value = true
          }

          EditorModule.setLeuckentext(resp.leuckentext)
          EditorModule.setLessonSlideshows(currentEditorLesson.value?.slideShow ?? null).then(() => {
            lessonSlideshows.value?.forEach((slide) => {
              eventBus.$set(slide, 'editorId', uuidv4())
              eventBus.$set(slide, 'slideImageData', '')
            })
          })
          EditorModule.setLessonDownloadable(currentEditorLesson.value?.downloadables ?? null)
        })
      }

      if (!props.lessonToRender) fetchLesson()

      watch(lessonId, (newLessonId: string, oldLessonId: string) => {
        fetchLesson(!!oldLessonId)
        trackPage('Course Lesson View')
        pageViewed('Course Lesson View')
      })

      watch(inEditorMode, (value: boolean) => {
        if (value) {
          if (CourseModule.currentLessonQuestions === null) {
            CourseModule.getCurrentLessonQuestions({
              courseId: getCourseId.value,
              moduleId: props.lessonModuleId || moduleId.value,
              lessonId: props.lessonId || lessonId.value,
            })
          }
        } else {
          editorTabIndex.value = 0
        }
      })

      watch(currentLesson, (lesson) => {
        if (lesson && UserModule.currentCompany) {
          lessonViewed(
            UserModule.currentCompany.id,
            lesson.id,
            lesson.title,
            moduleId.value,
            currentCourseId.value,
            CookieHelper.getCookieValue('language-code') || 'de',
          )
        }
      })

      const downloadContent = (contentUrl: string) => {
        lessonImageDownloaded(
          getCourseId.value,
          course.value!.title,
          moduleId.value,
          currentModule.value.name,
          lessonId.value,
          currentLesson.value!.title,
          contentUrl,
        )
        downloadItem(contentUrl)
      }

      const editorLessonTypeDropdownItems = computed(() => [
        { selectable: isNewLesson.value, type: 'Text', title: 'Text' },
        { selectable: isNewLesson.value, type: 'Video', title: 'Video' },
        { selectable: isNewLesson.value, type: 'SlideShow', title: translateString('editor.multipleImagesWithText') },
        { selectable: isNewLesson.value, type: 'Quiz', title: 'Quiz' },
        { selectable: isNewLesson.value, type: 'Audio', title: 'Audio' },
        { selectable: isNewLesson.value, type: 'Downloadable', title: 'Downloadable' },
        { selectable: isNewLesson.value, type: 'Table', title: 'Table' },
        { selectable: isNewLesson.value, type: 'Profile', title: 'Profile' },
        { selectable: isNewLesson.value, type: 'Script', title: 'Script' },
      ])

      const editorSlideshowLayoutDropdownItems = computed(() => [
        { selectable: true, type: false, title: translateString('editor.asSlideshow') },
        { selectable: true, type: true, title: translateString('editor.asScrollablePage') },
      ])

      const onLayoutChange = (type: boolean) => {
        EditorModule.setEditableState({
          key: 'currentLessonisOnePager',
          path: route.path,
          state: 'currentLesson',
          property: 'isOnePager' as keyof Lesson,
          value: type,
        })
      }

      const onLessonTypeChange = (type: string) => {
        EditorModule.setEditableState({
          key: 'currentLessontype',
          path: route.path,
          state: 'currentLesson',
          property: 'type' as keyof (Lesson | CourseModuleState),
          value: type,
        })
      }

      onMounted(async () => {
        if (!course.value) await CourseModule.getCourse({ courseId: getCourseId.value })
        if (!course.value?.trial && !isPurchasedCourse.value && !UserModule.isCompanyEditor) {
          showStartCourseForFreeModal(getCourseId.value, true)
        }
        await trackPage('Course Lesson View')
        await pageViewed('Course Lesson View')
      })

      onBeforeUnmount(() => {
        if (!props.lessonToRender) CourseModule.resetCurrentLesson()
        if (inEditorMode.value) {
          EditorModule.setLessonDownloadable(null)
          EditorModule.resetEditorChanges()
        }
      })

      return {
        fetchingLesson,
        showNextCTA,
        currentLesson,
        arrowRight,
        download,
        forbidden,
        getCourseId,
        moduleId,
        showDownloadCTA,
        showPreviousLessonCTA,
        currentLessonTitle,
        course,
        inEditorMode,
        editorTabIndex,
        showMobileLeuckentextQuiz,
        editorTabs,
        editorLessonTypeDropdownItems,
        editorSlideshowLayoutDropdownItems,
        currentEditorLesson,
        isNewLesson,
        isDesktopDevice,
        downloadContent,
        onLessonTypeChange,
        completeALesson,
        onLayoutChange,
      }
    },
    beforeRouteLeave(to, from, next) {
      if (from.params.lessonId) {
        this.completeALesson(from.params.lessonId)
      }
      next()
    },
    beforeRouteUpdate(to, from, next) {
      if (from.params.lessonId) {
        this.completeALesson(from.params.lessonId)
      }
      next()
    },
  })
</script>

<style lang="postcss">
  .lesson-wrapper {
    @apply ketch-w-full ketch-pb-c40;
    &.trial-expired {
      @apply ketch-relative;
      > *:not(.course-trial-expired-wrapper) {
        @apply ketch-blur-sm ketch-pointer-events-none;
      }
    }
    .loading {
      @apply ketch-mx-auto;
    }
    > .loading {
      @apply ketch-pt-c40;
    }
    .cta-wrapper {
      .download {
        @apply ketch-space-x-c10;
      }
    }
    .separator {
      @apply ketch-border-dashed ketch-border-border-color ketch-my-c30;
    }
    .notes {
      @apply ketch-mb-c40;
    }
    .notes-feedback {
      @apply ketch-relative;
      .blurred-out-non-editable-section {
        @apply ketch-bg-white;
      }
    }
    .lesson-container {
      @apply ketch-space-y-c30 md:ketch-mb-c70;
      .header-info {
        @apply ketch-w-full;
        .tabs {
          @apply ketch-mb-c30 ketch-pt-c20;
        }
        .duration-lesson-types {
          @apply ketch-flex ketch-space-x-c30 ketch-mb-c20;
        }
        .layout-drop-down-container {
          @apply ketch-mb-c20;
        }
      }
    }
  }
</style>
