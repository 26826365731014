<template lang="pug">
.course-search
    .search-input
        input(
            :placeholder='$t("courses.browseCourse")',
            @keyup.enter='onSearch',
            v-model='searchText'
        )
        img(
            :src='closeIcon',
            @click='searchText = ""',
            v-if='searchText'
        )
    transition(
        :name='searchText ? "next-slide" : "prev-slide"',
        mode='out-in'
    )
        KetchUpButton.primary(
            @click.native='onSearch',
            v-if='showSearchButton'
        )
            h5 {{ $t('courses.search') }}
</template>

<script setup lang="ts">
  import { onMounted, ref, watch, nextTick } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useIcons from '@/composables/useIcons'
  import eventBus from '@/main'

  const searchText = ref('')
  const showSearchButton = ref(false)
  const { closeIcon } = useIcons()

  watch(searchText, (value: string) => {
    if (value) showSearchButton.value = true
    else {
      onSearch()
      nextTick(() => {
        showSearchButton.value = false
      })
    }
  })

  onMounted(() => {
    eventBus.$on('reset-search-term', () => {
      searchText.value = ''
    })
  })

  const onSearch = () => {
    eventBus.$emit('course-search-term', searchText.value)
  }
</script>

<style lang="postcss" scoped>
  .course-search {
    @apply ketch-flex ketch-items-center ketch-justify-between ketch-space-x-c10;
    .search-input {
      @apply ketch-relative ketch-w-full;
      input {
        @apply ketch-bg-transparent ketch-border ketch-border-border-color ketch-rounded-half-normal ketch-w-full;
        @apply ketch-h-c40 ketch-pl-c15 ketch-pr-c20 ketch-py-c10 ketch-outline-none ketch-text-primary-text-color;
        &::placeholder {
          @apply ketch-text-primary-text-color ketch-text-opacity-30;
        }
      }
      img {
        @apply ketch-absolute ketch-top-[11px] ketch-right-c6 ketch-w-c20 ketch-cursor-pointer;
      }
    }
    button {
      @apply ketch-w-auto;
    }
  }
</style>
