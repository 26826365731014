import { render, staticRenderFns } from "./CourseExpiredBanner.vue?vue&type=template&id=ce9c3960&scoped=true&lang=pug"
import script from "./CourseExpiredBanner.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseExpiredBanner.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseExpiredBanner.vue?vue&type=style&index=0&id=ce9c3960&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce9c3960",
  null
  
)

export default component.exports