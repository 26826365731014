<template lang="pug">
LoadingSpinner(v-if='slideshow === null')
.lesson-slideshow-editor(v-else)
    transition-group.slideshow-container(
        :name='transitionName',
        tag='div'
    )
        CollapsibleWidget(
            :can-drag='true',
            :draggable='true',
            :header-title='widgetHeaderTitle(slide)',
            :initial-open-state='false',
            :key='slide.editorId',
            @delete='removeSlide(slide)',
            @dragend.native='onSlideshowDragEnd',
            @dragenter.native='onSlideshowDragEnter(slide)',
            @dragover.native.prevent='onSlideshowDragOver',
            @dragstart.native='onSlideshowDragStart(slide, $event)',
            @drop.native='onSlideshowDrop',
            v-for='slide in slideshow'
        )
            TextRenderer.title(
                :edit-title='$t("editor.titleOptional")',
                :editor-state='`slide-title-${slide.editorId}`',
                :mutate-and-queue-func='mutateAndQueueFunc(slide.editorId)',
                :placeholder='$t("editor.questions.yourQuestion")',
                :source='slide.title',
                editor-property='title'
            )
            VideoOrImageRenderer(
                :editor-property='slideImageOrVideoEditorProperty(slide)',
                :editor-state='`slide-video-or-image-url-${slide.editorId}`',
                :image-url='slide.imageUrl',
                :mutate-and-queue-func='mutateAndQueueFunc(slide.editorId)',
                :video-url='slide.videoUrl',
                @uploaded-image='uploadedImage = true',
                @video-url='onSlideUpdate(slide, "videoUrl", $event)',
                file-type='slide_image',
                track-image-data
            )
            template(v-if='slide.videoUrl')
                VideoTranscript(
                    :mutate-and-queue-func='mutateAndQueueFunc(slide.editorId)',
                    :video-transcript='slide.videoTranscript || ""',
                    v-if='slide.videoUrl'
                )
                DurationInput.editor(
                    :edit-subtext='$t("editor.timestampInputEditSubtext")',
                    :edit-title='$t("editor.timestampInputEditTitle")',
                    :key='slide.thumbnailTimestamp',
                    :mutate-and-queue-func='mutateAndQueueFunc(slide.editorId)',
                    :value='slide.thumbnailTimestamp || 0',
                    duration-calibration='sec',
                    editor-property='thumbnailTimestamp',
                    editor-state='slide'
                )
            TextRenderer.credits(
                :edit-sub-title='$t("editor.teaserCreditsSubtext")',
                :is-small-text='true',
                :mutate-and-queue-func='mutateAndQueueFunc(slide.editorId)',
                :placeholder='$t("editor.newTeaserCredits")',
                :show-text-input='true',
                :source='slide.credits',
                edit-title='Teaser-Credits',
                editor-property='credits',
                editor-state='slide',
                v-else-if='slide.imageUrl || slide.slideImageData'
            )
            TextRenderer.description(
                :editor-state='`slide-text-${slide.editorId}`',
                :mutate-and-queue-func='mutateAndQueueFunc(slide.editorId)',
                :placeholder='$t("editor.questions.yourQuestion")',
                :source='slide.description',
                edit-title='Text <span>(optional)</span>',
                editor-property='description'
            )
            AudioAuthor(
                :add-author='addAuthor(slide)',
                :audio-url='slide.audioUrl',
                :author-id='slideAuthorId(slide)',
                :remove-author='removeAuthor(slide)',
                :set-audio-url='setAudioUrl(slide)'
            )
    KetchUpButton.editor(@click.native='addNewSlide')
        h5 {{ $t('editor.addNewSlide') }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, ref } from 'vue'
  import useEditor from '@/composables/useEditor'
  import CollapsibleWidget from '@/components/common/CollapsibleWidget.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import { EditorModule } from '@/store/modules/editor'
  import { v4 as uuidv4 } from 'uuid'
  import VideoTranscript from '@/components/course/VideoTranscript.vue'
  import DurationInput from '@/components/common/DurationInput.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import AudioAuthor from '@/components/editor/AudioAuthor.vue'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { EditorStatePayload, LessonMap, EditorSlide, Slideshow } from '@/services/interfaces/Course'
  import type { Nullable } from '@/services/interfaces/Content'

  const { translateString } = useI18n()
  const route = useRoute()
  const { lessonSlideshows } = useEditor()
  const transitionName = ref('')
  const uploadedImage = ref(false)

  const slideshow = computed(() => {
    if (!lessonSlideshows.value || (lessonSlideshows.value.length && !lessonSlideshows.value.some((l) => l.editorId))) {
      return null
    }
    return lessonSlideshows.value?.filter((s: EditorSlide) => s._destroy !== true) as EditorSlide[]
  })

  const widgetHeaderTitle = computed(() => {
    return (slide: EditorSlide) => {
      const title = slide.title ? ': ' + slide.title : ''
      if (slide.videoUrl) return translateString('editor.video') + title
      if (slide.imageUrl || slide.slideImageData) return translateString('editor.image') + title
      return translateString('editor.image') + ' / ' + translateString('editor.video') + title
    }
  })

  const slideAuthorId = computed(() => (slide: EditorSlide) => {
    return slide.audioAuthor?.id ?? ''
  })

  const slideImageOrVideoEditorProperty = computed(() => {
    return (slide: EditorSlide) => {
      if (slide?.videoUrl) return 'videoUrl'
      return slide?.imageUrl || slide?.slideImageData || uploadedImage.value ? 'imageUrl' : undefined
    }
  })

  const addNewSlide = () => {
    transitionName.value = 'fadeInUp'
    lessonSlideshows.value?.push({
      id: 'new-' + uuidv4(),
      editorId: 'new-' + uuidv4(),
      title: '',
      imageUrl: '',
      slideImageData: '',
      credits: '',
      description: '',
      audioUrl: '',
      audioAuthor: null,
      videoUrl: '',
      videoTranscript: '',
      orderingNumber: (lessonSlideshows.value?.length || 0) + 1,
      thumbnailTimestamp: null,
    })
  }

  const removeSlide = (slide: EditorSlide) => {
    transitionName.value = 'next-slide'
    eventBus.$set(slide, '_destroy', true)
  }

  const addAuthor = (slide: EditorSlide) => {
    return (authorId: string) => {
      const selectedAuthor = EditorModule.editorAllAuthors!.find((author) => author.id === authorId)
      if (selectedAuthor) {
        onSlideUpdate(slide, 'audioAuthor', selectedAuthor)
      }
    }
  }

  const removeAuthor = (slide: EditorSlide) => {
    return () => {
      if (slide.audioAuthor && slide.editorId) {
        onSlideUpdate(slide, 'audioAuthor', null)
        eventBus.$set(slide, 'audioAuthor', null)
      }
    }
  }

  const setAudioUrl = (slide: EditorSlide) => {
    return (url: string) => onSlideUpdate(slide, 'audioUrl', url)
  }

  const slideshowRedoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const slide: EditorSlide | Slideshow | undefined = lessonSlideshows.value?.find(
      (s) => s.editorId === (data.value as LessonMap).editorId,
    )

    if (slide) {
      if (data.property === 'imageUrl') {
        ;(slide as any)[data.property!] = data.apiPayload.url as string
        ;(slide as any)['slideImageData'] = data.apiPayload.imageData as string
      } else {
        ;(slide as any)[data.property!] = data.apiPayload as string
      }
    }
  }

  const slideshowUndoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const slide = lessonSlideshows.value?.find((s) => s.editorId === (data.value as LessonMap).editorId)
    if (slide) {
      if (data.property === 'imageUrl') {
        ;(slide as any)['slideImageData'] = (data.value as LessonMap).imageData
      }
      ;(slide as any)[data.property!] = (data.value as LessonMap).prevPropValue
    }
  }

  const mutateAndQueueFunc = (editorId: string) => {
    return async (data: EditorStatePayload) => {
      const changesPayload = EditorModule.editorChanges[data.path]?.find((c) => c.data.key === data.key)
      const slide: any = lessonSlideshows.value?.find((s) => s.editorId === editorId)

      /*
            if property is thumbnailTimestamp, and it's not set, we set it to 0
            if property is audioAuthor, and it's not set, we set it to null
          */
      let customValue
      if (data.property === 'thumbnailTimestamp') {
        customValue = 0
      } else if (data.property === 'audioAuthor') {
        customValue = null
      } else {
        customValue = ''
      }

      const payload = Object.assign({}, data, {
        apiPayload: data.value,
        value: {
          editorId,
          prevPropValue:
            (changesPayload?.data.value as LessonMap)?.prevPropValue ?? slide[data.property!] ?? customValue,
          imageData: slide['slideImageData'] ?? '',
        },
      })
      await EditorModule.addEditorChange(payload)
      if (slide) {
        if (payload.property === 'imageUrl') {
          eventBus.$set(slide, payload.property!, payload.apiPayload.url as string)
          eventBus.$set(slide, 'slideImageData', payload.apiPayload.imageData as string)
        } else {
          eventBus.$set(slide, payload.property!, payload.apiPayload as string)
        }
      }
      EditorModule.subscribe({
        type: 'save',
        key: payload.key,
        callback: slideshowRedoCallback,
      })
      EditorModule.subscribe({
        type: 'discard',
        key: 'discard-' + payload.key,
        callback: slideshowUndoCallback,
      })
    }
  }

  const onSlideUpdate = (slide: EditorSlide, property: keyof EditorSlide, value: any) => {
    uploadedImage.value = false
    mutateAndQueueFunc(slide.editorId!)({
      key: `currentLessonslideshow-${slide.editorId}-${property}`,
      path: route.path,
      state: 'currentLesson',
      property,
      value,
      mutateAndQueue: () => {
        return
      },
    })
  }

  const unsetLessonSlideshows = () => {
    EditorModule.setLessonSlideshows(null)
  }

  const dragData = ref({
    slide: null,
    element: null,
  } as {
    slide: Nullable<EditorSlide>
    element: Nullable<HTMLDivElement>
  })
  const dropData = ref({
    slide: null,
    element: null,
    index: -1,
  } as {
    slide: Nullable<EditorSlide>
    element: Nullable<HTMLDivElement>
    insertId?: string
  })

  const onSlideshowDragEnd = () => {
    dragData.value.slide = null
    dragData.value.element?.classList.remove('drag-slide')
    dragData.value.element = null
    dropData.value.slide = null
    dropData.value.element = null
    dropData.value.insertId = undefined
  }

  const onSlideshowDragStart = (slide: EditorSlide, e: { target: HTMLDivElement }) => {
    dragData.value.slide = slide
    dragData.value.element = e.target?.closest('.collapsible-widget') || e.target
    setTimeout(function () {
      dragData.value.element?.classList.add('drag-slide')
    }, 0)
  }

  const onSlideshowDragEnter = (slide: EditorSlide) => {
    if (slide === dropData.value.slide) return
    dropData.value.slide = slide
  }

  const onSlideshowDragOver = (e: { target: Nullable<HTMLDivElement>; clientY: number; clientX: number }) => {
    dropData.value.element = e.target?.closest('.collapsible-widget') || e.target

    if (
      dragData.value.element?.classList.contains('collapsible-widget') &&
      dropData.value.element?.classList.contains('collapsible-widget') &&
      dragData.value.element !== dropData.value.element
    ) {
      const dropElementPosition = dropData.value.element!.getBoundingClientRect()
      const nextPosition =
        (e.clientY - dropElementPosition.top) / (dropElementPosition.bottom - dropElementPosition.top) > 0.1 ||
        (e.clientX - dropElementPosition.left) / (dropElementPosition.right - dropElementPosition.left) > 0.3
      dropData.value.element!.parentNode!.insertBefore(
        dragData.value.element!,
        nextPosition ? dropData.value.element!.nextSibling : dropData.value.element,
      )
      const slideIndex = lessonSlideshows.value!.findIndex((s) => s.editorId === dropData.value.slide!.editorId)
      const slide = nextPosition ? lessonSlideshows.value![slideIndex + 1] : lessonSlideshows.value![slideIndex]
      dropData.value.insertId = slide ? slide.editorId : ''
    }
  }

  const onSlideshowDrop = () => {
    if (dropData.value.slide && dragData.value.slide) {
      const lessonSlideshowsCopy = structuredClone(lessonSlideshows.value ?? [])

      const dragSlideIndex = lessonSlideshows.value?.findIndex((s) => s.editorId === dragData.value.slide!.editorId)
      if (typeof dragSlideIndex === 'number' && dragSlideIndex >= 0) lessonSlideshows.value!.splice(dragSlideIndex, 1)

      const index = lessonSlideshows.value?.findIndex((s) => s.editorId === dropData.value.insertId)
      if (index === undefined) {
        lessonSlideshows.value!.push(dragData.value.slide)
      } else {
        lessonSlideshows.value!.splice(index, 0, dragData.value.slide)
      }
      lessonSlideshows.value?.forEach((s, index) => {
        s.orderingNumber = index
      })
      registerOrderingChanges(lessonSlideshowsCopy, lessonSlideshows.value!)
    }
  }

  const registerOrderingUndoCallback = (data: EditorStatePayload) => {
    EditorModule.setLessonSlideshows((data.value as LessonMap)?.prevPropValue as EditorSlide[])
  }

  const registerOrderingRedoCallback = (data: EditorStatePayload) => {
    EditorModule.setLessonSlideshows(data.apiPayload as EditorSlide[])
  }

  const registerOrderingChanges = (lessonSlideshows: EditorSlide[], newlessonSlideshows: EditorSlide[]) => {
    const key = 'lesson-slideshows-reordering-' + uuidv4()
    EditorModule.addEditorChange({
      key,
      path: route.path,
      skipGrouping: true,
      value: {
        prevPropValue: lessonSlideshows,
      } as LessonMap,
      apiPayload: newlessonSlideshows,
    })
    EditorModule.subscribe({
      type: 'discard',
      key,
      callback: registerOrderingUndoCallback,
    })
    EditorModule.subscribe({
      type: 'save',
      key,
      callback: registerOrderingRedoCallback,
    })
  }

  onBeforeUnmount(() => {
    unsetLessonSlideshows()
    EditorModule.unsubscribe({ type: 'discard', callback: registerOrderingUndoCallback })
    EditorModule.unsubscribe({ type: 'discard', callback: unsetLessonSlideshows })
    EditorModule.unsubscribe({ type: 'discard', callback: slideshowUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: slideshowRedoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: registerOrderingRedoCallback })
  })
</script>

<style lang="postcss">
  .lesson-slideshow-editor {
    .collapsible-widget {
      @apply ketch-mb-c20;
      &.drag-slide {
        @apply ketch-border ketch-border-dashed ketch-border-editor-primary-color;
        @apply ketch-bg-transparent ketch-py-c12 ketch-ml-c20;
        > * {
          @apply ketch-hidden;
        }
      }
    }
  }
</style>
