<template lang="pug">
.all-user-bookmarks
    h2.title-with-underline {{ $t('bookmarks.yourBookmarks') }}
    GenericContent(
        :content='userBookmarks',
        :limit='limit'
    )
</template>

<script setup lang="ts">
  import { computed, onMounted, provide, watch } from 'vue'
  import GenericContent from '@/components/curated-content/GenericContent.vue'
  import { BookmarksModule } from '@/store/modules/Bookmarks'
  import useSegment from '@/composables/useSegment'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { trackPage, pageViewed } = useSegment()
  const { fetchCourses } = useCourse()
  const { setIsLoadingComponentViewData } = useCommonMixin()
  const limit = 10
  provide('itemPurpose', 'bookmarks')

  const userBookmarks = computed(() => {
    return Object.values(BookmarksModule.bookmarks)
  })

  const loadingBookmarks = computed(() => !userBookmarks.value.length)

  if (!CourseModule.courses && userBookmarks.value.length > 0) {
    fetchCourses()
  }

  onMounted(() => {
    trackPage('Bookmarks')
    pageViewed('Bookmarks')
  })

  watch(loadingBookmarks, (value) => {
    setIsLoadingComponentViewData(value)
  })
</script>

<style lang="postcss">
  .all-user-bookmarks {
    > h2 {
      @apply ketch-font-bold ketch-mb-c40 ketch-pb-c20;
    }
  }
</style>
