<template lang="pug">
.edit-gamification-wrapper
  LoadingSpinner(v-if='fetchingData')
  template(v-else)
    h1.header-text {{ $t('editor.editGamification.gamificationSettings') }}
    .distributions-wrapper
      .level-distribution
        .gamification-content
          .helper-text
            h5.setting-title Level distribution
            h5.setting-description {{ $t('editor.editGamification.levelSettingDescription') }}
          .editable-content
            .level(
              :key='index',
              v-for='(level, index) in filteredGamificationLevels'
            )
              h5.level-count Level {{ index + 1 }}
              input.level-title(v-model='level.name')
              h5.reached-at {{ $t('editor.editGamification.reachedAt') }}
              input.max-points(
                min='0',
                type='number',
                v-model.number='level.maximumPoints'
              )
              h5.points {{ $t('editor.editGamification.points') }}
              .delete-level(@click='deleteLevel(index, level.id)')
                SVGRenderer(
                  :fill-color='"var(--secondary-text-color)"',
                  :has-hover='false',
                  :icon='trashIcon',
                  height='20',
                  width='17'
                )
            .cta
              h5.add-level(@click='addNewLevel') {{ $t('editor.editGamification.addLevel') }}
              KetchUpButton.primary(
                :class='{ hasLevelChanges: hasLevelChanges, applyingLevelChanges: applyingLevelChanges }',
                @click.native='applyLevelChanges'
              )
                LoadingSpinner(v-if='applyingLevelChanges')
                template(v-else)
                  SVGRenderer(
                    :has-hover='false',
                    :icon='checkmark',
                    :stroke-color='"var(--primary-foreground-color)"',
                    width='15'
                  )
                  h5 {{ $t('editor.editGamification.applyChanges') }}
      .points-distribution
        .gamification-content
          .helper-text
            h5.setting-title Points distribution
            h5.setting-description {{ $t('editor.editGamification.pointSettingDescription') }}
          .editable-content
            .gamification-activity(
              :key='index',
              v-for='(activity, index) in filteredGamificationActivities'
            )
              h5.if {{ $t('editor.editGamification.if') }}
              Dropdown(
                :items='gamificationActivitiesDropdownItems(activity.gamificationActivityId)',
                :value='activity.gamificationActivityId',
                @input='setGamificationActivity',
                default-dropdown-behavior,
                v-tooltip='{ content: activity.name }'
              )
              Dropdown.gain-or-remove(
                :items='gainOrRemoveItems',
                :value='activity.subtract',
                @input='setGainOrRemove(activity.gamificationActivityId, $event)',
                default-dropdown-behavior
              )
              input.points(
                type='number',
                v-model.number='activity.points'
              )
              h5 {{ $t('editor.editGamification.points') }}
              .delete-activity(@click='deleteGamificationActivity(index, activity.id)')
                SVGRenderer(
                  :fill-color='"var(--secondary-text-color)"',
                  :has-hover='false',
                  :icon='trashIcon',
                  height='20',
                  width='17'
                )
            .cta
              h5.add-action(@click='addGamificationAction') {{ $t('editor.editGamification.addAction') }}
              KetchUpButton.primary(
                :class='{ hasActivityChanges: hasActivityChanges, applyingActivityChanges: applyingActivityChanges }',
                @click.native='applyActivityChanges'
              )
                LoadingSpinner(v-if='applyingActivityChanges')
                template(v-else)
                  SVGRenderer(
                    :has-hover='false',
                    :icon='checkmark',
                    :stroke-color='"var(--primary-foreground-color)"',
                    width='15'
                  )
                  h5 {{ $t('editor.editGamification.applyChanges') }}
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import Dropdown from '@/components/common/Dropdown.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import useEditor from '@/composables/useEditor'
  import UsersApi from '@/services/api/UsersApi'
  import { UserModule } from '@/store/modules/user'
  import useI18n from '@/composables/useI18n'
  import eventBus from '@/main'
  import type { GamificationActivity, UserGamificationActivity, UserLevel } from '@/services/interfaces/Auth0'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { checkmark, trashIcon } = useIcons()
  const { hasLocalChanges } = useEditor()
  const { translateString } = useI18n()
  const { setIsLoadingComponentViewData } = useCommonMixin()

  const fetchingData = ref(false)
  const gamificationLevels = ref([] as UserLevel[])
  const previousGamificationLevels = ref([] as UserLevel[])
  const gamificationActivities = ref([] as GamificationActivity[])
  const previousGamificationActivities = ref(
    [] as { id: string; name: string; points: number; subtract: boolean; repeatable: boolean }[],
  )
  const allGamificationActivities = ref([] as GamificationActivity[])
  const gainOrRemoveItems = ref([
    { type: false, title: translateString('editor.editGamification.gain'), selectable: true },
    { type: true, title: translateString('editor.editGamification.remove'), selectable: true },
  ])
  const applyingLevelChanges = ref(false)
  const applyingActivityChanges = ref(false)

  const gamificationActivitiesDropdownItems = computed(() => {
    return (activityId: string) => {
      if (!activityId)
        return allGamificationActivities.value.map((a) => ({
          type: a.gamificationActivityId,
          title: a.name,
          selectable: !activityTrackedBefore.value(a.gamificationActivityId),
        }))
      const filteredActivities = gamificationActivities.value.filter((a) => a.name.length)
      return filteredActivities.map((a) => ({
        type: a.gamificationActivityId,
        title: a.name,
        selectable: a.gamificationActivityId === activityId,
      }))
    }
  })

  const activityTrackedBefore = computed(() => {
    return (activityId: string) => {
      return !!gamificationActivities.value.find((a) => a.gamificationActivityId === activityId && a._destroy !== true)
    }
  })

  const hasLevelChanges = computed(() => {
    return hasLocalChanges.value(previousGamificationLevels.value, gamificationLevels.value)
  })

  const hasActivityChanges = computed(() => {
    return hasLocalChanges.value(previousGamificationActivities.value, gamificationActivities.value)
  })

  const companyId = computed(() => UserModule.currentCompany!.id)

  const filteredGamificationLevels = computed(() => gamificationLevels.value.filter((l) => l._destroy !== true))

  const filteredGamificationActivities = computed(() => gamificationActivities.value.filter((a) => a._destroy !== true))

  const gamificationActivityName = computed(() => {
    return (activityName: UserGamificationActivity, locale: string) => {
      return translateString(`editor.editGamification.gamificationActivities.${activityName}`, { locale })
    }
  })

  const localizedGamificationActivities = computed(() => {
    return (activities: GamificationActivity[], locale: string) => {
      return activities.map((a) => ({
        id: a.id,
        gamificationActivityId: a.gamificationActivityId,
        name: gamificationActivityName.value(a.name as UserGamificationActivity, locale),
        key: a.name as UserGamificationActivity,
        points: a.points,
        subtract: a.subtract,
        repeatable: a.repeatable,
      }))
    }
  })

  const addNewLevel = () => {
    gamificationLevels.value.push({
      id: '',
      name: '',
      maximumPoints: 0,
    })
  }

  const addGamificationAction = () => {
    gamificationActivities.value.push({
      id: '',
      gamificationActivityId: '',
      name: '',
      points: 0,
      subtract: false,
      repeatable: false,
    })
  }

  const deleteLevel = (index: number, levelId: string) => {
    if (levelId) {
      const level = gamificationLevels.value[index]
      eventBus.$set(level, '_destroy', true)
    } else {
      gamificationLevels.value.splice(index, 1)
    }
  }

  const deleteGamificationActivity = (index: number, activityId: string) => {
    if (activityId) {
      const activity = filteredGamificationActivities.value[index]
      eventBus.$set(activity, '_destroy', true)
    } else {
      filteredGamificationActivities.value.splice(index, 1)
    }
  }

  const setGamificationActivity = (activityId: string) => {
    const newActivity = allGamificationActivities.value.find((a) => a.gamificationActivityId === activityId)
    if (newActivity) {
      newActivity.id = ''
      gamificationActivities.value.splice(gamificationActivities.value.length - 1, 1, newActivity)
    }
  }

  const setGainOrRemove = (activityId: string, subtract: boolean) => {
    const activity = gamificationActivities.value.find((a) => a.gamificationActivityId === activityId)
    if (activity) activity.subtract = subtract
  }

  const applyLevelChanges = () => {
    if (hasLevelChanges.value && !applyingLevelChanges.value) {
      applyingLevelChanges.value = true
      UsersApi.updateGamificationLevelsOfACompany(companyId.value, gamificationLevels.value)
        .then(async () => {
          const currentUserLevel = gamificationLevels.value.find(
            (l) => l.id === UserModule.userGamificationStats?.level.id,
          )
          if (hasLocalChanges.value(currentUserLevel, UserModule.userGamificationStats?.level)) {
            await UserModule.getUserGamificationStats()
            UserModule.setUserGamificationLevel(structuredClone(currentUserLevel!))
          }
          eventBus.$toasted.success(translateString('editor.editGamification.levelsUpdated'))
        })
        .finally(() => {
          applyingLevelChanges.value = false
          previousGamificationLevels.value = structuredClone(gamificationLevels.value)
        })
    }
  }

  const applyActivityChanges = () => {
    // remove unfilled gamification activities
    gamificationActivities.value.forEach((a, index) => {
      if (!a.gamificationActivityId) {
        gamificationActivities.value.splice(index, 1)
      }
    })
    if (hasActivityChanges.value && !applyingActivityChanges.value) {
      applyingActivityChanges.value = true
      // we have to use the name as the key which is in en-US version
      // before we send them to the BE because that's how they're saved on the DB
      const enLocalizedActivities = gamificationActivities.value.map((a) => ({
        id: a.id,
        gamificationActivityId: a.gamificationActivityId,
        name: a.name,
        points: a.points,
        subtract: a.subtract,
        _destroy: a._destroy,
        repeatable: a.repeatable,
      }))
      const activities = structuredClone(enLocalizedActivities)
      UsersApi.updateGamificationActivitiesOfACompany(companyId.value, activities)
        .then(() => {
          getAllGamificationActivities()
          eventBus.$toasted.success(translateString('editor.editGamification.activitiesUpdated'))
        })
        .finally(() => {
          applyingActivityChanges.value = false
          previousGamificationActivities.value = structuredClone(
            localizedGamificationActivities.value(activities, eventBus.$i18n.locale),
          )
        })
    }
  }

  const getAllGamificationActivities = async () => {
    if (!(UserModule.companyGamificationActivities.length > 0)) await UserModule.getCompanyGamificationActivities()
    return (allGamificationActivities.value = localizedGamificationActivities.value(
      structuredClone(UserModule.companyGamificationActivities),
      eventBus.$i18n.locale,
    ))
  }

  const getGamificationSettingsData = () => {
    fetchingData.value = true
    const getLevels = UsersApi.getGamificationLevelsOfACompany(companyId.value).then((levels) => {
      gamificationLevels.value = levels
      previousGamificationLevels.value = structuredClone(levels)
    })
    const getActivities = UsersApi.getGamificationActivitiesOfACompany(companyId.value).then((activities) => {
      const localizedActivities = localizedGamificationActivities.value(activities, eventBus.$i18n.locale)
      gamificationActivities.value = localizedActivities
      previousGamificationActivities.value = structuredClone(localizedActivities)
    })
    const allTrackedGamificationActivities = getAllGamificationActivities()
    Promise.all([getLevels, getActivities, allTrackedGamificationActivities]).finally(
      () => (fetchingData.value = false),
    )
  }

  watch(fetchingData, (value) => {
    setIsLoadingComponentViewData(value)
  })

  getGamificationSettingsData()
</script>

<style lang="postcss">
  .edit-gamification-wrapper {
    @apply ketch-flex ketch-flex-col;
    .header-text {
      @apply ketch-mb-c50 ketch-font-big-daily-short md:ketch-text-2xl md:ketch-leading-lg;
    }
    .distributions-wrapper {
      @apply ketch-flex ketch-flex-col;
      .level-distribution,
      .points-distribution {
        @apply ketch-py-c20;
        h5.setting-title {
          @apply ketch-text-primary-color ketch-pb-c10;
        }
        .gamification-content {
          @apply ketch-flex ketch-space-x-[100px];
          .helper-text {
            @apply ketch-max-w-[290px];
          }
          .editable-content {
            @apply ketch-w-full;
          }
          input {
            @apply ketch-h-c45 ketch-outline-none ketch-px-c12 ketch-border ketch-border-border-color;
          }
          input.max-points,
          input.points {
            @apply ketch-max-w-[60px];
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
      .level-distribution {
        @apply ketch-border-b ketch-border-dashed ketch-border-border-color;
        .level {
          @apply ketch-flex ketch-items-center ketch-space-x-c15 ketch-py-c15 ketch-relative;
          @apply ketch-border-b ketch-border-dashed ketch-border-border-color;
          .level-count {
            @apply ketch-font-bold;
          }
          input.level-title {
            @apply ketch-w-[190px];
          }
          .delete-level {
            @apply ketch-absolute ketch-right-0 ketch-cursor-pointer;
          }
        }
      }
      .points-distribution {
        .gamification-activity {
          @apply ketch-flex ketch-items-center ketch-py-c10 ketch-space-x-c15;
          @apply ketch-border-b ketch-border-border-color ketch-border-dashed;
          .if {
            @apply ketch-font-bold;
          }
          .dropdown {
            @apply ketch-w-[305px];
            .selected-item {
              @apply ketch-h-c45 ketch-rounded-none ketch-bg-white;
            }
            &.gain-or-remove {
              @apply ketch-w-auto;
            }
          }
          .delete-activity {
            @apply ketch-cursor-pointer;
          }
        }
      }
      .cta {
        @apply ketch-flex ketch-items-center ketch-justify-between ketch-pt-c15;
        .add-level,
        .add-action {
          @apply ketch-underline ketch-cursor-pointer;
        }
        button {
          @apply ketch-w-auto ketch-h-auto ketch-space-x-c10 ketch-py-[17px] ketch-px-c20;
          &:not(.hasLevelChanges, .hasActivityChanges) {
            @apply ketch-opacity-[0.3] ketch-cursor-default;
          }
          &.applyingLevelChanges,
          &.applyingActivityChanges {
            @apply ketch-w-[230px] ketch-h-[56px];
          }
        }
      }
    }
  }
  .ketch-dark .edit-gamification-wrapper {
    input {
      @apply ketch-bg-background-color ketch-text-white;
    }
    .dropdown {
      .selected-item {
        @apply ketch-bg-background-color;
      }
    }
  }
</style>
