<template lang="pug">
.package-price-section-wrapper
    .save-25-percent(v-if='!inPackageInfoPage')
        h4 {{ $t('save25Percent') }}
        h5 {{ $t('withThePackageTitle', { packageTitle: courseBundle.title }) }}
    .package-price-section(:class='{ inPackageInfoPage: inPackageInfoPage }')
        .package-header-info
            h2.prefix {{ courseBundle.packagePrefix }}
        .teaser-description {{ courseBundle.teaserDescription }}
        FeedbackPriceSection(
            :is-purchased='packagePurchased',
            :product-amount='productAmount',
            :rating='courseBundle.rating || 0',
            :user-comment='courseBundle.userComment'
        )
        KetchUpButton.primary.to-package(
            @click.native='packageCheckoutAction',
            v-if='canUseShopifyCheckout && !packagePurchased'
        )
            h5 {{ $t('courses.buyPackage') }}
            SVGRenderer(
                :has-hover='false',
                :icon='arrowRight',
                stroke-color='var(--primary-foreground-color)'
            )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import FeedbackPriceSection from '@/components/course/FeedbackPriceSection.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import { useRoute } from 'vue-router/composables'
  import useShopifyClient from '@/composables/useShopifyClient'

  const route = useRoute()
  const { arrowRight } = useIcons()
  const { coursePackagePurchaseIntended } = useSegment()
  const { courseBundle, packageId } = useCourse()
  const { createShopifyCheckout } = useShopifyClient()

  defineProps({
    productAmount: {
      type: Number,
      required: true,
    },
  })

  const inPackageInfoPage = computed(() => route.name === 'PackageDetails')

  const packagePurchased = computed(() => {
    return courseBundle.value?.courses?.every((course) => course.purchased) || false
  })

  const canUseShopifyCheckout = computed(() => !!courseBundle.value?.shopifyProductId)

  const packageCheckoutAction = async () => {
    await coursePackagePurchaseIntended(packageId.value, courseBundle.value!.title, 'package_details_page')
    await createShopifyCheckout()
  }
</script>

<style lang="postcss" scoped>
  .package-price-section-wrapper {
    @apply ketch-flex ketch-flex-col;
    .package-price-section {
      @apply ketch-border ketch-border-border-color ketch-rounded-large ketch-p-c20 ketch-h-[fit-content];
      @apply ketch-flex ketch-flex-col ketch-space-y-c20;
      &:not(.inPackageInfoPage) {
        @apply ketch-rounded-t-none;
      }
      .package-header-info {
        @apply ketch-flex ketch-flex-col ketch-items-start;
      }
      .prefix,
      .discounted-text {
        @apply ketch-font-bold;
      }
      button {
        @apply ketch-w-full;
      }
    }
  }
</style>
