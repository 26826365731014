<template lang="pug">
.bar-chart-wrapper
    Bar(
        :chart-data='chartData',
        :chart-id='chartId',
        :chart-options='chartOptions',
        :dataset-id-key='datasetIdKey',
        :height='height'
    )
</template>

<script setup lang="ts">
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  import { Bar } from 'vue-chartjs/legacy'
  import type { PropType } from 'vue'
  import type { BarChartData } from '@/services/interfaces/Common'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  const props = defineProps({
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    chartData: {
      type: Object as PropType<BarChartData>,
      required: true,
    },
    showLegendLabel: {
      type: Boolean,
      default: true,
    },
    maxYAxes: Number,
    height: {
      type: Number,
      default: 275,
    },
  })
  const chartOptions = {
    responsive: true,
    scales: {
      yAxes: {
        max: props.maxYAxes,
      },
    },
    plugins: {
      legend: {
        display: props.showLegendLabel,
      },
    },
  }
</script>

<style lang="postcss" scoped></style>
