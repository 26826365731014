import eventBus from '@/main'
import { Values } from 'vue-i18n'

interface useI18nReturn {
  translateString: (path: string, values?: Values) => string
}

export default function useI18n(): useI18nReturn {
  const translateString = (path: string, values?: Values) => {
    return eventBus?.$t(path, values) as string
  }

  return {
    translateString,
  }
}
