import { render, staticRenderFns } from "./CheckoutSuccess.vue?vue&type=template&id=15565048&scoped=true&lang=pug"
import script from "./CheckoutSuccess.vue?vue&type=script&setup=true&lang=ts"
export * from "./CheckoutSuccess.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CheckoutSuccess.vue?vue&type=style&index=0&id=15565048&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15565048",
  null
  
)

export default component.exports