<template lang="pug">
.testimonial
    .detail
        .avatar-url(v-if='testimonial.avatarUrl')
            img(:src='testimonial.avatarUrl')
        h3.initial(
            :style='{ "background-color": initialColor }',
            v-else
        ) {{ initial }}
        .name-age-rating
            .name-age
                h5.name {{ testimonial.name }}
                h5.age(v-if='testimonial.age') , {{ testimonial.age }}
            StarRating(:rating='testimonial.rating')
    .review
        h5 {{ testimonial.review }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import StringToColor from 'string-to-color'
  import StarRating from '@/components/common/StarRating.vue'
  import type { PropType } from 'vue'
  import type { Testimonial as TestimonialState } from '@/services/interfaces/Course'

  const props = defineProps({
    testimonial: {
      required: true,
      type: Object as PropType<TestimonialState>,
    },
  })

  const initial = computed(() => {
    const splitNames = props.testimonial?.name.split(' ') || []
    let initial = splitNames.length ? splitNames[0][0] : ''
    if (initial && splitNames[1]) {
      initial += splitNames[1][0]
    }
    return initial
  })

  const initialColor = computed(() => StringToColor(props.testimonial.name))
</script>

<style lang="postcss">
  .testimonial {
    @apply ketch-flex ketch-flex-col;
    .detail {
      @apply ketch-flex ketch-items-center ketch-space-x-c15 ketch-mb-c10;
      .initial {
        @apply ketch-w-[48px] ketch-h-[48px] ketch-flex ketch-justify-center;
        @apply ketch-items-center ketch-font-bold ketch-rounded-full ketch-text-primary-foreground-color;
      }
      .avatar-url {
        @apply ketch-w-c80 ketch-h-c80 ketch-rounded-full ketch-overflow-hidden;
      }
      .name-age-rating {
        @apply ketch-flex ketch-flex-col ketch-space-y-c5;
        .name-age {
          @apply ketch-flex;
          h5 {
            @apply ketch-font-bold;
          }
        }
      }
    }
  }
</style>
