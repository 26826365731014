import axios from './interceptor'
import { CourseSearchItem, CourseLessonSearchItem } from '@/services/interfaces/Course'

export default class SearchApi {
  static async search(term: string, courseId?: string): Promise<CourseSearchItem[] | CourseLessonSearchItem[]> {
    const config: any = { params: {} }
    if (courseId) {
      config.params.courseId = courseId
    }
    return axios
      .post('/search', { term }, config)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
