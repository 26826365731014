<template lang="pug">
.package-courses-slider
    h2.header-title {{ $t('courses.package.contents') }}
    CarouselSwiper(
        :break-points='breakPoints',
        :key='rerenderKey',
        :nav-class='`nav-${courses[0].id}`',
        :slides-per-group='2',
        :slides-per-view='2.65',
        :space-between='50',
        :swiper-items='courses',
        v-if='courses'
    )
        template(#slide-slot='{ item }')
            .course(@click='viewCourse(item.id)')
                img(:src='item.cardTeaserImageUrl || item.teaserImageUrl')
                .button-wrapper
                    KetchUpButton.tertiary(@click.native.stop='viewCourse(item.id)')
                        h5 {{ $t('courses.package.toTheProduct') }}
                        SVGRenderer(
                            :has-hover='false',
                            :icon='arrowRight',
                            stroke-color='var(--primary-text-color)'
                        )
        template(
            #slide-nav,
            v-if='!isMobileDevice'
        )
            .navigation(:class='`nav-${courses[0].id}`')
                .prev-nav-button
                    img(:src='leftNavigation')
                .next-nav-button
                    img(:src='rightNavigation')
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CarouselSwiper from '@/components/common/CarouselSwiper.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import { useRouter } from 'vue-router/composables'
  import type { CourseItem } from '@/services/interfaces/Course'
  import type { PropType } from 'vue'

  defineProps({
    courses: {
      required: true,
      type: Array as PropType<CourseItem[]>,
    },
  })

  const router = useRouter()
  const { leftNavigation, rightNavigation, arrowRight } = useIcons()

  const breakPoints = {
    1200: {
      slidesPerView: 2.35,
      spaceBetween: 30,
      slidesPerGroup: 2,
    },
    1024: {
      slidesPerView: 2.25,
      spaceBetween: 30,
      slidesPerGroup: 2,
    },
    991: {
      slidesPerView: 1.65,
      spaceBetween: 20,
      slidesPerGroup: 1,
    },
    767: {
      slidesPerView: 1.25,
      spaceBetween: 20,
      slidesPerGroup: 1,
    },
    540: {
      slidesPerView: 1.2,
      spaceBetween: 15,
      slidesPerGroup: 1,
    },
  }

  const { isMobileDevice } = useBreakpoint()
  const rerenderKey = ref('')
  watch(isMobileDevice, () => {
    rerenderKey.value = Math.random().toString()
  })

  const viewCourse = (courseId: string) => {
    router
      .push({
        name: 'CourseDetails',
        params: {
          courseId: courseId,
        },
      })
      .catch(() => {
        return
      })
  }
</script>

<style lang="postcss">
  .package-courses-slider {
    .swiper-slide {
      @apply ketch-px-c1;
    }
    .header-title {
      @apply ketch-mb-c40;
    }
    .swiper-wrapper {
      @apply ketch-h-[440px];
    }
    .course {
      @apply ketch-rounded-large ketch-border ketch-border-border-color hover:ketch-cursor-pointer;
      @apply hover:ketch-border-primary-text-color ketch-overflow-hidden ketch-relative ketch-h-full;
      img {
        @apply ketch-w-full;
      }
      .button-wrapper {
        @apply ketch-px-c10 ketch-absolute ketch-w-full ketch-bottom-c15;
        button {
          @apply ketch-w-full ketch-border-primary-text-color;
          h5 {
            @apply ketch-font-bold;
          }
        }
      }
    }
  }
</style>
