<template lang="pug">
.leuckentext-editor
    .title-area
        h5
            span.bold {{ $t('courses.leuckentext.title') }}
            span (Optional)
        h5.remove(
            @click='removeLeuckentext',
            v-if='leuckentext && (leuckentext.id || leuckentext.editorId)'
        ) {{ $t('courses.leuckentext.removeLeuckentext') }}
    .editor-container(v-if='leuckentext && (leuckentext.id || leuckentext.editorId)')
        .meta-section
            TextRenderer.title(
                :allow-source-changes-in-editor='true',
                :mutate-and-queue-func='mutateAndQueueFunc()',
                :placeholder='$t("editor.newTitle")',
                :show-text-input='true',
                :source='originalLeuckentext ? originalLeuckentext.title : ""',
                edit-title='Title',
                editor-property='title',
                editor-state='leuckentext',
                show-toolbar-text-block-only
            )
            TextRenderer.description(
                :allow-source-changes-in-editor='true',
                :mutate-and-queue-func='mutateAndQueueFunc()',
                :placeholder='$t("editor.newDescription")',
                :source='originalLeuckentext ? originalLeuckentext.description : ""',
                edit-title='Description',
                editor-property='description',
                editor-state='leuckentext',
                show-toolbar-text-block-only
            )
            TextRenderer.success-message(
                :allow-source-changes-in-editor='true',
                :edit-title='$t("editor.messages.leuckentextSuccessMessage")',
                :mutate-and-queue-func='mutateAndQueueFunc()',
                :placeholder='$t("editor.messages.leuckentextSuccessMessage")',
                :source='originalLeuckentext ? originalLeuckentext.successMessage || "" : ""',
                editor-property='successMessage',
                editor-state='leuckentext',
                show-toolbar-text-block-only
            )
            TextRenderer.failed-message(
                :allow-source-changes-in-editor='true',
                :edit-title='$t("editor.messages.leuckentextFailedMessage")',
                :mutate-and-queue-func='mutateAndQueueFunc()',
                :placeholder='$t("editor.messages.leuckentextFailedMessage")',
                :source='originalLeuckentext ? originalLeuckentext.failureMessage || "" : ""',
                editor-property='failureMessage',
                editor-state='leuckentext',
                show-toolbar-text-block-only
            )
        .statement-button
            h5 {{ $t('courses.leuckentext.statements') }}
            transition-group.statements(
                :name='transitionName',
                tag='div'
            )
                .statement(
                    :key='statement.id || statement.editorId',
                    v-for='(statement, index) in leuckentextItems'
                )
                    .top-header
                        span {{ index + 1 }}. {{ $t('courses.leuckentext.statement') }}
                        span(@click='removeStatement(statement.editorId || statement.id)')
                            SVGRenderer(
                                :has-hover='false',
                                :icon='trashIcon',
                                fill-color='var(--editor-primary-color)',
                                width='16'
                            )
                    TextRenderer(
                        :editor-state='`statement-text-${statement.editorId || statement.id}`',
                        :mutate-and-queue-func='mutateAndQueueFunc(statement.editorId || statement.id)',
                        :placeholder='$t("editor.questions.yourQuestion")',
                        :source='statement.text || ""',
                        editor-property='text',
                        show-toolbar-text-block-only
                    )
            KetchUpButton.editor(@click.native='addNewStatement')
                h5 {{ $t('courses.leuckentext.addNewStatement') }}
    .no-text-container(v-else)
        .button-container
            KetchUpButton.editor(@click.native='showEditorContainer')
                h5 {{ $t('courses.leuckentext.addTest') }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useCourse from '@/composables/useCourse'
  import { EditorModule } from '@/store/modules/editor'
  import { v4 as uuidv4 } from 'uuid'
  import type { EditorStatePayload, LessonMap } from '@/services/interfaces/Course'
  import eventBus from '@/main'

  const { trashIcon } = useIcons()
  const { lessonId } = useCourse()
  const transitionName = ref('')

  const originalLeuckentext = computed(() => {
    if (EditorModule.leuckentextDeleteParams?.id) return null
    return EditorModule.currentLesson?.leuckentext?.id ? EditorModule.currentLesson?.leuckentext : null
  })

  const leuckentext = computed(() => EditorModule.lessonLeuckentext)
  const leuckentextItems = computed(() => {
    return leuckentext.value?.leuckentextItems?.filter((item) => item._destroy !== true) || []
  })

  const leuckentextRedoCallback = (data: EditorStatePayload) => {
    const statement = leuckentext.value?.leuckentextItems.find(
      (item) => (data.value as LessonMap).questionId === (item.id || item.editorId),
    )
    if (statement) (statement as any)[data.property!] = data.apiPayload as string
    else leuckentext.value![data.property as 'title' | 'description'] = data.apiPayload as string
  }

  const leuckentextUndoCallback = (data: EditorStatePayload) => {
    const statement = leuckentext.value?.leuckentextItems.find(
      (item) => (data.value as LessonMap).questionId === (item.id || item.editorId),
    )
    if (statement) (statement as any)[data.property!] = (data.value as LessonMap).prevPropValue
    else {
      leuckentext.value![data.property as 'title' | 'description'] = (data.value as LessonMap).prevPropValue as string
    }
  }

  const mutateAndQueueFunc = (statementId?: string) => {
    return async (data: EditorStatePayload) => {
      const changesPayload = EditorModule.editorChanges[data.path]?.find((c) => c.data.key === data.key)
      let statement: any
      if (statementId) {
        statement = leuckentext.value?.leuckentextItems.find((item) => statementId === (item.id || item.editorId))
      }

      const newData = Object.assign({}, data, {
        apiPayload: data.value,
        value: {
          questionId: statementId || '',
          prevPropValue:
            (changesPayload?.data.value as LessonMap)?.prevPropValue ??
            (statement?.[data.property!] || leuckentext.value![data.property as 'title' | 'description'] || ''),
        },
      })
      await EditorModule.addEditorChange(newData)
      if (statement) statement![newData.property!] = newData.apiPayload as string
      else leuckentext.value![data.property as 'title' | 'description'] = newData.apiPayload as string

      EditorModule.subscribe({
        type: 'save',
        key: data.key,
        callback: leuckentextRedoCallback,
        allowDuplicate: true,
      })
      EditorModule.subscribe({
        type: 'discard',
        key: 'discard-' + data.key,
        callback: leuckentextUndoCallback,
      })
    }
  }

  const addNewStatement = () => {
    transitionName.value = 'fadeInUp'
    const editorId = 'editor-question-' + uuidv4()
    leuckentext.value?.leuckentextItems.push({
      editorId,
      text: '',
      orderNumber: leuckentext.value.leuckentextItems.length + 1,
    })
  }

  const removeStatement = (id: string) => {
    transitionName.value = 'next-slide'
    const itemIndex = leuckentext.value!.leuckentextItems.findIndex((item) => [id].includes(item.editorId! || item.id!))
    if (leuckentext.value?.leuckentextItems[itemIndex]?.editorId) {
      leuckentext.value?.leuckentextItems.splice(itemIndex, 1)
    } else eventBus.$set(leuckentext.value!.leuckentextItems[itemIndex], '_destroy', true)
  }

  const removeLeuckentext = async () => {
    if (leuckentext.value!.id) {
      EditorModule.setLeuckentextDeleteParams({ id: leuckentext.value!.id, lessonId: lessonId.value })
    }
    EditorModule.setLeuckentext(null)
  }
  const showEditorContainer = () => {
    EditorModule.setLeuckentext({
      editorId: uuidv4(),
      lessonId: lessonId.value,
      title: '',
      description: '',
      leuckentextItems: [],
      successMessage: '',
      failureMessage: '',
    })
  }

  onBeforeUnmount(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: leuckentextUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: leuckentextRedoCallback })
  })
</script>

<style lang="postcss">
  .leuckentext-editor {
    @apply ketch-py-c40;
    .title-area {
      @apply ketch-flex ketch-justify-between;
      h5 {
        @apply ketch-text-editor-primary-color ketch-mb-c5;
        .bold {
          @apply ketch-font-bold ketch-mr-c5;
        }
      }
      .remove {
        @apply hover:ketch-underline ketch-cursor-pointer;
      }
    }
    .editor-container,
    .no-text-container {
      @apply ketch-bg-editor-primary-color ketch-bg-opacity-5 ketch-p-c16 ketch-rounded-normal;
    }
    .editor-container {
      @apply ketch-space-y-c15;
      .meta-section {
        @apply ketch-border-b ketch-border-editor-primary-color ketch-border-dashed ketch-pb-c30 ketch-space-y-c15;
      }
      .statement-button {
        > h5 {
          @apply ketch-font-bold ketch-text-editor-primary-color;
        }
      }
      .statements {
        @apply ketch-py-c15 ketch-space-y-c20 ketch-mb-c20;
        .top-header {
          @apply ketch-flex ketch-space-x-c10;
          svg {
            @apply ketch-cursor-pointer;
          }
        }
      }
    }
    .no-text-container {
      @apply ketch-h-c150;
      .button-container {
        @apply ketch-border ketch-border-transparent hover:ketch-border-dashed hover:ketch-border-editor-primary-color;
        @apply ketch-flex ketch-relative ketch-items-center ketch-justify-center ketch-h-full ketch-rounded-normal;
        @apply ketch-bg-editor-primary-color ketch-bg-opacity-10;
        button h5 {
          @apply ketch-font-bold;
        }
      }
    }
  }
</style>
