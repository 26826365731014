<template lang="pug">
.iframe-popup
    LoadingSpinner(v-if='loading')
    iframe#iframePopupModal(
        @load='iframeLoaded',
        src='https://www.ketchup.academy/pricing-popup',
        v-show='!loading'
    )
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import LoadingSpinner from '../common/LoadingSpinner.vue'

  const loading = ref(true)

  const iframeLoaded = () => {
    ;(window as any).iFrameResize({ log: false }, '#iframePopupModal')
    loading.value = false
  }
</script>

<style lang="postcss" scoped>
  iframe {
    @apply ketch-w-c1 ketch-min-w-full ketch-min-h-[330px] ketch-border-none;
  }
</style>
