import axios from 'axios'
import { UserModule } from '@/store/modules/user'
import { LessonViewed } from '@/services/interfaces/Analytics'

export default class AnalyticsApi {
  static async getInsightsReports(bookmarkId: number): Promise<any> {
    const config: any = { params: {} }

    if (bookmarkId) {
      config.params.bookmark_id = bookmarkId
    }

    return axios
      .get('https://ketchup-analytics-api-staging.herokuapp.com/insights', config)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getEventReportData(event: string, fromDate: string, toDate: string): Promise<LessonViewed[]> {
    return axios
      .get('https://ketchup-analytics-api-staging.herokuapp.com/export', {
        params: {
          event,
          from_date: fromDate,
          to_date: toDate,
          company_id: UserModule.currentCompany?.id,
        },
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
