<template lang="pug">
.download-materials
    .materials-header
        h2.title-with-underline {{ $t('downloads') }}
        h5(v-html='$t("courses.downloadDescription")')
    .materials
        .download-item(
            :key='index',
            v-for='(item, index) in downloadables'
        )
            .icon-content
                .icon
                    SVGRenderer(
                        :has-hover='false',
                        :icon='itemImage(item.downloadables.downloadUrl)',
                        fill-color='var(--primary-text-color)'
                    )
                .content
                    h5.title {{ item.title }}
                    HTMLRenderer.intro-text(
                        :html='item.introText || ""',
                        link-target='_blank'
                    )
                    HTMLRenderer.outro-text(
                        :html='item.outroText || ""',
                        link-target='_blank'
                    )
            .download-button-wrapper
                KetchUpButton.download(@click.native='downloadContent(item.downloadables.downloadUrl)')
                    h5 {{ $t('download') }}
                    SVGRenderer(
                        :has-hover='false',
                        :icon='bigDownloadIcon',
                        fill-color='var(--primary-text-color)'
                    )
</template>

<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import useCourse from '@/composables/useCourse'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useSegment from '@/composables/useSegment'
  import useCommonMixin from '@/composables/useCommonMixin'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'

  const { course } = useCourse()
  const { bigDownloadIcon, iconPdf } = useIcons()
  const { courseMaterialDownloaded, trackPage, pageViewed } = useSegment()
  const { downloadItem, isPdfFile, isImageFile } = useCommonMixin()

  const downloadables = computed(() => course.value?.downloadables)
  const downloadContent = (downloadUrl: string) => {
    courseMaterialDownloaded(course.value!.id, course.value!.title, downloadUrl)
    downloadItem(downloadUrl)
  }

  const itemImage = (url: string) => {
    if (url && isPdfFile(url)) return iconPdf.value
    if (url && isImageFile(url)) return bigDownloadIcon.value
    return bigDownloadIcon.value
  }

  onMounted(() => {
    trackPage('Download Materials')
    pageViewed('Download Materials')
  })
</script>

<style lang="postcss" scoped>
  .download-materials {
    @apply ketch-pb-c40;
    .materials-header h2 {
      @apply ketch-pb-c10;
      &:after {
        @apply ketch-bg-secondary-color;
      }
    }
    .materials {
      @apply ketch-mt-c60;
      .download-item {
        @apply ketch-flex ketch-flex-col ketch-space-y-c15 ketch-py-c20 ketch-border-b ketch-border-border-color;
        @apply sm:ketch-flex-row sm:ketch-space-y-0 sm:ketch-items-end sm:ketch-justify-between;
        .icon-content {
          @apply ketch-space-x-c20 ketch-flex ketch-items-start sm:ketch-mr-c40 sm:ketch-flex-1;
          .title {
            @apply ketch-font-bold;
          }
          .icon {
            @apply ketch-flex ketch-justify-center;
            flex: 0 0 40px;
          }
          .content {
            @apply ketch-flex-1;
          }
        }
        .download-button-wrapper {
          @apply ketch-flex ketch-justify-end ketch-w-full sm:ketch-flex-none sm:ketch-w-auto;
          button {
            @apply ketch-border-border-hover-color;
            @screen sm {
              flex: 0 0 165px;
            }
          }
        }
      }
    }
  }
</style>
