<template lang="pug">
.create-course-group-modal
    h3.title-with-underline {{ createOrUpdateCourseGroupText }}
    .select-course-group(v-if='updateCourseGroup')
        Dropdown.defaultBehavior(
            :dropdown-placeholder='$t("courses.selectADifferentCourseGroup")',
            :items='courseGroupDropdownItems',
            :key='selectedCourseGroupId',
            default-dropdown-behavior,
            v-if='courseGroupDropdownItems && courseGroupDropdownItems.length',
            v-model='selectedCourseGroupId'
        )
        .separator {{ $t('or') }}
    TextRenderer(
        :placeholder='$t("courses.enterNewCourseGroupTitle")',
        :show-text-input='true',
        :source='courseGroupTitle',
        @text-input='courseGroupTitle = $event'
    )
    .create-course-group-cta
        KetchUpButton.editor(
            :disabled='disabledCTA',
            @click.native='createOrUpdateCourseGroup'
        )
            LoadingSpinner(v-if='submittingRequests')
            h5(v-else) {{ courseGroupCTA }}
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { EditorModule } from '@/store/modules/editor'
  import Dropdown from '@/components/common/Dropdown.vue'
  import useCourse from '@/composables/useCourse'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { CourseGroupItem } from '@/services/interfaces/Course'

  const props = defineProps({
    courseId: {
      type: String,
      required: true,
    },
    addingNewCourseGroup: {
      type: Boolean,
      default: false,
    },
    updateCourseGroup: {
      type: Boolean,
      default: false,
    },
  })

  const { course, currentModule } = useCourse()
  const { translateString } = useI18n()
  const courseGroupTitle = ref('')
  const submittingRequests = ref(false)
  const selectedCourseGroupId = ref('')

  const createOrUpdateCourseGroupText = computed(() => {
    if (props.updateCourseGroup) return translateString('courses.createOrUpdateCourseGroup')
    return translateString('courses.createCourseGroup')
  })

  const courseGroupDropdownItems = computed(() => {
    return (
      course.value?.courseGroups?.map((group) => ({
        selectable: CourseModule.currentCourseGroupId !== group.id,
        type: group.id,
        title: group.name,
      })) || []
    )
  })

  const courseGroupCTA = computed(() =>
    props.updateCourseGroup
      ? translateString('courses.updateAndAssignToCourseGroup')
      : translateString('courses.createAndAssignToCourseGroup'),
  )

  const disabledCTA = computed(() => {
    if (submittingRequests.value) return true
    if (props.updateCourseGroup) return !(selectedCourseGroupId.value || courseGroupTitle.value)
    return !courseGroupTitle.value.length
  })

  const patchCoursesModuleMap = (courseGroupId: string, moduleId: string) => {
    const courseModule = CourseModule.coursesModuleMap[props.courseId]?.find((m) => m.id === moduleId)
    const editorModule = EditorModule.coursesModuleMap[props.courseId].find((m) => m.id === moduleId)
    if (editorModule) {
      editorModule.courseGroupId = courseGroupId
    }
    if (courseModule) {
      courseModule.courseGroupId = courseGroupId
    }
  }

  const createAndAssign = (moduleIds: string[]) => {
    const courseGroups = CourseModule.course?.courseGroups
    const orderingNumber = courseGroups ? courseGroups[courseGroups.length - 1].orderingNumber + 1 : 1
    CourseApi.createCourseGroup({
      name: courseGroupTitle.value,
      orderingNumber: orderingNumber,
      courseId: props.courseId,
    }).then((resp) => {
      CourseModule.course?.courseGroups.push(resp.courseGroup)

      // assign modules to course groups
      if (!props.addingNewCourseGroup) {
        assignModulesToCourseGroup(moduleIds, resp.courseGroup)
      }
      CourseModule.setCurrentCourseGroup(resp.courseGroup.id)
      eventBus.$emit('close-modal')
      eventBus.$toasted.success(translateString('courses.createdCourseGroupSuccessfully'))
      submittingRequests.value = false
    })
  }

  const assignModulesToCourseGroup = async (moduleIds: string[], courseGroup: CourseGroupItem) => {
    const assignModuleToCourseGroupPromises: any[] = []

    moduleIds.forEach((moduleId) => {
      patchCoursesModuleMap(courseGroup.id, moduleId)
      assignModuleToCourseGroupPromises.push(
        CourseApi.updateModuleContent(props.courseId, moduleId, {
          module: { courseGroupIds: [courseGroup.id] },
        }),
      )
    })

    await Promise.all(assignModuleToCourseGroupPromises)
  }

  const updateAndAssign = () => {
    if (courseGroupTitle.value) {
      // this means it's a new course group which needs to be created and assigned to the module
      createAndAssign([currentModule.value.id])
    } else {
      // update course group
      CourseApi.updateModuleContent(props.courseId, currentModule.value.id, {
        module: { courseGroupIds: [selectedCourseGroupId.value] },
      })
        .then(() => {
          patchCoursesModuleMap(selectedCourseGroupId.value, currentModule.value.id)
          CourseModule.setCurrentCourseGroup(selectedCourseGroupId.value)
        })
        .finally(() => {
          submittingRequests.value = false
          eventBus.$emit('close-modal')
          eventBus.$toasted.success(translateString('courses.updatedCourseGroupSuccessfully'))
        })
    }
  }

  const createOrUpdateCourseGroup = () => {
    submittingRequests.value = true
    if (props.updateCourseGroup) {
      updateAndAssign()
    } else {
      /*
        Create a new course group and assign all module IDs in the course to it,
        if there isn't already a course group in the course.
      */
      const moduleIds = CourseModule.coursesModuleMap[props.courseId]?.map((mod) => mod.id) || []
      createAndAssign(moduleIds)
    }
  }

  watch(selectedCourseGroupId, (value) => {
    if (value.length && props.updateCourseGroup) {
      courseGroupTitle.value = ''
    }
  })

  watch(courseGroupTitle, (value) => {
    if (value.length && props.updateCourseGroup) {
      selectedCourseGroupId.value = ''
    }
  })
</script>

<style lang="postcss">
  .create-course-group-modal {
    @apply ketch-flex ketch-flex-col;
    .select-course-group {
      @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-mb-c20;
      .separator {
        @apply ketch-text-center;
      }
    }
    .text-renderer {
      .text-input {
        @apply ketch-rounded-normal;
      }
    }
    .create-course-group-cta {
      @apply ketch-flex ketch-justify-end ketch-mt-c20;
      button {
        @apply ketch-w-auto;
      }
    }
  }
</style>
