<template lang="pug">
.flags-panel
  h2 Flags Controller
  .flags-wrapper
    .flag(
      :key='flag',
      v-for='flag of Object.keys(flags)'
    )
      h4.flag-text {{ flag }}
      ToggleSwitch(
        :value='flagsValues[flag]',
        @toggled='toggleFlag(flag, $event)'
      )
</template>

<script setup lang="ts">
  import useFlags from '@/composables/useFlags'
  import ToggleSwitch from '@/components/common/ToggleSwitch.vue'
  import eventBus from '@/main'
  import { onMounted } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import { useRouter } from 'vue-router/composables'

  const router = useRouter()
  const { flags } = useFlags()
  const flagsValues: { [key: string]: boolean } = flags

  for (const flagName of Object.keys(flags)) {
    const storageFlagName = 'flag' + flagName
    const storageFlagValue = window.localStorage.getItem(storageFlagName)
    if (storageFlagValue) {
      flagsValues[flagName] = storageFlagValue === 'true'
    }
  }

  const toggleFlag = (flag: string, value: boolean): void => {
    if (flagsValues[flag] !== value) {
      flagsValues[flag] = value
      const storageFlagName = 'flag' + flag
      if (value) {
        window.localStorage.setItem(storageFlagName, 'true')
      } else {
        window.localStorage.setItem(storageFlagName, 'false')
      }
      refreshApp()
    }
  }

  const refreshApp = (): void => {
    setTimeout(() => {
      eventBus.$emit('refresh')
    }, 1000)
  }

  onMounted(() => {
    if (!UserModule.isCompanyAdmin) {
      router.push('/trainings')
    }
    UserModule.setLoadingComponentViewData(false)
  })
</script>

<style lang="postcss">
  .flags-panel {
    .flags-wrapper {
      @apply ketch-mt-c40 ketch-space-y-c30 ketch-rounded-xl ketch-border;
      @apply ketch-border-border-color ketch-w-c200 ketch-px-c20 ketch-py-c10;
      .flag {
        @apply ketch-flex ketch-items-center ketch-justify-between;
        .flag-text {
          @apply ketch-text-secondary-text-color;
        }
      }
    }
  }
</style>
