import { render, staticRenderFns } from "./EditorButtons.vue?vue&type=template&id=9ef32448&lang=pug"
import script from "./EditorButtons.vue?vue&type=script&setup=true&lang=ts"
export * from "./EditorButtons.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./EditorButtons.vue?vue&type=style&index=0&id=9ef32448&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports