<template lang="pug">
.collapsible-widget(:class='{ collapsed: state || delayOpen }')
    span
    .collapsible-widget-header
        .svg-div(@click='onHeaderClick')
            SVGRenderer(
                :has-hover='false',
                :icon='state ? closedCategory : openCategory',
                stroke-color='var(--primary-text-color)',
                width='16'
            )
            HTMLRenderer.h5(:html='headerTitle')
        span(@click='$emit("delete")')
            SVGRenderer(
                :has-hover='false',
                :icon='trashIcon',
                fill-color='var(--editor-primary-color)',
                width='16'
            )
        Drag(v-if='canDrag')
    transition(name='up-down-fade')
        .content-elements(v-if='!state')
            slot
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import Drag from '@/components/common/Drag.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import eventBus from '@/main'

  const props = defineProps({
    headerTitle: {
      type: String,
      default: '',
    },
    canDrag: {
      type: Boolean,
      default: false,
    },
    initialOpenState: {
      type: Boolean,
      default: true,
    },
  })
  defineEmits(['delete'])

  const state = ref(props.initialOpenState)
  const delayOpen = ref(props.initialOpenState)
  const { trashIcon, closedCategory, openCategory } = useIcons()

  const onHeaderClick = () => {
    state.value = !state.value
    delayOpen.value = true
    setTimeout(() => {
      delayOpen.value = false
    }, 600)
    setTimeout(() => {
      eventBus.$emit('reset-background-tile-height')
    }, 300)
  }
</script>

<style lang="postcss">
  .collapsible-widget {
    @apply ketch-pl-c20 ketch-relative;
    &:not(.collapsed) {
      @apply ketch-pb-c40;
    }
    &.collapsed {
      @apply ketch-mb-c20;
      &:before,
      &:after,
      > span {
        @apply ketch-hidden;
      }
      .collapsible-widget-header {
        @apply ketch-mb-0;
      }
    }
    &:before,
    &:after,
    > span {
      @apply ketch-absolute ketch-left-0;
      background-image: linear-gradient(
        to bottom,
        var(--primary-text-color),
        var(--primary-text-color) 75%,
        transparent 75%,
        transparent 100%
      );
      content: '';
    }
    &:after,
    > span {
      background-image: linear-gradient(
        to right,
        var(--primary-text-color),
        var(--primary-text-color) 75%,
        transparent 75%,
        transparent 100%
      );
    }
    &:before {
      @apply ketch-top-c12 ketch-bottom-c6 ketch-w-c1;
      background-size: 1px 8px;
    }
    &:after {
      @apply ketch-bottom-c5 ketch-h-c1 ketch-w-c12;
      background-size: 6px 1px;
    }
    > span {
      @apply ketch-top-c10 ketch-h-c1 ketch-w-c12;
      background-size: 6px 1px;
    }
    &:last-of-type {
      &:before {
        @apply ketch-bottom-c6;
      }
      &:after {
        @apply ketch-bottom-c4;
      }
    }
    &-header {
      @apply ketch-inline-flex ketch-items-center ketch-space-x-c20 ketch-mb-c10;
      .svg-div {
        @apply ketch-cursor-pointer ketch-flex ketch-items-center;
        .h5 {
          @apply ketch-overflow-hidden ketch-text-ellipsis ketch-pl-c10;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          p {
            @apply ketch-p-0 ketch-inline;
          }
        }
      }
      svg {
        @apply ketch-w-c16 ketch-h-c16 ketch-cursor-pointer;
        flex: 0 0 16px;
      }
      .drag-icon {
        @apply ketch-cursor-move;
      }
    }
    .content-elements {
      @apply ketch-space-y-c20;
    }
  }
</style>
