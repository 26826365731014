<template lang="pug">
.user-table
  .user-table-container(:class='{ "showing-time-spent": showTimeSpent }')
    .users-header.users-grid-style
      h6.user-header.name(
        :class='{ sorted: sortByName }',
        @click='sortByName = !sortByName'
      )
        span Name
        SVGRenderer(
          :fill-color='"var(--primary-color)"',
          :has-hover='false',
          :icon='vectorArrow',
          width='10'
        )
      h6.user-header {{ $t('homepage.groups') }}
      h6.user-header.last-activity(
        :class='{ sorted: sortByLastActivity }',
        @click='sortByLastActivity = !sortByLastActivity'
      )
        span {{ $t('homepage.lastActivity') }}
        SVGRenderer(
          :fill-color='"var(--primary-color)"',
          :has-hover='false',
          :icon='vectorArrow',
          width='10'
        )
      h6.user-header.time-spent(
        :class='{ sorted: sortByTimeSpent }',
        @click='sortByTimeSpent = !sortByTimeSpent',
        v-if='showTimeSpent'
      )
        span {{ $t('homepage.timeSpent') }}
        SVGRenderer(
          :fill-color='"var(--primary-color)"',
          :has-hover='false',
          :icon='vectorArrow',
          width='10'
        )
    h5.no-users(v-if='!users.length') {{ noUsersText }}
    transition-group(
      name='fadeOut',
      v-else-if='mutableUsers'
    )
      .user(
        :class='{ selected: selectedUser && user.id === selectedUser.id && inUserManagementTab, "in-user-tab": inUserManagementTab }',
        :key='`${user.id} + ${index}`',
        v-for='(user, index) in mutableUsers'
      )
        .user-abstract-details.users-grid-style(@click='selectUser(user)')
          .user-details-wrapper
            .user-initials
              h6 {{ getInitials(user.name) }}
            .user-details
              h5.name {{ user.name ? user.name.trim() : '' || '-' }}
              h5.email {{ user.email }}
          .user-groups
            template(v-if='user.groups && user.groups.length')
              h6.group-wrapper(
                :key='group.id',
                :style='{ "background-color": groupBgColor(group.colorId) }',
                v-for='group in filteredUserGroups(user.groups)'
              )
                span.group {{ group.name }}
            h6.group(v-else) -
          .last-activity
            h6(v-if='!user.inviteAccepted') {{ $t('homepage.invitationPending') }}
            h6(v-else-if='user.lastActivityDate') {{ lastActivityDate(user.lastActivityDate) }} {{ daysDifference(user.lastActivityDate) }}
          .time-spent(v-if='showTimeSpent')
            h6 {{ totalUserSessionTime(user.sessions) }}
        transition(name='menu-slide')
          UserPreviewDetails(
            :user='user',
            :user-role-category='userRoleCategory',
            @create-new-group='$emit("create-new-group")',
            @delete-user='$emit("delete-user", $event)',
            @reload-user-members='$emit("reload-user-members")',
            v-if='showUserPreviewDetails(user)'
          )
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import UserPreviewDetails from '@/components/user-management/UserPreviewDetails.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { MemberGroup, UserMember, UserSessions } from '@/services/interfaces/Auth0'
  import { DateTime } from 'luxon'
  import useDuration from '@/composables/useDuration'

  const props = defineProps({
    users: {
      required: true,
      type: Array as PropType<UserMember[]>,
    },
    selectedUser: {
      default: null,
      type: Object as PropType<UserMember>,
    },
    inUserManagementTab: {
      type: Boolean,
      default: false,
    },
    userRoleCategory: {
      type: String,
      default: '',
    },
    noUsersText: {
      type: String,
      required: true,
    },
    showTimeSpent: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['set-selected-user', 'delete-user', 'create-new-group', 'reload-user-members'])
  const { vectorArrow } = useIcons()
  const { formatDate, daysDifferenceFromNow, getInitials, groupBgColor } = useCommonMixin()
  const { translateString } = useI18n()
  const { formatDurationToHHMM } = useDuration()

  const sortByName = ref(false)
  const sortByTimeSpent = ref(false)
  const sortByLastActivity = ref(false)

  const mutableUsers = computed((): UserMember[] => {
    return props.users.slice().map((user) => {
      const totalTimeInMinutes = calculateUserTotalSessionTime.value(user.sessions)

      return { ...user, totalSessionTime: totalTimeInMinutes }
    })
  })

  const lastActivityDate = computed(() => {
    return (date: string) => {
      return formatDate(date, 'dd.MM.yyyy')
    }
  })

  const daysDifference = computed(() => {
    return (date: string) => {
      if (!daysDifferenceFromNow(date)) return ''
      const difference = translateString('homepage.daysAgo', { days: daysDifferenceFromNow(date) })
      return `(${difference})`
    }
  })

  const showUserPreviewDetails = computed(() => {
    return (user: UserMember) => {
      if (!props.selectedUser) return false
      return props.inUserManagementTab && props.selectedUser.id === user.id
    }
  })

  const filteredUserGroups = computed(
    () => (userGroups: MemberGroup[]) => userGroups.filter((g) => g.name !== 'All Users'),
  )

  const calculateUserTotalSessionTime = computed(() => (sessions: UserSessions[]) => {
    return sessions.reduce((acc, session) => {
      const sessionStart = DateTime.fromISO(session.startTime)
      const sessionEnd = DateTime.fromISO(session.endTime)
      const sessionDuration = sessionEnd.diff(sessionStart, 'minutes').minutes
      acc += sessionDuration
      return acc
    }, 0)
  })

  const totalUserSessionTime = computed(() => (sessions: UserSessions[]) => {
    if (sessions.length === 0) return '-'
    const totalTime = calculateUserTotalSessionTime.value(sessions)
    return totalTime < 1 ? translateString('homepage.underOneMinute') : `${formatDurationToHHMM(totalTime)}h`
  })

  watch(sortByName, () => sortMembersByName())
  watch(sortByTimeSpent, () => sortMembersByTimeSpent())
  watch(sortByLastActivity, () => sortMembersByLastActivity())

  const sortMembersByName = () => {
    mutableUsers.value.sort((a, b) => {
      if (!a.name || !b.name) return 0
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    if (sortByName.value) mutableUsers.value.reverse()
  }

  const sortMembersByTimeSpent = () => {
    mutableUsers.value.sort((a, b) => {
      if (a.totalSessionTime < b.totalSessionTime) return -1
      if (b.totalSessionTime > a.totalSessionTime) return 1
      return 0
    })
    if (sortByTimeSpent.value) mutableUsers.value.reverse()
  }

  const sortMembersByLastActivity = () => {
    mutableUsers.value.sort((a, b) => {
      if (!a.lastActivityDate || !b.lastActivityDate) return 0
      const aTime = DateTime.fromISO(a.lastActivityDate)
      const bTime = DateTime.fromISO(b.lastActivityDate)
      if (aTime < bTime) return -1
      if (bTime > aTime) return 1
      return 0
    })
    if (sortByLastActivity.value) mutableUsers.value.reverse()
  }

  const selectUser = (user: UserMember) => {
    // only allow selecting in user management view for now
    if (props.inUserManagementTab) {
      if (props.selectedUser === user) {
        // deselect user
        emit('set-selected-user', null)
        return
      }
      emit('set-selected-user', user)
    }
  }
</script>

<style lang="postcss">
  .user-table {
    @apply ketch-w-full;
    &-container {
      @apply ketch-border ketch-border-border-color ketch-rounded-normal;
      .users-grid-style {
        @apply ketch-grid ketch-grid-cols-3 ketch-gap-c15 ketch-items-center;
        grid-template-columns: 1.5fr 1.5fr 1fr;
      }
      &.showing-time-spent {
        .users-grid-style {
          @apply ketch-grid-cols-4;
          grid-template-columns: 1.5fr 1fr 1fr 0.7fr;
        }
      }
      .users-header {
        @apply ketch-py-c10 ketch-bg-black ketch-bg-opacity-[0.04] ketch-px-c15;
        @apply ketch-border-b ketch-border-border-color ketch-rounded-t-normal;
        .user-header {
          @apply ketch-font-bold ketch-opacity-[0.3] ketch-uppercase ketch-tracking-wider;
          @apply ketch-flex ketch-items-center ketch-space-x-c10;
          &.name,
          &.time-spent,
          &.last-activity {
            @apply ketch-cursor-pointer ketch-opacity-100;
            svg {
              @apply ketch-transition-transform ketch-duration-300 ketch-rotate-180;
            }
            &.sorted {
              svg {
                @apply ketch-rotate-0;
              }
            }
          }
        }
      }
      .no-users {
        @apply ketch-text-center ketch-pt-c60 ketch-pb-c40;
      }
      .user {
        @apply ketch-px-c15 ketch-border-b ketch-border-border-color;
        &.selected {
          @apply ketch-bg-module-selector-background-color;
        }
        &.in-user-tab {
          @apply hover:ketch-bg-module-selector-background-color hover:ketch-transition hover:ketch-ease-in-out;
          @apply hover:ketch-delay-[160ms];
          .user-abstract-details {
            @apply ketch-cursor-pointer;
          }
        }
        &:last-of-type {
          @apply ketch-border-b-0 hover:ketch-rounded-b-normal;
        }
        .user-abstract-details {
          @apply ketch-py-c10;
          .user-details-wrapper {
            @apply ketch-flex ketch-items-center ketch-space-x-c10;
            .user-initials {
              @apply ketch-h-c30 ketch-w-c30;
              flex: 0 0 30px;
            }
            .user-details {
              @apply ketch-flex ketch-flex-col;
              .name {
                @apply ketch-font-bold;
              }
              .email {
                @apply ketch-text-secondary-text-color ketch-w-[190px];
                @apply ketch-whitespace-nowrap ketch-overflow-hidden ketch-text-ellipsis;
              }
            }
          }
          .user-groups {
            @apply ketch-flex ketch-flex-wrap ketch-space-x-c8;
            .group-wrapper {
              @apply ketch-rounded-[5px] ketch-px-c5 ketch-w-[fit-content];
              .group {
                @apply ketch-text-white;
              }
            }
          }
          .last-activity {
            > * {
              @apply ketch-text-secondary-text-color;
            }
          }
        }
      }
    }
  }
</style>
