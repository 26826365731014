import { render, staticRenderFns } from "./ExternalCourses.vue?vue&type=template&id=3a2f1c4e&lang=pug"
import script from "./ExternalCourses.vue?vue&type=script&setup=true&lang=ts"
export * from "./ExternalCourses.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ExternalCourses.vue?vue&type=style&index=0&id=3a2f1c4e&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports