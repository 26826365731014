<template lang="pug">
.course-item(
  :class='{ inUpsellModal: usedInUpsellModal }',
  data-cy='course-item'
)
  .image-container(@click='showCourseUpsellOrRouteToCourseInfo(course.state)')
    img(:src='isMobileDevice ? course.cardTeaserImageUrl : course.teaserImageUrl')
  .copy-container
    .in-trial-tags
      .in-trial(v-if='course.trial && !course.purchased')
        SVGRenderer(
          :has-hover='false',
          :icon='checkmark',
          stroke-color='var(--primary-foreground-color)',
          width='10px'
        )
        h6 {{ $t('courses.inTest') }}
      h5.tags {{ tags }}
    h2.course-title {{ course.title }}
    h4.teaser-description {{ course.teaserDescription }}
    h5.completed-modules-text(
      v-html='$t("courses.completionScoreText", { completedModules: completedModules(course), totalModules: totalCourseGroupModules })',
      v-if='course.purchased && totalCourseGroupModules !== null'
    )
    CourseProgressBar(
      :combine-course-groups='true',
      :course-groups='course.courseGroups',
      :course-id='course.id',
      v-if='course.purchased && !usedInUpsellModal'
    )
    .feedback-price-section(v-else)
      .user-feedback-price
        .feedback-rating
          h6.course-rating {{ $t('courses.userRating') }}
          .user-comment
            span.h3 {{ course.userComment }}
            span.quote ”
          StarRating(:rating='course.rating || 0')
        .price(v-if='course.product')
          h5.has-discount(v-if='discount') {{ discountValue }}
          h3.actual-price(v-if='productPrice(course)') {{ currencyFormatDE(productPrice(course)) }}
    .bottom-container(v-if='!usedInUpsellModal')
      CourseModuleDuration(
        :course='course',
        :number-of-modules='totalCourseGroupModules'
      )
      .cta-container
        KetchUpButton.tertiary(@click.native='showCourseUpsellOrRouteToCourseInfo(course.state)')
          h5(v-if='course.purchased') {{ $t('courses.toTheTraining') }}
          h5(v-else) {{ $t('courses.trainingDetails') }}
          SVGRenderer(
            :has-hover='false',
            :icon='arrowRight',
            stroke-color='var(--primary-text-color)'
          )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import StarRating from '@/components/common/StarRating.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import CourseProgressBar from '@/components/course/CourseProgressBar.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import { CourseModule } from '@/store/modules/course'
  import useIcons from '@/composables/useIcons'
  import CourseModuleDuration from '@/components/course/CourseModuleDuration.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { useRouter } from 'vue-router/composables'
  import type { CourseItem as CourseItemState } from '@/services/interfaces/Course'
  import eventBus from '@/main'

  const props = defineProps({
    course: {
      required: true,
      type: Object as () => CourseItemState,
    },
    discount: {
      type: Number,
      default: 0,
    },
    usedInUpsellModal: {
      type: Boolean,
      default: false,
    },
  })

  const router = useRouter()
  const { arrowRight, checkmark } = useIcons()
  const { courseItemClicked, courseUpsellModalShown } = useSegment()
  const { isMobileDevice } = useBreakpoint()
  const { completedModules, productPrice } = useCourse()
  const { currencyFormatDE } = useCommonMixin()

  const tags = computed(() => {
    return props.course.tags.map((t) => t.title).join(', ')
  })

  const discountValue = computed(() => {
    const amount = productPrice.value(props.course)
    return currencyFormatDE(amount + props.discount * amount)
  })

  const showCourseUpsellOrRouteToCourseInfo = async (state: string) => {
    await courseItemClicked(props.course.id, props.course.title, props.course.state, 'courses_page')
    if (state === 'ready') {
      eventBus.$emit('turn-on-loader')
      if (!CourseModule.coursesModuleMap[props.course.id]) {
        await CourseModule.getCourseModules(props.course.id)
      }
      await router.push({
        name: 'CourseDetails',
        params: { courseId: props.course.id, discount: String(props.discount) },
      })
      eventBus.$emit('turn-off-loader')
    } else {
      await courseUpsellModalShown(props.course.id, props.course.title, 'courses_page')
      eventBus.$emit('show-modal', {
        modalContentComponent: 'UpsellModal',
        cssClass: 'upsell',
        modalProps: {
          recommendedCourse: props.course,
          discount: props.discount,
        },
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
        },
      })
    }
  }

  const totalCourseGroupModules = computed(() => {
    const courseGroupsIds = props.course.courseGroups?.map((c) => c.id) || []
    return CourseModule.totalCourseGroupModules(props.course.id, courseGroupsIds)
  })
</script>

<style lang="postcss">
  .course-item {
    @apply ketch-flex ketch-flex-col md:ketch-flex-row;
    &.inUpsellModal {
      @apply md:ketch-flex-col;
      .image-container {
        @apply ketch-flex-none md:ketch-mb-c20 md:ketch-mr-0;
        img {
          @apply ketch-w-full;
        }
      }
    }
    .image-container {
      @apply ketch-mb-c25 ketch-cursor-pointer md:ketch-mr-c50 md:ketch-mb-0;
      @screen xs {
        flex: 1 1 100%;
      }
      @screen md {
        flex: 0 0 450px;
      }
      @screen xl {
        flex: 0 0 550px;
      }
      img {
        @apply ketch-w-full;
      }
    }
    .copy-container {
      @apply ketch-flex-1;
      .course-progress-bar {
        @apply ketch-mt-c10;
      }
      .feedback-price-section {
        @apply ketch-flex ketch-flex-col ketch-space-y-c10;
      }
      .bottom-container {
        @apply ketch-flex ketch-justify-between ketch-items-center ketch-mt-c30;
        .cta-container {
          @apply ketch-min-w-[165px] ketch-max-w-[200px];
          button {
            @apply ketch-space-x-c16 ketch-w-full ketch-px-c15;
            h5 {
              @apply ketch-font-bold;
            }
          }
        }
      }
    }
  }

  .in-trial-tags {
    @apply ketch-flex ketch-items-center ketch-space-x-c10;
    .in-trial {
      @apply ketch-bg-primary-color ketch-rounded-large ketch-space-x-c10;
      @apply ketch-flex ketch-items-center ketch-px-c10 ketch-py-c2 ketch-min-w-[90px];
      h6 {
        @apply ketch-text-primary-foreground-color ketch-font-bold;
      }
    }
    .tags {
      @apply ketch-text-primary-text-color ketch-opacity-50 ketch-font-bold;
    }
  }
  .course-title {
    @apply ketch-font-bold ketch-mt-c15 ketch-mb-c10;
  }
  .completed-modules-text {
    @apply ketch-mt-c20;
    span {
      @apply ketch-font-bold;
    }
  }
  .course-rating {
    @apply ketch-mb-c5 ketch-text-primary-text-color ketch-opacity-50 ketch-font-bold;
  }
  .user-comment {
    @apply ketch-space-x-c6 ketch-flex;
    span {
      @apply ketch-font-big-daily-short;
    }
    .quote {
      @apply ketch-text-3xl ketch-leading-lg;
    }
  }
  .module-duration {
    @apply ketch-flex ketch-flex-col xs4:ketch-flex-row ketch-space-y-c10 xs4:ketch-space-x-c20 xs4:ketch-space-y-0;
    .module,
    .duration {
      @apply ketch-flex ketch-items-center ketch-space-x-c10;
    }
  }
</style>
