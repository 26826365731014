import flags from '../../flags.json'
import { UserModule } from '@/store/modules/user'

interface useFlagsReturn {
  isFlagEnabled: (flagName: string) => boolean
  hasFlag: (flagName: string) => boolean
  flags: { [key: string]: boolean }
}

export default function useFlags(): useFlagsReturn {
  const storageFlagName = (flagName: string): string => {
    return `flag${flagName}`
  }

  const flagValue = (flagName: string): boolean => {
    return (flags as { [key: string]: boolean })[flagName]
  }

  const hasFlag = (flagName: string): boolean => {
    return flagName in flags
  }

  const flagIsSet = (flagKey: string, flagValue: string, params: URLSearchParams): boolean => {
    return params.has(flagKey) && params.get(flagKey) === flagValue
  }

  const setFlagInLocalStorage = (flagName: string, params: URLSearchParams) => {
    if (flagIsSet('flagOn', flagName, params)) {
      window.localStorage.setItem(storageFlagName(flagName), 'true')
    }
    if (flagIsSet('flagOff', flagName, params)) {
      window.localStorage.setItem(storageFlagName(flagName), 'false')
    }
  }

  const setFlagFromQueryStringToLocalStorage = (flagName: string): void => {
    const params = new URLSearchParams(window.location.search)
    setFlagInLocalStorage(flagName, params)
  }

  const isFlagEnabled = (flagName: string): boolean => {
    setFlagFromQueryStringToLocalStorage(flagName)
    const storageFlagValue = window.localStorage.getItem(storageFlagName(flagName))

    if (storageFlagValue) return storageFlagValue === 'true'
    return flagValue(flagName) && UserModule.isCompanyAdmin
  }

  return {
    isFlagEnabled,
    hasFlag,
    flags,
  }
}
