<template lang="pug">
.faqs
    h2 {{ $t('faqs.headerTitle') }}
    .faq-items
        .faq-item(
            :key='index',
            v-for='(faq, index) in faqs'
        )
            h3 {{ faq.title }}
            h5(v-html='faq.description')
</template>

<script setup lang="ts">
  import useI18n from '@/composables/useI18n'

  const { translateString } = useI18n()

  const faqs = [
    {
      title: translateString('faqs.item1.title'),
      description: translateString('faqs.item1.description'),
    },
    {
      title: translateString('faqs.item2.title'),
      description: translateString('faqs.item2.description'),
    },
    {
      title: translateString('faqs.item3.title'),
      description: translateString('faqs.item3.description'),
    },
  ]
</script>

<style lang="postcss">
  .faqs {
    h2 {
      @apply ketch-font-bold ketch-mb-c30 md:ketch-mb-c70 ketch-text-left md:ketch-text-center;
      @apply ketch-font-big-daily-short;
    }
    .faq-items {
      @apply ketch-flex ketch-flex-col md:ketch-grid md:ketch-grid-cols-2 md:ketch-gap-y-c60;
      @apply ketch-justify-between ketch-space-y-c20 md:ketch-space-y-0 md:ketch-gap-x-c40;
      .faq-item {
        h3 {
          @apply ketch-mb-c10 ketch-font-bold;
        }
        h5 {
          span {
            @apply ketch-font-bold;
          }
          a {
            @apply ketch-underline;
          }
        }
      }
    }
  }
</style>
