<template lang="pug">
.get-started-loader.get-started-panel
    .panel-header-info-completion-status
        .panel-header-info
            PlaceholderShimmer(
                animate,
                height='25px'
            )
            PlaceholderShimmer(
                animate,
                height='20px'
            )
        .completion-status
            PlaceholderShimmer(
                animate,
                height='20px'
            )
    .panel-body
        .panel-body-steps
            .get-started-step(
                :key='step',
                v-for='step in 4'
            )
                .step-info-wrapper
                    .step-count
                        PlaceholderShimmer(
                            animate,
                            height='30px',
                            width='30px'
                        )
                    .step-text
                        PlaceholderShimmer(
                            animate,
                            height='30px'
                        )
        .panel-step-content(v-if='isDesktopDevice')
            PlaceholderShimmer(
                animate,
                height='140px'
            )
</template>

<script setup lang="ts">
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  const { isDesktopDevice } = useBreakpoint()
</script>

<style lang="postcss">
  .get-started-loader {
    .panel-header-info-completion-status {
      .panel-header-info {
        @apply ketch-w-full md:ketch-max-w-[500px];
      }
      .completion-status {
        @apply ketch-w-full ketch-max-w-[150px];
      }
    }
    .panel-body {
      .panel-body-steps {
        @apply ketch-w-full;
        .get-started-step {
          @apply ketch-w-full md:ketch-w-[435px];
          .step-info-wrapper {
            @apply ketch-w-full;
            .step-count {
              @apply ketch-mr-c20;
              .placeholder-shimmer .shimmer-wrapper {
                @apply ketch-rounded-full;
              }
            }
            .step-text {
              @apply ketch-w-full;
            }
          }
        }
      }
      .panel-step-content {
        @apply ketch-w-full;
      }
    }
  }
</style>
