<template lang="pug">
.module-lesson-layout(:class='{ notranslate: isCompanyEditor }')
    ModuleLessonLayoutLoader(v-if='fetchingCourseModules || fetchingCourse')
    template(v-else)
        CourseExpiredBanner.on-module-lesson-layout
        .module-lesson-content
            .fixed-module-cta(
                :class='{ "no-exam-button": !showExamButton, selector: mobileSelector }',
                v-if='isMobileDevice'
            )
                template(v-if='mobileSelector')
                    .start-exam-cta(v-if='showExamButton')
                        StartCourseExamCTA(
                            :course='course',
                            @start-exam-cta-clicked='mobileSelector = false'
                        )
                        .blurred-out-non-editable-section(v-if='inEditorMode')
                    KetchUpButton.quaternary.cancel(@click.native='mobileSelector = false')
                        h5 {{ $t('cancel') }}
                template(v-else)
                    KetchUpButton.tertiary.choose-lesson(@click.native='mobileSelector = true')
                        h5 {{ $t('courses.chooseLesson') }}
                        SVGRenderer(
                            :has-hover='false',
                            :icon='menuIcon',
                            fill-color='black'
                        )
                    KetchUpButton.primary.start-module(
                        @click.native='startModule',
                        v-if='$route.name === "CourseModule"'
                    )
                        h5 {{ $t('courses.sideshow.startModule') }}
                        SVGRenderer(
                            :has-hover='false',
                            :icon='arrowRight',
                            stroke-color='var(--primary-foreground-color)'
                        )
                    KetchUpButton.primary.next-lesson-module(
                        @click.native='nextLessonOrModule',
                        v-else-if='showNextCTA(lessonCTA)'
                    )
                        h5 {{ lessonCTA }}
                        SVGRenderer(
                            :has-hover='false',
                            :icon='arrowRight',
                            stroke-color='var(--primary-foreground-color)'
                        )
            .content
                .promote-buy-course(v-if='showPromoteBuyCourseCTA')
                    .buy-cta(@click='shopifyCheckout')
                        h5 {{ $t('courses.buyThisCourseFor', { price: productPrice(course) }) }}
                ModuleLessonsSelector(
                    @next-lesson-or-module='nextLessonOrModule',
                    @show-content='mobileSelector = false',
                    @start-module='startModule',
                    @trial-period-expired='trialPeriodExpired = $event',
                    v-if='isMobileDevice && mobileSelector'
                )
                .view-container(
                    :class='{ editing: inEditorMode && isLargeDesktop }',
                    v-else
                )
                    CourseSearchResults(
                        :search-term='searchTerm',
                        v-if='searchTerm'
                    )
                    template(v-else)
                        EditorButtons(v-if='inEditorMode && isLargeDesktop')
                        FollowupLesson(v-if='inEditorMode && isLargeDesktop && onLessonPage')
                        CourseBreadcrumbs(v-if='!$route.meta.hideCourseBreadcrumb && isLargeDesktop && inEditorMode')
                        router-view(
                            :course-teaser-image-url='isMobileDevice ? course.cardTeaserImageUrl : course.teaserImageUrl',
                            :lesson-c-t-a='lessonCTA',
                            :trial-period-expired='trialPeriodExpired',
                            @next-lesson-or-module='nextLessonOrModule',
                            @previous-lesson='previousLesson',
                            @start-module='startModule'
                        )
            .sideshow(v-if='isDesktopDevice')
                LanguageTranslateToggle(v-if='showTranslateSelector')
                ModuleLessonsSelector(
                    @current-course-group-id='currentCourseGroupId = $event',
                    @next-lesson-or-module='nextLessonOrModule',
                    @start-module='startModule',
                    @trial-period-expired='trialPeriodExpired = $event'
                )
        GoogleReviewWidget(
            :course-id='course.id',
            v-if='onModulePage && isDesktopDevice && !inEditorMode'
        )
</template>

<script setup lang="ts">
  import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue'
  import ModuleLessonLayoutLoader from '@/components/loaders/ModuleLessonLayoutLoader.vue'
  import ModuleLessonsSelector from '@/components/course/ModuleLessonsSelector.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CookieHelper from '@/helpers/CookieHelper'
  import { EditorModule } from '@/store/modules/editor'
  import useToursAndHints from '@/composables/useToursAndHints'
  import useEditor from '@/composables/useEditor'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import EditorButtons from '@/components/editor/EditorButtons.vue'
  import CourseBreadcrumbs from '@/components/course/CourseBreadcrumbs.vue'
  import CourseSearchResults from '@/components/course/CourseSearchResults.vue'
  import FollowupLesson from '@/components/course/followup/FollowupLesson.vue'
  import { v4 as uuidv4 } from 'uuid'
  import GoogleReviewWidget from '@/components/GoogleReviewWidget.vue'
  import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { CourseModule as CourseModuleState } from '@/services/interfaces/Course'
  import LanguageTranslateToggle from '@/components/common/LanguageTranslateToggle.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseExpiredBanner from '@/components/course/CourseExpiredBanner.vue'
  import useShopifyClient from '@/composables/useShopifyClient'
  import { UserModule } from '@/store/modules/user'
  import StartCourseExamCTA from '@/components/course/StartCourseExamCTA.vue'

  defineProps({
    searchTerm: {
      type: String,
      default: '',
    },
  })

  const route = useRoute()
  const router = useRouter()
  const { translateString } = useI18n()
  const lessonCTA = ref('')
  const {
    currentCourseId,
    lessonId,
    moduleId,
    courseModules,
    currentLesson,
    currentLessonQuestions,
    currentModule,
    currentModuleLessonsLength,
    course,
    fetchingEditorDependencies,
    isPurchasedCourse,
    isTrialCourse,
    showNextCTA,
    nextSiblingModule,
    nextCourseModule,
    onModulePage,
    trackCoursePurchaseIntended,
    currentLessonIndex,
    productPrice,
  } = useCourse()
  const { showOrHideToursAndHints, hideAllHints } = useToursAndHints()
  const { startModuleButtonClicked } = useSegment()
  const { isDesktopDevice, isMobileDevice, isLargeDesktop } = useBreakpoint()
  const { isNewLesson, inEditorMode, lessonSlideshows, lessonAudioFiles } = useEditor()
  const { arrowRight, menuIcon } = useIcons()
  const { showTranslateSelector } = useCommonMixin()
  const { createShopifyCheckout } = useShopifyClient()
  const mobileSelector = ref(false)
  const trialPeriodExpired = ref(false)
  const currentCourseGroupId = ref('')

  const onLessonPage = computed((): boolean => !!(lessonId.value && moduleId.value && currentCourseId.value))

  const showExamButton = computed(() => {
    return isMobileDevice.value && course.value?.exam && (isPurchasedCourse.value || isTrialCourse.value)
  })

  const showPromoteBuyCourseCTA = computed(
    () => isMobileDevice.value && (course.value?.trial || !course.value?.purchased) && course.value?.shopifyProductId,
  )

  const isCompanyEditor = computed(() => UserModule.isCompanyEditor)

  const nextModuleOrCourseDetails = (nextModule: CourseModuleState | null) => {
    if (nextModule) {
      lessonCTA.value = translateString('courses.sideshow.nextModule')
    } else {
      lessonCTA.value = translateString('courses.toCourseDetails')
    }
  }

  const setLessonCTA = () => {
    const nextModule = nextCourseModule(CourseModule.currentCourseGroupId)
    if (currentLessonIndex.value === currentModuleLessonsLength.value - 1) {
      if (currentModule.value?.guidedQuiz && !course.value?.expired) {
        lessonCTA.value = translateString('courses.sideshow.toGuidedQuiz')
      } else {
        const siblingIsFree = nextSiblingModule.value(currentCourseId.value, CourseModule.currentCourseGroupId)?.isFree
        if (isTrialCourse.value && !siblingIsFree) {
          lessonCTA.value = translateString('courses.buyCourse')
        } else {
          nextModuleOrCourseDetails(nextModule)
        }
      }
    } else {
      if (route.name === 'GuidedQuiz') {
        nextModuleOrCourseDetails(nextModule)
      } else {
        lessonCTA.value = translateString('courses.sideshow.nextLesson')
      }
    }
  }

  watch(lessonId, setLessonCTA)

  const areModulesAvailable = computed(() => !!CourseModule.coursesModuleMap[currentCourseId.value])
  watch(areModulesAvailable, () => {
    setLessonCTA()
    CourseModule.setCurrentModule(currentModule.value as CourseModuleState)
    gotoCourseDetailsPage()
  })

  const fetchingCourseModules = computed(() => courseModules.value === undefined)
  if (fetchingCourseModules.value) CourseModule.getCourseModules(currentCourseId.value)

  const fetchingCourse = computed(() => course.value === null)
  const purchasedCourseIds = CookieHelper.getCookieValue('purchased_course_id')
  if (purchasedCourseIds || fetchingCourse.value) {
    CourseModule.getCourse({ courseId: currentCourseId.value }).then((course) => {
      setLessonCTA()
      purchasedCourseIds?.forEach((courseId: string) => {
        if (courseId === course.id) {
          if (course.purchased) {
            CookieHelper.removeCookie('purchased_course_id')
          } else {
            CourseModule.course!.purchased = true
          }
        }
      })
    })
  }

  const startModule = () => {
    startModuleButtonClicked(
      currentCourseId.value,
      course.value!.title,
      currentModule.value.id,
      currentModule.value.name,
    )
    if (currentModule.value.lessons.length) {
      return router
        .push({
          name: 'Lesson',
          params: { lessonId: currentModule.value.lessons[0].id, moduleId: currentModule.value.id },
        })
        .catch(() => {
          return
        })
    }
    if (currentModule.value?.guidedQuiz) {
      return router
        .push({
          name: 'GuidedQuiz',
          params: { courseId: currentCourseId.value, moduleId: currentModule.value.id },
        })
        .catch(() => {
          return
        })
    }
    nextLessonOrModule()
  }

  const nextLessonOrModule = () => {
    if (currentLessonIndex.value >= 0) {
      if (currentLessonIndex.value === currentModuleLessonsLength.value - 1) {
        if (currentModule.value.guidedQuiz && !course.value?.expired) {
          return router.push({ name: 'GuidedQuiz' }).catch(() => {
            return
          })
        }
        handleNextModule()
      } else {
        handleRouteToLesson(currentLessonIndex.value + 1)
      }
    } else {
      handleNextModule()
    }
  }

  const previousLesson = () => {
    /*
     just a fail proof check
     we only listen to this event if there is an actual previous lesson in the current module
     */
    if (currentLessonIndex.value > 0) {
      handleRouteToLesson(currentLessonIndex.value - 1)
    }
  }

  const handleNextModule = () => {
    const module = nextCourseModule(CourseModule.currentCourseGroupId)
    if (module) {
      router
        .push({
          name: 'CourseModule',
          params: { courseId: currentCourseId.value, moduleId: module.id || '' },
        })
        .catch(() => {
          return
        })
    } else {
      return router.push({ name: 'CourseDetails' }).catch(() => {
        return
      })
    }
  }

  const handleRouteToLesson = (lessonIndex: number) => {
    router
      .push({
        name: 'Lesson',
        params: {
          lessonId: currentModule.value.lessons[lessonIndex].id,
          moduleId: currentModule.value.id,
        },
      })
      .catch(() => {
        return
      })
  }

  watch(moduleId, async () => {
    EditorModule.setCurrentModule(null)
    CourseModule.setCurrentModule(null)
    CourseModule.setCurrentModule(currentModule.value as CourseModuleState)
    if (inEditorMode.value) {
      const curModules = structuredClone(courseModules.value || []) as CourseModuleState[]
      updateEditorStore(currentModule.value as CourseModuleState, curModules)
    }
  })

  watch(
    () => EditorModule.editMode,
    (inEditMode: boolean) => {
      if (inEditMode && currentLesson.value !== null) {
        CourseModule.getCurrentLessonQuestions({
          courseId: currentCourseId.value,
          moduleId: moduleId.value,
          lessonId: lessonId.value,
        })
      }
    },
  )

  const updateEditorStore = (curMod: CourseModuleState, curModules: CourseModuleState[]) => {
    EditorModule.setCurrentLesson(currentLesson.value)
    // if current lesson is a new lesson and is yet to be added to the module it belongs to
    if (isNewLesson.value && !curMod.lessons.some((l) => l.id === currentLesson.value!.id)) {
      curMod.lessons.push(currentLesson.value!)
    }
    EditorModule.setCurrentModule(curMod)
    EditorModule.setCourseModules({ courseId: currentCourseId.value, modules: curModules })
    EditorModule.setCurrentLessonQuestions(currentLessonQuestions.value)
    EditorModule.setLeuckentext(currentLesson.value?.leuckentext ?? null)
    EditorModule.setLessonSlideshows(currentLesson.value?.slideShow ?? null).then(() => {
      lessonSlideshows.value?.forEach((slide) => {
        eventBus.$set(slide, 'editorId', uuidv4())
        eventBus.$set(slide, 'slideImageData', '')
      })
    })
    EditorModule.setCurrentLessonAudioFiles(currentLesson.value?.audioFiles ?? null).then(() => {
      lessonAudioFiles.value?.forEach((audio) => {
        eventBus.$set(audio, 'editorId', uuidv4())
      })
    })
    EditorModule.setLessonDownloadable(currentLesson.value?.downloadables ?? null)
  }

  const resetEditorStore = (curMod: CourseModuleState) => {
    const lastLesson = curMod?.lessons.slice(-1)[0]
    if (isNewLesson.value && curMod) {
      if (lastLesson) {
        router.replace({
          name: 'Lesson',
          params: { lessonId: lastLesson.id, moduleId: curMod.id },
        })
      } else {
        router
          .push({
            name: 'CourseModule',
            params: { courseId: currentCourseId.value, moduleId: curMod.id },
          })
          .catch(() => {
            return
          })
      }
    }
    EditorModule.setCurrentLesson(null)
    EditorModule.setCurrentModule(null)
    EditorModule.setCourseModules({ courseId: currentCourseId.value, modules: [] })
    EditorModule.setCurrentLessonQuestions(null)
    EditorModule.setCurrentLessonAudioFiles(null)
    EditorModule.setLessonDownloadable(null)
  }

  watch(
    () => EditorModule.editMode && !fetchingEditorDependencies.value,
    (inEditMode: boolean) => {
      const curModules = structuredClone(courseModules.value || []) as CourseModuleState[]
      const curMod = curModules.find((m) => m.id === moduleId.value) as CourseModuleState

      if (inEditMode) {
        updateEditorStore(curMod, curModules)
        setTimeout(() => hideAllHints(), 10000)
      } else {
        if (!EditorModule.editMode) resetEditorStore(curMod)
        if (isDesktopDevice.value) showOrHideToursAndHints()
      }
    },
  )

  const gotoCourseDetailsPage = () => {
    if (currentModule.value?.disabled && !inEditorMode.value) {
      router.push({ name: 'CourseDetails' }).catch(() => {
        return
      })
    }
  }

  const removeUnsavedNewModule = () => {
    const index = CourseModule.coursesModuleMap[currentCourseId.value]!.findIndex((mod) => mod.id === moduleId.value)
    if (index > -1) {
      CourseModule.coursesModuleMap[currentCourseId.value]!.splice(index, 1)
    }
  }

  const removeUnsavedNewLesson = () => {
    const courseModule = CourseModule.coursesModuleMap[currentCourseId.value]!.find(
      (m) => m.id === moduleId.value,
    ) as CourseModuleState
    const lessonIndex = courseModule.lessons!.findIndex((lesson) => lesson.id === lessonId.value)
    if (lessonIndex > -1) {
      courseModule.lessons.splice(lessonIndex, 1)
    }
  }

  const shopifyCheckout = () => {
    trackCoursePurchaseIntended()
    createShopifyCheckout()
  }

  onBeforeMount(() => gotoCourseDetailsPage())
  onMounted(() => {
    if (areModulesAvailable.value) {
      setLessonCTA()
      CourseModule.setCurrentModule(currentModule.value as CourseModuleState)
    }
  })
  onUnmounted(() => {
    CourseModule.setCurrentCourseGroup('')
  })

  onBeforeRouteLeave((to, from, next) => {
    const { isNewModule, isNewLesson } = useEditor()
    if (isNewModule.value) {
      removeUnsavedNewModule()
    }
    if (isNewLesson.value) {
      removeUnsavedNewLesson()
    }
    next()
  })

  onBeforeRouteUpdate((to, from, next) => {
    const { hasChanges, inEditorMode, discardAllChanges, saveAllChanges, isNewLesson, isNewModule } = useEditor()
    if (
      !inEditorMode ||
      (inEditorMode.value &&
        (!EditorModule.currentLesson || !EditorModule.currentLesson.id.startsWith('new-')) &&
        isNewLesson.value) ||
      (inEditorMode.value &&
        (!EditorModule.currentModule || !EditorModule.currentModule.id.startsWith('mod-')) &&
        isNewModule.value)
    ) {
      return next()
    }
    if (hasChanges.value) {
      eventBus.$emit('show-modal', {
        modalContentComponent: 'ConfirmationModal',
        modalTitle: translateString('editor.thereAreChanges'),
        modalProps: {
          confirmationText: translateString('editor.saveOrDiscard'),
          cancelButtonText: translateString('editor.discardChanges'),
          okButtonText: translateString('editor.saveAllChanges'),
          showSvgs: true,
          okCallback: async (closeCallback: () => void) => {
            saveAllChanges()
            next()
            if (typeof closeCallback === 'function') closeCallback()
          },
          cancelCallback: () => {
            discardAllChanges()
            next()
          },
        },
        cssClass: 'confirmation editor',
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
        },
      })
    } else {
      next()
    }
  })
</script>

<style lang="postcss">
  .module-lesson-layout {
    @apply ketch-flex ketch-flex-col ketch-space-y-c20;
    .module-lesson-content {
      @apply ketch-flex ketch-flex-col ketch-pb-[45px];
      @apply xs3:ketch-pb-[65px] md:ketch-flex-row md:ketch-justify-between md:ketch-pb-0;
      .fixed-module-cta {
        @apply ketch-flex ketch-justify-between ketch-fixed ketch-bottom-0 ketch-left-0 ketch-right-0;
        @apply ketch-bg-white ketch-p-c10 xs3:ketch-p-c20 ketch-space-x-c10 xs1:ketch-space-x-c20 ketch-z-10;
        box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.05);
        button {
          @apply ketch-space-x-c10 xs1:ketch-space-x-c20 ketch-w-auto xs3:ketch-min-w-[180px] xs4:ketch-min-w-[200px];
          &.choose-lesson {
            @apply ketch-p-c10;
          }
          &.cancel {
            @apply ketch-min-w-[auto] ketch-px-0 ketch-h-c40;
          }
        }
        &.selector.no-exam-button {
          @apply ketch-justify-end;
        }
      }
      .start-exam-cta {
        @apply ketch-relative ketch-min-w-[220px];
      }
      .content {
        @apply ketch-flex-auto md:ketch-pr-c30 xl:ketch-pr-c50 md:ketch-max-w-[700px];
        .promote-buy-course {
          @apply ketch--mx-c16 ketch-flex ketch-items-center ketch-justify-center ketch-py-c5;
          @apply sm:ketch--mx-c20;
          background: linear-gradient(82deg, var(--primary-color) 0%, var(--secondary-color) 100%);
          .buy-cta {
            @apply ketch-bg-white ketch-text-primary-color ketch-p-c10 ketch-rounded-normal ketch-underline;
          }
        }
        .view-container {
          @apply ketch-mt-c15;
          &.editing {
            @apply ketch-rounded-large ketch-p-c20 ketch-pt-0 ketch-bg-module-selector-background-color;
          }
          .followup-lesson-wrapper {
            @apply ketch--mt-c5 ketch-mb-c15;
          }
          .editor-buttons {
            @apply ketch-border-b ketch-border-border-color ketch--mx-c20 ketch-px-c20 ketch-rounded-t-large;
          }
          .course-breadcrumbs {
            @apply ketch-grid ketch-grid-cols-[auto_auto_auto_1fr] ketch-h-c16 ketch-overflow-hidden;
            .crumb {
              @apply ketch-text-ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
        .selector-container {
          @apply ketch--mx-c16 sm:ketch--mx-c20;
        }
        .module-wrapper,
        .lesson-wrapper,
        .guided-quiz {
          > &:not(.credits) {
            @apply ketch-space-y-c30;
          }

          .module-name,
          .lesson-title {
            @apply md:ketch-pb-c10 ketch-mb-c10 ketch-whitespace-pre-wrap;
            word-break: break-word;
            h2 {
              @apply ketch-font-bold;
            }
          }

          .start-module,
          .next-lesson,
          .previous-lesson,
          .lesson-cta {
            @apply ketch-flex ketch-items-center ketch-justify-center ketch-rounded-normal hover:ketch-bg-opacity-80;
            @apply ketch-border ketch-border-border-color ketch-bg-transparent ketch-cursor-pointer;
            @apply ketch-h-c40 ketch-w-c200 ketch-space-x-c15;
            @apply hover:ketch-transition-all hover:ketch-border-primary-text-color;
            h5 {
              @apply ketch-font-bold;
            }
          }
          .previous-lesson {
            svg {
              @apply ketch-rotate-180;
            }
          }

          .buyCourse {
            @apply ketch-border-primary-color ketch-text-primary-foreground-color ketch-bg-primary-color;
            svg {
              @apply ketch-ml-c20;
            }
          }
        }
        .guided-quiz .module-name {
          @apply ketch-mb-c5 md:ketch-mb-c10;
        }
        .teaser-module-video,
        .lesson-video-content {
          @apply ketch-mb-c30 md:ketch-mb-0;
          .video-player {
            @apply ketch-max-h-[unset] ketch-h-[162px] xs2:ketch-h-[193px] xs3:ketch-h-[221px];
            @apply sm:ketch-h-[410px] md:ketch-h-[323px] xl:ketch-h-[366px];
          }
        }
        .cta-wrapper {
          @apply ketch-w-full ketch-flex ketch-flex-col ketch-space-y-c20 ketch-mt-c30;
          &.hasDownloadable {
            button.download {
              @apply ketch-w-[200px];
            }
          }
          .cta {
            @apply ketch-flex ketch-items-center ketch-justify-between;
            &.in-module {
              @apply ketch-justify-end;
            }
            &.has-no-previous-lesson {
              @apply ketch-justify-end;
            }
            .bookmark-next-lesson {
              @apply ketch-flex ketch-items-center ketch-space-x-c15;
            }
            button {
              @apply ketch-space-x-c15 ketch-bg-transparent;
            }
            svg {
              @apply ketch-cursor-pointer;
            }
          }
        }
      }
      .sideshow {
        @apply ketch-flex ketch-flex-col ketch-space-y-c15 ketch-mb-c20 md:ketch-mb-0 md:ketch-max-w-[360px];
        @screen md {
          flex: 0 0 360px;
        }
        .language-translate-toggle {
          @apply ketch-justify-end;
        }
      }
    }
  }
</style>
