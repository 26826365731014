<template lang="pug">
.lesson-quiz-question
    LoadingSpinner(v-if='question === null')
    QuestionWithButtons(
        :current-question-number='1',
        :in-results-mode='inResultsMode',
        :is-guided-quiz='true',
        :questions='[question]',
        :show-button-slot='true',
        :show-next-button='!inResultsMode',
        :show-previous-button='false',
        :show-question-number='false',
        :total-questions='1',
        @submit='inResultsMode = true',
        v-else
    )
        template(#button-text)
            KetchUpButton.tertiary.to-lesson(@click.native='toLesson')
                h5 {{ $t('homepage.toTheLesson') }}
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import CourseApi from '@/services/api/CourseApi'
  import QuestionWithButtons from '@/components/common/QuestionWithButtons.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { useRoute, useRouter } from 'vue-router/composables'
  import type { Question } from '@/services/interfaces/QuizQuestion'

  const router = useRouter()
  const route = useRoute()
  const question = ref<Question | null>(null)
  const questionId = route.params.questionId
  const inResultsMode = ref(false)

  CourseApi.getQuestion(questionId).then((data) => {
    question.value = data
  })

  const toLesson = () => {
    router.push({ name: 'Lesson' }).catch(() => {
      return
    })
  }
</script>

<style lang="postcss">
  .lesson-quiz-question {
    @apply ketch-mt-c60;
    .question-with-buttons {
      @apply ketch-max-w-[500px];
    }
    button.to-lesson {
      @apply ketch-w-auto ketch-min-w-[unset];
    }
  }
</style>
