<template lang="pug">
.panel-step-content(:class='{ "showing-progress-step": activeStep === 4 }')
    PanelStepEmptyStateContent(
        :active-step='activeStep',
        :active-step-entity='activeStepEntity',
        :step-empty-state-c-t-a='stepEmptyStateCTA',
        :step-empty-state-description='stepEmptyStateDescription',
        :step-empty-state-title='stepEmptyStateTitle',
        :step-video-url='stepVideoUrl',
        :thumbnail-timestamp='stepVideoUrlThumbnailTimestamp',
        :total-questions-answered='totalQuestionsAnswered',
        :total-questions-answered-percentage='totalQuestionsAnsweredPercentage',
        v-if='!stepIsCompleted || activeStep === 4'
    )
    TrialOrPurchasedCoursesSlider(
        :courses='inTrialOrPurchasedCourses',
        v-else-if='activeStep === 1'
    )
    .completed-lessons-and-guided-quiz(v-else-if='activeStep === 2 || 3')
        .thumbs-up
            img(:src='heavyThumbsUp')
        .count-info-wrapper
            .count-info
                h1.count {{ completedLessonsOrGuidedQuiz }}
                h5.title {{ completedCountTitle }}
            .count-description
                h5 {{ completedCountDescription }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import PanelStepEmptyStateContent from '@/components/get-started-panel/PanelStepEmptyStateContent.vue'
  import TrialOrPurchasedCoursesSlider from '@/components/get-started-panel/TrialOrPurchasedCoursesSlider.vue'
  import useIcons from '@/composables/useIcons'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { GetStartedStepEntity, OnboardingDetails } from '@/services/interfaces/Common'

  const props = defineProps({
    activeStep: {
      type: Number,
      required: true,
    },
    onboardingDetails: {
      type: Object as PropType<OnboardingDetails>,
      required: true,
    },
    activeStepEntity: {
      type: String as PropType<GetStartedStepEntity>,
      required: true,
    },
    inTrialOrPurchasedCourses: Array as PropType<{ id: string; cardTeaserImageUrl: string; teaserImageUrl: string }[]>,
    stepIsCompleted: Boolean,
    stepEmptyStateTitle: String,
    stepEmptyStateDescription: String,
    totalQuestionsAnsweredPercentage: Number,
    totalQuestionsAnswered: Number,
    stepEmptyStateCTA: String,
  })

  const { heavyThumbsUp } = useIcons()
  const { translateString } = useI18n()

  const completedLessonsOrGuidedQuiz = computed(() => {
    // completed lessons
    if (props.activeStep === 2) return props.onboardingDetails.totalLessonsCompleted
    // completed guided quizzes
    return props.onboardingDetails.guidedQuizzesCompleted
  })

  const completedCountTitle = computed(() => {
    if (props.activeStep === 2) return translateString('homepage.getStartedPanel.completedLessonsAlready')
    return translateString('homepage.getStartedPanel.completedGuidedQuizzesAlready')
  })

  const completedCountDescription = computed(() => {
    if (props.activeStep === 2) return translateString('homepage.getStartedPanel.completedLessonsDescription')
    return translateString('homepage.getStartedPanel.completedGuidedQuizzesDescription')
  })

  const stepVideoUrlDetails = computed(
    () => (videoEntity: GetStartedStepEntity) =>
      props.onboardingDetails.videoUrlDetails.find((v) => v.videoEntity === videoEntity),
  )

  const stepVideoUrl = computed(() => stepVideoUrlDetails.value(props.activeStepEntity)?.videoUrl)

  const stepVideoUrlThumbnailTimestamp = computed(
    () => stepVideoUrlDetails.value(props.activeStepEntity)?.thumbnailTimestamp || 0,
  )
</script>

<style lang="postcss">
  .panel-step-content {
    @apply ketch-px-c8 ketch-py-c10 ketch-border ketch-border-border-color ketch-rounded-normal;
    @apply ketch-w-full ketch-h-full ketch-overflow-hidden md:ketch-px-c20 md:ketch-py-c15;
    &.showing-progress-step {
      @apply ketch-h-auto md:ketch-px-c25;
    }
    .completed-lessons-and-guided-quiz {
      @apply ketch-flex ketch-items-center ketch-space-x-c20 ketch--ml-c8;
      @apply md:ketch-space-x-c55 md:ketch--ml-c20;
      .count-info-wrapper {
        @apply ketch-flex ketch-flex-col ketch-space-y-c5 md:ketch-space-y-0;
        .count-info {
          @apply ketch-flex ketch-space-x-c20;
          .count,
          .title {
            @apply ketch-font-bold;
          }
          .count {
            @apply ketch-font-big-daily-short md:ketch-text-[78px] md:ketch-leading-lg;
          }
          .title {
            @apply md:ketch-max-w-[215px] md:ketch-mt-c20;
          }
        }
        .count-description {
          @apply ketch-opacity-50;
        }
      }
    }
  }
</style>
