<template lang="pug">
.create-update-course
    h3.title-with-underline {{ editorTitle }}
    .course-modal-wrapper
        .title-short-title
            TextRenderer(
                :edit-title='$t("editor.createCourse.courseTitle")',
                :placeholder='$t("editor.createCourse.addCourseTitle")',
                :show-text-input='true',
                :source='editorCourse.title',
                @text-input='editorCourse.title = $event',
                required
            )
            TextRenderer(
                :edit-title='$t("editor.createCourse.shortTitle")',
                :placeholder='$t("editor.createCourse.addCourseShortTitle")',
                :show-text-input='true',
                :source='editorCourse.shortTitle',
                @text-input='editorCourse.shortTitle = $event'
            )
        .description-teaser-description
            .course-description.editor-column
                h5.editor-title.required {{ $t('editor.createCourse.courseDescription') }}
                ExpandableTextAreaInput(
                    :text-area-placeholder='$t("editor.createCourse.addCourseDescription")',
                    :text-message='editorCourse.description',
                    @text-change='editorCourse.description = $event'
                )
            .course-teaser-description.editor-column
                h5.editor-title.required {{ $t('editor.createCourse.teaserDescription') }}
                ExpandableTextAreaInput(
                    :text-area-placeholder='$t("editor.createCourse.addCourseTeaserDescription")',
                    :text-message='editorCourse.teaserDescription',
                    @text-change='editorCourse.teaserDescription = $event'
                )
        hr
        .teaser-card-image
            .editor-column.teaser
                h5.editor-title.required {{ $t('editor.createCourse.addTeaserImage') }}
                h6.editor-subtitle {{ $t('editor.createCourse.teaserImageSize') }}
                VideoOrImageRenderer(
                    :has-image-shadow='true',
                    :image-url='teaserImageUrl',
                    @image-data='onTeaserImageUpload',
                    @remove-image-url='removeTeaserImageUrl',
                    file-type='teaser_image',
                    image-only
                )
            .editor-column.card-image
                h5.editor-title.required {{ $t('editor.createCourse.addCardTeaserImage') }}
                h6.editor-subtitle {{ $t('editor.createCourse.cardTeaserImageSize') }}
                VideoOrImageRenderer(
                    :has-image-shadow='true',
                    :image-url='cardTeaserImageUrl',
                    @image-data='onCardTeaserImageUpload',
                    @remove-image-url='removeCardTeaserImageUrl',
                    file-type='card_teaser_image',
                    image-only
                )
        hr
        .editor-column
            h5.editor-title {{ $t('editor.createCourse.addTeaserVideo') }}
            VideoOrImageRenderer(
                :file-type='""',
                :video-url='editorCourse.teaserVideoUrl',
                @remove-video-url='onTeaserVideoUpload("")',
                @video-url='onTeaserVideoUpload',
                video-only
            )
            DurationInput.editor(
                :edit-subtext='$t("editor.timestampInputEditSubtext")',
                :edit-title='$t("editor.timestampInputEditTitle")',
                :value='editorCourse.thumbnailTimestamp || 0',
                duration-calibration='sec'
            )
        hr
        .course-benefits-tags
            .course-tags
                .meta-infos
                    .text-info
                        h5.title {{ $t('editor.createCourse.courseTags') }}
                        h6 {{ $t('editor.createCourse.selectCourseTag') }}
                    .configurable-options
                        .configurable-option(
                            :key='tag.id',
                            @click='onTagClick(tag.id)',
                            v-for='tag in tagItems'
                        )
                            .icon-status
                                SVGRenderer(
                                    :has-hover='false',
                                    :icon='checkedCheckboxSquare',
                                    stroke-color='var(--primary-text-color)',
                                    v-if='isTagSelected(tag.id)',
                                    width='20'
                                )
                                SVGRenderer(
                                    :has-hover='false',
                                    :icon='checkboxSquare',
                                    stroke-color='var(--primary-text-color)',
                                    v-else,
                                    width='20'
                                )
                            .text-info
                                h5 {{ tag.title }}
                h6.or {{ $t('or') }}
                .create-new-tags
                    TextRenderer(
                        :placeholder='$t("editor.createCourse.enterTagTitle")',
                        :show-text-input='true',
                        :source='newTagTitle',
                        @text-input='newTagTitle = $event'
                    )
                    KetchUpButton.editor(
                        :disabled='creatingTag',
                        @click.native='createTag'
                    )
                        LoadingSpinner(v-if='creatingTag')
                        h5(v-else) {{ $t('editor.createCourse.createTag') }}
            .course-benefits.editor-column
                h5.editor-title {{ $t('editor.createCourse.benefits') }}
                h6.editor-subtitle {{ $t('editor.createCourse.addCourseBenefitsDescription') }}
                ExpandableTextAreaInput(
                    :text-area-placeholder='$t("editor.createCourse.addCourseBenefits")',
                    :text-message='courseBenefits',
                    @text-change='setCourseBenefits($event)'
                )
        hr
        .host-description.editor-column
            EditAuthors(
                :add-author='addHost',
                :add-author-text='$t("editor.createCourse.addHost")',
                :author-text='$t("editor.createCourse.host")',
                :authors='hostAuthor',
                :can-add-more-authors='false',
                :remove-author='removeHost'
            )
            .editor-column
                h5.editor-title {{ $t('editor.createCourse.hostDescription') }}
                ExpandableTextAreaInput(
                    :text-area-placeholder='$t("editor.createCourse.addHostDescription")',
                    :text-message='editorCourse.hostDescription',
                    @text-change='editorCourse.hostDescription = $event'
                )
        hr
        EditAuthors(
            :add-author='addCourseAuthor',
            :add-author-text='$t("editor.createCourse.addCourseAuthors")',
            :author-text='$t("editor.createCourse.courseAuthors")',
            :authors='courseAuthors',
            :remove-author='removeCourseAuthor'
        )
        hr
        .state-and-feedback-email
            .editor-column
                h5.editor-title.required {{ $t('editor.createCourse.setCourseState') }}
                Dropdown(
                    :dropdown-placeholder='$t("editor.createCourse.selectCourseState")',
                    :items='stateItems',
                    :value='editorCourse.state',
                    @input='setCourseState',
                    default-dropdown-behavior,
                    stroke-color-for-expand-icon='var(--editor-primary-color)'
                )
            TextRenderer(
                :placeholder='"yourname@email.com"',
                :show-text-input='true',
                :source='editorCourse.sendFeedbackEmail',
                @text-input='editorCourse.sendFeedbackEmail = $event',
                edit-title='Feedback-E-Mail'
            )
        .assign-groups-meta-info
            .meta-infos(v-if='createCourse')
                .text-info
                    h5.title {{ $t('editor.createCourse.userGroups') }}
                    h6 {{ $t('editor.createCourse.assignToGroup') }}
                .configurable-options
                    .configurable-option(
                        :key='group.id',
                        @click='onGroupClick(group.id)',
                        v-for='group in allMemberCourseGroups'
                    )
                        .icon-status
                            SVGRenderer(
                                :has-hover='false',
                                :icon='checkedCheckboxSquare',
                                stroke-color='var(--primary-text-color)',
                                v-if='isGroupSelected(group.id)',
                                width='20'
                            )
                            SVGRenderer(
                                :has-hover='false',
                                :icon='checkboxSquare',
                                stroke-color='var(--primary-text-color)',
                                v-else,
                                width='20'
                            )
                        .text-info
                            h5 {{ group.name }}
            .meta-infos
                .text-info
                    h5.title Meta-Infos
                    h6 {{ $t('editor.createCourse.configureCourse') }}
                .configurable-options
                    .configurable-option(
                        :key='index',
                        @click='configureCourseOption(option.action)',
                        v-for='(option, index) in configurableCourseOptions'
                    )
                        .icon-status
                            SVGRenderer(
                                :has-hover='false',
                                :icon='checkedCheckboxSquare',
                                stroke-color='var(--primary-text-color)',
                                v-if='option.checked',
                                width='20'
                            )
                            SVGRenderer(
                                :has-hover='false',
                                :icon='checkboxSquare',
                                stroke-color='var(--primary-text-color)',
                                v-else,
                                width='20'
                            )
                        .text-info
                            h5 {{ option.title }}
    .course-cta-wrapper
        .course-cta
            KetchUpButton.editor(
                @click.native='$emit("update-training-content")',
                v-if='showUpdateContentCTA'
            )
                h5 {{ $t('editor.createCourse.updateTrainingContent') }}
            KetchUpButton.primary(
                :disabled='creatingOrUpdatingCourse || unfilledRequiredAndHasNoChanges',
                @click.native='submitCourseDetails'
            )
                LoadingSpinner(v-if='creatingOrUpdatingCourse')
                h5(v-else) {{ createOrUpdateCourseText }}
</template>

<script setup lang="ts">
  import { computed, onMounted, reactive, ref, watch } from 'vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import DurationInput from '@/components/common/DurationInput.vue'
  import EditAuthors from '@/components/editor/EditAuthors.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import Dropdown from '@/components/common/Dropdown.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import ExpandableTextAreaInput from '@/components/common/ExpandableTextAreaInput.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseApi from '@/services/api/CourseApi'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useEditor from '@/composables/useEditor'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { Author } from '@/services/interfaces/Common'
  import type { ConfigurableCourseOption, CourseItem, EditorCoursePayload } from '@/services/interfaces/Course'

  const props = defineProps({
    editorTitle: {
      type: String,
      required: true,
    },
    createCourse: {
      type: Boolean,
      default: false,
    },
    updateCourse: {
      type: Boolean,
      default: false,
    },
    showUpdateContentCTA: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['update-course-section', 'has-course-changes', 'update-training-content'])

  const { checkboxSquare, checkedCheckboxSquare, cardEmptyImage } = useIcons()
  const { convertStringWithNewLineCharactersIntoArrayOfStrings, joinArrayOfStringsWithNewlineCharacter } =
    useCommonMixin()
  const { course, currentCourseId } = useCourse()
  const { hasLocalChanges } = useEditor()
  const { translateString } = useI18n()

  const editorCourse = reactive({
    title: '',
    shortTitle: '',
    description: '',
    teaserDescription: '',
    teaserImageUrl: '',
    teaserData: null,
    cardTeaserImageUrl: '',
    cardTeaserData: null,
    teaserVideoUrl: '',
    thumbnailTimestamp: 0,
    benefits: [],
    hostId: '',
    hostDescription: '',
    authors: [],
    allowFeedback: false,
    free: false,
    locked: false,
    allowExpiringCourse: false,
    state: 'in_progress',
    sendFeedbackEmail: 'feedback@ketchup.academy',
    tags: [],
    downloadables: [],
  } as EditorCoursePayload)
  const creatingOrUpdatingCourse = ref(false)
  const courseUserGroupIds = ref([] as string[])
  const newTagTitle = ref('')
  const creatingTag = ref(false)

  const hostAuthor = computed(() => {
    const host = EditorModule.editorAllAuthors?.find((author) => author.id === editorCourse.hostId)
    return host ? [host] : []
  })

  const courseAuthors = computed(() => {
    const authors: Author[] = []
    editorCourse.authors.forEach((a) => {
      const author = EditorModule.editorAllAuthors?.find((author) => author.id === a.authorId)
      if (author) authors.push(author)
    })
    return authors
  })

  const configurableCourseOptions = computed(
    () =>
      [
        {
          title: translateString('editor.createCourse.courseIsFree'),
          action: 'free',
          checked: editorCourse.free,
        },
        {
          title: translateString('editor.createCourse.allowFeedback'),
          action: 'allowFeedback',
          checked: editorCourse.allowFeedback,
        },
        {
          title: translateString('editor.createCourse.isLocked'),
          action: 'locked',
          checked: editorCourse.locked,
        },
        {
          title: translateString('editor.createCourse.allowExpiringCourse'),
          action: 'allowExpiringCourse',
          checked: editorCourse.allowExpiringCourse,
        },
      ] as ConfigurableCourseOption[],
  )

  const courseBenefits = computed(() => joinArrayOfStringsWithNewlineCharacter(editorCourse.benefits))

  const courseStates = ['teaser_ready', 'ready', 'in_progress']
  const stateItems = computed(() => {
    return courseStates.map((state) => ({
      type: state,
      title: stateText.value(state),
      selectable: editorCourse.state !== state,
      selectedBefore: editorCourse.state === state,
    }))
  })

  const stateText = computed(() => {
    return (state: string) => {
      switch (state) {
        case 'in_progress':
          return translateString('editor.createCourse.inProgress')
        case 'ready':
          return translateString('editor.createCourse.ready')
        case 'teaser_ready':
          return translateString('editor.createCourse.teaserReady')
        default:
          return translateString('editor.createCourse.inProgress')
      }
    }
  })

  const unfilledRequiredAndHasNoChanges = computed(() => {
    // for updating course if the course has the teaserVideoUrl we exempt checking the teaserData or teaserImageUrl
    return !(
      editorCourse.title &&
      editorCourse.description &&
      editorCourse.teaserDescription &&
      (props.updateCourse
        ? editorCourse.teaserData || editorCourse.teaserImageUrl || editorCourse.teaserVideoUrl
        : editorCourse.teaserData) &&
      (props.updateCourse
        ? editorCourse.cardTeaserData || editorCourse.cardTeaserImageUrl
        : editorCourse.cardTeaserData) &&
      editorCourse.state &&
      (props.updateCourse ? hasCourseChanges.value : true)
    )
  })

  const allMemberCourseGroups = computed(() => UserModule.userGroups || [])

  const isGroupSelected = computed(() => (groupId: string) => courseUserGroupIds.value.includes(groupId))

  const tagItems = computed(() => CourseModule.tags)

  const isTagSelected = computed(() => (tagId: string) => !!editorCourse.tags.find((t) => t.tagId === tagId))

  const createOrUpdateCourseText = computed(() =>
    props.createCourse
      ? translateString('editor.createCourse.createCourseTitle')
      : translateString('editor.createCourse.updateCourse'),
  )

  const teaserImageUrl = computed(() => {
    return props.createCourse ? cardEmptyImage.value : editorCourse.teaserImageUrl
  })

  const cardTeaserImageUrl = computed(() => {
    return props.createCourse ? cardEmptyImage.value : editorCourse.cardTeaserImageUrl
  })

  const hasCourseChanges = computed(() => hasLocalChanges.value(editorCourse, EditorModule.course))

  const onTeaserImageUpload = (imageData: string) => {
    editorCourse.teaserData = imageData
  }

  const onCardTeaserImageUpload = (imageData: string) => {
    editorCourse.cardTeaserData = imageData
  }

  const removeTeaserImageUrl = () => {
    editorCourse.teaserImageUrl = ''
    editorCourse.teaserData = null
  }

  const removeCardTeaserImageUrl = () => {
    editorCourse.cardTeaserImageUrl = ''
    editorCourse.cardTeaserData = null
  }

  const onTeaserVideoUpload = (url: string) => {
    editorCourse.teaserVideoUrl = url
  }

  const addHost = (hostId: string) => {
    editorCourse.hostId = hostId
  }

  const addCourseAuthor = (authorId: string) => {
    editorCourse.authors.push({
      authorId,
    })
  }

  const removeHost = () => {
    editorCourse.hostId = ''
  }

  const removeCourseAuthor = (authorId: string) => {
    const index = editorCourse.authors.findIndex((a) => a.authorId === authorId)
    if (index > -1) {
      editorCourse.authors.splice(index, 1)
    }
  }

  const configureCourseOption = (action: 'free' | 'allowFeedback' | 'locked' | 'allowExpiringCourse') => {
    editorCourse[action] = !editorCourse[action]
  }

  const setCourseState = (state: 'in_progress' | 'ready' | 'teaser_ready') => {
    editorCourse.state = state
  }

  const onTagClick = (tagId: string) => {
    if (isTagSelected.value(tagId)) {
      const index = editorCourse.tags.findIndex((t) => t.tagId === tagId)
      editorCourse.tags.splice(index, 1)
      return
    }
    editorCourse.tags.push({ tagId })
  }

  const setCourseBenefits = (benefits: string) => {
    editorCourse.benefits = convertStringWithNewLineCharactersIntoArrayOfStrings(benefits)
  }

  const onGroupClick = (groupId: string) => {
    if (isGroupSelected.value(groupId)) {
      const index = courseUserGroupIds.value.findIndex((id) => id === groupId)
      courseUserGroupIds.value.splice(index, 1)
      return
    }
    courseUserGroupIds.value.push(groupId)
  }

  const createTag = () => {
    creatingTag.value = true
    CourseApi.createTag(newTagTitle.value)
      .then((resp) => {
        CourseModule.tags.unshift(resp.tag)
      })
      .catch((error) => {
        if (error.response.status === 400) {
          eventBus.$toasted.error(translateString(`${error.response.data}`), { duration: 8000 })
        } else {
          eventBus.$toasted.error(translateString('editor.createCourse.errorInCreatingTag'), {
            duration: 8000,
          })
        }
      })
      .finally(() => (creatingTag.value = false))
  }

  const setDefaultTeaserImageData = () => {
    // set default image data
    editorCourse.teaserData =
      '{"id":"card_empty_image.png","storage":"store","metadata":{"filename":"card_empty_image.png","size":5820,"mime_type":"image/png"}}'
    editorCourse.cardTeaserData =
      '{"id":"card_empty_image.png","storage":"store","metadata":{"filename":"card_empty_image.png","size":5820,"mime_type":"image/png"}}'
  }

  const prefillCourseDetails = (course: CourseItem) => {
    editorCourse.title = course.title
    editorCourse.shortTitle = course.shortTitle
    editorCourse.description = course.description
    editorCourse.teaserDescription = course.teaserDescription || ''
    editorCourse.teaserImageUrl = course.teaserImageUrl
    editorCourse.cardTeaserImageUrl = course.cardTeaserImageUrl
    editorCourse.teaserVideoUrl = course.teaserVideoUrl || ''
    editorCourse.thumbnailTimestamp = 0
    editorCourse.benefits = course.benefits
    editorCourse.hostId = course.host?.id || ''
    editorCourse.hostDescription = course.hostDescription
    editorCourse.authors = course.authors.map((a) => ({ authorId: a.id }))
    editorCourse.allowFeedback = course.allowFeedback
    editorCourse.free = course.free || false
    editorCourse.locked = course.locked
    editorCourse.allowExpiringCourse = course.allowExpiringCourse
    editorCourse.state = course.state
    editorCourse.sendFeedbackEmail = course.sendFeedbackEmail
    editorCourse.tags = course.tags.map((t) => ({ tagId: t.id }))
    editorCourse.downloadables =
      course.downloadables?.map((lessonDownloadable) => ({ lessonId: lessonDownloadable.id })) ?? []
  }

  const createNewCourse = () => {
    CourseApi.createCourse({ course: editorCourse })
      .then(async (resp) => {
        emit('update-course-section', {
          courseId: resp.courseId,
          courseGroupIds: courseUserGroupIds.value,
        })
      })
      .catch((error) => {
        if (error.response.status === 400) {
          eventBus.$toasted.error(translateString(`${error.response.data}`), { duration: 8000 })
        } else {
          eventBus.$toasted.error(translateString('editor.createCourse.errorInCreatingCourse'), {
            duration: 8000,
          })
        }
      })
      .finally(() => (creatingOrUpdatingCourse.value = false))
  }

  const updateExistingCourse = () => {
    const properties = [
      'title',
      'shortTitle',
      'description',
      'teaserDescription',
      'teaserImageUrl',
      'teaserData',
      'cardTeaserImageUrl',
      'cardTeaserData',
      'teaserVideoUrl',
      'thumbnailTimestamp',
      'benefits',
      'hostId',
      'hostDescription',
      'authors',
      'allowFeedback',
      'free',
      'locked',
      'allowExpiringCourse',
      'state',
      'sendFeedbackEmail',
      'tags',
      'downloadables',
    ] as (keyof EditorCoursePayload)[]

    const payload: any = {
      course: {},
    }

    const alwaysSubmitProperties = ['authors', 'tags', 'downloadables']

    properties.forEach((property) => {
      if (
        alwaysSubmitProperties.includes(property) ||
        hasLocalChanges.value(EditorModule.course?.[property], editorCourse[property])
      ) {
        payload.course[property] = editorCourse[property]
      }
    })

    CourseApi.updateCourse(currentCourseId.value, payload)
      .then((resp) => {
        if (resp === 200) {
          CourseModule.getCourse({ courseId: currentCourseId.value })
          eventBus.$toasted.success(translateString('editor.createCourse.updatedTrainingSuccessfully'))
        }
      })
      .finally(() => (creatingOrUpdatingCourse.value = false))
  }

  const submitCourseDetails = () => {
    creatingOrUpdatingCourse.value = true
    if (props.createCourse) {
      createNewCourse()
    } else {
      updateExistingCourse()
      EditorModule.setSubmitCourseChanges(false)
    }
  }

  const discardAllCourseChanges = () => {
    if (course.value) {
      prefillCourseDetails(course.value)
      EditorModule.setDiscardCourseChanges(false)
    }
  }

  const discardCourseChanges = computed(() => EditorModule.discardCourseChanges)
  watch(discardCourseChanges, (value) => {
    if (value) {
      discardAllCourseChanges()
    }
  })

  const submitCourseChanges = computed(() => EditorModule.submitCourseChanges)
  watch(submitCourseChanges, (value) => {
    if (value) {
      submitCourseDetails()
    }
  })

  watch(hasCourseChanges, (value) => {
    emit('has-course-changes', value)
  })

  watch(course, (value) => {
    if (value) {
      prefillCourseDetails(course.value!)
      EditorModule.setEditorCourse(editorCourse)
    }
  })

  onMounted(() => {
    if (props.createCourse) {
      setDefaultTeaserImageData()
    }
    if (props.updateCourse) {
      if (course.value) {
        prefillCourseDetails(course.value)
        EditorModule.setEditorCourse(editorCourse)
      }
    }
  })
</script>

<style lang="postcss">
  .create-update-course {
    @apply ketch-relative;
    .course-modal-wrapper {
      @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-mb-c20;
      hr {
        @apply ketch-bg-transparent ketch-border-dashed ketch-border-editor-primary-color;
      }
      .title-short-title,
      .description-teaser-description,
      .state-and-feedback-email,
      .assign-groups-meta-info,
      .course-benefits-tags {
        @apply ketch-flex ketch-items-start ketch-space-x-c20 ketch-w-full;
        .text-renderer,
        .image-upload-wrapper,
        .expandable-text-area-wrapper,
        .meta-infos,
        .course-tags,
        .course-benefits {
          @apply ketch-w-full;
        }
        .dropdown {
          @apply ketch-w-[200px];
        }
        .course-tags {
          @apply ketch-flex ketch-flex-col ketch-space-y-c10;
          .meta-infos {
            @apply ketch-max-h-[175px] ketch-overflow-auto;
          }
          h6.or {
            @apply ketch-text-center;
          }
          .create-new-tags {
            @apply ketch-flex ketch-space-x-c20;
            button {
              @apply ketch-w-[200px];
            }
          }
        }
      }
      .editor-column {
        @apply ketch-flex ketch-flex-col ketch-space-y-c5;
        h5.editor-title {
          @apply ketch-font-bold ketch-text-editor-primary-color;
        }
        h6.editor-subtitle {
          @apply ketch-text-secondary-text-color;
        }
      }
      .teaser-card-image {
        @apply ketch-flex;
        .editor-column {
          @apply ketch-w-full;
        }
        .teaser {
          @apply ketch-flex-auto xl:ketch-space-x-0 xl:ketch-mr-c50;
          img {
            @apply ketch-w-full ketch-rounded-large ketch-object-cover lg:ketch-h-[400px];
          }
        }
        .card-image {
          @screen xl {
            flex: 0 0 350px;
          }
          img {
            @apply ketch-w-full ketch-rounded-large ketch-object-cover lg:ketch-h-[220px];
          }
        }
      }
      .course-description,
      .course-teaser-description {
        @apply ketch-w-full;
      }
      .host-description {
        .expandable-text-area-wrapper {
          @apply ketch-w-1/2;
        }
      }
    }
    .course-cta-wrapper {
      @apply ketch-fixed ketch-bottom-[15px] ketch-right-[25px];
      .course-cta {
        @apply ketch-flex ketch-space-x-c50 ketch-justify-end;
        button {
          @apply ketch-w-auto;
        }
      }
    }
  }
</style>
