<template lang="pug">
.edit-module-authors
    EditAuthors(
        :add-author='onAuthorSelected',
        :author-subtext='$t("editor.editAuthorsSubtext")',
        :author-text='$t("editor.moduleAuthors")',
        :authors='authors',
        :remove-author='removeAuthor'
    )
</template>

<script setup lang="ts">
  import { onMounted } from 'vue'
  import { EditorModule } from '@/store/modules/editor'
  import EditAuthors from '@/components/editor/EditAuthors.vue'
  import { useRoute } from 'vue-router/composables'
  import type { PropType } from 'vue'
  import type { Author } from '@/services/interfaces/Common'
  import type { AuthorMap, EditorStatePayload } from '@/services/interfaces/Course'

  defineProps({
    authors: {
      type: Array as PropType<Author[]>,
      required: true,
    },
  })

  const route = useRoute()
  const onAuthorSelected = (authorId: string) => {
    const selectedAuthor = EditorModule.editorAllAuthors!.find((author) => author.id === authorId)
    if (selectedAuthor) {
      EditorModule.addModuleAuthor({ path: route.path, author: selectedAuthor })
    }
  }

  const removeAuthor = (authorId: string) => {
    EditorModule.removeModuleAuthor({ path: route.path, authorId })
  }

  const authorsRedoCallback = (data: EditorStatePayload) => {
    const value = data.value as AuthorMap
    if (value.operation === 'remove-author') {
      EditorModule.currentModule?.authors?.splice(value.index!, 1)
    } else if (value.operation === 'add-author') {
      const selectedAuthor = EditorModule.editorAllAuthors!.find((author) => author.id === value.id)
      EditorModule.currentModule?.authors?.push(selectedAuthor!)
    }
  }

  onMounted(() => {
    EditorModule.subscribe({
      type: 'save',
      key: 'currentModuleauthors',
      callback: authorsRedoCallback,
    })
  })
</script>

<style lang="postcss" scoped>
  .edit-module-authors {
    .authors-wrapper {
      @apply ketch-mt-c10 ketch-mb-c30;
    }
  }
</style>
