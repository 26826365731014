import { render, staticRenderFns } from "./TopLessonsViewedLoader.vue?vue&type=template&id=df1756f8&scoped=true&lang=pug"
import script from "./TopLessonsViewedLoader.vue?vue&type=script&setup=true&lang=ts"
export * from "./TopLessonsViewedLoader.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df1756f8",
  null
  
)

export default component.exports