<template lang="pug">
.pagination
    .previous-page(
        :class='{ disable: currentPage === 1 }',
        @click='onPreviousPage'
    )
        img(:src='previousIcon')
        h5 {{ $t('previous') }}
    .pagination-dots
        .dot(
            :class='{ active: page === currentPage }',
            :key='page',
            @click='onPageClick(page)',
            v-for='page in totalPages'
        )
    .next-page(
        :class='{ disable: !submitText && currentPage === totalPages }',
        @click='onNextPage'
    )
        h5(v-if='submitText && currentPage === totalPages') {{ submitText }}
        h5(v-else) {{ $t('next') }}
        img(:src='nextIcon')
</template>

<script setup lang="ts">
  import useIcons from '@/composables/useIcons'

  const props = defineProps({
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    allowPageClick: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
      default: '',
    },
  })
  const emit = defineEmits(['next', 'previous', 'submit', 'page'])
  const { previousIcon, nextIcon } = useIcons()
  const onPreviousPage = () => {
    if (props.currentPage === 1) return
    emit('previous')
  }

  const onNextPage = () => {
    if (props.currentPage === props.totalPages) {
      if (props.submitText) emit('submit')
      return
    }
    emit('next')
  }

  const onPageClick = (page: number) => {
    if (props.allowPageClick) emit('page', page)
  }
</script>

<style lang="postcss" scoped>
  .pagination {
    @apply ketch-flex ketch-items-center ketch-justify-between;
    .previous-page,
    .next-page {
      @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-cursor-pointer;
      img {
        filter: invert(1);
      }
      &.disable {
        @apply ketch-opacity-40 ketch-cursor-default;
      }
    }
    .pagination-dots {
      @apply ketch-flex ketch-flex-wrap ketch-justify-center;
      flex-basis: 40%;
      .dot {
        @apply ketch-w-c8 ketch-h-c8 ketch-rounded-normal ketch-border ketch-border-primary-text-color;
        @apply ketch-transition-all ketch-ease-in ketch-duration-300 ketch-cursor-pointer ketch-my-c4;
        @apply ketch-mr-c5;
        &:last-of-type {
          @apply ketch-mr-0;
        }
        &.active {
          @apply ketch-w-[20px] ketch-bg-primary-text-color;
        }
      }
    }
  }
  .ketch-dark .pagination {
    .previous-page,
    .next-page {
      img {
        filter: none;
      }
    }
  }
</style>
