import Cookies, { CookieAttributes } from 'js-cookie'

interface LearningUnit {
  slug: string
  created: string
}

interface CookieApi {
  attributes: CookieAttributes
  get: (key: string) => string
  set: (key: string, value: string | any, attributes?: CookieAttributes) => void
}

export default class CookieHelper {
  cookie!: { videoTracker: { [key: string]: string }; learningUnits: LearningUnit[] }
  cookieName: string
  api: CookieApi

  constructor() {
    this.cookieName = 'cookie'
    this.api = (Cookies as any).withAttributes({
      expires: 365,
      domain: CookieHelper.domain,
      secure: !['development'].includes(process.env.APP_ENV as string),
      path: '/',
    })
  }

  private static get domain(): string {
    if (['development', 'review'].includes((process.env.APP_ENV as string) || 'development')) {
      return window.location.hostname
    }
    return '.ketchup.academy'
  }

  initialize(cookieName: string): void {
    this.cookieName = cookieName
    this.createCookie()
  }

  private createCookie(): void {
    this.cookie = CookieHelper.getJSON(this.getInstanceCookieValue(this.cookieName))
    if (!this.cookie) {
      this.setCookieValue(this.cookieName, '{ "learningUnits": [] }')
      this.cookie = CookieHelper.getJSON(this.getInstanceCookieValue(this.cookieName))
    }
  }

  static getCookieValue(key: string): any {
    return CookieHelper.getJSON(Cookies.get(key))
  }
  getInstanceCookieValue(key: string): any {
    const value = Cookies.get(key)
    if (value) {
      return CookieHelper.getJSON(decodeURIComponent(value))
    }
    return value
  }

  private static getJSON(value: string | undefined): any {
    try {
      if (value) {
        return JSON.parse(value)
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      return value
    }
  }

  static getRawCookieValue(key: string): string | undefined {
    return Cookies.get(key)
  }

  static setCookieValue(key: string, value: string): any {
    Cookies.set(key, value)
  }
  setCookieValue(key: string, value: string): any {
    this.api.set(key, encodeURIComponent(value))
  }

  static removeCookie(key: string): any {
    return Cookies.remove(key)
  }

  static setTempCookie(key: string, value: string): any {
    const inFifteenSecs = new Date(new Date().getTime() + 15 * 1000)
    return Cookies.set(key, value, {
      expires: inFifteenSecs,
      domain: '.ketchup.academy',
      path: '/',
      secure: true,
    })
  }

  static setCrossDomainCookie(key: string, value: string): any {
    return Cookies.set(key, value, {
      expires: 365,
      domain: CookieHelper.domain,
      path: '/',
      secure: CookieHelper.domain !== 'localhost',
    })
  }

  static removeCrossDomainCookie(key: string): any {
    return Cookies.remove(key, {
      expires: 365,
      domain: CookieHelper.domain,
      path: '/',
      secure: CookieHelper.domain !== 'localhost',
    })
  }

  static removePaypalCookies(): any {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      if (cookieName.indexOf('paypal') === 0) {
        Cookies.remove(cookieName)
      }
    })
  }
}
