import axios from './interceptor'

export default class VersionApi {
  static async getAppVersion(): Promise<{ commitId: string }> {
    return axios
      .get(`/version.json`, {
        baseURL: window.location.origin,
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
