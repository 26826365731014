<template lang="pug">
.youtube-video-wrapper(data-cy='youtube-modal')
    LoadingSpinner(v-if='!isPlayerReady')
    #player
    .replay-wrapper(v-if='showReplayButtonOverlay')
        KetchUpButton.replay-video-button(@click.native='replayVideo')
            h5 Replay Video
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '../common/LoadingSpinner.vue'
  import useSegment from '@/composables/useSegment'

  const props = defineProps({
    videoId: {
      type: String,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
  })

  const player = ref(null as any)
  const { videoCompleted, videoStarted, videoPaused } = useSegment()
  const showReplayButtonOverlay = ref(false)
  const isPlayerReady = ref(false)

  const showOverlay = () => {
    showReplayButtonOverlay.value = true
  }

  const replayVideo = () => {
    player.value?.seekTo(0, true)
    showReplayButtonOverlay.value = false
  }

  const onYouTubeIframeAPIReady = (
    videoId: string,
    contentId: string,
    showOverlay: () => void,
    onPlayerReady: () => void,
  ): any => {
    if ((window as any).YT) {
      return new (window as any).YT.Player('player', {
        height: '100%',
        width: '100%',
        videoId: videoId,
        events: {
          onReady: () => {
            if (typeof onPlayerReady === 'function') onPlayerReady()
          },
          onStateChange: (e: { data: number }) => {
            onPlayerStateChange(e, contentId, showOverlay)
          },
        },
      })
    }
  }

  const onPlayerStateChange = (e: { data: number }, contentId: string, showOverlay: () => void) => {
    if (e.data === 0) {
      videoCompleted(contentId, 'external')
      showOverlay()
    } else if (e.data === 1) {
      videoStarted(contentId, 'external')
    } else if (e.data === 2) {
      videoPaused(contentId, 'external')
    }
  }

  onMounted(() => {
    player.value = onYouTubeIframeAPIReady(props.videoId, props.contentId, showOverlay, () => {
      isPlayerReady.value = true
    })
  })
</script>

<style lang="postcss">
  .youtube-video-wrapper {
    @apply ketch-relative ketch-h-c200 sm:ketch-h-c400 md:ketch-h-[550px];
    .replay-wrapper {
      z-index: 1;
      @apply ketch-absolute ketch-top-0 ketch-bottom-0 ketch-left-0 ketch-right-0;
      @apply ketch-bg-white ketch-flex ketch-items-center ketch-justify-center;
    }
  }
</style>
