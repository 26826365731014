<template lang="pug">
LoadingSpinner(v-if='loadingFollowUps')
.followup-table(v-else)
  h2 {{ $t('editor.followup.followups') }}
  h5 {{ $t('editor.followup.followupDescription') }}
  .table-container
    .table-header
      .header-item(
        :class='{ sortable: col.sort, desc: sortDirection }',
        :key='col.title',
        @click='sortFollowups',
        v-for='col in columns'
      )
        h6 {{ col.title }}
        SVGRenderer(
          :fill-color='"var(--primary-color)"',
          :has-hover='false',
          :icon='vectorArrow',
          v-if='col.sort',
          width='6'
        )
    .no-follow-ups(v-if='!followups')
      h5 {{ $t('editor.followup.noFollowUpText') }}
    transition-group.table-content(
      name='flip-in-x',
      tag='div',
      v-else
    )
      .row-item(
        :key='`${row.id + index}`',
        @click='showLessonModal(row)',
        v-for='(row, index) in followups'
      )
        .lesson
          h5.title {{ row.lesson.name }}
          ul
            li.h6(
              :key='item.courseId',
              v-for='item in row.moduleCourses'
            ) {{ item.courseName }}
        h6.date {{ formatDate(row.date, 'dd.MM.yyyy') }}
        HTMLRenderer.comment(:html='row.comment')
        h6.delete(@click.stop='deleteFollowup(row.lesson.name, row.id)') {{ $t('editor.followup.columns.delete') }}
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { CourseModule } from '@/store/modules/course'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import type { CourseItem, FollowUp } from '@/services/interfaces/Course'
  import { DateTime } from 'luxon'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import useI18n from '@/composables/useI18n'

  const { vectorArrow } = useIcons()
  const { deleteFollowup } = useEditor()
  const { formatDate, setIsLoadingComponentViewData } = useCommonMixin()
  const { fetchCourses, openLessonModal } = useCourse()
  const { translateString } = useI18n()

  const followups = computed(() => CourseModule.companyFollowups)
  const columns = [
    {
      title: translateString('editor.followup.columns.lesson'),
      sort: false,
    },
    {
      title: translateString('editor.followup.columns.date'),
      sort: true,
    },
    {
      title: translateString('editor.followup.columns.comment'),
      sort: false,
    },
    {
      title: translateString('editor.followup.columns.delete') + '?',
      sort: false,
    },
  ]

  const loadingFollowUps = computed(() => followups.value === null)

  const showLessonModal = (followup: FollowUp) => {
    const accessibleCourses: CourseItem[] = []
    followup.moduleCourses.forEach((course) => {
      const _course = CourseModule.courses?.find((c) => c.id === course.courseId)
      if (_course?.visibleToGroup) {
        accessibleCourses.push(_course)
      }
    })
    // we select any of the accessible courses
    const courseId = accessibleCourses[Math.floor(Math.random() * accessibleCourses.length)].id
    const moduleId = followup.moduleCourses.find((c) => c.courseId === courseId)?.moduleId

    if (courseId && moduleId) {
      openLessonModal({
        lessonId: followup.lesson.id,
        courseId: courseId,
        moduleId: moduleId!,
      })
    }
  }

  const sortDirection = ref(false)
  const sortFollowups = () => {
    sortDirection.value = !sortDirection.value
    CourseModule.companyFollowups?.sort((a, b) => {
      const dateA = DateTime.fromISO(a.date)
      const dateB = DateTime.fromISO(b.date)
      if (dateA < dateB) return -1
      if (dateA > dateB) return 1
      return 0
    })
    if (sortDirection.value) CourseModule.companyFollowups?.reverse()
  }

  CourseModule.getFollowups().then((followups) => {
    if (followups) {
      if (!CourseModule.courses) {
        fetchCourses()
      }
    }
  })

  watch(loadingFollowUps, (value) => {
    setIsLoadingComponentViewData(value)
  })
</script>

<style lang="postcss">
  .followup-table {
    h2 {
      @apply ketch-font-big-daily-short ketch-font-bold ketch-mb-c15;
    }
    .table-container {
      @apply ketch-border ketch-border-border-color ketch-rounded-t-[5px] ketch-mt-c70;
      .table-header,
      .table-content .row-item {
        @apply ketch-grid ketch-gap-c20;
        grid-template-columns: 3fr 1fr 3fr 1fr;
      }
      .table-header {
        @apply ketch-bg-black ketch-bg-opacity-[0.04] ketch-items-center ketch-h-c30 ketch-px-c15;
        .header-item {
          @apply ketch-flex ketch-space-x-c15;
          h6 {
            @apply ketch-text-xs ketch-leading-sm ketch-font-bold ketch-uppercase;
          }
          &.sortable {
            @apply ketch-cursor-pointer;
            svg {
              @apply ketch-transition-transform ketch-duration-300;
            }
            &.desc svg {
              @apply ketch-rotate-180;
            }
            svg {
              @apply ketch-w-c6;
            }
          }
          &:not(.sortable) {
            @apply ketch-pointer-events-none;
          }
        }
      }
      .no-follow-ups {
        @apply ketch-text-center ketch-py-c50;
      }
      .table-content {
        ul {
          @apply ketch-relative ketch-pl-c30 ketch-pt-c5 ketch-list-none;
          li {
            @apply ketch-pl-c15;
            &:not(:first-of-type) {
              @apply ketch-pt-c5;
            }
            &:before {
              @apply ketch-inline-block ketch-whitespace-nowrap ketch-left-c30 ketch-absolute;
              content: '\2022';
            }
          }
        }
        .row-item {
          @apply ketch-border-b ketch-border-border-color ketch-py-c10 ketch-px-c15 ketch-cursor-pointer;
          &:last-of-type {
            @apply ketch-border-b-0;
          }
          .lesson .title,
          .date {
            @apply ketch-font-bold;
          }
          .lesson li,
          .date,
          .comment,
          .delete {
            @apply ketch-text-primary-text-color ketch-text-opacity-[0.6];
          }
          .delete {
            @apply ketch-cursor-pointer hover:ketch-underline ketch-h-[fit-content] ketch-w-[fit-content];
          }
        }
      }
    }
  }
</style>
