<script setup lang="ts">
  import { computed, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useShopifyClient from '@/composables/useShopifyClient'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import { DateTime } from 'luxon'

  const { course } = useCourse()
  const { createShopifyCheckout } = useShopifyClient()
  const { coursePurchaseIntended } = useSegment()
  const { translateString } = useI18n()

  defineProps({
    formattedProductAmount: {
      type: String,
      required: true,
    },
  })

  const initiatingCheckout = ref(false)

  const iddCourses = computed(() => CourseModule.iddCourses)

  const accessIDDCoursesFrom = computed(() =>
    translateString('courses.accessIDDCoursesFrom', { currentYear: DateTime.now().year }),
  )

  const closeModal = () => {
    eventBus.$emit('close-modal')
  }

  const initiateShopifyCheckout = () => {
    initiatingCheckout.value = true
    coursePurchaseIntended(course.value!.id, course.value!.title, 'course_details_page')
    createShopifyCheckout()
  }
</script>

<template lang="pug">
.idd-courses-checkout-modal
  h3.title-with-underline {{ $t('checkout.iddCourseSubscription') }}
  .idd-courses
    h4.subscribe-info {{ $t('checkout.subscribeToOurIDDCourses') }}
    ul
      li(
        :key='course.id',
        v-for='course in iddCourses'
      )
        router-link.h5(
          :to='{ name: "CourseDetails", params: { courseId: course.id } }',
          @click.native='closeModal'
        ) {{ course.title }}
  .cta-wrapper
    .cta
      KetchUpButton.primary(
        :disabled='initiatingCheckout',
        @click.stop.native='initiateShopifyCheckout'
      )
        LoadingSpinner(v-if='initiatingCheckout')
        h5(v-else) {{ $t('checkout.amountPerYear', { amount: formattedProductAmount }) }}
    .access-info
      h6 {{ accessIDDCoursesFrom }}
</template>

<style scoped lang="postcss">
  .idd-courses-checkout-modal {
    .idd-courses {
      @apply ketch-mb-c20;
      .subscribe-info {
        @apply ketch-mb-c10;
      }
      ul {
        @apply ketch-pl-c20;
        li {
          @apply ketch-flex ketch-mb-c5;
          .h5 {
            @apply ketch-underline ketch-underline-offset-2;
          }
        }
        > li:before {
          @apply ketch-mr-c10;
          content: '\26AC';
        }
      }
    }
    .cta-wrapper {
      @apply ketch-w-full ketch-flex ketch-flex-col ketch-items-end ketch-space-y-c5;
    }
  }
</style>
