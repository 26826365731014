<template lang="pug">
.new-release-version
    transition(name='fade')
        .new-version(v-if='isNewReleaseAvailable')
            h4.title {{ $t('release.newAppVersion') }}
            .message.h5
                span {{ $t('release.releaseAvailable') }}
                a(@click.prevent='reload') {{ $t('release.reload') }}
                span {{ $t('release.newExperience') }}
            p.h6 {{ $t('release.automaticallyReload', { reloadTimeValue }) }}
</template>

<script setup lang="ts">
  import { onBeforeUnmount, reactive, ref } from 'vue'
  import VersionApi from '@/services/api/VersionApi'
  import CookieHelper from '@/helpers/CookieHelper'
  import { DateTime } from 'luxon'

  const isNewReleaseAvailable = ref(false)
  const intervalTimerId = ref(0)
  const timeoutTimerId = ref(0)
  let version: { [key: string]: string } | null = reactive({
    commitId: '',
  })
  const reloadTimeValue = ref(0)

  const reload = (time: number) => {
    if (timeoutTimerId.value) {
      window.clearTimeout(timeoutTimerId.value)
    }
    timeoutTimerId.value = window.setTimeout(
      () => {
        CookieHelper.removeCookie('release')
        if (version) localStorage.setItem('version', version.commitId)
        //@ts-ignore
        window.location.reload(true)
      },
      (time || 0) * 60000,
    )
  }

  const reloadTime = () => {
    const diff = getTimeDiffFromCookie()
    const time = parseInt(process.env.VUE_APP_RELEASE_NOTIFICATION_FORCE_RELOAD!)
    const timeLeft = time / 60000 - diff
    reloadTimeValue.value = timeLeft
    return timeLeft
  }

  const getMetaValue = (metaName: string) => {
    const metas = document.getElementsByTagName('meta')

    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].getAttribute('content')
      }
    }

    return ''
  }

  const checkAppVersion = () => {
    if (process.env.APP_ENV !== 'development') {
      intervalTimerId.value = window.setInterval(async () => {
        const metaVersion = getMetaValue('version')
        try {
          // { commitId: process.env.GIT_COMMIT_ID! }
          version = await VersionApi.getAppVersion()
        } catch (error) {
          version = { commitId: metaVersion || '' }
        }
        const oldCommitId = localStorage.getItem('version')

        if (!oldCommitId || oldCommitId === version.commitId || metaVersion !== oldCommitId) {
          isNewReleaseAvailable.value = false
          localStorage.setItem('version', version.commitId)
          CookieHelper.removeCookie('release')
          return
        }
        if (oldCommitId !== version.commitId) {
          isNewReleaseAvailable.value = true
          setCookie()
          reload(reloadTime())
        }
      }, parseInt(process.env.VUE_APP_RELEASE_NOTIFICATION_CHECK_INTERVAL!))
    }
  }

  const getTimeDiffFromCookie = () => {
    const cookie = CookieHelper.getCookieValue('release')
    if (cookie) {
      const createdDate = DateTime.fromISO(cookie.created)
      const now = DateTime.utc()
      return Math.floor(now.diff(createdDate, ['minutes']).minutes)
    }
    return 0
  }

  const checkCookieAndReload = () => {
    const cookie = CookieHelper.getCookieValue('release')
    if (cookie) {
      reload(reloadTime())
    }
  }

  const setCookie = () => {
    const cookie = CookieHelper.getCookieValue('release')
    if (isNewReleaseAvailable.value && !cookie) {
      DateTime.DATETIME_SHORT
      CookieHelper.setCookieValue('release', JSON.stringify({ created: DateTime.utc().toISO() }))
    }
  }

  checkCookieAndReload()
  checkAppVersion()

  onBeforeUnmount(() => {
    if (intervalTimerId.value) window.clearInterval(intervalTimerId.value)
    if (timeoutTimerId.value) window.clearTimeout(timeoutTimerId.value)
  })
</script>

<style lang="postcss" scoped>
  .new-version {
    @apply ketch-p-c15 ketch-border;
    @apply ketch-bg-white ketch-text-gray-700 ketch-rounded-md ketch-shadow-d-sm ketch-max-w-[250px];

    .title {
      @apply ketch-font-bold ketch-mb-c10;
    }
    .message a {
      @apply ketch-mx-c5 ketch-text-blue-700 ketch-cursor-pointer;
    }
    p {
      @apply ketch-mt-c15 ketch-text-red-500;
    }
  }
</style>
