<script setup lang="ts">
  import { onMounted, ref } from 'vue'

  const active = ref(false)

  const props = defineProps({
    value: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['toggled'])

  const toggleSwitch = () => {
    active.value = !active.value
    emit('toggled', active.value)
  }

  onMounted(() => {
    active.value = props.value
  })
</script>

<template lang="pug">
.switch-wrap(@click='toggleSwitch')
  .switch(:class='{ active }')
    .switch-toggle(:class='{ "ketch-translate-x-[14px]": active }')
</template>

<style lang="postcss">
  .switch-wrap {
    @apply ketch-w-c30 ketch-h-c16 ketch-cursor-pointer;
    .switch {
      @apply ketch-flex ketch-items-center ketch-rounded-[50%/16px] ketch-p-c2 ketch-duration-300 ketch-ease-in-out;
      &.active {
        @apply ketch-bg-editor-primary-color;
      }
      &:not(.active) {
        @apply ketch-bg-gray-470;
      }
      .switch-toggle {
        @apply ketch-bg-white ketch-w-c12 ketch-h-c12 ketch-rounded-full ketch-shadow-md;
        @apply ketch-transform ketch-duration-300 ketch-ease-in-out;
      }
    }
  }
</style>
