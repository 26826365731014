<template lang="pug">
.login-page.auth-flow(class='!ketch-min-h-fit')
    .auth-flow-container
        h1 {{ showRegisterForm ? $t('onboarding.register') : $t('onboarding.login') }}
        h4.description {{ $t('onboarding.description') }}
        .register-form(v-if='showRegisterForm')
            Signup
            .left-column-ctas
                .login-here
                    h5
                        span {{ $t('onboarding.alreadyHaveAnAccount') }}
                        br
                        span(@click='showRegisterForm = !showRegisterForm') {{ $t('onboarding.loginHere') }}
        form.login-form(
            @submit.prevent='signInDirectConnection',
            v-else
        )
            .form-wrapper
                .auth-errors(v-if='errorMessage')
                    h5.error {{ errorMessage }}
                .form-group
                    label.h3(for='email') Email
                    input#email.h3(
                        :class='{ "has-error": emailErrors[0] }',
                        @blur='$v.userEmail.$touch()',
                        @input='$v.userEmail.$touch()',
                        autofocus,
                        placeholder='name@email.com',
                        required,
                        type='text',
                        v-model='form.userEmail'
                    )
                    h5.error(v-if='emailErrors.length') {{ emailErrors[0] }}
                .form-group
                    label.h3(for='password') {{ $t('onboarding.password') }}
                    input#password.h3(
                        :class='{ "has-error": passwordErrors[0] }',
                        @blur='$v.password.$touch()',
                        @input='$v.password.$touch()',
                        placeholder='************',
                        required,
                        type='password',
                        v-model='form.password'
                    )
                    h5.error(v-if='passwordErrors.length') {{ passwordErrors[0] }}
                KetchUpButton.primary(
                    :class='{ "default-academy": isKetchUpCompany }',
                    :disabled='submitting'
                )
                    LoadingSpinner(v-if='submitting')
                    h3(v-else) {{ $t('onboarding.login') }}
                .rule
                    hr
                    span.h4 {{ $t('or') }}
                .oauth-connections
                    .facebook(
                        @click='signInWithFacebookConnection',
                        v-if='false'
                    )
                        img(:src='facebookAvatar')
                        h4 {{ $t('onboarding.facebookLogin') }}
                    .google(@click='signInWithGoogleConnection')
                        img(:src='googleAvatar')
                        h4 {{ $t('onboarding.googleLogin') }}
            .left-column-ctas
                .register-here
                    h5
                        span {{ $t('onboarding.noAccountYet') }}
                        br
                        span.cta(
                            @click='showRegisterForm = !showRegisterForm',
                            data-cy='registerHere'
                        ) {{ $t('onboarding.registerHere') }}
                .reset-password
                    h5
                        span {{ $t('onboarding.forgotPassword') }}
                        br
                        router-link.cta(:to='{ name: "ResetPassword" }') {{ $t('onboarding.requestNewPassword') }}
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import Auth0Api from '@/services/api/Auth0Api'
  import useAuthentication from '@/composables/useAuthentication'
  import Signup from '@/components/Signup.vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import useI18n from '@/composables/useI18n'
  import useCommonMixin from '@/composables/useCommonMixin'

  const {
    validator,
    emailErrors,
    passwordErrors,
    signInWithGoogleConnection,
    signInWithFacebookConnection,
    form,
    redirect,
    tokenKey,
  } = useAuthentication()
  const { facebookAvatar, googleAvatar } = useIcons()
  const { translateString } = useI18n()
  const { setIsLoadingComponentViewData, isKetchUpCompany } = useCommonMixin()

  const errors = ref([] as { message: string; items: string[] | null }[])
  const errorMessage = ref('')
  const submitting = ref(false)
  const showRegisterForm = ref(false)

  const $v = computed(() => validator.value)

  const signInDirectConnection = () => {
    $v.value.$touch()
    errors.value = []
    if (!$v.value.$invalid) {
      submitting.value = true
      Auth0Api.dbConnectionSignin(form.value.userEmail, form.value.password)
        .then((data) => {
          CookieHelper.setCrossDomainCookie(tokenKey.value, data.access_token)
          window.location.href = `/profile-page?redirect=${redirect.value}`
        })
        .catch(() => {
          submitting.value = false
          errorMessage.value = translateString('onboarding.errors.wrongEmailOrPassword')
        })
    }
  }

  onMounted(() => {
    setIsLoadingComponentViewData(false)
  })
</script>

<style lang="postcss">
  .login-page {
    .login-form,
    .register-form {
      @apply ketch-flex ketch-flex-col md:ketch-flex-row;
      .form-wrapper {
        @apply ketch-space-y-c20 ketch-flex-1;
        .loading .dot {
          @apply ketch-bg-white;
        }
        .auth-errors {
          @apply ketch-p-c10 ketch-border ketch-border-red-500 ketch-bg-red-50 ketch-rounded-small;
          .error {
            @apply ketch-text-red-500;
          }
        }
        .form-group {
          @apply ketch-flex ketch-flex-col;
          label {
            @apply ketch-mb-c5 ketch-font-bold;
          }
          input {
            @apply ketch-border ketch-px-c15 ketch-py-c10 focus:ketch-border-primary-color;
            @apply ketch-outline-none ketch-rounded-small;
          }
          .error {
            @apply ketch-text-red-500;
          }
          .has-error {
            @apply ketch-border-red-500;
          }
        }
        button {
          @apply ketch-w-full ketch-rounded-small ketch-border-none ketch-h-c50;
          &.default-academy {
            @apply ketch-bg-black;
          }
        }
        .rule {
          @apply ketch-relative ketch-py-c15;
          span {
            @apply ketch-absolute ketch-px-c20 ketch-bg-background-color ketch-bottom-c5;
            left: calc(50% - 28px);
          }
        }
        .oauth-connections {
          .google,
          .facebook {
            @apply ketch-flex ketch-items-center ketch-px-c10 ketch-py-c5 ketch-border;
            @apply ketch-cursor-pointer ketch-rounded-small;
            img {
              @apply ketch-mr-c2 ketch-w-c35 ketch-h-c35;
            }
          }
          .google {
            @apply hover:ketch-border-gray-400 ketch-bg-white;
          }
          .facebook {
            @apply ketch-text-white ketch-mb-c10 hover:ketch-bg-blue-900;
            background-color: #3b5998;
            img {
              @apply ketch-w-c45 ketch-h-c45 ketch-p-c10;
            }
          }
        }
      }
    }
    .login-form .register-here,
    .login-form .reset-password,
    .register-form .login-here {
      h5 {
        :last-child {
          @apply ketch-underline ketch-cursor-pointer ketch-pl-c5 md:ketch-pl-0;
        }
        br {
          @apply ketch-hidden md:ketch-block;
        }
      }
    }
    .login-form {
      .reset-password {
        h5 {
          @apply ketch-text-primary-text-color ketch-text-opacity-50;
        }
      }
    }
    .register-form {
      .signup-wrapper {
        @apply ketch-py-0;
      }
      .signup-form {
        @apply ketch-bg-transparent;
      }
    }
  }
</style>
