<script setup lang="ts">
  import Dropdown from '@/components/common/Dropdown.vue'
  import Choice from '@/components/common/Choice.vue'
  import PhoneNumberInput from '@/components/common/PhoneNumberInput.vue'
  import ExpandableTextAreaInput from '@/components/common/ExpandableTextAreaInput.vue'
  import type { PropType } from 'vue'
  import type { ProfileQuestionAnswer, StructuredCompanyProfileQuestion } from '@/services/interfaces/Auth0'
  import { computed } from 'vue'
  import type { DropdownItem, SvgRendererProps } from '@/services/interfaces/Common'
  import type { QuestionAnswer } from '@/services/interfaces/QuizQuestion'
  import useIcons from '@/composables/useIcons'

  const { checkedCheckboxSquareWhite, checkboxSquareWhite } = useIcons()

  const props = defineProps({
    question: {
      type: Object as PropType<StructuredCompanyProfileQuestion>,
      required: true,
    },
    selectedAnswers: {
      type: Array as PropType<QuestionAnswer[]>,
      required: true,
    },
    onAnswerChoiceClick: {
      type: Function as PropType<
        (selectedAnswers: QuestionAnswer[], choiceId: string, questionId: string, type?: string) => void
      >,
      required: true,
    },
    setAnswer: {
      type: Function as PropType<(questionId: string, text: string, answerType: 'phone' | 'text') => void>,
      required: true,
    },
    showErrorText: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    questionTextAnswer: {
      type: String,
      default: '',
    },
  })

  const dropdownChoices = computed(() => {
    return (questionId: string, answers: ProfileQuestionAnswer[]): DropdownItem[] => {
      return answers.map((answer) => ({
        type: answer.id,
        title: answer.answer,
        selectable: true,
      }))
    }
  })

  const checkSelectedChoices = computed(() => (questionId: string, answerId: string) => {
    if (!props.selectedAnswers.length) return false
    return props.selectedAnswers.some((q) => q.questionId === questionId && q.selectedAnswerIds.includes(answerId))
  })

  const selectedChoice = computed(() => (questionId: string, choiceId: string): SvgRendererProps => {
    return checkSelectedChoices.value(questionId, choiceId)
      ? { icon: checkedCheckboxSquareWhite.value, strokeColor: 'var(--primary-text-color)' }
      : { icon: checkboxSquareWhite.value, strokeColor: 'var(--primary-text-color)' }
  })

  const selectedDropdownChoice = computed(
    () => (questionId: string) =>
      props.selectedAnswers?.find((a) => a.questionId === questionId)?.selectedAnswerIds[0] ?? '',
  )
</script>

<template lang="pug">
.profile-question
    h3(:class='{ "required-input": question.mandatory }') {{ question.name }}
    .dropdown-question-wrapper(v-if='question.type === "dropdown"')
        Dropdown(
            :items='dropdownChoices(question.id, question.answers)',
            :value='selectedDropdownChoice(question.id)',
            @input='onAnswerChoiceClick(selectedAnswers, $event, question.id, question.type)',
            default-dropdown-behavior
        )
    .multiple-type-questions-wrapper(v-if='question.type === "multiple"')
        .choice-wrapper
            Choice(
                :key='choice.id',
                :selected='checkSelectedChoices(question.id, choice.id)',
                :svg-renderer-props='selectedChoice(question.id, choice.id)',
                :title='choice.answer',
                @click.native='onAnswerChoiceClick(selectedAnswers, choice.id, question.id)',
                v-for='choice in question.answers'
            )
    .phone-number-wrapper(v-if='question.type === "phone_input"')
        PhoneNumberInput(
            :phone-number='phoneNumber',
            @phone-input='setAnswer(question.id, $event, "phone")'
        )
    .text-question-wrapper(v-if='question.type === "text"')
        ExpandableTextAreaInput.text-input(
            :text-message='questionTextAnswer',
            @text-change='setAnswer(question.id, $event, "text")'
        )
</template>

<style scoped lang="postcss">
  .profile-question {
    @apply ketch-flex ketch-flex-col ketch-space-y-c10;
    h3.required-input::after {
      content: ' *';
      color: red;
    }
    .dropdown-question-wrapper {
      @apply ketch-flex ketch-items-center ketch-space-x-c10;
      .dropdown-choices {
        @apply ketch-flex ketch-flex-col;
      }
    }
    .multiple-type-questions-wrapper,
    .text-question-wrapper,
    .phone-number-wrapper {
      h3 {
        @apply ketch-mb-c15;
      }
      .choice-wrapper {
        @apply ketch-flex ketch-flex-col ketch-space-y-c10;
        .choice {
          @apply ketch-w-full;
        }
      }
      .text-input {
        @apply ketch-rounded-normal;
      }
    }
  }
</style>
