<script setup lang="ts">
  import type { CourseItem } from '@/services/interfaces/Course'
  import type { PropType } from 'vue'
  import { VPopover } from 'v-tooltip'
  import { computed, ref, watch } from 'vue'
  import { useRouter } from 'vue-router/composables'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { formatDate, courseExpiryDuration } = useCommonMixin()

  const router = useRouter()

  const props = defineProps({
    course: {
      type: Object as PropType<CourseItem>,
      required: true,
    },
  })

  const emit = defineEmits(['start-exam-cta-clicked'])

  const hoveringStartExamCTA = ref(false)
  const showExpiredCourseTooltip = ref(false)

  const expired = computed(() => props.course?.expired)

  const expiredAt = computed(() => props.course?.expiredAt ?? null)

  watch(hoveringStartExamCTA, (value) => {
    showExpiredCourseTooltip.value = value && expired.value
  })

  const routeToExam = () => {
    if (expired.value) return

    emit('start-exam-cta-clicked')
    router.push(`/courses/${props.course.id}/exam/${props.course.exam.id}/start`).catch(() => {
      return
    })
  }
</script>

<template lang="pug">
  VPopover(
      :disabled='!showExpiredCourseTooltip',
      :open='showExpiredCourseTooltip',
      :auto-hide='false'
  )
    .start-exam(
      data-cy='start-exam-link',
      :class="{ 'disabled': expired }",
      @click='routeToExam',
      @mouseover='hoveringStartExamCTA = true',
      @mouseleave='hoveringStartExamCTA = false',
    )
      h5 {{ $t('courses.examSimulation.start') }}
    template(#popover)
      h6 {{ $t("courses.courseExpiredInfo", { expiredDate: formatDate(expiredAt, 'dd.MM.yyyy'), expiryDuration: courseExpiryDuration }) }}
</template>

<style lang="postcss">
  .v-popover {
    .trigger {
      @apply ketch-w-full;
      .start-exam {
        @apply ketch-bg-secondary-color ketch-text-secondary-foreground-color ketch-rounded-normal;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-h-[44px] ketch-px-c20 ketch-cursor-pointer;

        &.disabled {
          @apply ketch-opacity-50;
        }
      }
    }
  }
</style>
