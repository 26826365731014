import axios from './interceptor'
import { FolderItemStruct } from '../interfaces/Folder'
import { NewsItem } from '@/services/interfaces/Content'

export default class CuratedContentApi {
  static async getContent(rootFolderId: string): Promise<FolderItemStruct> {
    return axios
      .get(`/folders/${rootFolderId}`)
      .then((resp) => (resp.data as any).folderContent)
      .catch((err) => Promise.reject(err))
  }

  static async postInterestedCourse(courseId: string): Promise<number> {
    return axios
      .post('/interested_course', {
        courseId,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getNews(): Promise<NewsItem[]> {
    return axios
      .get(`/news`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
