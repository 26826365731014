<template lang="pug">
.course-progress-bar
    span.marker(
        :class='{ fill: completedModules[index - 1] }',
        :key='index',
        v-for='index in numberOfModules'
    )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { CourseModule } from '@/store/modules/course'
  import type { PropType } from 'vue'
  import type { CourseGroupItem } from '@/services/interfaces/Course'

  const props = defineProps({
    courseId: {
      required: true,
      type: String,
    },
    courseGroupId: String,
    combineCourseGroups: {
      default: false,
    },
    courseGroups: {
      type: Array as PropType<CourseGroupItem[]>,
      default: null,
    },
  })

  const completedModules = computed(() => {
    if (props.combineCourseGroups) {
      const states: boolean[] = []
      if (props.courseGroups.length) {
        props.courseGroups.forEach((group) => {
          states.push(...CourseModule.completedModuleStates(props.courseId, group.id))
        })
      } else {
        states.push(...CourseModule.completedModuleStates(props.courseId))
      }
      return states
    }
    return CourseModule.completedModuleStates(props.courseId, props.courseGroupId)
  })

  const numberOfModules = computed(() => {
    if (props.combineCourseGroups) {
      const states: number[] = []
      if (props.courseGroups.length) {
        props.courseGroups.forEach((group) => {
          states.push(CourseModule.numberOfCourseModules(props.courseId, group.id))
        })
      } else {
        states.push(CourseModule.numberOfCourseModules(props.courseId))
      }
      return states.reduce((acc, value) => {
        acc += value
        return acc
      }, 0)
    }
    return CourseModule.numberOfCourseModules(props.courseId, props.courseGroupId)
  })
</script>

<style lang="postcss" scoped>
  .course-progress-bar {
    @apply ketch-flex ketch-justify-between ketch-items-center ketch-w-full;
    .marker {
      @apply ketch-inline-block ketch-border ketch-border-border-color ketch-w-full ketch-h-c12 ketch-relative;
      &:first-of-type {
        @apply ketch-rounded-l-md;
      }
      &:last-of-type {
        @apply ketch-rounded-r-md;
      }
      &.fill {
        @apply ketch-bg-primary-color ketch-border-primary-color;
      }
    }
  }
</style>
