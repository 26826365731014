import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import { CourseSearchItem } from '@/services/interfaces/Course'
import BookmarkApi from '@/services/api/BookmarkApi'

@Module({ name: 'bookmarks', store, dynamic: true })
class Bookmarks extends VuexModule {
  public courseLessonBookmarks: CourseSearchItem[] = []

  get bookmarks() {
    if (!this.courseLessonBookmarks) return {}
    return this.courseLessonBookmarks.reduce(
      (acc, value) => {
        acc[value.id] = value
        return acc
      },
      {} as { [key: string]: CourseSearchItem },
    )
  }

  @Mutation
  private SET_BOOKMARKS(bookmarks: CourseSearchItem[] | []) {
    this.courseLessonBookmarks = bookmarks
  }

  @Action
  getUserBookmarks() {
    return BookmarkApi.getAllUserBookmarks()
      .then((bookmarks) => {
        this.SET_BOOKMARKS(bookmarks)
      })
      .catch(() => [])
  }
}

export const BookmarksModule = getModule(Bookmarks)
