import axios from './interceptor'
import { CompanyTheme } from '../interfaces/Theme'

export default class ThemeApi {
  static async getTheme(companyId: string): Promise<CompanyTheme> {
    return axios
      .get(`/companies/${companyId}/themes`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }
}
