<template lang="pug">
.course-exam-header
    .course-exam-header-wrapper
        h2.title {{ $t('courses.examSimulation.title') }}
        .progress-bar(v-if='!isSmallerMobileDevice')
            MultipleProgressBar(
                :progress-bar-options='progressBarOptions',
                :total-questions='totalExamQuestions',
                v-if='progressBarOptions'
            )
        .cta(v-if='!loadingExamContent')
            .continue(
                :class='{ disabled: continueExamRequest }',
                @click='$emit("continue-exam")',
                v-if='showContinueExam'
            )
                SVGRenderer(
                    :has-hover='false',
                    :icon='playBtn',
                    fill-color='var(--primary-text-color)'
                )
                h5 {{ $t('courses.examSimulation.continue') }}
            .pause(
                @click='$emit("pause-or-end-exam")',
                data-cy='pause-exam',
                v-else-if='!isPartOneDone'
            )
                template(v-if='showEndExam')
                    h5 {{ $t('courses.examSimulation.end') }}
                template(v-else)
                    SVGRenderer(
                        :has-hover='false',
                        :icon='pauseBtn',
                        fill-color='var(--primary-text-color)'
                    )
                    h5 {{ $t('courses.examSimulation.pause') }}
    .progress-bar-mobile(v-if='isSmallerMobileDevice')
        .progress-bar
            MultipleProgressBar(
                :progress-bar-options='progressBarOptions',
                :total-questions='totalExamQuestions',
                v-if='progressBarOptions'
            )
</template>

<script setup lang="ts">
  import MultipleProgressBar from '@/components/common/MultipleProgressBar.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import type { CourseProgress } from '@/services/interfaces/Course'
  import { computed } from 'vue'
  import type { PropType } from 'vue'

  const props = defineProps({
    loadingExamContent: {
      type: Boolean,
      required: true,
    },
    progressBarOptions: {
      type: Array as PropType<CourseProgress[]>,
      required: true,
    },
    isPartOneDone: {
      type: Boolean,
      required: true,
    },
    noQuestionsAnsweredYet: {
      type: Boolean,
      default: true,
    },
    continueExamRequest: {
      type: Boolean,
      required: true,
    },
  })

  defineEmits(['continue-exam', 'pause-or-end-exam'])

  const { playBtn, pauseBtn } = useIcons()
  const { inCourseExamResultsPage, isCourseExamPaused, totalExamQuestions, reviewingQuestion } = useCourse()
  const { isSmallerMobileDevice } = useBreakpoint()

  // const isFirstQuestion = computed(() => currentExamQuestion.value?.questionNumber === 1)

  const showContinueExam = computed(
    () =>
      isCourseExamPaused.value && !props.isPartOneDone && !inCourseExamResultsPage.value && !reviewingQuestion.value,
  )

  const showEndExam = computed(
    () => inCourseExamResultsPage.value || (reviewingQuestion.value ? true : props.noQuestionsAnsweredYet),
  )
</script>

<style lang="postcss" scoped>
  .course-exam-header {
    @apply ketch-flex ketch-flex-col ketch-px-c20 ketch-pt-c15 ketch-h-c100 ketch-border-b ketch-border-border-color;
    @apply sm:ketch-h-c80 sm:ketch-px-c30;
    .course-exam-header-wrapper {
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-mb-c10;
      @apply sm:ketch-grid sm:ketch-grid-cols-3 sm:ketch-place-content-center sm:ketch-mb-0;
      .title {
        @apply ketch-font-bold;
      }
      .progress-bar {
        @apply ketch-place-self-center;
        .multiple-progress-bar {
          @apply ketch-mt-[7px];
        }
      }
      .cta {
        @apply ketch-flex ketch-items-center ketch-justify-end;
        .pause,
        .continue {
          @apply ketch-space-x-c12 ketch-flex ketch-items-center ketch-cursor-pointer;
        }
        .continue {
          &.disabled {
            @apply ketch-pointer-events-none ketch-opacity-50;
          }
        }
      }
    }
    .progress-bar-mobile {
      @apply ketch-flex ketch-items-center ketch-justify-center;
    }
  }
</style>
