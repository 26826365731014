import { Ref, ref, computed, ComputedRef, watch, nextTick } from 'vue'
import { useVuelidate, Validation } from '@vuelidate/core'
import { required, requiredIf, email } from '@vuelidate/validators'
import eventBus from '@/main'

interface useUserManagementReturn {
  inputItems: Ref<string[]>
  setInputItems: (items: string[], field: string) => void
  formState: Ref<{ [key: string]: string }>
  onInputChange: ComputedRef<(inputKey: string) => void>
  validator: ComputedRef<Validation<{ [key: string]: any }>>
  hasValidationError: ComputedRef<(field: string) => boolean>
  filteredFormState: ComputedRef<string[]>
}

export default function useUserManagement(): useUserManagementReturn {
  const inputItems = ref([] as string[])
  const fieldValue = ref('')
  const setInputItems = (items: string[], field: string) => {
    inputItems.value = items
    fieldValue.value = field
  }
  const formState = ref({} as { [key: string]: string })
  const filteredFormState = computed(() => Object.values(formState.value).filter((value) => value.trim()))
  const checkIfNewInputCanBeAdded = () => {
    const newFieldCanBeAdded = filteredFormState.value.length === inputItems.value.length && !$v.value.$invalid
    if (newFieldCanBeAdded) {
      const newField = `${fieldValue.value}${inputItems.value.length + 1}`
      inputItems.value.push(newField)
      eventBus.$set(formState.value, newField, '')
    }
  }

  const onInputChange = computed(() => {
    return (inputKey: string) => {
      $v.value[inputKey].$touch()
      checkIfNewInputCanBeAdded()
    }
  })

  const rules = computed(() => {
    return inputItems.value.reduce(
      (acc, field, index) => {
        if (index === 0) acc[field] = { required }
        else {
          acc[field] = {
            required: requiredIf(function (value: string) {
              return !!value?.length
            } as any),
          }
        }
        if (fieldValue.value === 'email') {
          acc[field].email = email
        }
        return acc
      },
      {} as { [key: string]: any },
    )
  })

  let $v = useVuelidate(rules.value, formState.value)
  watch(
    () => inputItems.value,
    () => {
      inputItems.value.forEach((field) => {
        if (!formState.value[field]) {
          eventBus.$set(formState.value, field, '')
        }
      })
      nextTick(() => ($v = useVuelidate(rules.value, formState.value)))
    },
  )
  const hasValidationError = computed(() => {
    return (field: string) => {
      const inputField = ($v.value as any)[field]
      if (!inputField?.$dirty) return false
      if (inputField.required.$invalid || inputField.email?.$invalid) return true
      return false
    }
  })
  const validator = computed(() => $v.value)

  return {
    validator,
    setInputItems,
    inputItems,
    formState,
    onInputChange,
    hasValidationError,
    filteredFormState,
  }
}
