interface useVideoReturn {
  getPosterImage: (videoUrl: string, thumbnailTimestamp?: number, width?: number) => string
}

export default function useVideo(): useVideoReturn {
  const getPosterImage = (videoUrl: string, thumbnailTimestamp = 0, width = 500): string => {
    if (!videoUrl) return ''
    const videoFile = videoUrl.split('/').pop() as string
    const playbackId = videoFile.replace('.m3u8', '')
    const url = `https://image.mux.com/${playbackId}/thumbnail.png`
    const time = thumbnailTimestamp ? '&time=' + thumbnailTimestamp : ''
    return `${url}?fit_mode=preserve&width=${width}${time}`
  }

  return {
    getPosterImage,
  }
}
