import { render, staticRenderFns } from "./FollowupModal.vue?vue&type=template&id=5de2b823&lang=pug"
import script from "./FollowupModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./FollowupModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FollowupModal.vue?vue&type=style&index=0&id=5de2b823&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports