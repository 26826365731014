<template lang="pug">
TextRenderer(
    :edit-sub-title='$t("courses.videoTranscript.updateTranscript")',
    :edit-title='$t("courses.videoTranscript.transcriptToVideo")',
    :mutate-and-queue-func='mutateAndQueueFunc',
    :source='videoTranscript || ""',
    editor-property='videoTranscript',
    editor-state='currentLesson',
    v-if='inEditorMode'
)
.video-transcript-wrapper(v-else-if='videoTranscript')
    .transcript-title-cta
        h5.transcript-title {{ $t('courses.videoTranscript.transcriptToVideo') }}
        h5.show-less(
            @click='toggleTranscriptClamp',
            v-if='!transcriptClamped && shouldClampTranscript'
        ) {{ $t('courses.videoTranscript.showLess') }}
    .video-transcript
        .transcript(ref='videoTranscriptElement')
            HTMLRenderer(
                :content-clamped='transcriptClamped',
                :html='videoTranscript',
                link-target='_blank'
            )
        h5.show-all-or-less(
            @click='toggleTranscriptClamp',
            v-if='shouldClampTranscript'
        ) {{ showAllOrLess }}
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch, nextTick } from 'vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { Nullable } from '@/services/interfaces/QuizQuestion'
  import type { EditorStatePayload } from '@/services/interfaces/Course'

  defineProps({
    videoTranscript: {
      type: String,
      required: true,
    },
    mutateAndQueueFunc: Function as PropType<(data: EditorStatePayload) => void>,
  })

  const { translateString } = useI18n()
  const videoTranscriptElement = ref(null as Nullable<HTMLDivElement>)
  const shouldClampTranscript = ref(false)
  const transcriptClamped = ref(false)
  const { inEditorMode } = useEditor()

  const showAllOrLess = computed(() => {
    return transcriptClamped.value
      ? translateString('courses.videoTranscript.showAll')
      : translateString('courses.videoTranscript.showLess')
  })

  watch(inEditorMode, (value) => {
    if (!value) {
      nextTick(() => {
        clampVideoTranscript()
      })
    }
  })

  const clampVideoTranscript = () => {
    if (videoTranscriptElement.value) {
      const { height } = (videoTranscriptElement.value.firstChild as HTMLElement).getBoundingClientRect()
      shouldClampTranscript.value = height > 40
      if (shouldClampTranscript.value) {
        transcriptClamped.value = true
      }
    }
  }

  const toggleTranscriptClamp = () => {
    transcriptClamped.value = !transcriptClamped.value
  }

  onMounted(() => {
    clampVideoTranscript()
  })
</script>

<style lang="postcss" scoped>
  .video-transcript-wrapper {
    @apply ketch-bg-header-foreground-color ketch-bg-opacity-[0.05] ketch-px-c15;
    @apply ketch-border-l-[3px] ketch-border-secondary-color ketch-flex ketch-flex-col;
    .show-less,
    .show-all-or-less {
      @apply ketch-underline ketch-cursor-pointer ketch-text-secondary-color;
    }
    .transcript-title-cta {
      @apply ketch-flex ketch-items-center ketch-justify-between;
      .transcript-title {
        @apply ketch-bg-secondary-color ketch-text-secondary-foreground-color ketch-py-c5 ketch-w-[fit-content];
        @apply ketch--ml-c15 ketch-px-c15;
      }
    }
    .video-transcript {
      @apply ketch-py-c15 ketch-flex ketch-flex-col ketch-space-y-c10;
    }
  }
</style>
