import { computed } from 'vue'
import { UserModule } from '@/store/modules/user'
import CookieHelper from '@/helpers/CookieHelper'
import { SEEN_PAGE_TOUR, CLOSED_PAGE_HINT, TOURS_AND_HINTS } from '@/helpers/Constants'
import PagesHelpData from '@/views/pages.help.json'
import SachkundegurusHelpData from '@/views/sachkundegurus.help.json'
import useCommonMixin from './useCommonMixin'
import removeAccents from 'remove-accents'
import useSegment from './useSegment'
import { DateTime } from 'luxon'
import useI18n from '@/composables/useI18n'
import eventBus from '@/main'

interface PageOption {
  hints?: PageOptionHint[]
  steps?: PageOptionStep[]
  hintButtonLabel: string
}
interface PageOptionStep {
  title: string
  element: string
  intro: string
}
interface PageOptionHint {
  hint: string
  element: string
  hintAnimation?: boolean
  hintPosition?: string
}

interface useToursAndHintsReturn {
  showOrHideToursAndHints: (timeout?: number) => void
  hideAllHints: () => void
  showTourAfterFirstMarch: (callback: () => void) => void
}

export default function useToursAndHints(): useToursAndHintsReturn {
  const { locale, replaceNonAlphanumericChars, triggerToursAndHints } = useCommonMixin()
  const { nextTourTipRequested } = useSegment()
  const { translateString } = useI18n()

  const showIntroJsTour = (steps?: PageOptionStep[]) => {
    if (steps && UserModule.hasValidUser) {
      let stepNum = 1

      const introJs = (window as any).introJs
      introJs()
        .setOptions({
          steps: steps,
          prevLabel: translateString('previous'),
          nextLabel: translateString('next'),
          doneLabel: translateString('done'),
        })
        .onafterchange(() => {
          nextTourTipRequested(eventBus.$route.name as string, stepNum++)
        })
        .onexit(() => {
          CookieHelper.setCookieValue(SEEN_PAGE_TOUR.replace('{route_name}', currentPage.value), 'true')
        })
        .start()
    }
  }

  const showIntroJsHints = (hintButtonLabel: string, hints?: PageOptionHint[]): void => {
    if (hints) {
      const introJs = (window as any).introJs
      introJs()
        .setOptions({ hints: hints, hintButtonLabel: hintButtonLabel })
        .addHints()
        .showHints()
        .onhintclose((hintIndex: number) => {
          const closedHintKey = CLOSED_PAGE_HINT.replace('{route_name}', currentPage.value)
          const closedHints = CookieHelper.getCookieValue(closedHintKey) || []
          closedHints.push(hintIndex)
          CookieHelper.setCookieValue(closedHintKey, JSON.stringify(closedHints))
        })

      hideClosedHints()
    }
  }

  const hideAllHints = (): void => {
    const introJs = (window as any).introJs
    introJs().hideHints()
  }

  const hideIntroJsHint = (hintId: number): void => {
    const introJs = (window as any).introJs
    introJs().hideHint(hintId)
  }

  const hideClosedHints = (): void => {
    const closedHintKey = CLOSED_PAGE_HINT.replace('{route_name}', currentPage.value)
    const closedHints = CookieHelper.getCookieValue(closedHintKey) || []
    closedHints?.forEach((hintId: number) => {
      hideIntroJsHint(hintId)
    })
  }

  const pageDataOptions = computed((): PageOption | undefined => {
    const companies: { [key: string]: { [key: string]: PageOption } } = {
      sachkundegurus: SachkundegurusHelpData[locale.value],
    }
    let pageData: { [key: string]: PageOption } | null = null
    if (UserModule.currentCompany) {
      const companySlug = replaceNonAlphanumericChars(removeAccents(UserModule.currentCompany.name)) || ''
      pageData = companies[companySlug.toLowerCase()]
    }
    if (!pageData) pageData = PagesHelpData[locale.value]
    if (pageData[eventBus.$route.name as string]) {
      return structuredClone(pageData[eventBus.$route.name as string])
    }
    return undefined
  })

  const currentPage = computed((): string => {
    return eventBus.$route.name?.toUpperCase() as string
  })

  const filterCurrentPageElements = (pageOptions: PageOption): void => {
    pageOptions.steps = pageOptions.steps?.filter((step: { element: string }) => {
      if (step.element && document.querySelector(step.element)) {
        return step
      }
      return false
    })
    pageOptions.hints = pageOptions.hints?.filter((hint: { element: string }) => {
      if (hint.element && document.querySelector(hint.element)) {
        return hint
      }
      return false
    })
  }

  const showOrHideToursAndHints = (timeout = 2000): void => {
    if (!triggerToursAndHints.value) return
    let i = 0
    let tourSeen = false

    const intervalId = window.setInterval(() => {
      if (i === 4) return window.clearInterval(intervalId)

      const pageOptions = {
        steps: pageDataOptions.value?.steps?.slice(),
        hints: pageDataOptions.value?.hints?.slice(),
        hintButtonLabel: pageDataOptions.value?.hintButtonLabel || '',
      }
      filterCurrentPageElements(pageOptions)

      const showTourAndHints = CookieHelper.getCookieValue(TOURS_AND_HINTS)
      const seenTour = CookieHelper.getCookieValue(SEEN_PAGE_TOUR.replace('{route_name}', currentPage.value))

      if (pageOptions) {
        if (seenTour || (!pageOptions.steps?.length && tourSeen)) {
          delete pageOptions.steps
        }
        if (!pageOptions?.hints?.length) delete pageOptions.hints
        if (showTourAndHints) {
          if (!tourSeen && pageOptions.steps?.length) {
            tourSeen = true
            showIntroJsTour(pageOptions.steps)
          }
          showIntroJsHints(pageOptions.hintButtonLabel, pageOptions.hints)
        } else {
          pageOptions.steps = []
          pageOptions.hints = []
        }
      }

      pageOptions.hints?.forEach((hint, index) => {
        const el = document.querySelector(hint.element) as HTMLDivElement
        const hintEl = document.querySelector(`.introjs-hints > a:nth-child(${index + 1})`) as HTMLAnchorElement
        const rect = el?.getBoundingClientRect() || { top: 0, left: 0 }
        const offsetTop = Math.round(rect.top + document.documentElement.scrollTop)
        const offsetLeft = Math.round(rect.left + document.documentElement.scrollLeft)

        if (hintEl?.style.top !== offsetTop + 'px') hintEl.style.setProperty('top', offsetTop + 'px')
        if (hintEl?.style.left !== offsetLeft + 'px') hintEl.style.setProperty('left', offsetLeft + 'px')
      })
      i++
    }, timeout)
  }

  const showTourAfterFirstMarch = (callback: () => void) => {
    const createdAt = DateTime.fromSQL(UserModule.createdAt)
    const firstMarch = DateTime.fromISO('2022-03-01')
    if (createdAt > firstMarch) {
      callback()
    }
  }

  return {
    showOrHideToursAndHints,
    hideAllHints,
    showTourAfterFirstMarch,
  }
}
