<template lang="pug">
.new-level-congratulations-modal
    .level-up-header-info
        h1 {{ $t('navigation.gamificationPoints.levelUp') }}
        h5 {{ $t('navigation.gamificationPoints.congratulations') }}
    .trophy
        img(:src='trophy')
    h4.current-points {{ currentPoints }}
    .new-level
        h5 {{ $t('navigation.gamificationPoints.yourNewLevel') }}
        h1 {{ newLevelName }}
    h5.next-level {{ nextLevelDetails }}
    KetchUpButton.tertiary(@click.native='$emit("exit-confetti")')
        h5 {{ $t('yeahThankYou') }}
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import useIcons from '@/composables/useIcons'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import useGamification from '@/composables/useGamification'
  import useI18n from '@/composables/useI18n'

  defineEmits(['exit-confetti'])
  const { translateString } = useI18n()
  const { trophy } = useIcons()
  const { userStats, totalPoints, currentLevel } = useGamification()

  const currentPoints = computed(() =>
    translateString('navigation.gamificationPoints.points', { points: totalPoints.value }),
  )

  const newLevelName = computed(() => currentLevel.value?.name)

  const nextLevelName = computed(() => userStats.value?.nextLevelName)

  const nextLevelPoints = computed(() => userStats.value?.level.maximumPoints)

  const nextLevelDetails = computed(() =>
    translateString('navigation.gamificationPoints.nextLevel', {
      name: nextLevelName.value,
      points: nextLevelPoints.value,
    }),
  )
</script>

<style lang="postcss">
  .new-level-congratulations-modal {
    @apply ketch-flex ketch-flex-col ketch-items-center md:ketch-px-c50;
    h1 {
      @apply ketch-font-bold md:ketch-text-[34px];
    }
    .level-up-header-info {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-space-y-[17px] ketch-mb-c55;
    }
    .trophy {
      img {
        @apply ketch-w-[190px] ketch-h-[210px];
      }
    }
    .current-points {
      @apply ketch-font-bold ketch-mt-c10 ketch-mb-c30;
    }
    .new-level {
      @apply ketch-py-c8 ketch-px-c30 ketch-flex ketch-flex-col ketch-items-center ketch-justify-center;
      @apply ketch-bg-primary-color ketch-bg-opacity-20 ketch-border ketch-border-primary-color ketch-rounded-normal;
      h1 {
        @apply ketch-text-center;
      }
    }
    .next-level {
      @apply ketch-text-center ketch-mt-c10 ketch-mb-c40 ketch-opacity-[50%];
    }
  }
</style>
