<script setup lang="ts">
  import GuidedQuiz from '@/views/GuidedQuiz.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import { computed, onMounted, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import type { CoursePackage } from '@/services/interfaces/Course'
  import * as Sentry from '@sentry/vue'

  const { currentCourseId } = useCourse()
  const { setIsLoadingComponentViewData, showGuidedQuizRegisterModal } = useCommonMixin()

  const embeddedQuizLoaded = ref(false)

  const loadingData = computed(
    () =>
      CourseModule.courseBundles === null ||
      CourseModule.course === null ||
      !(CourseModule.courseModules(currentCourseId.value).length > 0),
  )

  const currentPackage = computed(() => {
    const courseBundles = CourseModule.courseBundles as CoursePackage[]
    return courseBundles?.find((bundle) => !!bundle.courses.find((c) => c.id === currentCourseId.value))
  })

  const courseVariant = computed(() => currentPackage.value?.title.replace('Komplettpaket', '').replace(/\s+/g, ''))

  const triggerRegisterModal = () => {
    if (currentPackage.value) {
      const allowedPrefixes = ['34i', '34d', '34f']
      const packageTitle = currentPackage.value.title

      const bundlePrefix = allowedPrefixes.find((prefix) => packageTitle.includes(prefix))

      if (bundlePrefix) {
        showGuidedQuizRegisterModal(bundlePrefix)
      }
    }
  }

  const fetchData = async () => {
    try {
      const promises = [
        await CourseModule.getCourse({ courseId: currentCourseId.value }),
        await CourseModule.getCourseModules(currentCourseId.value),
      ]

      await Promise.all(promises)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const postIframeScrollHeight = () => {
    window.parent.postMessage({ action: 'resizeIframe', height: document.body.scrollHeight }, '*')
  }

  onMounted(() => {
    fetchData()
  })

  watch(loadingData, (value) => {
    if (!value) {
      postIframeScrollHeight()
    }
  })

  watch(embeddedQuizLoaded, (value) => {
    if (value) {
      setTimeout(() => {
        postIframeScrollHeight()
      }, 300)
    }
  })

  setIsLoadingComponentViewData(false)
</script>

<template lang="pug">
.embedded-guided-quiz-wrapper
  LoadingSpinner(v-if='loadingData')
  .ketch-flex.ketch-flex-col.ketch-pt-c20.ketch-space-y-c10.ketch-rounded-2xl(class='ketch-bg-[#080d22]', v-else)
    .quiz-package-details.ketch-px-c20
      h6.ketch-text-primary-color {{ $t('courses.embeddedQuiz.trainingFor', { packageTitle: currentPackage?.jobTitle }) }}
      h3.ketch-text-white.ketch-font-bold {{ $t('courses.embeddedQuiz.examQuestions', { courseVariant }) }}
    .ketch-bg-background-color.ketch-px-c20.ketch-py-c30.ketch-border.ketch-border-border-color.ketch-border-t-0.ketch-rounded-b-2xl
      GuidedQuiz(
        @embedded-quiz-loaded='embeddedQuizLoaded = true',
        @show-guided-quiz-register-modal='triggerRegisterModal',
        is-embedded
      )
</template>

<style scoped lang="postcss"></style>
