<template lang="pug">
.webinar-modal
    h2.title-with-underline {{ $t('courses.examWebinar') }}
    .webinar-container(v-if='webinar')
        .video-container(v-if='webinar.teaserVideoUrl')
            VideoPlayer(
                :course-id='currentCourseId',
                :poster-image='getPosterImage(webinar.teaserVideoUrl)',
                :video-source='webinar.teaserVideoUrl',
                content-type='course_webinar'
            )
        HTMLRenderer(
            :html='webinar.description || ""',
            :increase-padding='true',
            link-target='_blank'
        )
    .button-wrapper
        KetchUpButton.tertiary(@click.native='openBooking')
            h5(v-if='purchasedCourse') {{ $t('courses.toWebinarDates') }}
            h5(v-else) {{ $t('courses.buyAndAttendWebinar') }}
</template>

<script setup lang="ts">
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import useCourse from '@/composables/useCourse'
  import useVideo from '@/composables/useVideo'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import type { PropType } from 'vue'
  import type { Webinar } from '@/services/interfaces/Course'
  import useShopifyClient from '@/composables/useShopifyClient'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    webinar: {
      type: Object as PropType<Webinar>,
      required: true,
    },
    purchasedCourse: {
      type: Boolean,
      required: true,
    },
  })
  const emit = defineEmits(['close'])
  const { currentCourseId } = useCourse()
  const { getPosterImage } = useVideo()
  const { createShopifyCheckout } = useShopifyClient()
  const { windowOpenProxyCheck } = useCommonMixin()

  const openBooking = async () => {
    if (props.purchasedCourse) {
      windowOpenProxyCheck(props.webinar.bookingUrl, 'webinar')
    } else {
      emit('close')
      await createShopifyCheckout()
    }
  }
</script>

<style lang="postcss">
  .webinar-modal {
    @apply ketch-pb-c30;
    .title-with-underline {
      @apply ketch-pb-c10;
    }
    .webinar-container {
      .video-container {
        @apply ketch-mb-c20;
        .video-player {
          @apply ketch-max-h-[400px];
          .plyr {
            @apply ketch-rounded-large;
            .plyr__controls {
              @apply ketch-pt-c6 sm:ketch-pt-c15;
            }
          }
        }
      }
    }
    .button-wrapper {
      @apply ketch-flex ketch-justify-end ketch-absolute ketch-bottom-c30 ketch-right-c30;
      button {
        @apply ketch-h-[44px] ketch-w-[fit-content];
      }
    }
  }
</style>
