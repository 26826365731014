<template lang="pug">
.followup-lesson-wrapper
  LoadingSpinner(v-if='!currentEditorLesson')
  .followup-lesson(
      data-cy='followup-lesson',
      v-else
  )
      .header-area
          .title-change
              h5.title-with-date(v-if='followUp && followUp.date') 📆 {{ $t('editor.followup.seeYouAgain', { date: formatDate(followUp.date, 'dd.MM.yyyy') }) }}
              h5.title(
                  @click='openFollowupModal',
                  v-else
              ) 📆 {{ $t('editor.followup.title') }}
              h5.change(
                  @click='openFollowupModal',
                  v-if='followUp && followUp.id'
              ) {{ $t('change') }}
          h5.remove(
              @click='deleteFollowup(followUp.lesson.name)',
              v-if='followUp && followUp.id'
          ) {{ $t('editor.followup.remove') }}
      HTMLRenderer.comment(
          :html='followUp.comment',
          v-if='followUp && followUp.comment'
      )
</template>

<script setup lang="ts">
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const { followUp, currentEditorLesson, deleteFollowup } = useEditor()
  const { formatDate } = useCommonMixin()
  const { translateString } = useI18n()

  const openFollowupModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'FollowupModal',
      modalTitle: translateString('editor.followup.modalTitle'),
      cssClass: 'followup-modal',
      modalCloseCallback: (callback: () => void) => {
        const datePicker = document.querySelector('.mx-datepicker-popup')
        if (typeof callback === 'function' && !datePicker) callback()
      },
    })
  }
</script>

<style lang="postcss" scoped>
  .followup-lesson-wrapper {
    .followup-lesson {
      @apply ketch-bg-secondary-color ketch-bg-opacity-5 ketch-rounded-normal ketch-px-c15 ketch-py-c10;
      @apply ketch-border ketch-border-secondary-color ketch-text-secondary-color;
      .header-area {
        @apply ketch-flex ketch-justify-between ketch-items-center;
        .title-change {
          @apply ketch-flex ketch-space-x-c10;
          .title,
          .title-with-date {
            @apply ketch-font-bold;
          }
        }
        h5:not(.title-with-date) {
          @apply hover:ketch-underline ketch-cursor-pointer;
        }
        .change,
        .remove {
          @apply ketch-opacity-50 hover:ketch-opacity-100;
        }
      }
      .comment {
        @apply ketch--mx-c15 ketch-px-c15 ketch-mt-c8 ketch-border-t ketch-border-t-secondary-color;
        @apply ketch-border-opacity-[0.2] ketch-pt-c10;
      }
    }
  }
</style>
