<template lang="pug">
.get-your-own-academy-banner-wrapper(ref='academyBannerElement')
    .get-your-own-academy-banner.component-spacing
        h5(@click='downloadGetYourAcademyDocument') {{ $t('startYourOwnAcademy') }}
        .close-your-own-academy
            SVGRenderer(
                :has-hover='false',
                :icon='closeIcon',
                @click.native='closeGetYourOwnAcademy',
                stroke-color='#ffffff'
            )
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CookieHelper from '@/helpers/CookieHelper'
  import useSegment from '@/composables/useSegment'

  const emit = defineEmits(['hide-banner', 'academy-banner-height'])

  const { closeIcon } = useIcons()
  const { downloadItem } = useCommonMixin()
  const { getYourOwnAcademyDocumentDownloaded, getYourOwnAcademyBannerClosed } = useSegment()
  const academyBannerElement = ref(null as HTMLDivElement | null)

  const downloadGetYourAcademyDocument = () => {
    getYourOwnAcademyDocumentDownloaded()
    downloadItem('https://s3.eu-central-1.amazonaws.com/static.ketchup.academy/marketing/Eigene%2BFinanz-Akademie.pdf')
  }

  const closeGetYourOwnAcademy = () => {
    getYourOwnAcademyBannerClosed()
    emit('hide-banner')
    CookieHelper.setCookieValue('hide-get-your-own-academy-banner', 'true')
  }

  onMounted(() => {
    setTimeout(() => {
      if (academyBannerElement.value) {
        emit('academy-banner-height', academyBannerElement.value.getBoundingClientRect().height)
      }
    }, 300)
  })

  onBeforeUnmount(() => {
    emit('academy-banner-height', 0)
  })
</script>

<style lang="postcss" scoped>
  .get-your-own-academy-banner-wrapper {
    @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center ketch-bg-[#4B2AAD] ketch-py-c5;
    .get-your-own-academy-banner {
      @apply ketch-flex ketch-items-center ketch-justify-between;
      h5 {
        @apply ketch-text-white ketch-font-bold hover:ketch-cursor-pointer hover:ketch-underline;
      }
      .close-your-own-academy {
        @apply ketch-cursor-pointer;
      }
    }
  }
</style>
