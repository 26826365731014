<template lang="pug">
.panel-step-empty-step-content
    .title-description
        h5.title {{ stepEmptyStateTitle }}
        h5.description {{ stepEmptyStateDescription }}
    ProgressBar(
        :actual-value='totalQuestionsAnswered',
        :percentage='totalQuestionsAnsweredPercentage',
        :style='{ width: progressBarWidth }',
        @badge-left-width='badgeLeftWidth = $event',
        show-badge,
        slim-with-no-border,
        v-if='activeStep === 4'
    )
    KetchUpButton.primary(
        @click.native='showStepModal',
        v-else
    )
        h5 {{ stepEmptyStateCTA }}
        SVGRenderer(
            :has-hover='false',
            :icon='arrowRight',
            :stroke-color='"var(--primary-foreground-color)"'
        )
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import ProgressBar from '@/components/common/ProgressBar.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import { UserModule } from '@/store/modules/user'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import eventBus from '@/main'
  import type { PropType } from 'vue'
  import type { GetStartedStepEntity } from '@/services/interfaces/Common'

  const props = defineProps({
    activeStep: {
      type: Number,
      required: true,
    },
    activeStepEntity: {
      type: String as PropType<GetStartedStepEntity>,
      required: true,
    },
    stepEmptyStateTitle: String,
    stepEmptyStateDescription: String,
    totalQuestionsAnsweredPercentage: Number,
    totalQuestionsAnswered: Number,
    stepVideoUrl: String,
    thumbnailTimestamp: Number,
    stepEmptyStateCTA: String,
  })

  const { arrowRight } = useIcons()
  const { companyOnboardingPopupClosed, getStartedVideoModalShown } = useSegment()
  const badgeLeftWidth = ref(0)

  const progressBarWidth = computed(() => {
    return `calc(100% -  ${props.totalQuestionsAnsweredPercentage === 100 ? badgeLeftWidth.value : 0}px`
  })

  const showStepModal = () => {
    getStartedVideoModalShown(props.activeStep, props.activeStepEntity)
    eventBus.$emit('show-modal', {
      modalContentComponent: 'GetStartedOnboardingModal',
      cssClass: 'get-started-onboarding-modal',
      modalProps: {
        videoUrl: props.stepVideoUrl || '',
        thumbnailTimestamp: props.thumbnailTimestamp,
        activeStepEntity: props.activeStepEntity,
      },
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
        companyOnboardingPopupClosed(UserModule.currentCompany!.id)
      },
    })
  }
</script>

<style lang="postcss" scoped>
  .panel-step-empty-step-content {
    @apply ketch-flex ketch-flex-col ketch-h-full;
    .title-description {
      @apply ketch-flex ketch-flex-col ketch-space-y-c6 ketch-mb-c15 md:ketch-mb-c35;
      .title {
        @apply ketch-font-bold;
      }
      .description {
        @apply ketch-opacity-50;
      }
    }
    .progress-bar-wrapper {
      @apply ketch-mb-c25;
    }
    button {
      @apply ketch-w-[fit-content];
    }
  }
</style>
