<script setup lang="ts">
  import CommonApi from '@/services/api/CommonApi'
  import { ref, watch } from 'vue'

  const emit = defineEmits(['selected-country'])

  const selectedCountry = ref('DE')
  const countries = ref([] as { code: string; name: string }[])

  const fetchCountries = () => {
    CommonApi.fetchCountries().then((countriesData: any[]) => {
      countries.value = countriesData
        .map((country) => ({
          code: country.cca2,
          name: country.name.common,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      emit('selected-country', 'DE')
    })
  }

  watch(selectedCountry, (value) => {
    emit('selected-country', value)
  })

  fetchCountries()
</script>

<template lang="pug">
.country-box
    div(:class='`flag-icon flag-icon-${selectedCountry.toLowerCase()}`')
    select(v-model='selectedCountry')
        option(
            :key='country.code',
            :value='country.code',
            v-for='country in countries'
        )
            h5 {{ country.name }}
</template>

<style lang="postcss">
  .country-box {
    @apply ketch-flex ketch-items-center ketch-space-x-c10;
    select {
      @apply ketch-w-[inherit] ketch-bg-transparent ketch-outline-none ketch-h-c40;
    }
  }
</style>
