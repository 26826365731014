<template lang="pug">
.course-exam-timeout-modal
    h3.title-with-underline {{ $t('courses.examSimulation.timesUp') }}
    h5.description {{ $t('courses.examSimulation.examExpiredDescription') }}
    .cta
        KetchUpButton.tertiary(@click.native='toStartPage')
            h5 {{ $t('courses.examSimulation.end') }}
        KetchUpButton.primary.ketch-w-auto(@click.native='toExamResultsPage')
            h5 {{ $t('courses.examSimulation.toTheResults') }}
</template>

<script setup lang="ts">
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'

  const { isKnowledgeCheckCompany } = useCommonMixin()
  const { latestExamCompletionId } = useCourse()
  const router = useRouter()

  const toStartPage = () => {
    router.push({ name: isKnowledgeCheckCompany.value ? 'KnowledgeCheckInfo' : 'ExamStartPage' }).catch(() => {
      return
    })
    eventBus.$emit('close-modal')
  }

  const toExamResultsPage = () => {
    router.push({ name: 'CourseExamResults', params: { completionId: latestExamCompletionId.value! } }).catch(() => {
      return
    })
    eventBus.$emit('close-modal')
  }
</script>

<style lang="postcss" scoped>
  .course-exam-timeout-modal {
    .title-with-underline {
      @apply ketch-pb-c12;
    }
    .cta {
      @apply ketch-mt-c30 ketch-flex ketch-justify-end ketch-space-x-c10;
      button:first-of-type {
        @apply ketch-w-c100;
      }
    }
  }
</style>
