<template lang="pug">
.external-courses
    LoadingSpinner(v-if='!Object.keys(groupedCourses).length')
    template(
        v-else,
        v-for='(courses, key) in groupedCourses'
    )
        .courses(
            :class='key',
            :key='key',
            v-if='courses.length'
        )
            .course-header(@click='setCollapsedState(key)')
                h6
                    span.title {{ headerTitle(key) }}
                    span.number [{{ courses.length }}]
                .svg-wrap(:class='{ collapsed: collapsedStates[key] }')
                    SVGRenderer(
                        :has-hover='false',
                        :icon='arrowDown',
                        :stroke-color='"var(--primary-color)"',
                        width='11'
                    )
            transition(name='menu-slide')
                .courses-container(v-if='!collapsedStates[key]')
                    .course(
                        :class='{ purchased: isPurchasedItem(item), disabled: !item.shopifyProductId }',
                        :key='item.id',
                        @click='buyCourse(item)',
                        v-for='item in courses'
                    )
                        .course-items(:class='{ bundle: key === "bundles" }')
                            h5.title
                                span.package(v-if='key === "bundles"') {{ $t('package') }}:
                                span {{ item.title }}
                            .price-buy
                                h6.price(v-if='productPrice(item)') {{ currencyFormatDE(productPrice(item)) }}
                                .bought(v-if='item.id && isPurchasedCourse(item.id)')
                                    SVGRenderer(
                                        :has-hover='false',
                                        :icon='thickCheckmark',
                                        :stroke-color='"var(--primary-color)"',
                                        width='12'
                                    )
                                    h6 {{ $t('homepage.assignedCourses.bought') }}
                                .buy(v-else) {{ $t('homepage.assignedCourses.buy') }}
                        LastActivity(
                            :last-activity='getUserActivity(item.id).lastActivity',
                            v-if='getUserActivity(item.id)'
                        )
                            h6.progress {{ getUserActivity(item.id).completionPercentage }}%
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import useIcons from '@/composables/useIcons'
  import useCommonMixin from '@/composables/useCommonMixin'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import LastActivity from '@/components/common/LastActivity.vue'
  import eventBus from '@/main'
  import type { PropType } from 'vue'
  import type { CourseItem, CoursePackage } from '@/services/interfaces/Course'
  import type { UserCourseActivity } from '@/services/interfaces/Auth0'
  import useShopifyClient from '@/composables/useShopifyClient'
  import useCourse from '@/composables/useCourse'

  const props = defineProps({
    user: {
      type: Object as PropType<{ id: string; name: string; email: string }>,
      required: true,
    },
    groupedCourses: {
      type: Object as PropType<{ [key: string]: (CourseItem | CoursePackage)[] }>,
      required: true,
    },
    headerTitle: {
      type: Function as PropType<(key: string) => string>,
      required: true,
    },
    isPurchasedItem: {
      type: Function as PropType<(item: CourseItem | CoursePackage) => boolean>,
      required: true,
    },
    isPurchasedCourse: {
      type: Function as PropType<(courseId: string) => boolean>,
      required: true,
    },
    getUserActivity: {
      type: Function as PropType<(courseId?: string) => UserCourseActivity | undefined>,
      required: true,
    },
  })

  const { thickCheckmark, arrowDown } = useIcons()
  const { currencyFormatDE } = useCommonMixin()
  const { createShopifyCheckout } = useShopifyClient()
  const { productPrice } = useCourse()
  const collapsedStates = ref({} as { [key: string]: boolean })

  const buyCourse = (item: CourseItem | CoursePackage) => {
    if (props.isPurchasedItem(item)) return
    createShopifyCheckout(props.user?.email, item.shopifyProductVariantId)
  }

  const setCollapsedState = (key: string) => {
    if (key in collapsedStates.value) {
      collapsedStates.value[key] = !collapsedStates.value[key]
    } else {
      eventBus.$set(collapsedStates.value, key, true)
    }
  }
</script>

<style lang="postcss">
  .external-courses {
    .course {
      &.disabled {
        @apply ketch-opacity-50 ketch-pointer-events-none;
      }
      .price-buy {
        @apply ketch-space-x-c10 ketch-flex ketch-items-center;
        .buy {
          @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-rounded-normal;
          @apply ketch-flex ketch-items-center ketch-h-c20 ketch-px-c10;
        }
        .bought {
          @apply ketch-flex ketch-space-x-c4;
          svg {
            @apply ketch-w-c12;
          }
          h6 {
            @apply ketch-text-primary-color ketch-font-bold;
          }
        }
        .price {
          @apply ketch-font-bold;
        }
      }
    }
  }
</style>
