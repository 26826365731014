<template lang="pug">
.generic-content-container
    .content-section
        .results(data-cy='results')
            .empty-content(v-if='content && !content.length')
                .empty-results(v-if='itemPurpose === "search-result"')
                    .empty-search
                        .copy
                            h3.ketch-font-bold {{ $t('search.notFoundText') }}
                            h3 {{ $t('search.sorryText') }}
                        img(:src='search404')
                .empty-bookmarks(v-if='itemPurpose === "bookmarks"')
                    h3.ketch-font-bold {{ $t('emptyBookmark') }}
            CourseSearchItem(
                :key='`${index}-${result.id}`',
                :search-item='result',
                v-else,
                v-for='(result, index) in slicedResults'
            )
        .load-more-pagination(v-if='offset < itemSize')
            span(@click='offset += limit') {{ $t('loadMore') }} ( {{ itemSize - offset }} )
</template>

<script setup lang="ts">
  import { inject, computed, onMounted, ref } from 'vue'
  import CourseSearchItem from '@/components/course/CourseSearchItem.vue'
  import useIcons from '@/composables/useIcons'
  import type { PropType } from 'vue'
  import type { CourseSearchItem as ContentItem } from '@/services/interfaces/Course'

  const props = defineProps({
    content: {
      type: Array as PropType<ContentItem[]>,
      required: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
  })

  const { search404 } = useIcons()
  const itemPurpose = inject('itemPurpose')
  const offset = ref(props.limit)

  const slicedResults = computed((): ContentItem[] => {
    if (!props.content) return []
    return props.content.slice(0, offset.value)
  })

  const itemSize = computed((): number => {
    return props.content?.length || 0
  })

  onMounted(() => {
    offset.value = props.limit
  })
</script>

<style lang="postcss">
  .generic-content-container {
    @apply ketch-flex ketch-flex-col md:ketch-flex-row;
    .content-section {
      .results {
        @apply ketch-space-y-c40 md:ketch-space-y-c50 ketch-pb-c50;
      }
    }
  }
</style>
