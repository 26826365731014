<template lang="pug">
.lesson-video-content(v-if='currentLesson.type === "Video"')
  VideoOrImageRenderer(
    :file-type='""',
    :thumbnail-timestamp='currentLesson.thumbnailTimestamp',
    :video-url='currentLesson.videoUrl',
    @video-url='onVideoUpload',
    editor-property='videoUrl',
    editor-state='currentLesson',
    video-only
  )
  VideoTranscript(:video-transcript='currentLesson.videoTranscript || ""')
  DurationInput.editor(
    :edit-subtext='$t("editor.timestampInputEditSubtext")',
    :edit-title='$t("editor.timestampInputEditTitle")',
    :key='currentLesson.thumbnailTimestamp',
    :value='currentLesson.thumbnailTimestamp || 0',
    duration-calibration='sec',
    editor-property='thumbnailTimestamp',
    editor-state='currentLesson',
    v-if='inEditorMode'
  )
.lesson-single-image-content(v-else-if='currentLesson.downloadables && currentLesson.type === "Downloadable"')
  VideoOrImageRenderer(
    :can-edit='inEditorMode',
    :has-image-shadow='true',
    :image-url='downloadTeaserImageUrl',
    @download-document='$emit("download-document")',
    editor-property='downloadTeaserImageUrl',
    editor-state='lessonDownloadable',
    file-type='lesson_downloadable',
    image-only,
    track-image-data
  )
  EditDownloadableDownloadUrl(
    :download-url='lessonDownloadable.downloadUrl || ""',
    editor-property='downloadUrl',
    editor-state='lessonDownloadable',
    v-if='inEditorMode'
  )
  AuthorAudioSection(
    :audio-author='lessonDownloadable.audioAuthor',
    :audio-source='lessonDownloadable.audioUrl',
    v-if='lessonDownloadable.audioUrl && !inEditorMode'
  )
  AudioAuthor(
    :add-author='addDownloadableAuthor',
    :audio-url='lessonDownloadable.audioUrl || ""',
    :author-id='lessonDownloadableAuthorId',
    :remove-author='removeDownloadableAudioAuthor',
    :set-audio-url='setDownloadableAudioUrl',
    v-else-if='inEditorMode'
  )
  EditLessonInCourseDownloadables(v-if='inEditorMode')
TextRenderer.lesson-text-content(
  :add-image-shadow='true',
  :allow-edit='inEditorMode ? "yes" : "no"',
  :edit-title='$t("content")',
  :format-content='formatContent',
  :source='currentLesson.content || ""',
  editor-property='content',
  editor-state='currentLesson',
  v-else-if='currentLesson.type === "Text"'
)
LessonSlideshow(
  :format-content='formatContent',
  :one-pager='currentLesson.isOnePager || false',
  :slides='currentLesson.slideShow',
  v-else-if='!inEditorMode && currentLesson.type === "SlideShow" && currentLesson.slideShow'
)
LessonSlideshowEditor(
  v-else-if='currentEditorLesson && currentEditorLesson.type === "SlideShow" && currentEditorLesson.slideShow'
)
QuestionWithButtons(
  :questions='quizQuestions',
  :retain-only-current-question-answer='false',
  :submit-button-text='$t("quiz.questionQuizModal.completeTestButtonText")',
  @submit='submitQuizAnswers',
  v-else-if='currentLesson.type === "Quiz" && currentLesson.quiz && currentLesson.quiz.hasQuestions && !inEditorMode'
)
LessonScript(
  :content='currentLesson.content',
  v-else-if='currentLesson.type === "Script" && currentLesson.content && !inEditorMode'
)
TextRenderer(
  :allow-edit='"yes"',
  :edit-title='$t("content")',
  :format-content='formatContent',
  :show-text-input='true',
  :source='currentLesson.content || ""',
  editor-property='content',
  editor-state='currentLesson',
  v-else-if='currentLesson.type === "Script" && inEditorMode'
)
TableLesson(
  :content='currentLesson.content',
  :format-content='formatContent',
  v-else-if='currentLesson.type === "Table" && currentLesson.content && !inEditorMode'
)
TextRenderer(
  :allow-edit='"yes"',
  :edit-title='$t("content")',
  :show-text-input='true',
  :source='currentLesson.content || ""',
  editor-property='content',
  editor-state='currentLesson',
  v-else-if='currentLesson.type === "Table" && inEditorMode'
)
AudioLesson(
  :audio-files='currentLesson.audioFiles',
  :content='currentLesson.content || ""',
  :format-content='formatContent',
  v-else-if='!inEditorMode && currentLesson.type === "Audio" && currentLesson.audioFiles'
)
AudioLessonEditor(v-else-if='inEditorMode && currentLesson.type === "Audio"')
.lesson-profile-content(v-else-if='currentLesson.type === "Profile" && (currentLesson.content || inEditorMode)')
  VideoOrImageRenderer(
    :can-edit='inEditorMode',
    :has-image-shadow='true',
    :image-url='currentLesson.profileImageUrl || ""',
    editor-property='profileImageUrl',
    editor-state='currentLesson',
    file-type='lesson_profile',
    image-only,
    track-image-data
  )
  TextRenderer(
    :add-image-shadow='true',
    :allow-edit='inEditorMode ? "yes" : "no"',
    :edit-title='$t("content")',
    :format-content='formatContent',
    :source='currentLesson.content || ""',
    editor-property='content',
    editor-state='currentLesson'
  )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import AudioLesson from '@/components/course/AudioLesson.vue'
  import TableLesson from '@/components/course/TableLesson.vue'
  import LessonSlideshow from '@/components/course/LessonSlideshow.vue'
  import AuthorAudioSection from '@/components/course/AuthorAudioSection.vue'
  import QuestionWithButtons from '@/components/common/QuestionWithButtons.vue'
  import LessonScript from '@/components/course/LessonScript.vue'
  import CourseApi from '@/services/api/CourseApi'
  import useCourse from '@/composables/useCourse'
  import useSegment from '@/composables/useSegment'
  import useGamification from '@/composables/useGamification'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import { EditorModule } from '@/store/modules/editor'
  import useEditor from '@/composables/useEditor'
  import DurationInput from '@/components/common/DurationInput.vue'
  import VideoTranscript from '@/components/course/VideoTranscript.vue'
  import LessonSlideshowEditor from '@/components/course/LessonSlideshowEditor.vue'
  import AudioLessonEditor from '@/components/course/AudioLessonEditor.vue'
  import EditDownloadableDownloadUrl from '@/components/editor/EditDownloadableDownloadUrl.vue'
  import EditLessonInCourseDownloadables from '@/components/editor/EditLessonInCourseDownloadables.vue'
  import AudioAuthor from '@/components/editor/AudioAuthor.vue'
  import { useRoute, useRouter } from 'vue-router/composables'
  import type { PropType } from 'vue'
  import type { Lesson, LessonDownloadable } from '@/services/interfaces/Course'
  import type { QuestionAnswer } from '@/services/interfaces/QuizQuestion'

  const props = defineProps({
    currentLesson: {
      required: true,
      type: Object as PropType<Lesson>,
    },
    courseId: {
      required: true,
      type: String,
    },
    moduleId: {
      required: true,
      type: String,
    },
    formatContent: {
      type: Boolean,
      default: false,
    },
    canEdit: Boolean,
  })
  defineEmits(['download-document'])
  const route = useRoute()
  const router = useRouter()
  const { quizId, completeALesson, lessonId, course, currentModule } = useCourse()
  const { lessonQuizCompleted } = useSegment()
  const { trackGamificationActivity } = useGamification()
  const { inEditorMode: editorMode, currentEditorLesson } = useEditor()

  const inEditorMode = computed(() => editorMode.value && props.canEdit)

  const quizQuestions = computed(() => {
    return props.currentLesson?.quiz?.questions ?? []
  })

  const lessonDownloadable = computed(() =>
    inEditorMode.value ? EditorModule.lessonDownloadable : props.currentLesson.downloadables,
  )

  const downloadTeaserImageUrl = computed(() => lessonDownloadable.value?.downloadTeaserImageUrl || '')

  const lessonDownloadableAuthorId = computed(() => lessonDownloadable.value?.audioAuthor?.id ?? '')

  const submitQuizAnswers = (answers: QuestionAnswer[]) => {
    CourseApi.postLessonQuizCompletion(props.courseId, props.moduleId, lessonId.value, answers, 'completed')
      .then((resp) => {
        lessonQuizCompleted(
          quizId.value,
          resp.passed,
          resp.completionId,
          props.courseId,
          course.value!.title,
          props.moduleId,
          currentModule.value.name,
        )
        if (resp.passed) {
          trackGamificationActivity('LESSON_QUIZ_PASSED', {
            courseId: props.courseId,
            moduleId: props.moduleId,
            lessonId: lessonId.value,
          })
          completeALesson(lessonId.value, resp.completionId)
        }
        router
          .push({
            name: 'LessonQuizResults',
            query: { completionId: resp.completionId },
          })
          .catch(() => {
            return
          })
      })
      .finally(() => {
        trackGamificationActivity('LESSON_QUIZ_COMPLETED', {
          courseId: props.courseId,
          moduleId: props.moduleId,
          lessonId: lessonId.value,
        })
      })
  }

  const onVideoUpload = (url: string) => {
    EditorModule.setEditableState({
      key: 'currentLessonvideoUrl',
      path: route.path,
      state: 'currentLesson',
      property: 'videoUrl',
      value: url,
    })
  }

  const onLessonDownloadableChange = (property: keyof LessonDownloadable, value: any) => {
    EditorModule.setEditableState({
      key: `lessonDownloadable${property}`,
      path: route.path,
      state: 'lessonDownloadable',
      property: property,
      value: value,
    })
  }

  const removeDownloadableAudioAuthor = () => {
    onLessonDownloadableChange('audioAuthor', null)
  }

  const addDownloadableAuthor = (authorId: string) => {
    const selectedAuthor = EditorModule.editorAllAuthors!.find((author) => author.id === authorId)
    if (selectedAuthor) {
      onLessonDownloadableChange('audioAuthor', selectedAuthor)
    }
  }

  const setDownloadableAudioUrl = (url: string) => {
    onLessonDownloadableChange('audioUrl', url)
  }
</script>

<style lang="postcss" scoped>
  .question-with-buttons {
    @apply ketch-mt-c75;
  }
  .lesson-single-image-content {
    @apply ketch-space-y-c30;
  }
  .lesson-table-content {
    @apply ketch-pb-c20;
  }
  .lesson-video-content {
    @apply ketch-space-y-c30;
  }
  .lesson-profile-content {
    .video-or-image-renderer {
      @apply ketch-pb-c20;
      img {
        @apply ketch-max-w-[200px] ketch-max-h-[40px];
      }
    }
  }
</style>
