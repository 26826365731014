<template lang="pug">
.video-or-image-renderer
    MediaUploader(
        :file-type='fileType',
        :image-only='imageOnly',
        :video-only='videoOnly',
        @image-file='onImageUpload',
        @video-url='onVideoUpload',
        v-if='inEditorMode && !hasImageOrVideoUrl'
    )
    template(v-else)
        .top-header(v-if='inEditorMode && hasImageOrVideoUrl')
            h6(@click='onRemoveTeaser') {{ $t('editor.removeTeaser') }}
        .teaser-module-video(v-if='contentVideoUrl')
            VideoPlayer(
                :content-type='"course_module"',
                :course-id='currentCourseId',
                :key='contentVideoUrl',
                :lesson-id='lessonId',
                :module-id='moduleId',
                :poster-image='getPosterImage(contentVideoUrl, thumbnailTimestamp)',
                :video-source='contentVideoUrl'
            )
        .teaser-image(v-else-if='contentImageUrl')
            .download-info(v-if='fileType === "lesson_downloadable" && !inEditorMode')
                h5(v-html='$t("courses.downloadToViewDocument")')
            .document-image-cta-wrapper(
                @mouseleave='hoveringDownloadDocument(false)',
                @mouseover='hoveringDownloadDocument(true)'
            )
                img(
                    :class='{ "ketch-shadow-content-image-shadow": hasImageShadow }',
                    :src='contentImageUrl'
                )
                transition(name='fade')
                    .download-document-cta-wrapper(
                        @click='$emit("download-document")',
                        v-if='hoveringImage && !inEditorMode'
                    )
                        .download-cta
                            SVGRenderer(
                                :has-hover='false',
                                :height='isMobileDevice ? "45px" : "95px"',
                                :icon='download',
                                :width='isMobileDevice ? "45px" : "95px"',
                                fill-color='var(--primary-text-color)'
                            )
                            h5 Download
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import VideoPlayer from '@/components/VideoPlayer.vue'
  import MediaUploader from '@/components/common/MediaUploader.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useVideo from '@/composables/useVideo'
  import useEditor from '@/composables/useEditor'
  import useCourse from '@/composables/useCourse'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { EditorModule } from '@/store/modules/editor'
  import { CourseModule } from '@/store/modules/course'
  import { useRoute } from 'vue-router/composables'
  import type { PropType } from 'vue'
  import type {
    CourseModule as CourseModuleType,
    EditorSlide,
    EditorStatePayload,
    Lesson,
    LessonDownloadable,
    LessonQuestion,
  } from '@/services/interfaces/Course'
  import type { Nullable } from '@/services/interfaces/Content'

  const props = defineProps({
    fileType: {
      required: true,
      type: String,
    },
    videoOnly: {
      type: Boolean,
      default: false,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    trackImageData: {
      type: Boolean,
      default: false,
    },
    hasImageShadow: {
      type: Boolean,
      default: false,
    },
    videoUrl: String,
    imageUrl: String,
    thumbnailTimestamp: Number,
    canEdit: {
      default: true,
      type: Boolean,
    },
    editorState: String as PropType<'currentLesson' | 'currentModule' | 'lessonDownloadable'>,
    editorProperty: String as PropType<
      keyof Lesson | keyof CourseModuleType | keyof LessonQuestion | keyof EditorSlide | keyof LessonDownloadable
    >,
    mutateAndQueueFunc: Function as PropType<(data: EditorStatePayload) => void>,
  })

  const emit = defineEmits([
    'remove-video-url',
    'remove-image-url',
    'image-data',
    'video-url',
    'uploaded-image',
    'download-document',
  ])

  const route = useRoute()
  const { getPosterImage } = useVideo()
  const { currentCourseId, moduleId, lessonId, currentModule, currentLesson } = useCourse()
  const { inEditorMode: editorMode } = useEditor()
  const { download } = useIcons()
  const { isMobileDevice } = useBreakpoint()
  const imageUploadPreviewUrl = ref(props.imageUrl || '')
  const imageUploadData = ref('')
  const uploadVideoUrl = ref(props.videoUrl || '')
  const hoveringImage = ref(false)

  const inEditorMode = computed(() => editorMode.value && props.canEdit)

  const hasImageOrVideoUrl = computed(() => {
    return !!(contentVideoUrl.value || contentImageUrl.value)
  })

  const contentVideoUrl = computed(() => {
    if (inEditorMode.value) return uploadVideoUrl.value
    return props.videoUrl
  })

  const contentImageUrl = computed(() => {
    if (inEditorMode.value) return imageUploadPreviewUrl.value
    return props.imageUrl
  })

  const onRemoveTeaser = () => {
    if (contentVideoUrl.value) {
      emit('remove-video-url')
      uploadVideoUrl.value = ''
    } else if (contentImageUrl.value) {
      emit('remove-image-url')
      imageUploadPreviewUrl.value = ''
    }
  }

  const onImageUpload = (imageData: string, previewUrl: string) => {
    emit('image-data', imageData)
    emit('uploaded-image')
    imageUploadPreviewUrl.value = previewUrl
    imageUploadData.value = imageData
  }

  const onVideoUpload = (url: string) => {
    emit('video-url', url)
    uploadVideoUrl.value = url
  }

  const hoveringDownloadDocument = (hovering: boolean) => {
    if (props.fileType === 'lesson_downloadable' && !inEditorMode.value) {
      hoveringImage.value = hovering
    }
  }

  const discardChangesCallback = (stateBeforeChange?: {
    currentLesson: Nullable<Lesson>
    currentModule: Nullable<CourseModuleType>
    lessonDownloadable: Nullable<LessonDownloadable>
  }) => {
    if (uploadVideoUrl.value) {
      uploadVideoUrl.value = (stateBeforeChange?.[props.editorState!] as any)?.[props.editorProperty!]
    } else if (imageUploadPreviewUrl.value) {
      imageUploadPreviewUrl.value = (stateBeforeChange?.[props.editorState!] as any)?.[props.editorProperty!]
    }
  }

  const updateStateModel = (data: EditorStatePayload) => {
    if (uploadVideoUrl.value) {
      uploadVideoUrl.value = data.value as string
    } else if (imageUploadPreviewUrl.value) {
      imageUploadPreviewUrl.value = data.value as string
    }
  }

  const uploadVideoUrlOrImageUploadPreviewUrl = (url: string) => {
    if (props.editorProperty && props.editorState) {
      const stateValue =
        (props.trackImageData &&
          (props.editorProperty === 'teaserImageUrl' ||
            props.editorProperty === 'profileImageUrl' ||
            props.editorProperty === 'imageUrl')) ||
        props.editorProperty === 'downloadTeaserImageUrl'
          ? {
            url,
            imageData: imageUploadData.value,
          }
          : url
      EditorModule.setEditableState({
        key: `${props.editorState}${props.editorProperty}`,
        path: route.path,
        state: props.editorState,
        property: props.editorProperty,
        value: stateValue,
        mutateAndQueue: props.mutateAndQueueFunc,
      })
    }
  }

  watch(
    () => props.imageUrl,
    (url) => {
      imageUploadPreviewUrl.value = url as string
    },
  )

  watch(imageUploadPreviewUrl, (url) => {
    uploadVideoUrlOrImageUploadPreviewUrl(url)
  })

  watch(
    () => props.videoUrl,
    (url) => {
      uploadVideoUrl.value = url as string
    },
  )

  watch(uploadVideoUrl, (url) => {
    uploadVideoUrlOrImageUploadPreviewUrl(url)
  })

  onMounted(() => {
    EditorModule.subscribe({
      type: 'discard',
      key: `${props.editorState}${props.editorProperty}`,
      callback: () =>
        discardChangesCallback({
          currentModule: currentModule.value as CourseModuleType,
          currentLesson: currentLesson.value,
          lessonDownloadable: CourseModule.currentLesson?.downloadables ?? null,
        }),
    })
    EditorModule.subscribe({
      type: 'save',
      key: `${props.editorState}${props.editorProperty}`,
      callback: updateStateModel,
    })
  })

  onBeforeUnmount(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: discardChangesCallback })
  })
</script>

<style lang="postcss" scoped>
  .video-or-image-renderer {
    .top-header {
      @apply ketch-flex ketch-justify-end;
      h6 {
        @apply ketch-text-editor-primary-color hover:ketch-underline ketch-cursor-pointer;
      }
    }
    .teaser-image {
      .download-info {
        @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-px-c10 ketch-py-c5;
      }
      .document-image-cta-wrapper {
        @apply ketch-relative;
        img {
          @apply ketch-w-full;
        }
        .download-document-cta-wrapper {
          @apply ketch-absolute ketch-top-0 ketch-right-0 ketch-bottom-0 ketch-left-0 ketch-cursor-pointer;
          @apply ketch-bg-black ketch-bg-opacity-[20%] ketch-flex ketch-items-center ketch-justify-center;
          .download-cta {
            @apply ketch-w-[110px] ketch-h-[110px] ketch-rounded-full ketch-bg-white;
            @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-space-y-c15;
            @apply md:ketch-w-[195px] md:ketch-h-[195px];
          }
        }
      }
    }
  }
</style>
