<template lang="pug">
.slider-wrapper
    swiper.swiper(:options='swiperOptions')
        swiper-slide(
            :key='item.id',
            v-for='item in swiperItems'
        )
            slot(
                :item='item',
                name='slide-slot'
            )
    slot(name='slide-nav')
</template>

<script setup lang="ts">
  import { reactive } from 'vue'
  import type { PropType } from 'vue'
  //@ts-ignore
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import useBreakpoint from '@/composables/useBreakpoint'

  const props = defineProps({
    slidesPerView: {
      required: true,
      type: Number,
    },
    slidesPerGroup: {
      required: true,
      type: Number,
    },
    spaceBetween: {
      type: Number,
      default: 20,
    },
    swiperItems: {
      required: true,
      type: Array as PropType<any[]>,
    },
    navClass: {
      type: String,
      default: '',
    },
    breakPoints: {
      type: Object as PropType<{
        [key: string]: { slidesPerView: string; spaceBetween: string; slidesPerGroup: string }
      }>,
    },
  })

  const { isMobileDevice } = useBreakpoint()
  const swiperOptions = reactive({
    breakpoints: props.breakPoints,
    slidesPerView: props.slidesPerView,
    spaceBetween: props.spaceBetween,
    slidesPerGroup: props.slidesPerGroup,
    allowTouchMove: isMobileDevice.value,
    navigation: {
      prevEl: `.${props.navClass} .prev-nav-button`,
      nextEl: `.${props.navClass} .next-nav-button`,
    },
  })
</script>

<style lang="postcss">
  .slider-wrapper {
    @apply ketch-relative;
    .swiper {
      @apply ketch-w-full;
    }
    .navigation {
      img {
        @apply ketch-h-c24 ketch-w-c12;
      }
      .prev-nav-button::after,
      .next-nav-button::after {
        content: none;
      }
      .prev-nav-button,
      .next-nav-button {
        @apply ketch-absolute ketch-top-1/2;
        &.swiper-button-disabled {
          @apply ketch-hidden;
        }
      }
      .prev-nav-button,
      .next-nav-button {
        @apply ketch-h-full ketch-top-0 ketch-flex ketch-items-center;
      }
      .prev-nav-button {
        left: -25px;
        img {
          filter: invert(1);
          &:hover {
            filter: drop-shadow(-4px 0px 3px white) invert(1);
          }
        }
      }
      .next-nav-button {
        right: -25px;
        img {
          filter: invert(1);
          &:hover {
            filter: drop-shadow(4px 0px 3px white) invert(1);
          }
        }
      }
    }
  }
  .ketch-dark .slider-wrapper {
    .navigation {
      .prev-nav-button {
        img {
          filter: none;
          &:hover {
            filter: drop-shadow(-4px 0px 3px white);
          }
        }
      }
      .next-nav-button {
        img {
          filter: none;
          &:hover {
            filter: drop-shadow(4px 0px 3px white);
          }
        }
      }
    }
  }
</style>
