<script setup lang="ts">
  import { computed } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import { DateTime } from 'luxon'

  const iddMinutesCounter = computed(() => UserModule.currentIddMinutesCount)

  const currentYear = computed(() => DateTime.now().year)
</script>

<template lang="pug">
.idd-minutes-widget
  h6.idd-minutes {{ $t('navigation.iddYearMinutes', { year: currentYear }) }}:
  h5 {{ iddMinutesCounter }}min
</template>

<style scoped lang="postcss">
  .idd-minutes-widget {
    @apply ketch-flex ketch-flex-col ketch-p-c15;
    .idd-minutes {
      @apply ketch-font-bold;
    }
  }
</style>
