<template lang="pug">
.maintenance-banner-wrapper(ref='maintenanceBannerElement')
  .maintenance-banner.component-spacing
    .maintenance-icon
      SVGRenderer(
        :fill-color='"var(--primary-foreground-color)"',
        :has-hover='false',
        :icon='warning',
        height='30',
        width='33'
      )
    .maintenance-details
      h4 {{ maintenanceBannerTitle }}
      h6.maintenance-description {{ maintenanceBannerDescription }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, nextTick } from 'vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'

  const emit = defineEmits(['maintenance-banner-height'])

  const { warning } = useIcons()

  const maintenanceBannerElement = ref(null as HTMLDivElement | null)

  const maintenanceBannerTitle = computed(() => process.env.VUE_APP_MAINTENANCE_BANNER_TITLE)

  const maintenanceBannerDescription = computed(() => process.env.VUE_APP_MAINTENANCE_BANNER_DESCRIPTION)

  onMounted(() => {
    nextTick(() => {
      if (maintenanceBannerElement.value) {
        emit('maintenance-banner-height', maintenanceBannerElement.value.getBoundingClientRect().height)
      }
    })
  })

  onBeforeUnmount(() => {
    emit('maintenance-banner-height', 0)
  })
</script>

<style lang="postcss" scoped>
  .maintenance-banner-wrapper {
    @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center ketch-bg-primary-color;
    .maintenance-banner {
      @apply ketch-py-c10 ketch-flex ketch-items-center ketch-space-x-c15;
      > * {
        @apply ketch-text-primary-foreground-color;
      }
      .maintenance-icon {
        @apply ketch-max-h-[30px];
      }
      .maintenance-details {
        @apply ketch-flex ketch-flex-col;
        .maintenance-description {
          @apply ketch-opacity-70;
        }
      }
    }
  }
</style>
