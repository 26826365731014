<template lang="pug">
.course-layout
    CourseBreadcrumbs(
        :is-searching='!!searchTerm',
        v-if='!$route.meta.hideCourseBreadcrumb && !(isLargeDesktop && inEditorMode)'
    )
    router-view(:search-term='searchTerm')
</template>

<script setup lang="ts">
  import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
  import CourseBreadcrumbs from '@/components/course/CourseBreadcrumbs.vue'
  import useToursAndHints from '@/composables/useToursAndHints'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useEditor from '@/composables/useEditor'
  import useCourse from '@/composables/useCourse'
  import { UserModule } from '@/store/modules/user'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const route = useRoute()
  const router = useRouter()
  const { isDesktopDevice, isLargeDesktop } = useBreakpoint()
  const { inEditorMode } = useEditor()
  const { showOrHideToursAndHints, hideAllHints } = useToursAndHints()
  const { course } = useCourse()
  const { translateString } = useI18n()

  const searchTerm = ref('')

  const showingCompanyOnboardingPopup = computed(() => UserModule.company?.showCompanyPopup)

  const _showOrHideToursAndHints = () => {
    nextTick(() => {
      if (isDesktopDevice.value) showOrHideToursAndHints()
      eventBus.$emit('reset-background-tile-height')
    })
  }

  watch(course, (value) => {
    if (value) {
      if (!course.value?.visibleToGroup && !UserModule.isCompanyEditor) {
        eventBus.$toasted.error(translateString('noAccessToCourse'))
        router.push({ name: 'Trainings' }).catch(() => {
          return
        })
      }
    }
  })

  watch(
    () => route.path,
    () => {
      hideAllHints()
      _showOrHideToursAndHints()
    },
  )

  watch(showingCompanyOnboardingPopup, (value) => {
    if (!value) {
      _showOrHideToursAndHints()
    }
  })

  onMounted(() => {
    eventBus.$on('course-search-term', (term: string) => {
      searchTerm.value = term
    })
    if (isDesktopDevice.value && !showingCompanyOnboardingPopup.value) {
      showOrHideToursAndHints()
    }
  })

  onUnmounted(() => hideAllHints())
</script>
